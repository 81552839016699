import { FC, useContext, useState } from 'react'
import { Button, Flex, Link, useDisclosure, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import TextInput from '../../../FormElements/TextInput'
import { CreditCheckValidation } from '../../../../formValidation/Business' //MUST Make another validation form
import PhoneInput from '../../../FormElements/PhoneInput'
import SubmitButton from '../../../FormElements/SubmitButton'
import axios from 'axios'
import { useNavigate } from 'react-router'
import DropDownInput from '../../../FormElements/DropDownInput'
import { BUSINESS_ENTITY_TYPES, BUSINESS_INDUSTRIES } from '../../../../constants'
import BackButton from '../../../FormElements/BackButton'
import ConnectedIDNumberInput from '../../../FormElements/ConnectedIDNumberInput'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import { DefaultModal } from '../../../Modals'
import ModalDialog from '../../../Modals/ModalDialog'
import ContainerHeading from '../../../Headings/ContainerHeading'
import { useUpdateDynamoUserMutation } from '../../../../generated/graphql'
import { useAuthContext } from '../../../../context/AuthProvider'

type InitialValuesType = {
  businessName: string
  registeredBusinessNumber: string
  registeredDate: string
  businessIndustry: string
  tradingName: string
  vatNumber: string
  businessDesignation: string
  firstName: string
  lastName: string
  email: string
  contactNumber: string
  idPassportNumber: string
  recordId: string | null
  // dateInput: string
}
const OnboardingCreditCheck: FC<InitialValuesType> = ({
  businessName,
  registeredBusinessNumber,
  registeredDate,
  businessIndustry,
  tradingName,
  vatNumber,
  firstName,
  lastName,
  contactNumber,
  email,
  businessDesignation,
  idPassportNumber,
  recordId
}) => {
  const InitialValuesType = {
    businessName: businessName,
    registeredBusinessNumber: registeredBusinessNumber,
    registeredDate: registeredDate,
    businessIndustry: businessIndustry,
    tradingName: tradingName,
    vatNumber: vatNumber,
    firstName: firstName,
    lastName: lastName,
    contactNumber: contactNumber,
    email: email,
    businessDesignation: businessDesignation,
    idPassportNumber: idPassportNumber,
    recordId: recordId,
  }
  const { handleNextStepIndex, handlePrevStepIndex, handleSetFormData, formData } =
    useContext(OnboardingFormContext)

  //credit check modals constants
  const {
    isOpen: isPassedModalOpen,
    onOpen: openPassedModal,
    onClose: closePassedModal
  } = useDisclosure()
  const {
    isOpen: isFailedModalOpen,
    onOpen: openFailedModal,
    onClose: closeFailedModal
  } = useDisclosure()
  const {
    isOpen: isOnHoldModalOpen,
    onOpen: openOnHoldModal,
    onClose: closeOnHoldModal
  } = useDisclosure()

  const successLinkNavigation: string = `/user/onboarding/documents/${recordId}`
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  //   This will be use to navigate the user to next page
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const handleSubmit = async (values: InitialValuesType) => {
    const data = { ...values }
    if (data) {
      
      try {
        await updateDynamoUser({
          variables: {
            email: user?.info?.email as string,
            input: {
              onboardingProgressCompletedStep: 7.1, 
            }
          }
        })
        await axios
          .put(process.env.REACT_APP_API_HOST + '/put-business-credit-check', data)
          .then(function (response) {
            navigate('../business-documents/' + recordId)
          })
          .catch(function (error) {
          }) //then function returns endpoints response
      }
      catch (e) { }
    }
  }

  const handleRegistrationNumberChange = (values: InitialValuesType) => { }

  return (
    <Flex w="full" maxW={'650px'} gap="25px" direction={'column'} color={'brand.text.primary'}>
      <Flex
        w="full"
        gap="25px"
        direction={'column'}
        p={'25px'}
        rounded="8px"
        bgColor={'brand.neutral'}
        h={'full'}
        shadow={'md'}
      >
        <ContainerHeading title={'Business credit check'} />
        <Formik
          initialValues={InitialValuesType}
          validateOnMount
          validateOnBlur={false}
          enableReinitialize
          validationSchema={CreditCheckValidation}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, setFieldValue }) => {
            return (
              <Form>
                <Flex
                  width="full"
                  justifyContent="space-between"
                  alignItems={'start'}
                  direction={'column'}
                  gap={15}
                  bgColor={'brand.neutral'}
                  p={25}
                >
                  <Flex direction={'column'} w="full" gap={15} justifyContent="space-between">
                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                      <TextInput
                        name="businessName"
                        label="Registered Business Name"
                        placeholder="Business Name"
                      />
                      <TextInput
                        name="registeredBusinessNumber"
                        label="Registered Business Number"
                        placeholder="Registered Business Number"
                      />
                    </Flex>
                  
                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                      <TextInput
                        name="tradingName"
                        label="Trading Name"
                        placeholder="Trading Name"
                      />
                      <TextInput name="vatNumber" label="VAT Number" placeholder="Vat Number" />
                    </Flex>
                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                      {/* <DateInput name="dateInput" label="Registered Date" setFieldValue={setFieldValue} /> */}
                      {/* <DropDownInput
                        name="businessEntitiyType"
                        label="Business Entity Type"
                        options={BUSINESS_ENTITY_TYPES}
                        placeholder={'Business Entity'}
                      /> */}
                      <DropDownInput
                        name="businessIndustry"
                        label="Business Industry"
                        options={BUSINESS_INDUSTRIES}
                        placeholder={'Business Industry'}
                      />
                       <TextInput
                        name="businessDesignation"
                        label="Business Designation"
                        placeholder="Business Designation"
                      />
                    </Flex>
                  </Flex>
                  <br></br>
                  <Flex direction={'column'} w="full" gap={'25px'}>
                    <div>Personal Information</div>
                  </Flex>
                  <Flex direction={'column'} w="full" gap={'15px'}>
                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                      <TextInput name="firstName" label="First name" placeholder="First name" />
                      <TextInput name="lastName" label="Last name" placeholder="Last name" />
                    </Flex>
                    <Flex gap={'25px'} direction={'column'} w="full">
                      <PhoneInput
                        name="contactNumber"
                        label="Phone Number"
                        placeholder="Phone number"
                      />
                      {/* <TextInput name="email" label="Email" placeholder="Email Address" /> */}
                    </Flex>
                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                     
                      {/* <ConnectedIDNumberInput
                        name="idPassportNumber"
                        label="ID Number/Passport"
                        placeholder="ID Number/Passport"
                      /> */}
                    </Flex>
                    

                    <Flex justifyContent={'space-between'}>
                      <BackButton
                        callback={() => {
                          navigate('../credit-type/' + recordId)
                        }}
                      />
                      <SubmitButton />
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            )
          }}
        </Formik>

        {/* Modals for showing feedback from Experian credit checks */}
        <ModalDialog
          status="success"
          title="Credit check passed"
          description="Congratulations! Your credit validation check has passed our criteria."
          isOpen={isPassedModalOpen}
          onClose={closePassedModal}
          successLink={successLinkNavigation}
          buttonTextSuccess="Next"
          buttonTextError="Cancel"
          hasCloseIcon={false}
        />

        <DefaultModal
          heading={'Credit check requires further investigation'}
          isOpen={isOnHoldModalOpen}
          onClose={closeOnHoldModal}
        >
          <Text textAlign={'justify'} color={'brand.text.primary'}>
            Unfortunately we are unable to proceed with your application at this time, as the credit
            rating was unsuccessful (below the required risk). However, you may request a review
            with one of your specialists. Our financial consultant may be able to suggest options to
            meet our credit risk requirements so you will be able to complete the application.
          </Text>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Feel free to contact us at{' '}
            <Link href={`mailto:hello@gosolr.co.za?subject=Support%20Request`} color={'#3b82f6'}>
              hello@gosolr.co.za
            </Link>{' '}
            or give us a call at{' '}
            <Link href={`tel:0108803948`} color={'#3b82f6'}>
              010 880 3948
            </Link>{' '}
            if you require more assistance. We will try our best to help resolve this issue for you.
          </Text>
          <Button alignSelf={'flex-middle'} variant={'dark'} onClick={closeOnHoldModal}>
            Okay
          </Button>
        </DefaultModal>

        <DefaultModal
          heading={'Retrieving credit check failed'}
          isOpen={isFailedModalOpen}
          onClose={closeFailedModal}
        >
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Unfortunately we are unable to proceed with your application at this time, as retrieving
            your credit rating was unsuccessful.
          </Text>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Feel free to contact us at{' '}
            <Link href={`mailto:hello@gosolr.co.za?subject=Support%20Request`} color={'#3b82f6'}>
              hello@gosolr.co.za
            </Link>{' '}
            or give us a call at{' '}
            <Link href={`tel:0108803948`} color={'#3b82f6'}>
              010 880 3948
            </Link>{' '}
            if you require more assistance. We will try our best to help resolve this issue for you.
          </Text>
          <Button alignSelf={'flex-middle'} variant={'dark'} onClick={closeFailedModal}>
            Okay
          </Button>
        </DefaultModal>
      </Flex>
    </Flex>
  )
}

export default OnboardingCreditCheck
