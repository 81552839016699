import React, { useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'

import AccBankingDetails from '../../Account/AccBankingDetails'

const AccBankingDetailsContainer = (): JSX.Element => {
  return (
    <Flex
      direction={'column'}
      w={['100%', '100%', '100%', '100%', '50%']}
      h={'250px'}
      bg="#ffff"
      p="25px"
      rounded="6px"
      shadow="md"
    >
      <AccBankingDetails />
    </Flex>
  )
}

export default AccBankingDetailsContainer
