import React, { useEffect, useState } from 'react'
import { IoMdCheckmark } from 'react-icons/io'
import { FiX } from 'react-icons/fi'
import { Flex, Text } from '@chakra-ui/react'

type Props = {
  status: boolean
}

const SubscriptionStatus = ({ status }: Props) => {
  const [isActive, setIsActive] = useState<boolean>(false)

  useEffect(() => {
    setIsActive(status)
  }, [])

  return (
    <Flex alignItems={'center'} justifyContent={'start'} gap={'10px'}>
      <Flex
        alignItems={'center'}
        justifyContent={'center'}
        rounded={'full'}
        w={'20px'}
        h={'20px'}
        bgColor={isActive ? 'brand.success' : 'brand.danger'}
        color={'brand.text.light'}
      >
        {isActive ? <IoMdCheckmark size={15} /> : <FiX size={15} />}
      </Flex>
      <Text fontSize={'12px'} color={'brand.text.secondary'}>
        {isActive ? 'Active Account' : 'Not Active'}
      </Text>
    </Flex>
  )
}

export default SubscriptionStatus
