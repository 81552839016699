import React, { useContext, createRef, useState, useEffect } from 'react'
import { Link as RouterLink, useNavigate, NavigateFunction } from 'react-router-dom'
import { Flex, Link, Avatar, useOutsideClick, Button } from '@chakra-ui/react'
import { FiHome, FiLogOut } from 'react-icons/fi'
import SideBarLink, { sideBarMainRoutes, sideBarMiscRoutes } from '../Sidebar/SideBarLink'
import { SideBarContext } from '../../context/SideBarContext'
import MobileNavHeader from './MobileNavHeader'
import { useAuthContext } from '../../context/AuthProvider'

type MobileNavbarType = {
  busyOnboarding?: boolean
}

const MobileNavbar: React.FC<MobileNavbarType> = ({ busyOnboarding }) => {
  const { mobileNavIsOpen, handleToggleMobileNav } = useContext(SideBarContext)

  const navbar = createRef<HTMLDivElement>()
  const { logout, user } = useAuthContext()

  const navigate: NavigateFunction = useNavigate()

  const [userPrortyNumber, setUserPropertyNumber] = useState<string | number | undefined | null>()

  useEffect(() => {
    if (user?.info) {
      // @ts-ignore
      if (user?.info?.properties[0] !== null && user?.info?.properties[0] !== undefined)
        setUserPropertyNumber(user?.info?.properties[0].orderNumber)
    }
  }, [user?.info])

  useOutsideClick({
    ref: navbar,
    handler: (e) => {
      if ((e?.target as HTMLElement).getAttribute('name') !== 'mobile-nav-button')
        mobileNavIsOpen && handleToggleMobileNav()
    }
  })

  const handleLogOut = async () => {
    logout && (await logout())
    navigate('/login')
  }

  return (
    <>
      <MobileNavHeader
        handleToggleMobileNav={handleToggleMobileNav}
        mobileNavIsOpen={mobileNavIsOpen!}
      />
      <Flex
        pos={'fixed'}
        top={0}
        right={mobileNavIsOpen ? 0 : '-100%'}
        w={'100vw'}
        h={'100vh'}
        bgColor={mobileNavIsOpen ? 'blackAlpha.600' : 'blackAlpha.50'}
        transition={'color 0.3s ease-in-out'}
        pointerEvents="none"
        zIndex={2}
      ></Flex>
      <Flex
        ref={navbar}
        pos={'fixed'}
        top={0}
        left={mobileNavIsOpen ? 0 : '-100%'}
        direction={'column'}
        alignItems="center"
        justifyContent="start"
        gap={'45px'}
        w={'70%'}
        h={'100vh'}
        bgColor={'#171717'}
        p={'40px'}
        pt={'125px'}
        transition={'all 0.3s ease-in-out'}
        zIndex={2}
      >
        <Flex direction={'column'} flex={1} alignItems="left" justifyContent="start" gap={'45px'}>
          {!busyOnboarding &&
            sideBarMainRoutes.map((item, index) => (
              <SideBarLink
                key={`${item.path}-${index}`}
                title={item.title}
                path={item.path}
                icon={item.icon}
              />
            ))}
        </Flex>

        <Flex
          direction={'column'}
          justifySelf="flex-end"
          alignItems="left"
          justifyContent="start"
          gap={'45px'}
        >
          {!busyOnboarding &&
            sideBarMiscRoutes.map((item, index) => (
              <SideBarLink
                key={`${item.path}-${index}`}
                title={item.title}
                path={item.path}
                icon={item.icon}
              />
            ))}
        </Flex>
        <Flex w={'100%'} justifyContent={'center'} mb="50px">
          <Button
            onClick={(e) => handleLogOut()}
            aria-label="log out button"
            leftIcon={<FiLogOut />}
            variant={'unstyled'}
            color={'white'}
            fontWeight={300}
            _active={{
              transform: 'scale(0.95)'
            }}
            transition="all 0.2s ease-in-out"
          >
            Log Out
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default MobileNavbar
