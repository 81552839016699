import { Flex, Text, Button } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { PropertyInvoiceType } from '../../types'
import { ContainerHeading } from '../Headings'

const TransactionInvoice = ({
  inv,
  setIsSendToEmailActive
}: {
  inv: PropertyInvoiceType
  setIsSendToEmailActive: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const [invDate, setSelectedInvDate] = useState<string>(() => formatDate(inv?.date || ''))
  const [invDueDate, setSelectedInvDueDate] = useState<string>(() => formatDate(inv?.dueDate || ''))
  const [invPaidDate, setSelectedInvPaidDate] = useState<string>(() =>
    formatDate(inv?.fullyPaidOnDate || '')
  )

  // dates must be formated in the following way: 5 Mar, 2024
  function formatDate(date: string) {
    const d = new Date(date)
    const month = d.toLocaleString('default', { month: 'short' })
    const year = d.getFullYear()
    return `${d.getDate()} ${month}, ${year}`
  }

  useEffect(() => {
    if (inv) {
      setSelectedInvDate(formatDate(inv.date))
      setSelectedInvDueDate(formatDate(inv.dueDate))
      setSelectedInvPaidDate(formatDate(inv.fullyPaidOnDate))
    }
  }, [inv])

  const handleSendToEmail = () => {
    setIsSendToEmailActive(true)
  }

  return (
    <>
      <Flex direction={'column'} gap={'25px'} width={'100%'}>
        <ContainerHeading title={inv?.invoiceNumber || ''} />
        {/* SubContainer 1 */}
        <Flex
          direction={'column'}
          gap={'25px'}
          width={'100%'}
          bgColor={'brand.input'}
          rounded={'md'}
          p={'25px'}
        >
          {/* container 1 */}
          <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
            <Flex direction={'column'} width={'100%'}>
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Amount Due
              </Text>
              <Text fontSize={'md'} fontWeight={'bold'} color={'brand.text.primary'}>
                R {inv?.amountDue}
              </Text>
            </Flex>
            <Flex
              rounded={'full'}
              bgColor={'brand.yellow'}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Text
                color={'brand.text.primary'}
                fontWeight={'bold'}
                fontSize={'sm'}
                px={'12px'}
                py={'4px'}
              >
                {inv?.status}
              </Text>
            </Flex>
          </Flex>
          {/* container 2 */}
          <Flex
            direction={'row'}
            justifyContent={'space-between'}
            width={'100%'}
            alignItems={'center'}
          >
            <Flex direction={'column'}>
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Isued on
              </Text>
              <Text fontSize={'md'} fontWeight={'bold'} color={'brand.text.primary'}>
                {invDate}
              </Text>
            </Flex>
            <Flex direction={'column'}>
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Due on
              </Text>
              <Text fontSize={'md'} fontWeight={'bold'} color={'brand.text.primary'}>
                {invDueDate}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        {/* Sub Container 2 */}
        <h3>Package</h3>
        <Flex
          direction={'column'}
          gap={'25px'}
          width={'100%'}
          bgColor={'brand.input'}
          rounded={'md'}
          p={'25px'}
        >
          {/* container 1 */}
          <Flex
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'start'}
            w={'full'}
          >
            <Text fontSize={'md'} fontWeight={'bold'} color={'brand.text.primary'}>
              {inv?.description}
            </Text>

            <Flex
              width={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // height={'mi'}
            >
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Monthly
              </Text>
              <Text color={'brand.text.primary'} fontWeight={'bold'} fontSize={'sm'}>
                R {inv?.total}
              </Text>
            </Flex>
          </Flex>
          {/* container 2 */}
          <Flex
            direction={'column'}
            justifyContent={'space-between'}
            alignItems={'start'}
            w={'full'}
          >
            <Flex
              width={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // height={'mi'}
            >
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Subtotal
              </Text>
              <Text color={'brand.text.primary'} fontWeight={'bold'} fontSize={'sm'}>
                R {inv?.subTotal}
              </Text>
            </Flex>
            <Flex
              width={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
              // height={'mi'}
            >
              <Text fontSize={'sm'} color={'brand.text.primary'}>
                Tax
              </Text>
              <Text color={'brand.text.primary'} fontWeight={'bold'} fontSize={'sm'}>
                R {inv?.tax}
              </Text>
            </Flex>
            <Flex
              width={'100%'}
              alignItems={'center'}
              justifyContent={'space-between'}
              bgColor={'brand.secondary'}
              color={'#ffff'}
              px={'12px'}
              py={'4px'}
              rounded={'md'}
              mt={'15px'}
            >
              <Text fontSize={'sm'}>Total</Text>
              <Text fontWeight={'bold'} fontSize={'sm'}>
                R {inv?.total}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Button
          width={'min-content'}
          alignSelf={'center'}
          variant={'dark'}
          onClick={handleSendToEmail}
        >
          Send to email
        </Button>
      </Flex>
    </>
  )
}

export default TransactionInvoice
