import { useContext, FC, useRef, useEffect } from 'react'
import { OnboardingFormContext } from '../../../context/intro/OnboardingContext'
import OnboardingStepHeading from './OnboardingStepHeading'
import OnboardingInfoText from './OnboardingInfoText'
import { Flex, Text } from '@chakra-ui/react'
import { InitGTMPageLayer } from '../../../utils/Google-Tag-Manager'

const OnboardingFormStepsContainer = ({ promoCode }: { promoCode?: string }) => {
  const { activeStep } = useContext(OnboardingFormContext)

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    if (activeStep?.stepName) InitGTMPageLayer(activeStep?.stepName)
  }, [activeStep])

  return (
    <Flex
      flex={1}
      direction={'column'}
      w={'full'}
      maxW={'700px'}
      alignItems={'center'}
      justifyContent={['start', 'center', 'center', 'center']}
      overflow="visible"
      pb={['25px', 0, 0, 0]}
    >
      <Flex direction="column" gap={'25px'} w={'full'}>
        {<OnboardingStepHeading title={activeStep?.stepName || ''} />}

        <OnboardingInfoText text={activeStep?.helperText as string} />
      </Flex>

      {activeStep?.container}
    </Flex>
  )
}

export default OnboardingFormStepsContainer
