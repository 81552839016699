import { FC, useState } from 'react'
import { Flex, Text, Button, Heading } from '@chakra-ui/react'
import { ButtonPill } from '../Buttons'

export type SupportRequestTypeProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const SupportRequestType: FC<SupportRequestTypeProps> = ({ setFieldValue }) => {
  const [activeRequest, setActiveReqest] = useState('Have No Power')

  const RequestTypes = [
    'Have no power',
    'Performance issue',
    'Request different settings',
    'Document query'
  ]

  const handleSelectRequestType = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    const target = event.target as HTMLInputElement
    setFieldValue('requestType', target?.name)
    setActiveReqest(target?.name)
  }

  return (
    <Flex direction={'column'} w="100%" gap={'15px'}>
      <Heading as={'h5'} fontSize={'16px'} fontWeight={'semibold'} color="brand.text.primary">
        Request type
      </Heading>
      <Flex flexWrap={'wrap'} gap={'10px'}>
        {RequestTypes.map((item, index) => (
          <ButtonPill
            key={`${item}-${index}`}
            title={item}
            isActive={activeRequest === item}
            onClick={(e) => handleSelectRequestType(e)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default SupportRequestType
