import React from 'react'
import { Box, Center, Flex, FlexProps, Heading, Highlight, Text } from '@chakra-ui/react'

import { Maybe } from 'yup/lib/types'
import formatMoneyValue from '../../../../utils/formatMoneyValue'
import { FaCheckCircle } from 'react-icons/fa'
import { fontSize } from 'styled-system'

type SubscriptionOptionType = {
  name?: Maybe<string>
  selected?: boolean
  price?: number
  productOption?: string
  description?: string
  subscriptionDetails?: string
  isReconmended?: boolean
  canSetSubscription?: boolean
  isSinglePhase?: boolean
} & FlexProps & {}

const SubscriptionOption: React.FC<SubscriptionOptionType> = ({
  name = 'Medium',
  selected,
  isReconmended,
  price = '1740',
  canSetSubscription = false,
  isSinglePhase = true,
  productOption,
  description,
  subscriptionDetails,
  ...rest
}) => {
  return (
    <Flex
      position={'relative'}
      as="button"
      direction={'column'}
      w="full"
      bgColor={
        canSetSubscription ? (selected ? 'brand.secondary' : 'brand.neutral') : 'brand.input'
      }
      border={'1px solid '}
      borderColor={'brand.secondary'}
      overflow={'hidden'}
      h="full"
      minH={'150px'}
      py="25px"
      rounded={'8px'}
      color={selected ? 'brand.text.light' : 'brand.text.primary'}
      justifyContent={'space-between'}
      alignItems={'center'}
      gap={'20px'}
      transition={'all 0.25s ease-in-out'}
      _hover={canSetSubscription ? { transform: 'scale(1.010)' } : { cursor: 'default' }}
      _active={canSetSubscription ? { transform: 'scale(0.98)' } : { cursor: 'default' }}
      {...rest}
    >
      {selected && (
        <Box position={'absolute'} top={'20px'} right={'20px'}>
          <FaCheckCircle fill="#FFD015" size={25} />
        </Box>
      )}
      <Heading as="h4" fontWeight={'light'} fontSize={'20px'}>
        {isReconmended && (
          <Text
            color={selected ? 'brand.primary' : 'brand.secondary'}
            fontSize={12}
            mb="10px"
            letterSpacing={'wider'}
          >
            (Recommended)
          </Text>
        )}
        <Highlight
          query={name?.replace('2023_', '') as string}
          styles={{
            color: selected ? 'brand.text.light' : 'brand.text.primary',
            fontWeight: 'bold'
          }}
        >{`GoSolr ${name?.replace('2023_', '') as string}`}</Highlight>{' '}
      </Heading>
      {!canSetSubscription ? (
        <Text
          fontSize={13}
          fontWeight={700}
          color={selected ? 'brand.text.light' : 'brand.text.primary'}
          width="70%"
        >
          This option is only available if you have {isSinglePhase ? '3phase ' : 'single phase '}{' '}
          electricity.
        </Text>
      ) : (
        <>
          <Text
            fontSize={12}
            w="full"
            maxW={'70%'}
            color={selected ? 'brand.text.light' : 'brand.text.primary'}
          >
            {subscriptionDetails}
          </Text>
          <Center
            bgColor={selected ? 'brand.primary' : 'white'}
            color={'brand.text.primary'}
            px={'15px'}
            py="4px"
            rounded="full"
            border={'1px solid '}
            borderColor={'brand.secondary'}
          >
            <Text> R{formatMoneyValue(price, '0,0')}pm</Text>
          </Center>
        </>
      )}

      {description && (
        <Text textAlign="center" fontSize={13}>
          ({description})
        </Text>
      )}
    </Flex>
  )
}

export default SubscriptionOption
