import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  StyleProps,
  Text
} from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { FiCheck } from 'react-icons/fi'
import { formatPhoneNumber } from '../../../utils'

type ConnectedFormGroupProps = StyleProps & {
  placeholder?: string
  label?: string | React.ReactNode
  name: string
  formatInput?: (value: string) => string
  moreInfo?: string | JSX.Element
  bgColor?: string
  onChange?: (value?: string) => void
  onBlur?: () => void
  disabled?: boolean
  type?: string
  testId?: string
}

const ConnectedPhoneNumberInput: React.FC<ConnectedFormGroupProps> = ({
  label,
  testId,
  ...rest
}) => {
  const [field, meta, helpers] = useField(rest.name)
  const [valid, setValid] = React.useState(false)
  const handleClick = () => setValid(!valid)

  const validNumber = field.value?.length === 12

  if (!validNumber) {
    meta.error = 'Invalid Phone Number'
  }

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl>
        {label && (
          <FormLabel mb={1} fontWeight="normal" fontSize={14} htmlFor={field.name}>
            {label}
          </FormLabel>
        )}
        <InputGroup size="md">
          <Input
            bgColor="white"
            textColor="black"
            border={'1px solid'}
            borderColor={'brand.input'}
            placeholder={rest.placeholder || 'Enter phone number'}
            _placeholder={{ color: 'neutral.100' }}
            {...field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
              const formattedValue = formatPhoneNumber(value)
              helpers.setValue(formattedValue)
              rest.onChange && rest.onChange(formattedValue)
            }}
            data-testid={testId}
            id={field.name}
          />
          {validNumber && (
            <InputRightElement>
              <Icon size="20px" onClick={handleClick} as={FiCheck} color="success.500" />
            </InputRightElement>
          )}
        </InputGroup>
        {meta.touched && meta.error ? (
          <Text fontSize="10px" color="red.500" textAlign="right">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedPhoneNumberInput

ConnectedPhoneNumberInput.defaultProps = {
  mb: 2,
  type: 'text'
}
