import React, { useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'

import AccPromoCode from '../../Account/AccPromoCode'
import UserContainer, { AccContainerUser } from '../../Account/UserContainer'

import { Maybe } from '../../../types'
import { convertBase64ToFile } from '../../../utils'
import {
  useGetFileQuery,
  useGetUserQuery,
  User,
  File as CustomFile
} from '../../../generated/graphql'
import LoadingComponent from '../../Loading'

export type UserDetailsType = {
  email: Maybe<string> | undefined
  fullName: Maybe<string> | undefined
  contactNumber: Maybe<string> | undefined
  alternateNumber: Maybe<string> | undefined
  referralCode: Maybe<string> | undefined
}

const AccHeroContainer: React.FC<UserDetailsType> = ({
  email,
  fullName,
  contactNumber,
  alternateNumber,
  referralCode
}) => {
  const user: AccContainerUser = {
    fullName,
    email,
    phone_one: contactNumber,
    phone_two: alternateNumber,
    profile_img: '',
    promoCode: referralCode
  }
  const [userAvatarImage, setUserAvatarImage] = useState<string>('')
  const [loadingData, setLoadingData] = useState<boolean>(true)

  const { data } = useGetUserQuery({
    variables: {
      email: email as string
    }
  })

  const { refetch: getFile } = useGetFileQuery({
    skip: true
  })

  const recentUserData = data?.getUser as User

  useEffect(() => {
    const getUserAvatar = async () => {
      setLoadingData(true)
      if (recentUserData?.avatarS3Key) {
        const s3File = await getFile({
          key: recentUserData.avatarS3Key as string
        })
        const splitKey = recentUserData?.avatarS3Key.split('/')
        const fileName = splitKey[splitKey.length - 1]
        setUserAvatarImage(
          URL.createObjectURL(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        )
        setLoadingData(false)
      }
      setLoadingData(false)
    }
    getUserAvatar()
  }, [recentUserData, getFile])

  if (loadingData) {
    return <LoadingComponent loadingText="Loading user data" />
  }

  return (
    <>
      <Flex w="100%" direction={['column', 'column', 'column', 'row']} gap="20px">
        <Flex
          w={['100%', '100%', '100%', '50%']}
          h={'300px'}
          p={'25px'}
          direction={'column'}
          alignItems={'start'}
          justifyContent={'space-between'}
          rounded="6px"
          bgColor={'white'}
          shadow="md"
        >
          <Flex w="full" justifyContent={'flex-end'}>
            {/* <Button onClick={handleGoToUserSettings} variant={'dark'} w="auto">
              Edit
            </Button> */}
          </Flex>
          <UserContainer user={user} avatar={userAvatarImage} />
        </Flex>

        <Flex
          w={['100%', '100%', '100%', '50%']}
          h={'300px'}
          p={'25px'}
          rounded="6px"
          bgColor={'#171717'}
          direction={'column'}
          shadow="md"
        >
          <AccPromoCode referralCode={user.promoCode as string} />
        </Flex>
      </Flex>
    </>
  )
}

export default AccHeroContainer
