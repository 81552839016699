import TagManager, { DataLayerArgs } from 'react-gtm-module'

const pageDataLayer = 'PageDataLayer'

export const InitGTM = (id: string, dataLayerName?: string) => {
  const tagManagerArgs = dataLayerName
    ? {
        gtmId: id,
        dataLayerName: pageDataLayer
      }
    : {
        gtmId: id
      }
  TagManager.initialize(tagManagerArgs)
}

export const InitGTMPageLayer = (page: string) => {
  const tagManagerArgs: DataLayerArgs = {
    dataLayer: {
      userProject: 'GoSolr web app',
      page: page
    },
    dataLayerName: pageDataLayer
  }

  TagManager.dataLayer(tagManagerArgs)
}
