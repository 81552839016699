/* istanbul ignore file */
import {
  fileExtensionMimeTypeMap,
  SA_PHONE_NUMBER_MATCH,
  STAGING_ENVIRONMENT,
  USER_STORAGE_KEY
} from '../constants'

window.Buffer = window.Buffer || require('buffer').Buffer
// validates ID Number according to Luhn's algorithm.
export const validateIdNumber = (id: string): boolean => {
  // remove all spaces
  const idNoWhitespace = id.replace(/\W+/gi, '')

  // check if month and day are valid
  const month = Number(idNoWhitespace.substring(2, 4))
  const day = Number(idNoWhitespace.substring(4, 6))

  if (month > 12 || day > 31) return false

  const idArray = idNoWhitespace.split('').map((x) => parseInt(x))

  let checksum = 0
  const idArrayLength = idArray.length

  for (let i = 1; i < idArrayLength; i = i + 2) {
    const digitModulus = idArray[idArrayLength - 1 - i] * 2
    if (digitModulus > 9) {
      idArray[idArrayLength - 1 - i] = digitModulus - 9
    } else {
      idArray[idArrayLength - 1 - i] = digitModulus
    }
  }

  for (let i = 0; i < idArrayLength; i++) {
    checksum = checksum + idArray[i]
  }

  // For testing purposes:
  // Output sum value to create a valid ID number by adding/subtracting to this value to ensure it is divisible by 10
  // if (STAGING_ENVIRONMENT !== 'prod') {
  //   console.log({ checksum })
  // }

  return checksum % 10 === 0
}

/**
 * Gets JWT of authenticatedUser from either sessionStorage or localStorage
 */
export const fetchJwt = (): string | null => {
  const localUser = localStorage.getItem(USER_STORAGE_KEY)
  const sessionUser = sessionStorage.getItem(USER_STORAGE_KEY)
  const user = sessionUser || localUser

  return user ? JSON.parse(user).jwt : null
}

export const formatIdNumber = (value: string): string => {
  let displayValue
  const search = /(\d{6})(\d{0,4})(\d{0,1})(\d{0,1})(\d{0,1})/

  if (value.length <= 17) {
    displayValue = value.replace(/[a-zA-Z\W]+/gi, '').replace(search, (g1, g2, g3, g4, g5, g6) => {
      let id = g2

      if (g3) {
        id += ' ' + g3
      }

      if (g3 && g4) {
        id += ' ' + g4
      }

      if (g3 && g4 && g5) {
        id += ' ' + g5
      }

      if (g3 && g4 && g5 && g6) {
        id += ' ' + g6
      }

      return id
    })
  } else {
    displayValue = value.substring(0, 17)
  }

  return displayValue
}

/**
 * Format a phone number so that either fits the standard local phone numbers and phone numbers with international dialing codes
 * @param phoneNumber 
 * @returns 
 */
export function formatPhoneNumber(phoneNumber: string): string {
  let formattedNumber = phoneNumber.replace(/^[a-zA-Z\W]+/gi, ''); // Remove all white spaces and non-digital characters
  // Preserve the "+" in the output
  if (phoneNumber.startsWith('+')) {
    formattedNumber = '+' + formattedNumber;
    return formattedNumber.slice(0, 13);
  }
  //Number with SA outgoing dialing code 00 at the start
  if (phoneNumber.startsWith('00')) {
    return formattedNumber.slice(0, 15);
  }
  //If not either of the international phone number formats then assume standard number
  return formattedNumber.slice(0, 10);
  
}

export const convertBase64ToFile = (base64: string, fileName: string): File => {
  const buffer = new Buffer(base64, 'base64')
  const fileExtension = getFileExtension(fileName)
  const mimeType = fileExtensionMimeTypeMap[fileExtension || '']

  return new File([buffer], fileName, {
    type: mimeType
  })
}

export const getFileExtension = (fileName: string): string => {
  const extensionRegex = /\.[0-9a-z]+$/i
  const match = fileName.match(extensionRegex)

  return (match?.length && match[0]) || ''
}

export const ordinal = (n: number) => {
  var s = ['th', 'st', 'nd', 'rd']
  var v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const mapRegistrationNumberToEntity = (code: string) => {
  switch (code) {
    case '06':
      return 'Public Company – Ltd.';
    case '07':
      return 'Private Company – (Pty) Ltd.';
    case '08':
      return 'Association (Sec 21) – Not for Gain';
    case '10':
      return 'External Company';
    case '11':
      return 'External Company (Sec21) – Not for Gain';
    case '21':
      return 'Incorporated – Inc.';
    case '23':
      return 'Close Corporation – CC.';
    case '24':
      return 'Primary Co-Op Ltd.';
    case '25':
      return 'Secondary Co-Op Ltd.';
    case '26':
      return 'Tertiary Co-Op Ltd.';
    default:
      return 'Unknown';
  }
}
