import React, { FC } from 'react'
import { Flex, useToast, Box } from '@chakra-ui/react'
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton
} from 'react-share'
import { RiLink, RiMailLine, RiTwitterLine, RiWhatsappLine, RiFacebookFill } from 'react-icons/ri'

type Props = {
  name: string
  color?: string
  url?: string
  children?: React.ReactNode
}

const SocialItem: FC<Props> = ({
  children,
  color = '#171717',
  name = 'facebook',
  url = 'https://app.gosolr.co.za'
}) => {
  const toast = useToast()

  const handleCopyPromoCode = () => {
    navigator.clipboard.writeText(url)
    toast({
      position: 'top',
      duration: 2000,
      isClosable: true,
      render: () => (
        <Box rounded="4px" bgColor="#171717" color="#FFD653" px="4px" py="8px" textAlign={'center'}>
          Promo Link Copied
        </Box>
      )
    })
  }

  return (
    <Flex
      _hover={{ transform: 'scale(1.075)' }}
      _active={{ transform: 'scale(0.95)' }}
      transition="all 0.2s ease-in-out"
      color={color}
    >
      {name === 'facebook' && (
        <FacebookShareButton url={url} quote="asdasdasd" hashtag="#gosolr">
          <RiFacebookFill size={25} />
        </FacebookShareButton>
      )}
      {name === 'whatsapp' && (
        <WhatsappShareButton url={url} title="GoSolr" separator=": ">
          <RiWhatsappLine size={25} />
        </WhatsappShareButton>
      )}
      {name === 'twitter' && (
        <TwitterShareButton url={url} title="GoSolr" hashtags={['#gosolr']}>
          <RiTwitterLine size={25} />
        </TwitterShareButton>
      )}
      {name === 'email' && (
        <EmailShareButton url={url} subject="GoSolr" body="Join GoSolr today" separator=": ">
          <RiMailLine size={25} />
        </EmailShareButton>
      )}
      {name === 'link' && (
        <Flex as="button" onClick={(e) => handleCopyPromoCode()}>
          <RiLink size={25} />
        </Flex>
      )}
    </Flex>
  )
}

export default SocialItem
