import React, { FC, useEffect, useState } from 'react'
import { Flex, Heading, Text, useMediaQuery } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import { RiShieldFlashLine, RiPulseLine, RiRefund2Line, RiDashboard3Line } from 'react-icons/ri'
import { FiAlertTriangle, FiClock, FiWatch } from 'react-icons/fi'
import { ButtonStandard } from '../Buttons'
import ModalBasic from '../Modals'
import BatterySettings from '../BatterySettings'

import { UserProperty } from '../../generated/graphql'

import { batterySettingsArr } from '../../helpers/batterySettings'
import { useLocation } from 'react-router-dom'
import { SET_GSM_ACTIVE_URL, UPDATE_DEVICE_SETTINGS_URL } from '../../constants'
import { getJWT } from '../../helpers/auth'
import axios from 'axios'
import { SettingStatusType } from '../../types'

export type Props = {
  property: UserProperty
  activeSetting: string
  isGsm: boolean
  settingsChangeStatus: SettingStatusType
}

export type SettingType = {
  plantID?: string
  name?: string
  description?: string
  img_url?: string
  icon?: React.ReactNode
}

const PropertyBatterySettings: FC<Props> = ({
  property,
  activeSetting: storedSetting,
  settingsChangeStatus,
  isGsm
}) => {
  const { orderNumber } = property
  const [settingName, setSettingName] = useState<string>(storedSetting || 'No Active Setting')
  const [settingIcon] = useState<React.ReactNode>(<FiAlertTriangle size={30} />)
  const isMobileSize = useMediaQuery('(max-width: 610px)')
  const [updateStatus, setUpdateStatus] = useState<SettingStatusType>(settingsChangeStatus)
  const [isLoading, setIsloading] = useState(false)
  const [isPropertyPage, setIsPropertPage] = useState<Boolean>(false)
  const [isGosolrManaged, setIsGosolrManaged] = useState<boolean>(isGsm)
  const [isGosolrManagedFreshlyActive, setIsGosolrManagedFreshlyActive] = useState<Boolean>(false)
  const location = useLocation()

  useEffect(() => {
    if (location.pathname.includes('user/property/')) {
      setIsPropertPage(true)
    }
  }, [location.pathname])

  useEffect(() => {
    setUpdateStatus(settingsChangeStatus)
  }, [settingsChangeStatus])

  useEffect(() => {
    loadActiveSettingFromStorage()
    setSettingName(storedSetting)
    setIsGosolrManaged(isGsm)
  }, [storedSetting, isGsm])

  const handleSetSettingIcon = (name: string) => {
    if (name) {
      if (name.toLowerCase() === 'max safety') return <RiShieldFlashLine size={30} />
      if (name.toLowerCase() === 'balanced') return <RiPulseLine size={30} />
      if (name.toLowerCase() === 'economical') return <RiRefund2Line size={30} />
      if (name.toLowerCase().includes('gosolr managed')) return <RiDashboard3Line size={30} />
    }

    return <FiAlertTriangle size={30} />
  }

  const [isOpenBatterySettings, setIsOpenBatterySettings] = useState<boolean>(false)

  const [activeSetting, setActiveSetting] = useState<SettingType>({
    name: settingName,
    description: '',
    icon: settingIcon || <FiAlertTriangle size={30} />
  })

  const handleSetGosolrManagedActive = async () => {
    const payload = {
      orderNumber: orderNumber,
      value: 'true'
    }

    const resp = await axios.post(SET_GSM_ACTIVE_URL, payload, {
      headers: {
        Authorization: getJWT()
      }
    })

    console.log(resp)
  }

  const setPresetSettingActive = async (setting: string) => {
    setUpdateStatus('pending')
    const url = UPDATE_DEVICE_SETTINGS_URL
    const payload = {
      orderNumber: orderNumber,
      setting: setting
    }
    const response = await axios.post(url, payload, {
      headers: {
        Authorization: getJWT()
      }
    })
    // Check if it was successfull
    if (response.status === 200) {
      console.log('Setting updated successfully')
    }

    const result = await response.data
    console.log(result)

    return result
  }

  const handleSetActiveSettings = async (setting: SettingType) => {
    setIsloading(true)
    console.log(setting.name)
    try {
      if (setting.name && setting?.name?.toLowerCase().includes('gosolr managed')) {
        setIsGosolrManaged(true)
        console.log('Setting GoSolr managed active')
        await handleSetGosolrManagedActive()
        setIsGosolrManagedFreshlyActive(true)
      } else {
        setIsGosolrManaged(false)
        setIsGosolrManagedFreshlyActive(false)
        const res = await setPresetSettingActive(setting.name as string)
      }

      setActiveSetting(setting)
      setIsloading(false)
      setIsOpenBatterySettings(false)
    } catch (e) {
      console.log('Error: ', e)
    }
  }
  const handleOpenBatterySettings = () => {
    setIsOpenBatterySettings(true)
  }

  const handleCloseBatterySettings = () => {
    setIsOpenBatterySettings(false)
  }

  const loadActiveSettingFromStorage = () => {
    if (!storedSetting) {
      setActiveSetting({
        name: 'No Active Setting',
        description: ' Click on the "Edit" button to set your battery settings',
        icon: <FiAlertTriangle size={30} />
      })
    } else {
      const settingDescription = batterySettingsArr.find(
        (item) => item.name.toLowerCase() === storedSetting.toLowerCase()
      )

      handleSetSettingIcon(storedSetting as string)

      if (isGsm) {
        setIsGosolrManaged(true)
      }

      setActiveSetting({
        name: storedSetting,
        description: settingDescription?.description,
        icon: settingIcon || <FiAlertTriangle size={30} />
      })
    }
  }

  return (
    <>
      {activeSetting.name ? (
        <Flex
          flex={1}
          direction={'column'}
          gap={'25px'}
          h="100%"
          maxH={'100%'}
          justifyContent={'space-between'}
        >
          {!isPropertyPage && (
            <Heading as="h3" size={'md'} fontWeight={600}>
              Battery settings
            </Heading>
          )}
          <Flex
            direction={['column', 'column', 'row', 'row']}
            gap={[15, 15, 25, 25]}
            alignItems={['start', 'start', 'center', 'center']}
            justifyContent={'start'}
            width={'full'}
          >
            {isGosolrManaged && (
              <Flex gap={'20px'} alignItems={'center'} justifyContent={'start'}>
                <RiDashboard3Line size={30} fill={'#FFD635'} />

                <Text color={'brand.text.primary'} fontWeight={500} casing={'capitalize'}>
                  GoSolr managed
                </Text>
              </Flex>
            )}
            {!isGosolrManagedFreshlyActive && updateStatus && updateStatus !== 'pending' ? (
              <Flex gap={'20px'} alignItems={'center'} justifyContent={'start'}>
                {activeSetting?.name?.toLowerCase() === 'max safety' && (
                  <RiShieldFlashLine size={30} fill={'#FFD635'} />
                )}
                {activeSetting?.name?.toLowerCase() === 'balanced' && (
                  <RiPulseLine size={30} fill={'#FFD635'} />
                )}
                {activeSetting?.name?.toLowerCase() === 'economical' && (
                  <RiRefund2Line size={30} fill={'#FFD635'} />
                )}

                {activeSetting?.name?.toLowerCase() === 'no active setting' && (
                  <FiAlertTriangle size={30} fill={'#FFD635'} />
                )}
                <Text color={'brand.text.primary'} fontWeight={500} casing={'capitalize'}>
                  {activeSetting.name}
                </Text>
              </Flex>
            ) : null}
          </Flex>

          {isGosolrManagedFreshlyActive && (
            <Text
              backgroundColor={'brand.secondary'}
              color={'brand.text.yellow'}
              padding={2}
              rounded={'8px'}
              fontWeight={400}
              fontSize={'12px'}
            >
              GoSolr will update your device in the background, refresh your page in a few minutes
              to see the active setting GoSolr has selected.
            </Text>
          )}
          {updateStatus === 'pending' && (
            <Flex
              backgroundColor={'brand.secondary'}
              color={'brand.text.yellow'}
              padding={4}
              rounded={'8px'}
              direction={['column', 'column', 'row', 'row']}
              gap={[15, 15, 25, 25]}
              justifyContent={'center'}
              alignItems={'center'}
            >
              <FiClock size={50} />
              <Text fontWeight={400} fontSize={'12px'} textAlign={'center'}>
                GoSolr will update your device in the background, refresh your page in a few minutes
                to see the new active setting you have has selected.
              </Text>
            </Flex>
          )}
          {!isGosolrManaged && !isGosolrManagedFreshlyActive && updateStatus !== 'pending' && (
            <Text color={'brand.text.primary'} fontWeight={400} fontSize={'12px'}>
              {activeSetting.description}
            </Text>
          )}
          <ButtonStandard
            onClick={handleOpenBatterySettings}
            w="100%"
            maxW={'min-content'}
            alignSelf={'flex-end'}
          >
            Edit
          </ButtonStandard>
        </Flex>
      ) : (
        <NoActiveBatterySettingsContainer handleOpenBatterySettings={handleOpenBatterySettings} />
      )}

      <ModalBasic
        size={isMobileSize[0] ? 'full' : 'lg'}
        heading={<ContainerHeading title={'Battery settings'} />}
        isOpen={isOpenBatterySettings}
        onClose={handleCloseBatterySettings}
      >
        <BatterySettings
          activeSetting={activeSetting}
          handleSetActiveSettings={handleSetActiveSettings}
          isLoading={isLoading}
          isGosolrManaged={isGosolrManaged}
        />
      </ModalBasic>
    </>
  )
}

export default PropertyBatterySettings

export type NoActiveBatteryType = {
  handleOpenBatterySettings: () => void
}

export const NoActiveBatterySettingsContainer: FC<NoActiveBatteryType> = ({
  handleOpenBatterySettings
}) => {
  return (
    <Flex flex={1} direction={'column'} gap={'15px'} h="100%" maxH={'100%'}>
      <Heading as="h3" size={'md'} fontWeight={600}>
        Battery settings
      </Heading>
      <Flex gap={'20px'} alignItems={'center'} justifyContent={'start'}>
        <FiAlertTriangle size={30} fill={'#FFD635'} />

        <Text color={'brand.text.primary'} fontWeight={500} casing={'capitalize'}>
          No Active Setting
        </Text>
      </Flex>
      <Text color={'brand.text.primary'} fontWeight={400} fontSize={'12px'}>
        Click on the "Edit" button to set your battery settings
      </Text>
      <ButtonStandard
        onClick={handleOpenBatterySettings}
        w="100%"
        maxW={'min-content'}
        alignSelf={'flex-end'}
      >
        Edit
      </ButtonStandard>
    </Flex>
  )
}
