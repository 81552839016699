import { Flex } from '@chakra-ui/react'
import React, { FC } from 'react'

import { ContainerHeading } from '../../Headings'

type HeadingType = {
  title: string
}
const OnboardingStepHeading: FC<HeadingType> = ({ title }) => {
  return (
    <Flex textAlign={'left'} w="full">
      <ContainerHeading title={title} />
    </Flex>
  )
}

export default OnboardingStepHeading
