import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { OnBoardingStep } from '../../context/intro/OnboardingContext'

type OnboardingStepContainerType = {
  steps: OnBoardingStep[]
  activeStep: BreadCrumbsType
  promoCode?: string
}

type OnboardingBreadCrumbsProps = {
  stepCount: number
  stepLabel: string
  stepDetails: string
  steps: OnBoardingStep[]
  promoCode?: string
}

export type BreadCrumbsType = {
  stepCount: number
  stepLabel: string
  stepDetails: string
}

const BreadCrumbs: FC<OnboardingBreadCrumbsProps> = ({
  stepCount,
  stepLabel,
  stepDetails,
  steps,
  promoCode
}) => {
  const [activeStep, setActiveStep] = useState<BreadCrumbsType>(() => ({
    stepCount,
    stepLabel,
    stepDetails
  }))

  useEffect(() => {
    setActiveStep({
      stepCount,
      stepLabel,
      stepDetails
    })
  }, [stepCount])

  return (
    <Flex
      direction="column"
      w="full"
      maxW={'650px'}
      bgColor={promoCode && promoCode.toLowerCase() === 'hippo' ? 'brand.hippo' : 'brand.secondary'}
      rounded="20px"
      alignItems={'center'}
      justifyContent="center"
      py={'15px'}
      px={['25px', '25px', '75px', '75px']}
      alignSelf={'center'}
    >
      <Flex direction="column" w="full" gap="15px" alignItems={'center'} justifyContent="center">
        <Heading
          as="h3"
          fontSize={'16px'}
          w="full"
          fontWeight={'medium'}
          color={'brand.text.light'}
        >
          Step {activeStep.stepCount + 1}: {activeStep.stepDetails}
        </Heading>
        <StepperPillsContainer steps={steps} activeStep={activeStep} promoCode={promoCode} />
      </Flex>
    </Flex>
  )
}

export default BreadCrumbs

export const StepperPillsContainer: FC<OnboardingStepContainerType> = ({
  steps,
  activeStep,
  promoCode
}) => {
  const completedStepColor = '#D9D9D9'
  const stepColor =
    promoCode && promoCode.toLowerCase() === 'hippo' ? 'brand.hippo-green' : 'brand.primary'
  const remainingStepColor = '#868585'

  return (
    <Flex w={'full'} justifyContent="space-between" alignItems={'center'} gap="15px">
      {steps?.map((step, index) => {
        let color = stepColor
        if (
          (step.stepCount && activeStep.stepCount > step.stepCount) ||
          activeStep.stepCount === step.stepCount
        ) {
          color = stepColor
        }

        if (step.stepCount && activeStep.stepCount < step.stepCount) {
          color = remainingStepColor
        }
        return (
          <Flex
            direction={'column'}
            w={'full'}
            alignItems="center"
            justifyContent={'center'}
            gap="5px"
            key={`${step.stepLabel}-${step.stepCount}-${index}-container`}
          >
            <Box
              key={`${step.stepLabel}-${step.stepCount}`}
              w={'full'}
              minW={['15px', '54px', '54px', '54px']}
              h="8px"
              rounded={'full'}
              bgColor={color}
            ></Box>
            <Text
              color="#fff"
              fontSize={['xs', 'sm', 'sm', 'sm']}
              key={`${step.stepLabel}-${step.stepCount}-${index}`}
            >
              {step.stepLabel}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
