import * as yup from 'yup'
import YupPassword from 'yup-password'
import {
  EMAIL_REGEX,
  POSTAL_CODE_REGEX,
  SA_PHONE_NUMBER_REGEX,
  SA_PROVINCE_OPTIONS
} from '../../constants'
YupPassword(yup)

type objArr = {
  value: string
  label: string
}

const objArrToIndexArr = (objArr: objArr[]) => {
  let newArr = objArr.map((obj) => obj.value)
  return newArr
}

export const CallMeValidation = yup.object().shape({
  //CREDIT CHECK
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Phone number is required.')
    .min(10)
    .max(13),
  email: yup
    .string()
    .email('Please use a valid email address.')
    .matches(EMAIL_REGEX, 'Please use a valid email address.')
    .required('Email is required'),
  streetName: yup.string().required('Street name is required'),
  streetNumber: yup.string().required('Street number is required'),
  city: yup.string().required('City is required'),
  provinces: yup
    .string()
    .oneOf(objArrToIndexArr(SA_PROVINCE_OPTIONS))
    .required('Province is required'),
  suburb: yup.string().required('Suburb is required'),
  postalCode: yup
    .string()
    .matches(POSTAL_CODE_REGEX.test, 'Invalid Postal Code')
    .max(4, 'Invalid Postal Code')
    .required('Postal code is required'),
  productOfInterest: yup.string().required('Product of interest is required'),
  //isHuman: yup.bool().oneOf([true], 'Please complete captcha')
})
