import { FC, useState } from 'react'
import { Button, Flex, Grid, Highlight, useDisclosure, Text, Heading } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import SupportRequestType from './SupportRequestType'
import SupportContactMethods from './SupportContactMethods'
import SupportRequestInputs from './SupportRequestInputs'
import { useAuthContext } from '../../context/AuthProvider'
import { useCreateSupportRequestMutation } from '../../generated/graphql'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'
import SubmitButton from '../FormElements/SubmitButton'
import ModalDialog from '../Modals/ModalDialog'
type Props = {}

type InitialValues = {
  requestType: string
  subject: string
  description: string
  preferredContactMethod: string
  property: string
}

type FormData = {
  name: string
  surname: string
  email: string
  requestType: string
  property: string
  subject: string
  description: string
  preferredContactMethod: string
}

const FormValidation = Yup.object().shape({
  requestType: Yup.string().required('Request type is required.'),
  subject: Yup.string().required('Subject is required.'),
  description: Yup.string().required('Description is required.'),
  preferredContactMethod: Yup.string().required('Contact method is required.')
})

const SupportContainer: FC<Props> = (props: Props) => {
  const { user } = useAuthContext()

  const {
    isOpen: isOpenSubmitDialog,
    onOpen: onOpenSubmitDialog,
    onClose: onCloseSubmitDialog
  } = useDisclosure()

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [createSupportRequest] = useCreateSupportRequestMutation()

  const INITIAL_VALUES: any = {
    requestType: '',
    subject: '',
    description: '',
    preferredContactMethod: 'Email'
    // property: ''
  }

  const [formData, setFormData] = useState<FormData>({
    name: user?.info?.firstName,
    surname: user?.info?.lastName,
    email: user?.info?.email,
    ...INITIAL_VALUES
  })

  return (
    <>
      <Flex
        direction={'column'}
        w="100%"
        maxW={'650px'}
        bg="#ffff"
        rounded="6px"
        shadow={'md'}
        p="25px"
        gap={'25px'}
      >
        <ContainerHeading title={'How can we help you?'} />
        <Formik
          validationSchema={FormValidation}
          initialValues={INITIAL_VALUES}
          enableReinitialize
          onSubmit={async (
            { requestType, property, subject, description, preferredContactMethod },
            { setSubmitting, setStatus }
          ) => {
            // console.log(requestType, property, subject, description, preferredContactMethod)
            try {
              setSubmitting(true)
              setFormData((prevState: FormData) => ({
                ...prevState,
                requestType,
                property,
                subject,
                description,
                preferredContactMethod
              }))
              onOpenSubmitDialog()
              setStatus(null)
              setSubmitting(false)
            } catch (error: any) {
              setStatus(error.message)
            }
          }}
        >
          {({ setFieldValue, isSubmitting, values }: FormikProps<InitialValues>) => {
            return (
              <Form>
                <Flex direction={'column'} w="100%" gap={'25px'}>
                  <SupportRequestType setFieldValue={setFieldValue} />
                  <SupportContactMethods setFieldValue={setFieldValue} />
                  <SupportRequestInputs setFieldValue={setFieldValue} />
                </Flex>
                <Flex w="full" justifyContent={'end'} pt="25px">
                  <SubmitButton />
                </Flex>
              </Form>
            )
          }}
        </Formik>
        <ModalDialog
          title=""
          description={
            <Flex flexDir="column">
              <Flex
                justify={'center'}
                alignItems={'center'}
                backgroundColor={'brand.secondary'}
                p={'25px'}
                color={'brand.text.yellow'}
                rounded={'8px'}
                mb="25px"
              >
                <Heading as="h3" fontSize={20} fontWeight={'medium'}>
                  Are you sure you want to submit this request?
                </Heading>
              </Flex>
              <Text>
                Once this is set in motion, the necessary steps will be taken to ensure your
                satisfaction
              </Text>
              <Flex w="full" gap={'25px'} justify="space-between" align={'center'} mt="50px">
                <Button variant={'unstyled'} rounded="full" onClick={onCloseSubmitDialog}>
                  Cancel
                </Button>
                <Button
                  variant={'dark'}
                  onClick={async () => {
                    await createSupportRequest({
                      variables: {
                        input: {
                          ...formData
                        }
                      }
                    })

                    onOpen()
                  }}
                >
                  Submit
                </Button>
              </Flex>
            </Flex>
          }
          isOpen={isOpenSubmitDialog}
          onClose={onCloseSubmitDialog}
          hasModalFooter={false}
          hasCloseIcon={false}
        />
        <ModalDialog
          status="success"
          title="Request sent"
          description="Your support request has been sent, we will be in contact to discuss  what is needed"
          isOpen={isOpen}
          onClose={onClose}
          buttonTextSuccess="Got It!"
          successLink="/user/dashboard"
          hasCloseIcon={false}
        />
      </Flex>
    </>
  )
}

export default SupportContainer
