import { Box, Button, Flex, Link, Text } from '@chakra-ui/react'
import { FC, useEffect, useState } from 'react'
import {
  Subscription,
  useGetUserQuery,
  User,
  useUpdateUserPropertyMutation
} from '../../../../generated/graphql'
import { AgreementDataType, UserStorage } from '../../../../types'
import { ContainerHeading } from '../../../Headings'
import * as yup from 'yup'
import ConnectedCheckbox from '../../.././../components/FormElements/ConnectedCheckbox'
import {
  useUpdateDynamoUserMutation,
  useUpdateUserMutation,
  useGenerateSignedDocumentsMutation,
  AgreementDataInput
} from '../../../../generated/graphql'
import { Form, Formik, useField } from 'formik'
import SubmitButton from '../../../FormElements/SubmitButton'
import BackButton from '../../../FormElements/BackButton'
import { useNavigate } from 'react-router-dom'
import { DocumentSigningCompleteModal } from '../../../Modals'
import OptionalUploadModal from '../../../Modals/OptionalUploadModal'
import axios from 'axios'
import { AGREEMENT_GENERATION_URL } from '../../../../constants'

type DocumentSigningType = {
  user?: UserStorage
  recordId: string
  inputValue: InputValue
  setInputValue: (vale: any) => void
  subscriptionAgreementData: AgreementDataType
  handleRefethUserData: () => void
  entityType: string
  subscriptionName: string
  orderNumber: number
}
interface InputValue {
  [key: string]: Object | Object[]
}

const validationSchema = yup.object({
  insurance: yup.boolean().oneOf([true], ''),
  acceptDocumentSigning: yup.boolean().oneOf([true], ''),
  debitOrder: yup.boolean().oneOf([true], ''),
  generalTerms: yup.boolean().oneOf([true], ''),
  compliance: yup.boolean().oneOf([true], ''),
  subscriptionBox: yup.boolean().oneOf([true], ''),
  installationFee: yup.boolean().oneOf([true], ''),
  monthlyFee: yup.boolean().oneOf([true], ''),
  annualEscalation: yup.boolean().oneOf([true], ''),
  otherFees: yup.boolean().oneOf([true], ''),
  termination: yup.boolean().oneOf([true], ''),
  purchaseOfSystem: yup.boolean().oneOf([true], ''),
  technicalAcknowledgment: yup.boolean().oneOf([true], ''),
  technicalAcknowledgmentAgreement: yup.boolean().oneOf([true], '')
})

const DocumentSigning: FC<DocumentSigningType> = ({
  user,
  recordId,
  orderNumber,
  subscriptionName,
  inputValue: agreementInfo,
  subscriptionAgreementData,
  handleRefethUserData,
  entityType
}) => {
  const navigate = useNavigate()
  const [updateUserProperty] = useUpdateUserPropertyMutation()
  const [updateUser] = useUpdateUserMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const [generateSignedDocuments] = useGenerateSignedDocumentsMutation()
  const [openCompletedModal, setOpenCompletedModal] = useState<boolean>(false)
  const [openOptionalUploadModal, setOpenOptionalUploadModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [previousPage, setPreviousPage] = useState<string>('documents')

  useEffect(() => {
    setTimeout(() => {
      handleRefethUserData && handleRefethUserData()
    }, 2000)
  }, [])

  useEffect(() => {
    if (entityType === 'business') {
      setPreviousPage('business-documents')
    }
  }, [entityType])

  const [inputValue, setInputValue] = useState<InputValue>({
    subscriptionAgreement: false,
    debitOrder: false,
    generalTerms: false,
    compliance: false,
    technicalAcknowledgmentAgreement: false
  })

  const [streetAddress, setStreetAddress] = useState<string | undefined | null>('')

  const { data } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const initialValues = {
    subscriptionAgreement: inputValue.subscriptionAgreement,
    debitOrder: inputValue.debitOrder,
    generalTerms: inputValue.generalTerms,
    compliance: inputValue.compliance,
    subscriptionBox: agreementInfo.subscriptionBox,
    installationFee: agreementInfo.installationFee,
    monthlyFee: agreementInfo.monthlyFee,
    annualEscalation: agreementInfo.annualEscalation,
    otherFees: agreementInfo.otherFees,
    termination: agreementInfo.termination,
    purchaseOfSystem: agreementInfo.purchaseOfSystem,
    insurance: agreementInfo.insurance,
    technicalAcknowledgment: subscriptionName.toLowerCase().includes('small')
      ? agreementInfo.technicalAcknowledgment
      : true,
    technicalAcknowledgmentAgreement: subscriptionName.toLowerCase().includes('small')
      ? inputValue.technicalAcknowledgmentAgreement
      : true
  }

  useEffect(() => {
    if (user) {
      if (user?.info) {
        // @ts-ignore
        setStreetAddress(user.info.properties[0].streetAddress)
      }
    }
  }, [user])

  useEffect(() => {
    if (!subscriptionName.toLowerCase().includes('small')) {
      setInputValue((prevState: any) => ({
        ...prevState,
        technicalAcknowledgment: true,
        technicalAcknowledgmentAgreement: true
      }))
    }
  }, [subscriptionName])

  //todo: handle agreement generation  Errors gracefully
  const handleGenerateAgreement = async (recordId: number, email: string, orderNumber: number) => {
    try {
      const resp = await axios.post(AGREEMENT_GENERATION_URL, {
        recordId,
        email,
        orderNumber,
        isSigned: true,
        type: 'email'
      })

      if (resp.status !== 200) {
        throw new Error('Error generating agreement')
      }

      const { status, message } = resp.data

      return { status, message }
    } catch (error) {
      // console.log(error)
      return { status: 'failed', message: 'Error generating agreement' }
    }
  }

  return (
    <Flex w="full" gap="25px" direction={'column'} color={'brand.text.primary'}>
      {/* Contract Summary */}
      <Flex
        w="full"
        gap="25px"
        direction={'column'}
        p={'25px'}
        rounded="8px"
        bgColor={'brand.neutral'}
        shadow={'md'}
      >
        <ContainerHeading title={'Agreement '} />
        <Formik
          validateOnMount
          initialValues={initialValues}
          validationSchema={validationSchema}
          validate={() => {}}
          validateOnChange={true}
          enableReinitialize
          onSubmit={async ({}, { setSubmitting }) => {
            try {
              setSubmitting(true)

              // Generate legal documents
              // await handleGenerateAgreement(+recordId, user?.info?.email as string, orderNumber)
              const { status, message } = await handleGenerateAgreement(
                +recordId,
                user?.info?.email as string,
                orderNumber
              )

              if (status !== 'success') {
                //  throw error
              }

              await updateUserProperty({
                variables: {
                  recordId,
                  input: {
                    orderStatus: 'Agreement Signed'
                  }
                }
              })

              await updateDynamoUser({
                variables: {
                  email: user?.info?.email as string,
                  input: {
                    onboardingProgressCompletedStep: 9,
                    onboardingInProgress: false
                  }
                }
              })

              setSubmitting(false)
              setOpenCompletedModal(true)
            } catch (e) {
              console.log(e)
            }
          }}
        >
          <Form>
            <Flex w="full" gap="25px" direction={'column'} color={'brand.text.primary'}>
              <Flex direction={'column'}>
                <AgreementItem
                  name={'debitOrder'}
                  label={'Yes, I agree to the '}
                  linkText={'Debit Order Mandate'}
                  url={`/user/agreement/debit-order-mandate/${recordId}`}
                  setInputValue={setInputValue}
                />
                <Text fontSize={13} my={'5px'} w="full">
                  The Customer hereby instructs and authorises the Seller to draw direct debits
                  against the Customer&#39;s bank account for an amount equal to the amounts owing
                  by the Customer under this Agreement as invoiced for each month in advance.
                </Text>
                <AgreementItem
                  name={'subscriptionAgreement'}
                  label={'Yes, I agree to the '}
                  linkText={'Subscription Agreement'}
                  url={`/user/agreement/subscription-agreement/${recordId}`}
                  setInputValue={setInputValue}
                />
                <Text fontSize={13} my={'5px'} w="full">
                  By clicking <b>"Yes, I agree to the Subscription Agreement"</b>, the Customer
                  acknowledges that such acceptance serves as a signature and will have the same
                  legal effect, validity or enforceability as a handwritten signature. The Customer
                  must not click <b>"Yes, I agree to the Subscription Agreement"</b> if they do not
                  wish to be bound by the terms and conditions of this Agreement and the Contract
                  Details.
                </Text>
                <Text fontSize={13} my={'5px'} w="full">
                  Further, by clicking on the <b>"Yes, I agree to the Subscription Agreement"</b>{' '}
                  option above, I/we hereby duly authorises GO SOLR Proprietary Limited ("the
                  Seller"), to obtain a credit report from a credit reporting company chosen by the
                  Seller in order to determine whether it is in the Seller's interest to conclude an
                  agreement with me/us.
                </Text>

                <AgreementItem
                  name={'generalTerms'}
                  label={'Yes, I agree to the '}
                  linkText={'General Terms and Conditions'}
                  url={
                    'https://gosolr-static-files.s3.eu-west-1.amazonaws.com/Go+Solr+general+terms+and+conditions.pdf'
                  }
                  setInputValue={setInputValue}
                />

                <Text fontSize={13} my={'5px'} w="full">
                  By clicking <b>"Yes, I agree to the General Terms and Conditions"</b>, the
                  Customer acknowledges that such an acceptance will serve as a signature and will
                  have the same legal effect, validity or enforceability as a handwritten signature.
                  The Customer must not click{' '}
                  <b>"Yes, I agree to the General Terms and Conditions"</b> if they do not wish to
                  be bound by the terms and conditions of this Agreement and the Contract Details.
                </Text>

                <AgreementItem
                  name={'compliance'}
                  label={'Yes, I agree to the'}
                  linkText={'Compliance Acknowledgement '}
                  url={`/user/agreement/compliance-acknowledgement/${recordId}`}
                  setInputValue={setInputValue}
                />

                <Text fontSize={13} my={'5px'} w="full">
                  By clicking <b>"Yes, I agree to the Compliance Acknowledgement"</b>, the Customer
                  acknowledges that such an acceptance will serve as a signature and will have the
                  same legal effect, validity or enforceability as a handwritten signature. The
                  Customer must not click <b>"Yes, I agree to the Compliance Acknowledgement"</b> if
                  they do not wish to be bound by the <b>Compliance Acknowledgement</b> of this
                  Agreement and the Contract Details.
                </Text>
                <Text fontSize={13} my={'5px'} w="full">
                  Note that installing a solar PV system on your house may impact the meter you use
                  as well as the tariff. Consequences are indicated in the compliance
                  acknowledgment.
                </Text>
                {subscriptionName.toLowerCase().includes('small') && (
                  <>
                    <AgreementItem
                      name={'technicalAcknowledgmentAgreement'}
                      label={'Yes, I agree to the'}
                      linkText={'Technical Acknowledgment'}
                      url={`/user/agreement/technical-acknowledgement/${recordId}`}
                      setInputValue={setInputValue}
                    />
                    <Text fontSize={13} my={'5px'} w="full">
                      Should the grid be unavailable, the GoSolr system will be limited to the
                      throughput of the inverter. For the GoSolr Small, this will be limited to
                      3,6kW. Should consumption in the home exceed this when the grid is
                      unavailable, the system will trip.
                    </Text>
                    <Text fontSize={13} my={'5px'} w="full">
                      By clicking <b>"Yes, I agree to the Technical Acknowledgmentt"</b>, the
                      Customer acknowledges that the Customer understood, and accepts the technical
                      limitations of the GoSolr Small product and that Customer is responsible for
                      managing the Customer's household consumption when the grid is unavailable.
                    </Text>

                    <Text fontSize={13} my={'5px'} w="full">
                      By clicking <b>"Yes, I agree to the Technical Acknowledgmentt"</b>, the
                      Customer acknowledges that such an acceptance will serve as a signature and
                      will have the same legal effect, validity or enforceability as a handwritten
                      signature. The Customer must not click{' '}
                      <b>"Yes, I agree to the Technical Acknowledgmentt"</b> if they do not wish to
                      be bound by the <b>Technical Acknowledgmentt</b> of this Agreement and the
                      Contract Details.
                    </Text>
                  </>
                )}

                <Text py={'25px'}>
                  If you have an issue with any of the above information, feel free to contact us at{' '}
                  <Link
                    href={`mailto: hello@gosolr.co.za?subject = Support Request`}
                    color={'#3b82f6'}
                  >
                    hello@gosolr.co.za
                  </Link>{' '}
                  or give us a call at{' '}
                  <Link href={`tel:010 880 3948`} color={'#3b82f6'}>
                    010 880 3948
                  </Link>
                </Text>
              </Flex>
            </Flex>
            <Flex w="full" justifyContent={'space-between'} alignItems="center" mt={'25px'}>
              <BackButton
                callback={() => {
                  navigate(`/user/onboarding/${previousPage}/${recordId}`)
                }}
              />

              <SubmitButton title="Sign agreement" />
            </Flex>
          </Form>
        </Formik>
      </Flex>
      <OptionalUploadModal
        isOpen={openOptionalUploadModal}
        onClose={() => {}}
        recordId={recordId}
      />

      <DocumentSigningCompleteModal
        isOpen={openCompletedModal}
        heading={'Agreement signing complete'}
        onClose={() => {}}
      >
        <Text textAlign={'center'} color={'brand.text.primary'}>
          Thanks for doing the legal stuff.
        </Text>
        <Text textAlign={'center'} color={'brand.text.primary'}>
          We can’t wait to help you take your power back!
        </Text>
        <Button
          isLoading={isLoading}
          variant={'dark'}
          onClick={async () => {
            setIsLoading(true)
            setOpenCompletedModal(false)
            setOpenOptionalUploadModal(true)
          }}
        >
          Next
        </Button>
      </DocumentSigningCompleteModal>
    </Flex>
  )
}

export default DocumentSigning

type AgreementItemType = {
  url: string
  name: string
  label: string
  linkText: string
  setInputValue: (vale: any) => void
}
export const AgreementItem: FC<AgreementItemType> = ({
  url,
  name,
  label,
  setInputValue,
  linkText
}) => {
  const [field, helpers] = useField(name)

  return (
    <Flex
      alignItems={['left', 'left', 'center', 'center']}
      gap={'5px'}
      mt="50px"
      direction={['column', 'column', 'row', 'row']}
    >
      <Box>
        <ConnectedCheckbox
          name={name}
          label={label}
          fontWeight={13}
          isInvalid={!field?.value}
          colorScheme="green"
          _active={{ outlineColor: 'black' }}
          onChange={(value) => {
            setInputValue((prevState: any) => ({ ...prevState, [name]: !field?.value }))
          }}
        />
      </Box>

      <Text
        _hover={{
          cursor: 'pointer'
        }}
        textAlign={['center', 'left', 'left', 'left']}
        variant={'unstyled'}
        color="#3b82f6"
        fontWeight={13}
        mb={'5px'}
        onClick={() => window.open(url, '_blank')}
      >
        {linkText}
      </Text>
    </Flex>
  )
}
