/* istanbul ignore file */
import axios, { AxiosResponse } from 'axios'
import { API_HOST } from '../../constants'
import { User } from '../../generated/graphql'
import { getJWT } from '../auth'
import { Buffer } from 'buffer'

/**
 *
 * @param key
 * @param user
 * @param file
 * @param onUploadProgress
 *
 * NOTE: The "key" param needs to match the same name as the one chosen for the backend, or vice versa.
 */
export const uploadUserFile = async (
  key: string,
  user: User,
  recordId: string,
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
): Promise<AxiosResponse<File[]>> => {
  try {
    const buffer = await file.arrayBuffer()
    const response = await axios.post(
      `${API_HOST}/upload-user-file`,
      {
        key,
        customerNumber: user?.customerNumber,
        recordId,
        email: user?.email,
        fileName: file.name,
        file: Buffer.from(buffer).toString('base64')
      },
      {
        onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
        headers: {
          Authorization: getJWT()
        }
      }
    )

    return response
  } catch (e) {
    throw e
  }
}

export const uploadFile = async (
  key: string,
  user: User,
  recordId: string,
  file: File,
  destination: string,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
): Promise<AxiosResponse<File[]>> => {
  try {
    const buffer = await file.arrayBuffer()
    const response = await axios.post(
      `${API_HOST}/upload-file`,
      {
        key,
        customerNumber: user?.customerNumber,
        recordId,
        email: user?.email,
        fileName: file.name,
        file: Buffer.from(buffer).toString('base64'),
        destination
      },
      {
        onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
        headers: {
          Authorization: getJWT()
        }
      }
    )

    return response
  } catch (e) {
    throw e
  }
}

export const uploadProofOfAddress = async (
  key: string,
  user: User,
  recordId: string,
  file: File,
  onUploadProgress?: (progressEvent: ProgressEvent, file: File) => void
): Promise<AxiosResponse<File[]>> => {
  try {
    const buffer = await file.arrayBuffer()
    const response = await axios.post(
      `${API_HOST}/upload-proof-of-address`,
      {
        key,
        customerNumber: user?.customerNumber,
        recordId,
        fileName: file.name,
        file: Buffer.from(buffer).toString('base64')
      },
      {
        onUploadProgress: (event) => onUploadProgress && onUploadProgress(event, file),
        headers: {
          Authorization: getJWT()
        }
      }
    )

    return response
  } catch (e) {
    throw e
  }
}
