import { FC } from 'react'
import { Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import {
  useAddBankMutation,
  useUpdateDynamoUserMutation,
  useUpdateUserMutation
} from '../../../../generated/graphql'
import FileUploader from './FileUploader'
import BackButton from '../../../FormElements/BackButton'

import RequiredDocumentContianer from './RequiredDocumentContianer'
import { useAuthContext } from '../../../../context/AuthProvider'

import { InitialBillingFormValueTypes } from '../../../../types'
import BillingFrom from '../Billing/billingForm'

import { bankAccountValidation } from '../../../../formValidation'

type InitialValues = {
  proofOfAddress: File | null
  idPassportDocument: File | null
  bankStatementDocument_01: File | null
  bankStatementDocument_02: File | null
  bankStatementDocument_03: File | null
  loadingFile?: boolean | false
  loadingIdFile?: boolean | false
  loadingBankStatement1?: boolean | false
  loadingBankStatement2?: boolean | false
  loadingBankStatement3?: boolean | false
} & InitialBillingFormValueTypes

type FormProps = {
  recordId: string
  file?: File | null
  idFile?: File | null
  bankStatementDocument_01?: File | null
  bankStatementDocument_02?: File | null
  bankStatementDocument_03?: File | null
  loadingFile?: boolean | false
  loadingIdFile?: boolean | false
  loadingBankStatement1?: boolean | false
  loadingBankStatement2?: boolean | false
  loadingBankStatement3?: boolean | false
  debitOrderDate?: string | ''
  bankName?: string | ''
  memberName?: string | ''
  bankAccountType?: string | ''
  bankAccountNumber?: string | ''
  branchCode?: string | ''
}

const docInfo = {
  proofOfAddress: {
    title: 'Proof of address',
    description:
      'Please upload proof of address so we can cross-check your property information with your identity information. This can be in the form of a utility bill or bank statement.'
  },
  idPassport: {
    title: 'ID/Passport document',
    description:
      'Please upload a copy of your ID document or passport for reference. This helps us cross-check your details with our credit check process.'
  },
  bankStatemenet1: {
    title: 'Latest 3 months bank statements',
    description:
      'Please upload your bank statements for the last 3 months in a single file or as 3 separate files.'
  },
  // bankStatemenet2and3: {
  //   title: 'Latest 3 Months bank statements',
  //   description: 'Please upload your bank statements for the last 3 months.'
  // },
  payslips: {
    title: 'Latest 3 months payslips',
    description: 'Please upload your payslips for the latest 3 months'
  },
  bankingDetails: {
    title: 'Banking details',
    description: 'Please input your banking details.'
  }
}

const DocumentUploadForm: FC<FormProps> = ({
  file,
  idFile,
  recordId,
  bankStatementDocument_01,
  bankStatementDocument_02,
  bankStatementDocument_03,
  loadingIdFile,
  loadingBankStatement1,
  loadingBankStatement2,
  loadingBankStatement3,
  debitOrderDate,
  bankName,
  memberName,
  bankAccountType,
  bankAccountNumber,
  branchCode
}) => {
  const navigate = useNavigate()

  // const { user } = useAuthContext() note that this was commented out for testing purposes and needs to be put back in

  const { user } = useAuthContext()

  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [addBank] = useAddBankMutation()

  const INITIAL_VALUES: InitialValues = {
    proofOfAddress: file || null,
    idPassportDocument: idFile || null,
    bankStatementDocument_01: bankStatementDocument_01 || null,
    bankStatementDocument_02: bankStatementDocument_02 || null,
    bankStatementDocument_03: bankStatementDocument_03 || null,
    debitOrderDate: debitOrderDate || '26th',
    bankName: bankName || '',
    memberName: memberName || '',
    bankAccountType: bankAccountType || '',
    bankAccountNumber: bankAccountNumber || '',
    branchCode: branchCode || ''
  }

  return (
    <Flex direction="column" w="100%">
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={bankAccountValidation}
        enableReinitialize
        // isInitialValid={false}
        // validateOnMount
        onSubmit={async (
          {
            proofOfAddress,
            idPassportDocument,
            bankStatementDocument_01,
            bankStatementDocument_02,
            bankStatementDocument_03,
            bankName,
            memberName,
            bankAccountType,
            bankAccountNumber,
            branchCode,
            debitOrderDate
          },
          { setSubmitting, setStatus, setFieldError }
        ) => {
          try {
            setSubmitting(true)
            let fieldError = {
              field: '',
              message: ''
            }

            if (!debitOrderDate) {
              fieldError = {
                field: 'debitOrderDate',
                message: 'Debit order date'
              }
              setFieldError(fieldError.field, `${fieldError.message} is required`)
            }
            if (!idPassportDocument) {
              fieldError = {
                field: 'idPassportDocument',
                message: docInfo.idPassport.title
              }
              setFieldError(fieldError.field, `${fieldError.message} is required`)
            }

            if (!bankStatementDocument_01) {
              fieldError = {
                field: 'bankStatementDocument_01',
                message: docInfo.bankStatemenet1.title
              }
              setFieldError(fieldError.field, `${fieldError.message} is required`)
            }

            if (fieldError.field && fieldError.message) {
              return setFieldError(fieldError.field, `${fieldError.message} is required`)
            }

            await updateDynamoUser({
              variables: {
                email: user?.info?.email as string,
                input: {
                  onboardingProgressCompletedStep: 8.0
                }
              }
            })

            await updateUser({
              variables: {
                email: user?.info?.email as string,
                input: {
                  recordId,
                  debitOrderDate: debitOrderDate
                }
              }
            })

            await addBank({
              variables: {
                email: user?.info?.email as string,
                recordId,
                input: {
                  bankName,
                  branchCode,
                  memberName,
                  bankAccountType,
                  bankAccountNumber
                }
              }
            })

            navigate(`/user/onboarding/agreement/${recordId}`)
          } catch (error) {
            let message
            if (error instanceof Error) message = error.message
            else message = String(error)
            setStatus(message)
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors }) => {
          return (
            <Flex direction="column" w="full" gap={'25px'}>
              <Form style={{ width: '100%' }}>
                <Grid
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(2, 1fr)'
                  ]}
                  gap={'15px'}
                >
                  {/* Banking Details */}
                  <GridItem colSpan={1}>
                    <Flex direction={'column'}>
                      <RequiredDocumentContianer
                        title={docInfo.bankingDetails.title}
                        description={docInfo.bankingDetails.description}
                      >
                        <BillingFrom
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                          values={values}
                          errors={errors}
                        />
                      </RequiredDocumentContianer>
                    </Flex>
                  </GridItem>

                  {/* Bank Statements */}
                  <GridItem colSpan={[1]}>
                    <RequiredDocumentContianer
                      title={docInfo.bankStatemenet1.title}
                      description={docInfo.bankStatemenet1.description}
                    >
                      <Flex
                        direction={'column'}
                        flex={1}
                        w="full"
                        h={'full'}
                        gap={15}
                        justifyContent={'space-between'}
                      >
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 1 / 3 months bank statements</Text>

                          <FileUploader
                            name="bankStatementDocument_01"
                            recordId={recordId}
                            loading={loadingBankStatement1}
                            destination={'property'}
                          />
                        </Flex>
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 2</Text>
                          <FileUploader
                            name="bankStatementDocument_02"
                            recordId={recordId}
                            loading={loadingBankStatement2}
                            destination={'property'}
                          />
                        </Flex>
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 3</Text>
                          <FileUploader
                            name="bankStatementDocument_03"
                            recordId={recordId}
                            loading={loadingBankStatement3}
                            destination={'property'}
                          />
                        </Flex>
                      </Flex>
                    </RequiredDocumentContianer>
                  </GridItem>
                  <GridItem colSpan={[1, 1, 2, 2]}>
                    <RequiredDocumentContianer
                      title={docInfo.idPassport.title}
                      description={docInfo.idPassport.description}
                    >
                      <FileUploader
                        name="idPassportDocument"
                        recordId={recordId}
                        loading={loadingIdFile}
                        destination={'user'}
                      />
                    </RequiredDocumentContianer>
                  </GridItem>
                </Grid>

                <Flex
                  w="full"
                  justifyContent={'space-between'}
                  alignItems="center"
                  mt={'25px'}
                  bgColor={'brand.neutral'}
                  p={'25px'}
                  rounded={'8px'}
                  shadow={'md'}
                >
                  <BackButton
                    callback={() => {
                      navigate(`/user/onboarding/credit-check/${recordId}`)
                    }}
                  />

                  <Button
                    variant={'dark'}
                    type={'submit'}
                    isLoading={isSubmitting}
                    isDisabled={!values.idPassportDocument || !values.bankStatementDocument_01}
                  >
                    Next
                  </Button>
                </Flex>
              </Form>
            </Flex>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default DocumentUploadForm
