import React from 'react'
import { Flex } from '@chakra-ui/react'
import SubscriptionItem from './SubscriptionItem'
import { Maybe, UserProperty } from '../../generated/graphql'
import { NoDataFound } from '../Misc/NoDataFound'

export type SubscriptionListType = {
  subscriptions: UserProperty[]
  onboardingStepsCompleted: Maybe<number> | undefined
}

const SubscriptionList: React.FC<SubscriptionListType> = ({
  subscriptions,
  onboardingStepsCompleted
}) => {
  return (
    <Flex w="full" gap={'15px'} flexWrap={'wrap'}>
      {subscriptions.length ? (
        subscriptions.map((item, index) => (
          <SubscriptionItem
            key={`transaction-${index}`}
            property={item}
            onboardingStepsCompleted={onboardingStepsCompleted ? onboardingStepsCompleted : 1}
          />
        ))
      ) : (
        <NoDataFound />
      )}
    </Flex>
  )
}

export default SubscriptionList
