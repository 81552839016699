import { Subscription } from '../generated/graphql'
import { Product } from '../types'

const gosolr_staging_product_catalogue: Product[] = [
  {
    recordId: 54,
    productId: 173,
    productName: 'Small',
    onceOffFee: 1399,
    subscriptionFee: 1399,
    inverterSpec: '3,6kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 128781,
    buyout_year_six: 133703,
    buyout_year_five: 138348,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '6',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 146609,
    buyout_year_four: 142380,
    subscriptionOption: 'Small',
    addOns: []
  },
  {
    recordId: 37,
    productId: 144,
    productName: 'Upgrade small to Medium',
    onceOffFee: 1740,
    subscriptionFee: 1740,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 142310,
    buyout_year_six: 147590,
    buyout_year_five: 152581,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '8',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 161464,
    buyout_year_four: 156909,
    subscriptionOption: 'Upgrade small to Medium',
    addOns: []
  },
  {
    recordId: 37,
    productId: 144,
    productName: 'Medium',
    onceOffFee: 1740,
    subscriptionFee: 1740,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 142310,
    buyout_year_six: 147590,
    buyout_year_five: 152581,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '8',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 161464,
    buyout_year_four: 156909,
    subscriptionOption: 'Medium',
    addOns: []
  },
  {
    recordId: 32,
    productId: 149,
    productName: 'Large + battery',
    onceOffFee: 3590,
    subscriptionFee: 3590,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 260464,
    buyout_year_six: 273431,
    buyout_year_five: 285530,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '14',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 306916,
    buyout_year_four: 296119,
    subscriptionOption: 'Large',
    addOns: ['battery']
  },
  {
    recordId: 33,
    productId: 148,
    productName: 'Large',
    onceOffFee: 2900,
    subscriptionFee: 2900,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 233946,
    buyout_year_six: 243833,
    buyout_year_five: 253119,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '14',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 269595,
    buyout_year_four: 261209,
    subscriptionOption: 'Large',
    addOns: []
  },
  {
    recordId: 34,
    productId: 146,
    productName: 'Medium + 2 panels + battery',
    onceOffFee: 2660,
    subscriptionFee: 2660,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 179000,
    buyout_year_six: 187329,
    buyout_year_five: 195122,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '10',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 208915,
    buyout_year_four: 201929,
    subscriptionOption: 'Medium',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 35,
    productId: 147,
    productName: 'Medium + battery',
    onceOffFee: 2430,
    subscriptionFee: 2430,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 168828,
    buyout_year_six: 177189,
    buyout_year_five: 184992,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '8',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 198785,
    buyout_year_four: 191820,
    subscriptionOption: 'Medium',
    addOns: ['battery']
  },
  {
    recordId: 36,
    productId: 145,
    productName: 'Medium + 2 panels',
    onceOffFee: 1970,
    subscriptionFee: 1970,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 152482,
    buyout_year_six: 157731,
    buyout_year_five: 162711,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '10',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 171594,
    buyout_year_four: 167018,
    subscriptionOption: 'Medium',
    addOns: ['2 panels']
  },

  {
    recordId: 38,
    productId: 153,
    productName: 'Large 3phase + 2 panels',
    onceOffFee: 3830,
    subscriptionFee: 3830,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 279130,
    buyout_year_six: 290653,
    buyout_year_five: 301488,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '16',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 320721,
    buyout_year_four: 310919,
    subscriptionOption: 'Large 3phase',
    addOns: ['2 panels']
  },
  {
    recordId: 39,
    productId: 154,
    productName: 'Extra Large + 2 panels',
    onceOffFee: 4630,
    subscriptionFee: 4630,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 334934,
    buyout_year_six: 349447,
    buyout_year_five: 363065,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '20',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 387208,
    buyout_year_four: 374935,
    subscriptionOption: 'Extra Large',
    addOns: ['2 panels']
  },
  {
    recordId: 40,
    productId: 162,
    productName: 'Extra Large',
    onceOffFee: 4400,
    subscriptionFee: 4400,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 324762,
    buyout_year_six: 339307,
    buyout_year_five: 352934,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 377078,
    buyout_year_four: 364826,
    subscriptionOption: 'Extra Large',
    addOns: []
  },
  {
    recordId: 50,
    productId: 151,
    productName: 'Large 3phase',
    onceOffFee: 3600,
    subscriptionFee: 3600,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 268958,
    buyout_year_six: 280512,
    buyout_year_five: 291358,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '14',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 310591,
    buyout_year_four: 300810,
    subscriptionOption: 'Large 3phase',
    addOns: []
  },

  {
    recordId: 41,
    productId: 161,
    productName: 'Large 3phase + 2 panels + battery',
    onceOffFee: 4520,
    subscriptionFee: 4520,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 305648,
    buyout_year_six: 320251,
    buyout_year_five: 333899,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '16',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 358043,
    buyout_year_four: 345830,
    subscriptionOption: 'Large 3phase',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 42,
    productId: 160,
    productName: 'Upgrade large 3phase to extra large',
    onceOffFee: 4400,
    subscriptionFee: 4400,
    inverterSpec: '',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '4',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Upgrade large 3phase to extra large',
    addOns: []
  },
  {
    recordId: 43,
    productId: 159,
    productName: 'Upgrade medium to large',
    onceOffFee: 2900,
    subscriptionFee: 2900,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '6',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Upgrade medium to large',
    addOns: []
  },
  {
    recordId: 44,
    productId: 158,
    productName: 'Large + 4 panels + battery',
    onceOffFee: 4050,
    subscriptionFee: 4050,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 280808,
    buyout_year_six: 293712,
    buyout_year_five: 305790,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 327177,
    buyout_year_four: 316338,
    subscriptionOption: 'Large',
    addOns: ['4 panels', 'battery']
  },

  {
    recordId: 45,
    productId: 157,
    productName: 'Extra Large + battery',
    onceOffFee: 5090,
    subscriptionFee: 5090,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 20kWh Lithium battery',
    batteryCount: 4,
    buyout_year_seven: 351280,
    buyout_year_six: 368905,
    buyout_year_five: 385345,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 414400,
    buyout_year_four: 399737,
    subscriptionOption: 'Extra Large',
    addOns: ['battery']
  },
  {
    recordId: 46,
    productId: 156,
    productName: 'Extra 2 Solar panels',
    onceOffFee: 230,
    subscriptionFee: 230,
    inverterSpec: '',
    batterySpec: '',
    batteryCount: null,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '2',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Extra 2 Solar panels',
    addOns: []
  },
  {
    recordId: 47,
    productId: 155,
    productName: 'Extra Lithium battery',
    onceOffFee: 690,
    subscriptionFee: 690,
    inverterSpec: '',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '',
    de_installation_fee_after_year_three: null,
    panelSpec: '',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Extra Lithium battery',
    addOns: []
  },
  {
    recordId: 48,
    productId: 125,
    productName: 'Extra Large + 2 panels + battery',
    onceOffFee: 5320,
    subscriptionFee: 5320,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 20kWh Lithium battery',
    batteryCount: 4,
    buyout_year_seven: 361452,
    buyout_year_six: 379046,
    buyout_year_five: 395475,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '20',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 424530,
    buyout_year_four: 409846,
    subscriptionOption: 'Extra Large',
    addOns: ['2 panels', 'battery']
  },

  {
    recordId: 49,
    productId: 152,
    productName: 'Large 3phase + battery',
    onceOffFee: 4290,
    subscriptionFee: 4290,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 3,
    buyout_year_seven: 295476,
    buyout_year_six: 310111,
    buyout_year_five: 323769,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '14',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 347912,
    buyout_year_four: 335720,
    subscriptionOption: 'Large 3phase',
    addOns: ['battery']
  },

  {
    recordId: 51,
    productId: 150,
    productName: 'Large + 2 panels',
    onceOffFee: 3130,
    subscriptionFee: 3130,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 244118,
    buyout_year_six: 253973,
    buyout_year_five: 263250,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '16',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 279725,
    buyout_year_four: 271318,
    subscriptionOption: 'Large',
    addOns: ['2 panels']
  },
  {
    recordId: 52,
    productId: 163,
    productName: 'Large + 2 panels + battery',
    onceOffFee: 3820,
    subscriptionFee: 3820,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium batteries',
    batteryCount: 2,
    buyout_year_seven: 270636,
    buyout_year_six: 283572,
    buyout_year_five: 295660,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '16',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 317046,
    buyout_year_four: 306229,
    subscriptionOption: 'Large',
    addOns: ['2 panels', 'battery']
  }
]

const gosolr_prod_product_catalogue: Product[] = [
  {
    recordId: 2275,
    productId: 173,
    productName: 'Small',
    onceOffFee: 1399,
    subscriptionFee: 1399,
    inverterSpec: '3,6kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 128781,
    buyout_year_six: 133703,
    buyout_year_five: 138348,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '6',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 146609,
    buyout_year_four: 142380,
    subscriptionOption: 'Small',
    addOns: []
  },
  {
    recordId: 37,
    productId: 144,
    productName: 'Medium',
    onceOffFee: 1740,
    subscriptionFee: 1740,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 142310,
    buyout_year_six: 147590,
    buyout_year_five: 152581,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '8',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 161464,
    buyout_year_four: 156909,
    subscriptionOption: 'Medium',
    addOns: []
  },
  {
    recordId: 32,
    productId: 149,
    productName: 'Large + battery',
    onceOffFee: 3590,
    subscriptionFee: 3590,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 260464,
    buyout_year_six: 273431,
    buyout_year_five: 285530,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '14',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 306916,
    buyout_year_four: 296119,
    subscriptionOption: 'Large',
    addOns: ['battery']
  },
  {
    recordId: 33,
    productId: 148,
    productName: 'Large',
    onceOffFee: 2900,
    subscriptionFee: 2900,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 233946,
    buyout_year_six: 243833,
    buyout_year_five: 253119,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '14',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 269595,
    buyout_year_four: 261209,
    subscriptionOption: 'Large',
    addOns: []
  },
  {
    recordId: 34,
    productId: 146,
    productName: 'Medium + 2 panels + battery',
    onceOffFee: 2660,
    subscriptionFee: 2660,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 179000,
    buyout_year_six: 187329,
    buyout_year_five: 195122,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '10',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 208915,
    buyout_year_four: 201929,
    subscriptionOption: 'Medium',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 35,
    productId: 147,
    productName: 'Medium + battery',
    onceOffFee: 2430,
    subscriptionFee: 2430,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 168828,
    buyout_year_six: 177189,
    buyout_year_five: 184992,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '8',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 198785,
    buyout_year_four: 191820,
    subscriptionOption: 'Medium',
    addOns: ['battery']
  },
  {
    recordId: 36,
    productId: 145,
    productName: 'Medium + 2 panels',
    onceOffFee: 1970,
    subscriptionFee: 1970,
    inverterSpec: '5kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: 152482,
    buyout_year_six: 157731,
    buyout_year_five: 162711,
    de_installation_fee_year_one_to_three: 22770,
    panelCount: '10',
    de_installation_fee_after_year_three: 22770,
    panelSpec: '455W Solar panels',
    flatRoofFee: 4340,
    buyout_year_three: 171594,
    buyout_year_four: 167018,
    subscriptionOption: 'Medium',
    addOns: ['2 panels']
  },

  {
    recordId: 38,
    productId: 153,
    productName: 'Large 3phase + 2 panels',
    onceOffFee: 3830,
    subscriptionFee: 3830,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 279130,
    buyout_year_six: 290653,
    buyout_year_five: 301488,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '16',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 320721,
    buyout_year_four: 310919,
    subscriptionOption: 'Large 3phase',
    addOns: ['2 panels']
  },
  {
    recordId: 39,
    productId: 154,
    productName: 'Extra Large + 2 panels',
    onceOffFee: 4630,
    subscriptionFee: 4630,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 334934,
    buyout_year_six: 349447,
    buyout_year_five: 363065,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '20',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 387208,
    buyout_year_four: 374935,
    subscriptionOption: 'Extra Large',
    addOns: ['2 panels']
  },
  {
    recordId: 40,
    productId: 162,
    productName: 'Extra Large',
    onceOffFee: 4400,
    subscriptionFee: 4400,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 324762,
    buyout_year_six: 339307,
    buyout_year_five: 352934,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 377078,
    buyout_year_four: 364826,
    subscriptionOption: 'Extra Large',
    addOns: []
  },
  {
    recordId: 41,
    productId: 161,
    productName: 'Large 3phase + 2 panels + battery',
    onceOffFee: 4520,
    subscriptionFee: 4520,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 305648,
    buyout_year_six: 320251,
    buyout_year_five: 333899,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '16',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 358043,
    buyout_year_four: 345830,
    subscriptionOption: 'Large 3phase',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 42,
    productId: 160,
    productName: 'Upgrade large 3p to extra large',
    onceOffFee: 4400,
    subscriptionFee: 4400,
    inverterSpec: '',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '4',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Upgrade large 3p to extra large',
    addOns: []
  },
  {
    recordId: 43,
    productId: 159,
    productName: 'Upgrade medium to large',
    onceOffFee: 2900,
    subscriptionFee: 2900,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '6',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Upgrade medium to large',
    addOns: []
  },
  {
    recordId: 44,
    productId: 158,
    productName: 'Large + 4 panels + battery',
    onceOffFee: 4050,
    subscriptionFee: 4050,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 280808,
    buyout_year_six: 293712,
    buyout_year_five: 305790,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 327177,
    buyout_year_four: 316338,
    subscriptionOption: 'Large',
    addOns: ['4 panels', 'battery']
  },
  {
    recordId: 45,
    productId: 157,
    productName: 'Extra Large + battery',
    onceOffFee: 5090,
    subscriptionFee: 5090,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 20kWh Lithium battery',
    batteryCount: 4,
    buyout_year_seven: 351280,
    buyout_year_six: 368905,
    buyout_year_five: 385345,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 414400,
    buyout_year_four: 399737,
    subscriptionOption: 'Extra Large',
    addOns: ['battery']
  },
  {
    recordId: 46,
    productId: 156,
    productName: 'Extra 2 Solar panels',
    onceOffFee: 230,
    subscriptionFee: 230,
    inverterSpec: '',
    batterySpec: '',
    batteryCount: null,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '2',
    de_installation_fee_after_year_three: null,
    panelSpec: '455W Solar panels',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Extra 2 Solar panels',
    addOns: []
  },
  {
    recordId: 47,
    productId: 155,
    productName: 'Extra Lithium battery',
    onceOffFee: 690,
    subscriptionFee: 690,
    inverterSpec: '',
    batterySpec: 'Minimum 5kWh Lithium battery',
    batteryCount: 1,
    buyout_year_seven: null,
    buyout_year_six: null,
    buyout_year_five: null,
    de_installation_fee_year_one_to_three: null,
    panelCount: '',
    de_installation_fee_after_year_three: null,
    panelSpec: '',
    flatRoofFee: null,
    buyout_year_three: null,
    buyout_year_four: null,
    subscriptionOption: 'Extra Lithium battery',
    addOns: []
  },
  {
    recordId: 48,
    productId: 125,
    productName: 'Extra Large + 2 panels + battery',
    onceOffFee: 5320,
    subscriptionFee: 5320,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 20kWh Lithium battery',
    batteryCount: 4,
    buyout_year_seven: 361452,
    buyout_year_six: 379046,
    buyout_year_five: 395475,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '20',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 424530,
    buyout_year_four: 409846,
    subscriptionOption: 'Extra Large',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 49,
    productId: 152,
    productName: 'Large 3phase + battery',
    onceOffFee: 4290,
    subscriptionFee: 4290,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 3,
    buyout_year_seven: 295476,
    buyout_year_six: 310111,
    buyout_year_five: 323769,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '14',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 347912,
    buyout_year_four: 335720,
    subscriptionOption: 'Large 3phase',
    addOns: ['battery']
  },
  {
    recordId: 50,
    productId: 151,
    productName: 'Large 3phase',
    onceOffFee: 3600,
    subscriptionFee: 3600,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 268958,
    buyout_year_six: 280512,
    buyout_year_five: 291358,
    de_installation_fee_year_one_to_three: 32900,
    panelCount: '14',
    de_installation_fee_after_year_three: 32900,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 310591,
    buyout_year_four: 300810,
    subscriptionOption: 'Large 3phase',
    addOns: []
  },
  {
    recordId: 51,
    productId: 150,
    productName: 'Large + 2 panels',
    onceOffFee: 3130,
    subscriptionFee: 3130,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 10kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 244118,
    buyout_year_six: 253973,
    buyout_year_five: 263250,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '16',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 279725,
    buyout_year_four: 271318,
    subscriptionOption: 'Large',
    addOns: ['2 panels']
  },
  {
    recordId: 52,
    productId: 163,
    productName: 'Large + 2 panels + battery',
    onceOffFee: 3820,
    subscriptionFee: 3820,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 15kWh Lithium battery',
    batteryCount: 2,
    buyout_year_seven: 270636,
    buyout_year_six: 283572,
    buyout_year_five: 295660,
    de_installation_fee_year_one_to_three: 30000,
    panelCount: '16',
    de_installation_fee_after_year_three: 30000,
    panelSpec: '455W Solar panels',
    flatRoofFee: 7340,
    buyout_year_three: 317046,
    buyout_year_four: 306229,
    subscriptionOption: 'Large',
    addOns: ['2 panels', 'battery']
  },
  {
    recordId: 54,
    productId: 165,
    productName: 'Extra Large + 2 panels + 2 battery',
    onceOffFee: 6010,
    subscriptionFee: 6010,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 25kWh Lithium battery',
    batteryCount: 5,
    buyout_year_seven: 387970,
    buyout_year_six: 408644,
    buyout_year_five: 427886,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '20',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 461852,
    buyout_year_four: 444757,
    subscriptionOption: 'Extra Large',
    addOns: ['2 panels', '2 battery']
  },
  {
    recordId: 55,
    productId: 166,
    productName: 'Extra Large + 2 battery',
    onceOffFee: 5780,
    subscriptionFee: 5780,
    inverterSpec: '12kva Hybrid inverter',
    batterySpec: 'Minimum 25kWh Lithium battery',
    batteryCount: 5,
    buyout_year_seven: 377798,
    buyout_year_six: 398503,
    buyout_year_five: 417756,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 451721,
    buyout_year_four: 434648,
    subscriptionOption: 'Extra Large',
    addOns: ['2 battery']
  },
  {
    recordId: 1553,
    productId: 172,
    productName: 'Large + 4 panels + 2 battery',
    onceOffFee: 4740,
    subscriptionFee: 4740,
    inverterSpec: '8kva Hybrid inverter',
    batterySpec: 'Minimum 20kWh Lithium battery',
    batteryCount: 4,
    buyout_year_seven: 307325,
    buyout_year_six: 323310,
    buyout_year_five: 338201,
    de_installation_fee_year_one_to_three: 35400,
    panelCount: '18',
    de_installation_fee_after_year_three: 35400,
    panelSpec: '455W Solar panels',
    flatRoofFee: 8335,
    buyout_year_three: 364498,
    buyout_year_four: 351249,
    subscriptionOption: 'Large',
    addOns: ['4 panels', '2 battery']
  }
]

export const gosolr_product_catalogue: Product[] =
  process.env.REACT_APP_STAGE === 'prod'
    ? gosolr_prod_product_catalogue
    : gosolr_staging_product_catalogue
