import { Flex } from '@chakra-ui/react'
import SupportContainer from '../../../components/Support/SupportContainer'

const RequestSupportPage = (): JSX.Element => {
  return (
    <Flex w="100%" h={'100%'} alignItems="start" justifyContent={'center'}>
      <SupportContainer />
    </Flex>
  )
}

export default RequestSupportPage
