import { FC, useState } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Box,
} from '@chakra-ui/react';

interface FAQItem {
  category: string;
  question: string;
  answer: string;
}

interface FAQProps {
  faqItems: FAQItem[];
}

const FAQ: FC<FAQProps> = ({ faqItems }) => {
  const [openQuestions, setOpenQuestions] = useState<string[]>([]);

  const toggleQuestion = (question: string) => {
    if (openQuestions.includes(question)) {
      setOpenQuestions(openQuestions.filter((q) => q !== question));
    } else {
      setOpenQuestions([...openQuestions, question]);
    }
  };

  const groupedItems: { [category: string]: FAQItem[] } = {};
  faqItems.forEach((item) => {
    if (!groupedItems[item.category]) {
      groupedItems[item.category] = [];
    }
    groupedItems[item.category].push(item);
  });

  return (
    <div>
      <Accordion allowToggle>
        {Object.entries(groupedItems).map(([category, items]) => (
          <AccordionItem key={category}>
            <h2>
              <AccordionButton>
                <Box as="span" flex="1" textAlign="left" style={{ width: 500, height: 50 }}>
                  {category}
                </Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              {items.map((item, index) => (
                <div key={index}>
                  <h3 onClick={() => toggleQuestion(item.question)}style={{ width: 400, height: 40 }}>
                    {item.question}
                    <AccordionIcon
                      ml={2}
                      transform={openQuestions.includes(item.question) ? 'rotate(180deg)' : ''}
                    />
                  </h3>
                  {openQuestions.includes(item.question) && <p>{item.answer}</p>}
                </div>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

export default FAQ;
