import { Button, Flex, Image, Img, Spinner, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiPencilLine } from 'react-icons/ri'
import { useGetFileQuery, useGetUserQuery, User, File as CustomFile } from '../../generated/graphql'

import ModalBasic from '../Modals'
import { FileUploader } from 'react-drag-drop-files'
import { Form, Formik, useField } from 'formik'
import { bytesToSize } from '../../helpers'
import { uploadUserFile } from '../../helpers/api'
import { convertBase64ToFile } from '../../utils'
import LoadingComponent from '../Loading'

type Props = {
  userData?: User
}

type InitialValues = {
  avatar: File | null
}

const UserProfilePictureSettings: React.FC<Props> = ({ userData }) => {
  const [file, setFile] = useState<File | null>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>()
  const [previewImage, setPreviewImage] = useState<string>()
  const [userAvatarImage, setUserAvatarImage] = useState<string>('')
  const [isDragging, setDragging] = React.useState(false)
  const [uploaded, setUploaded] = React.useState(false)
  const [busyUploading, setBusyUploading] = React.useState(false)
  const [loadingData, setLoadingData] = useState<boolean>(true)

  const { data, loading } = useGetUserQuery({
    variables: {
      email: userData?.email as string
    }
  })

  const { refetch: getFile } = useGetFileQuery({
    skip: true
  })

  const recentUserData = data?.getUser as User

  const fileTypes = ['JPG', 'PNG', 'jpg', 'jpeg', 'png']

  useEffect(() => {
    const getUserAvatar = async () => {
      setLoadingData(true)
      if (recentUserData?.avatarS3Key) {
        const s3File = await getFile({
          key: recentUserData.avatarS3Key as string
        })
        const splitKey = recentUserData?.avatarS3Key.split('/')
        const fileName = splitKey[splitKey.length - 1]
        setUserAvatarImage(
          URL.createObjectURL(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        )
        setLoadingData(false)
      }
      setLoadingData(false)
    }
    getUserAvatar()
  }, [userData, recentUserData])

  const handleChange = async (file: File) => {
    setErrorMessage('')
    const MAX_FILE_SIZE = 5120 // 5MB

    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      setErrorMessage('File must be in PNG or JPEG format. Please try again.')
      return
    }

    if (parseFloat(bytesToSize(file.size)) > MAX_FILE_SIZE) {
      setErrorMessage('File must be 5mb or less.')
      return
    }

    const imageSrc = URL.createObjectURL(file)
    setPreviewImage(imageSrc)

    setUploaded(false)
    await setFile(file)
  }

  const removeUploadedFile = () => {
    setErrorMessage('')
    setFile(null)
    setPreviewImage('')
  }

  const handleUploadFile = async () => {
    if (file && previewImage) {
      setBusyUploading(true)
      await uploadUserFile('avatar', userData as User, userData?.recordId as string, file as File)
      setUserAvatarImage(previewImage)
      setFile(null)
      setPreviewImage('')
      setIsOpen(false)
      setBusyUploading(false)
    }
  }

  const INITIAL_VALUES: InitialValues = {
    avatar: file || null
  }

  if (loadingData) {
    return <LoadingComponent loadingText="Loading Profile Image" />
  }

  return (
    <>
      <ModalBasic isOpen={isOpen} onClose={() => setIsOpen(false)} heading="Profile Image">
        <Formik
          initialValues={INITIAL_VALUES}
          enableReinitialize
          onSubmit={async ({ avatar }, { setStatus, setFieldError }) => {
            try {
              let fieldError = {
                field: '',
                message: ''
              }
              if (!avatar) {
                fieldError = {
                  field: 'avatar',
                  message: 'Image required'
                }
              }

              if (fieldError.field && fieldError.message) {
                return setFieldError(fieldError.field, `${fieldError.message} is required`)
              }
            } catch (error) {
              let message
              if (error instanceof Error) message = error.message
              else message = String(error)
              setStatus(message)
            }
          }}
        >
          <Form></Form>
        </Formik>
        <FileUploader
          hoverTitle=""
          types={fileTypes}
          handleChange={handleChange}
          name="avatar"
          classes="profile-upload-container"
          maxSize={5}
          recordId={(userData?.recordId as string) || (userData?.email as string)}
          onDraggingStateChange={(dragging: boolean) => {
            setDragging(dragging)
          }}
        >
          <Flex
            w="100%"
            direction="column"
            align="center"
            _hover={{ cursor: 'pointer' }}
            p={4}
            minH={'250px'}
            borderRadius={'8px'}
            border={'1px dashed'}
            justifyContent={'center'}
            alignItems={'center'}
          >
            {(!previewImage || (!file && !busyUploading)) && (
              <>
                <Text opacity={isDragging ? 0 : 1} mt={2} mb={2}>
                  Click to upload PDF or JPEG
                </Text>
                <Text opacity={isDragging ? 0 : 1}>or drag and drop</Text>
              </>
            )}

            {previewImage && !busyUploading && (
              <Flex w="150px" h="150px" maxH={'150px'}>
                <Img src={previewImage} objectFit={'cover'} />
              </Flex>
            )}
            {busyUploading && <Spinner />}
          </Flex>
        </FileUploader>
        <Flex w="full" justifyContent={'space-between'} alignItems="center" mt="25px">
          <Button variant={'unstyled'} onClick={removeUploadedFile}>
            Change
          </Button>
          <Button variant={'dark'} onClick={handleUploadFile}>
            Upload
          </Button>
        </Flex>
      </ModalBasic>
      <Flex
        direction={['column', 'row', 'row', 'row']}
        gap={['15px', '50px', '50px', '50px']}
        alignItems={'center'}
        justifyContent={'flex-start'}
      >
        <Flex pos="relative" h="120px" w="120px" alignItems={'center'} justifyContent={'center'}>
          <Image
            src={userAvatarImage}
            fallbackSrc={'/images/avatar_placeholder.png'}
            w={['90px', '100px', '120px', '120px']}
            h={['90px', '100px', '120px', '120px']}
            rounded={'full'}
            objectFit={'cover'}
          />
          <Flex
            position={'absolute'}
            top="2"
            right="2"
            w="25px"
            h="25px"
            alignItems={'center'}
            justifyContent={'center'}
            bgColor={'#acacac7f'}
            shadow="md"
            rounded="full"
            backdropBlur={'xl'}
          >
            <RiPencilLine size={20} />
          </Flex>
        </Flex>
        <Button variant={'dark'} onClick={() => setIsOpen(true)}>
          Update picture
        </Button>
      </Flex>
    </>
  )
}

export default UserProfilePictureSettings
