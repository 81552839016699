import React, { ForwardedRef } from 'react'
import { NavLink } from 'react-router-dom'
import { Flex, Text, Link, Icon, LinkProps } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { FiGrid, FiUser, FiHome, FiSettings } from 'react-icons/fi'
import { BsQuestionCircle } from 'react-icons/bs'

export type SideBarProps = {
  path: string
  title: string
  key?: string
  icon?: IconType
  reactIcon?: React.ReactNode
}
export type Ref = HTMLAnchorElement | null

export const sideBarMainRoutes = [
  {
    title: 'Dashboard',
    path: 'dashboard',
    icon: FiGrid
  },
  {
    title: 'Account',
    path: 'account',
    icon: FiUser
  },
  {
    title: 'Properties',
    path: 'properties',
    icon: FiHome
  }
]
export const sideBarMiscRoutes = [
  {
    title: 'Support',
    path: 'support',
    icon: BsQuestionCircle
  },
  {
    title: 'Settings',
    path: 'settings',
    icon: FiSettings
  }
]
const SideBarLink = React.forwardRef<Ref, SideBarProps>((props, ref) => {
  const { path, title, icon, reactIcon } = props

  return (
    <Link
      // key={key}
      as={NavLink}
      to={path}
      ref={ref}
      color="white"
      transition={'all 0.2s ease-in-out'}
      _hover={{ textDecorationLine: 'none' }}
      _activeLink={{ color: '#FFD015' }}
      _active={{ transform: 'scale(0.95)' }}
    >
      <Flex justifyContent="space-between">
        <Flex direction={'row'} alignItems="center" gap="15px">
          {icon && <Icon as={icon} />}
          {reactIcon && reactIcon}
          {title && <Text>{title}</Text>}
        </Flex>
      </Flex>
    </Link>
  )
})

export default SideBarLink
