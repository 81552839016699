import { Flex, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ContainerHeading } from '../../../Headings'

type RequiredDocType = {
  title: string
  description: string
  children?: React.ReactNode
}

const RequiredDocumentContianer: FC<RequiredDocType> = ({ title, description, children }) => {
  return (
    <Flex
      direction={'column'}
      gap="25px"
      bgColor={'brand.neutral'}
      p={25}
      rounded={'8px'}
      w="100%"
      h={'full'}
      // justifyContent={'space-between'}
      shadow={'md'}
    >
      <ContainerHeading title={title} />
      <Text color={'brand.text.primary'} fontSize={13}>
        {description}
      </Text>
      {title === 'Photo of installation location' && <Text color={'brand.text.primary'} fontSize={13}>
        This will help the installer with preparations for the installation.
      </Text>}
      <Flex flex={1}>{children}</Flex>
    </Flex>
  )
}

export default RequiredDocumentContianer
