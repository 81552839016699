import { Flex } from '@chakra-ui/react'
import React from 'react'
import CallMe from '../../components/Onboarding/Pre-singup-flow/CallMe/CallMe'
// import { useParams } from 'react-router-dom'
// import CreditCheckForm from '../../../components/Onboarding/CreditCheck/CreditCheck'
// import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
// import { useAuthContext } from '../../../context/AuthProvider'
 

const CallMePage = () => {
//   const { recordId } = useParams()
//   const { user } = useAuthContext()
  return (
    <Flex
      w="100%"
      gap="50px"
      direction={['column', 'column', 'column', 'column']}
      alignItems={'center'}
    >
      {/* <OnBoardingStepper /> */}
      {/* <CreditCheckForm recordId={recordId as string} user={user} /> */}
      <CallMe/>
    </Flex>
  )
}

export default CallMePage
