import { Box, Flex, Heading, Text } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import {
  User,
  UserProperty,
  useGetUserPropertyQuery,
  useGetUserQuery
} from '../../../generated/graphql'
import { useAuthContext } from '../../../context/AuthProvider'
import { Maybe } from '../../../types'
import { validateRecordIdPath } from '../../../helpers/auth'

export type OnBoardingStepperType = {
  stepCount: number
  stepLabel: string
}
type OnboardingStepContainerType = {
  steps: OnBoardingStepperType[]
  activeStep: OnBoardingStepperType
}

const OnBoardingStepper: FC = () => {
  const [activeStep, setActiveStep] = useState<OnBoardingStepperType>(() => ({
    stepCount: 1,
    stepLabel: 'Subscription Packages'
  }))
  const location = useLocation()
  const { logout } = useAuthContext()
  const [onboardingStepCompleted, setOnboardingStepCompleted] = useState<number>(0)
  const [recentUserData, setRecentUserData] = useState<User>()
  const [onboardingStep, setonBoardingStep] = useState('checkout-summary')
  const { user } = useAuthContext()
  const navigate = useNavigate()
  const urlParams = useParams()
  const { recordId: propertyRecordId } = urlParams

  const { data, refetch: fetchUserData } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })
  const previousStep = recentUserData?.onboardingProgressCompletedStep

  useEffect(() => {
    let activeStep: string = 'checkout-summary'

    switch (previousStep) {
      case 5:
        activeStep = 'credit-type'
        break
      case 6:
        activeStep = 'credit-type'
        break
      case 7.0:
        activeStep = 'credit-check'
        break
      case 7.1:
        activeStep = 'business-credit-check'
        break
      case 8:
        activeStep = 'documents'
        break

      case 8.1:
        activeStep = 'business-documents'
        break

      case 9:
        activeStep = 'agreement'
        break
      default:
        activeStep = 'checkout-summary'
        break
    }
    setonBoardingStep(activeStep)
  }, [previousStep])

  useEffect(() => {
    const currPath = location.pathname

    let selectedStep = { stepCount: 6, stepLabel: 'Property type' }

    if (currPath.includes('credit-type')) {
      selectedStep = { stepCount: 5, stepLabel: 'credit-type' }
    }
    if (currPath.includes('credit-type')) {
      selectedStep = { stepCount: 6, stepLabel: 'Property type' }
    }
    if (currPath.includes('credit-check')) {
      selectedStep = { stepCount: 7, stepLabel: 'Credit check' }
    }
    if (currPath.includes('documents')) {
      selectedStep = { stepCount: 8, stepLabel: 'Billing' }
    }
    if (currPath.includes('agreement')) {
      selectedStep = { stepCount: 9, stepLabel: 'Agreement' }
    }
    setActiveStep(selectedStep)
  }, [location.pathname])

  const steps: OnBoardingStepperType[] = [
    { stepCount: 6, stepLabel: 'credit-type' },
    { stepCount: 7, stepLabel: 'Credit check' },
    { stepCount: 8, stepLabel: 'Billing' },
    { stepCount: 9, stepLabel: 'Agreement' }
  ]
  return (
    <Flex
      direction="column"
      w="full"
      maxW={'650px'}
      bgColor={'brand.secondary'}
      rounded="20px"
      alignItems={'center'}
      justifyContent="center"
      py={'15px'}
      px={['25px', '75px', '75px', '75px']}
      alignSelf={'center'}
    >
      <Flex direction="column" w="full" gap="15px" alignItems={'center'} justifyContent="center">
        <Heading
          as="h3"
          fontSize={'16px'}
          w="full"
          fontWeight={'medium'}
          color={'brand.text.light'}
        >
          {activeStep.stepLabel}
        </Heading>
        <StepperPillsContainer steps={steps} activeStep={activeStep} />
      </Flex>
    </Flex>
  )
}

export default OnBoardingStepper

export const StepperPillsContainer: FC<OnboardingStepContainerType> = ({ steps, activeStep }) => {
  const completedStepColor = '#D9D9D9'
  const activeStepColor = 'brand.primary'
  const remainingStepColor = '#868585'

  return (
    <Flex w={'full'} justifyContent="space-between" alignItems={'start'} gap="15px">
      {steps.map((step, index) => {
        let color = activeStepColor
        if (activeStep.stepCount > step.stepCount) {
          color = activeStepColor
        }
        if (activeStep.stepCount === step.stepCount) {
          color = activeStepColor
        }
        if (activeStep.stepCount < step.stepCount) {
          color = remainingStepColor
        }
        return (
          <Flex
            direction={'column'}
            w={'full'}
            alignItems="center"
            justifyContent={'center'}
            gap="5px"
            key={`${step.stepLabel}-${step.stepCount}-${index}-container`}
          >
            <Box
              key={`${step.stepLabel}-${step.stepCount}`}
              w={'full'}
              h="8px"
              rounded={'full'}
              bgColor={color}
            ></Box>
            <Text
              // hidden={true}
              color="#fff"
              fontSize={'xx-small'}
              key={`${step.stepLabel}-${step.stepCount}-${index}`}
            >
              {step.stepLabel}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
