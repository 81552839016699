import React, { FC } from 'react'
import {
  FormControl,
  FormLabel,
  Input,
  Text,
  FormControlProps,
  FormErrorMessage,
  Flex,
  InputGroup
} from '@chakra-ui/react'
import { FieldHookConfig, FieldInputProps, useField } from 'formik'
import { formatPhoneNumber } from '../../utils'

export type TextFieldProps = {
  label?: string
  onChange?: (value?: string) => void
  
  onBlur?: () => void
  formatInput?: (value: string) => string
} & FieldHookConfig<string>

const PhoneInput: FC<TextFieldProps> = ({ label = '', ...props }) => {
  const [field, meta, helpers] = useField(props.name)
  const [valid, setValid] = React.useState(false)
  const handleClick = () => setValid(!valid)
  const validNumber = field.value?.length === 12

  return (
    <FormControl isDisabled={props.disabled} isInvalid={meta.touched && !!meta.error}>
      <Flex alignItems={'center'} gap={'10px'} mb={'2px'}>
        <FormLabel
          id={`${props.name}-label`}
          htmlFor={`${props.name}-input`}
          fontSize={'small'}
          m={0}
        >
          {label}
        </FormLabel>
      </Flex>
      <InputGroup>
        <Input
          {...field}
          type={props.type || 'text'}
          id={`${props.name}-input`}
          variant="filled"
          placeholder={props.placeholder}
          spellCheck={false}
          fontSize={'sm'}
          focusBorderColor="brand.primary"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value
            const formattedValue = formatPhoneNumber(value)
           // const formattedValue = value

            helpers.setValue(formattedValue)
            props.onChange && props.onChange(formattedValue)
          }}         
        />
      </InputGroup>
      {meta.error && (
        <FormErrorMessage fontSize={'x-small'} m={0}>
          {meta.error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default PhoneInput
