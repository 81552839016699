import { FC } from 'react'
import { Button, Flex, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { DefaultModal } from '.'
import { useNavigate } from 'react-router-dom'
type OptionalUploadModalType = {
  isOpen: boolean
  onClose: () => void
  recordId: string
}

const OptionalUploadModal: FC<OptionalUploadModalType> = ({ isOpen, onClose, recordId }) => {
  const navigate = useNavigate()

  return (
    <DefaultModal isOpen={isOpen} onClose={onClose} heading={'Almost done!'}>
      {/* <OptionalUploadsPage /> */}
      {/* <Button onClick={()=>navigate(route)} variant={'dark'}>
        Next
      </Button> */}

      <Text>
        Please provide some additional information about your property to make the installation as
        smooth as possible (this is optional).
      </Text>
      <Text>You can provide:</Text>
      <UnorderedList alignContent={'left'}>
        <ListItem>A photo of your electric board</ListItem>
        <ListItem>A photo of the desired installation location</ListItem>
        <ListItem>A photo of your roof</ListItem>
        <ListItem>Your utility bill</ListItem>
        <ListItem>Any additional notes (e.g. if you have multiple electric boards)</ListItem>
      </UnorderedList>

      <Flex
        direction={['column', 'column', 'row', 'row']}
        justifyContent={'space-between'}
        width={'full'}
      >
        <Button onClick={() => navigate(`/user/dashboard`)} variant="outline" rounded="full">
          Not now
        </Button>
        <Button onClick={() => navigate(`/user/optional-uploads/${recordId}`)} variant={'dark'}>
          Lets go!
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default OptionalUploadModal
