import { Transaction, TransactionTypeEnum } from '../types'
import { DateTime } from 'luxon'

export const transactionsData = [
  {
    type: TransactionTypeEnum.ONCE_OFF,
    date: DateTime.now().minus({ month: 5 }),
    amount: 1499,
    success: true
  },
  {
    type: TransactionTypeEnum.DEBIT_ORDER,
    date: DateTime.now().startOf('month').minus({ month: 4 }),
    amount: 2499,
    success: true
  },
  {
    type: TransactionTypeEnum.MAINTENANCE_FEE,
    date: DateTime.now().startOf('week'),
    amount: 750,
    success: true
  }
] as Transaction[]

const descriptionToDisplay = (transactionType?: TransactionTypeEnum): string => {
  switch (transactionType) {
    case TransactionTypeEnum.DEBIT_ORDER:
      return 'Monthly debit order'
    case TransactionTypeEnum.MAINTENANCE_FEE:
      return 'Maintenance fees'
    case TransactionTypeEnum.ONCE_OFF:
      return 'Once-off installation fee'
    default:
      return 'N/A'
  }
}

export const getTransactionsDummyData = (): Transaction[] => {
  return transactionsData.map((t) => ({
    ...t,
    description: descriptionToDisplay(t.type)
  }))
}
