import { Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import OnboardingCreditCheck from '../../../../components/Onboarding/Dashboard/Business/OnboardingCreditCheck'
import PreOnboardingStepper from '../../../../components/Onboarding/Dashboard/PreOnboardingStepper'
import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../../context/AuthProvider'
import { User, UserProperty, useGetUserQuery } from '../../../../generated/graphql'
import OnBoardingStepper from '../../../../components/Onboarding/Dashboard/OnBoardingStepper'

const BusinessCreditCheckPage = () => {
  const { recordId } = useParams()
  const { user } = useAuthContext()

  //Business
  const [businessName, setBusinessName] = useState<string>('')
  const [registeredBusinessNumber, setRegisteredBusinessNumber] = useState<string>('')
  const [registeredDate, setRegisteredDate] = useState<string>('') //might be a date field
  const [tradingName, setTradingName] = useState<string>('')
  const [vatNumber, setVatNumber] = useState<string>('')
  const [businessDesignation, setBusinessDesignation] = useState<string>('')
  const [businessIndustry, setBusinessIndustry] = useState<string>('')

  //Person registering on businesses behalf
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [email, setEmail] = useState<string>('')
  const [contactNumber, setContactNumber] = useState<string>('')
  const [idPassportNumber, setIdPassportNumber] = useState<string>('')

  //Copied from private-credit-check so let in incase need streetaddress and these other relevant things.
  // If they not used in credit check remove them
  //const [streetName, setStreetName] = useState<string>('')
  //const [streetNumber, setStreetNumber] = useState<string>('')
  //const [city, setCity] = useState<string>('')
  // const [suburb, setSuburb] = useState<string>('')
  // const [gender, setGender] = useState<string>('')
  // const [postalCode, setPostalCode] = useState<string>('')
  const { data, loading } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })
  const recentUserData = data?.getUser as User

  useEffect(() => {
    const init = async () => {
      if (recentUserData) {
        const property = (recentUserData?.properties as UserProperty[]).find(
          (p) => p.recordId === recordId
        )

        if (recentUserData?.firstName) {
          setFirstName(recentUserData.firstName)
        }
        if (recentUserData?.businessName) {
          setBusinessName(recentUserData.businessName)
        }

        if (recentUserData?.registeredBusinessNumber) {
          setRegisteredBusinessNumber(recentUserData.registeredBusinessNumber)
        }

        if (recentUserData?.registeredDate) {
          setRegisteredDate(recentUserData.registeredDate)
        }

        if (recentUserData?.tradingName) {
          setTradingName(recentUserData.tradingName)
        }

        if (recentUserData?.vatNumber) {
          setVatNumber(recentUserData.vatNumber)
        }

        if (recentUserData?.businessDesignation) {
          setBusinessDesignation(recentUserData.businessDesignation)
        }

        if (recentUserData?.businessIndustry) {
          setBusinessIndustry(recentUserData.businessIndustry)
        }

        if (recentUserData?.firstName) {
          setFirstName(recentUserData.firstName)
        }
        if (recentUserData?.lastName) {
          setLastName(recentUserData.lastName)
        }
        if (recentUserData?.email) {
          setEmail(recentUserData.email)
        }
        if (recentUserData?.contactNumber) {
          setContactNumber(recentUserData.contactNumber)
        }
        if (recentUserData?.idPassportNumber) {
          setIdPassportNumber(recentUserData.idPassportNumber)
        }

        //Copied from private-credit-check so let in incase need streetaddress and these other relevant things.
        // If they not used in credit check remove them

        // if (property?.streetAddress) {
        //   var fullAddress = property.streetAddress.split(" ")
        //   setStreetNumber(fullAddress[0])
        //   setStreetName(property.streetAddress.substring(streetNumber.length))
        // }
        // if (property?.city) {
        //   setCity(property.city)
        // }
        // if (property?.postalCode) {
        //   setPostalCode(property.postalCode)
        // }
        // if (property?.suburb) {
        //   setSuburb(property.suburb)
        // }
      }
    }
    init()
  }, [recentUserData])
  return (
    <Flex
      w="100%"
      gap="50px"
      direction={['column', 'column', 'column', 'column']}
      alignItems={'center'}
    >
      <OnBoardingStepper />

      <OnboardingCreditCheck
        businessName={businessName}
        registeredBusinessNumber={registeredBusinessNumber}
        registeredDate={registeredDate}
        businessIndustry={businessIndustry}
        tradingName={tradingName}
        vatNumber={vatNumber}
        businessDesignation={businessDesignation}
        firstName={firstName}
        lastName={lastName}
        email={email}
        contactNumber={contactNumber}
        idPassportNumber={idPassportNumber}
        recordId={recordId as string}
      />
    </Flex>
  )
}

export default BusinessCreditCheckPage
