import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Text, Button, Image, Center } from '@chakra-ui/react'
import { useAuthContext } from '../../../context/AuthProvider'

const DashboardOnboardingCompleted: FC = () => {
  const { logout } = useAuthContext()
  const navigate = useNavigate()
  const handleNavigateToMainPage = async () => {
    const newPropertyValue = await localStorage.getItem('isNewProperty')
    const isNewProperty = newPropertyValue ? JSON.parse(newPropertyValue) : false

    if (!isNewProperty?.value) {
      logout && (await logout())
      window.open('https://www.gosolr.co.za')
    } else {
      navigate(`/user/dashboard/property-added`)
    }
  }

  return (
    <Flex
      width={'100%'}
      height={'100vh'}
      position={'absolute'}
      top={0}
      left={0}
      direction={'column'}
      gap="50px"
      alignItems={'center'}
      justifyContent="center"
      color={'brand.text.primary'}
      backgroundColor={'#FBBD30'}
      zIndex={2}
    >
      <Center w="full" maxW={'500px'} top={0} left={0}>
        <Image
          w="full"
          maxW={'500px'}
          objectFit={'cover'}
          src={'/images/you-have-the-power-transparent.png'}
        />
      </Center>
      <Flex
        direction={'column'}
        gap="25px"
        alignItems={'center'}
        justifyContent="center"
        zIndex={2}
        w="full"
        maxW={'500px'}
        textAlign="center"
      >
        <Text>We have received your GoSolr application.</Text>
        <Text>
          One of our friendly consultants will be in touch shortly to arrange payment of your
          installation fee. Once paid, your installation will be scheduled.
        </Text>
      </Flex>
      <Button
        variant="dark"
        onClick={(e) => {
          handleNavigateToMainPage()
        }}
      >
        Got it!
      </Button>
    </Flex>
  )
}

export default DashboardOnboardingCompleted
