import React, { FC, useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { FiBatteryCharging } from 'react-icons/fi'
import { formatWattsToKW } from '../../helpers'

export type Props = {
  batteryPower: string
}

const ChargeItem: FC<Props> = ({ batteryPower }) => {
  return (
    <Flex
      height={['auto', 'auto', '124px', '124px']}
      direction={'column'}
      alignItems="center"
      justifyContent={'flex-end'}
      w={['100%', '100%', '50%', '50%']}
    >
      <Flex color={'brand.primary'}>
        <FiBatteryCharging
          color={'brand.primary'}
          size={40}
          strokeWidth={1}
          style={{ strokeWidth: '1px' }}
        />
      </Flex>

      <Text color={'brand.text.light'} fontWeight={700} fontSize={'xl'}>
        {formatWattsToKW(+batteryPower)}
      </Text>
      <Text color={'brand.text.light'} fontWeight={300} w="full" textAlign={'center'}>
        Current production
      </Text>
    </Flex>
  )
}

export default ChargeItem
