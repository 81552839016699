import React from 'react'
import { Button, Flex, Image, Text } from '@chakra-ui/react'
import { ButtonStandard } from '../Buttons'
import { UserDetailsType } from '../containers/Account/AccHeroContainer'
import { Maybe } from 'yup/lib/types'

export type AccContainerUser = {
  fullName: Maybe<string> | undefined
  email: Maybe<string> | undefined
  phone_one?: Maybe<string> | undefined
  phone_two?: Maybe<string> | undefined
  profile_img?: Maybe<string> | undefined
  promoCode: Maybe<string> | undefined
}

export type User = {
  user: AccContainerUser
  avatar: string
}

const UserContainer: React.FC<User> = ({ user, avatar }) => {
  return (
    <Flex
      direction={['column', 'column', 'row', 'row']}
      w="100%"
      h={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
      gap={['25px', '25px', '50px', '50px']}
    >
      <Flex h="100%" w="120px" alignItems={'center'} justifyContent={'center'}>
        <Image
          src={avatar}
          fallbackSrc={'/images/avatar_placeholder.png'}
          w={['90px', '100px', '120px', '120px']}
          h={['90px', '100px', '120px', '120px']}
          rounded={'full'}
          objectFit={'cover'}
        />
      </Flex>

      <Flex direction={'column'} h={'100%'} alignItems={'center'} justifyContent={'center'}>
        <Flex direction={'column'} alignItems={'start'} justifyContent={'center'}>
          <Text textAlign={'left'} fontWeight={'700'}>
            {user.fullName}
          </Text>
          <Text textAlign={'left'}>{user.email}</Text>
          <Text textAlign={'left'}>{user.phone_one}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default UserContainer
