import React, { FC } from 'react'
import { Text } from '@chakra-ui/react'

type TextType = {
  text: string
}
const OnboardingInfoText: FC<TextType> = ({ text }) => {
  if (!text) return null

  return (
    <>
      <Text
        color={'brand.text.primary'}
        w="full"
        textAlign={'left'}
        mb={`${text === 'Tell us about yourself' ? '25px' : '50px'} `}
      >
        {text}
      </Text>
      {text === 'Tell us about yourself' && (
        <Text
          color={'brand.text.primary'}
          w="full"
          textAlign={'left'}
          mb={`${text === 'Tell us about yourself' ? '25px' : '50px'} `}
        >
          GoSolr requires the home owner to sign-up. To save time later on in the process, please
          ensure the home owner is filling the following information.
        </Text>
      )}
    </>
  )
}

export default OnboardingInfoText
