import React from 'react'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { PropertyAndTransactionsType } from '../../types'
import PropertyTransactionContainer from './PropertyTransactionContainer'
import { UserProperties } from '../../generated/graphql'
import { useTransactionsContext } from '../../context/TransactionProvider'
import ModalBasic from '../Modals'
import TransactionInvoice from './TransactionInvoice'
import EmailInvoiceForm from './EmailInvoiceForm'

const TransactionList: React.FC<UserProperties> = () => {
  const {
    propertyTransactionList,
    loading,
    selectedInv,
    setSelectedInv,
    isInvoiceModalOpen,
    setIsInvoiceModalOpen,
    isSendToEmailActive,
    setIsSendToEmailActive,
    email,
    altEmail
  } = useTransactionsContext()

  const handleCloseModal = () => {
    setIsInvoiceModalOpen(false)
    setSelectedInv(null)
    setIsSendToEmailActive(false)
  }

  if (loading) {
    return (
      <Flex direction={'column'} w="full" gap={'15px'} mt={'25px'}>
        <Flex alignItems={'center'} justifyContent="center" w="full" gap="25px">
          <Spinner />
          <Text color="brand.text.primary" fontSize={13}>
            Loading Transaction History
          </Text>
        </Flex>
      </Flex>
    )
  }
  return (
    <>
      <Flex direction={'column'} w="full" gap={'15px'} mt={'25px'}>
        {propertyTransactionList &&
          propertyTransactionList.map((property: PropertyAndTransactionsType, index: number) => (
            <PropertyTransactionContainer
              key={`property-${index}`}
              property={property}
              setIsInvoiceModalOpen={setIsInvoiceModalOpen}
              setSelectedInv={setSelectedInv}
            />
          ))}
      </Flex>
      <ModalBasic
        isOpen={isInvoiceModalOpen}
        onClose={() => {
          handleCloseModal()
        }}
      >
        {selectedInv && !isSendToEmailActive && (
          <TransactionInvoice inv={selectedInv} setIsSendToEmailActive={setIsSendToEmailActive} />
        )}
        {selectedInv && isSendToEmailActive && (
          // User must select between email and altEmail radio buttons
          <EmailInvoiceForm
            inv={selectedInv}
            email={email}
            altEmail={altEmail}
            handleCloseModal={handleCloseModal}
          />
        )}
      </ModalBasic>
    </>
  )
}

export default TransactionList
