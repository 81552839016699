import { Flex, Grid, GridItem, Highlight, Image, Text } from '@chakra-ui/react'
import React, { FC } from 'react'
import { ContainerHeading } from '../../../Headings'
import { Product } from '../../../../types'

const WhatsIncluded: FC<{ selectedSubscription?: Product }> = ({ selectedSubscription }) => {
  const features = [
    {
      title: `${selectedSubscription?.panelCount} x ${selectedSubscription?.panelSpec}`,
      description: 'Known for their reliability, efficiency and high quality power output',
      iconSlug: '/images/solarpanel-icon.png'
    },
    {
      title: `1 x ${selectedSubscription?.inverterSpec}`,
      description: 'Transforming solar power into the electricity that powers your home',
      iconSlug: '/images/inverter-icon.png'
    },
    {
      title: `${selectedSubscription?.batterySpec}`,
      description: "For storing and using the sun's energy harvested by your solar panels",
      iconSlug: '/images/battery-icon.png'
    }
  ]

  return (
    <Flex
      direction={'column'}
      gap="25px"
      border={'1px solid '}
      borderColor={'brand.secondary'}
      p={'25px'}
      rounded={'8px'}
    >
      <ContainerHeading title={"What's included"} />
      <Grid w="full" templateColumns={['repeat(1, 1fr)']} gap="25px">
        <>
          {selectedSubscription ? (
            features.map((f, i) => (
              <GridItem key={`card-${i}`} display={'flex'} w="full">
                <Flex
                  userSelect={'none'}
                  alignItems="center"
                  justifyContent={'start'}
                  color={'brand.text.primary'}
                  w="full"
                  // maxW={'400px'}
                  h="full"
                  px="20px"
                  rounded="8px"
                >
                  <Flex alignItems="center" justifyContent={'center'} gap="25px">
                    <Image
                      src={f.iconSlug}
                      w="75px"
                      px={f.iconSlug === '/images/solarpanel-image.png' ? '16px' : 0}
                    />

                    <Flex
                      direction={'column'}
                      gap="15px"
                      w="full"
                      // alignItems="space-between"
                      // justifyContent={'space-between'}
                    >
                      <Flex gap="10px">
                        <Text fontWeight={'bold'}>{f.title}</Text>
                      </Flex>
                      <Text fontSize={12} w="full">
                        {f.description}
                      </Text>
                    </Flex>
                  </Flex>
                </Flex>
              </GridItem>
            ))
          ) : (
            <Text>No Subscription Package Selected</Text>
          )}
        </>
      </Grid>
    </Flex>
  )
}

export default WhatsIncluded
