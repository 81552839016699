import { Button, Flex, FlexProps, Text } from '@chakra-ui/react'
import { FC, useState } from 'react'
import { FiArrowRight } from 'react-icons/fi'
import { RiShieldFlashLine, RiPulseLine, RiRefund2Line, RiDashboard3Line } from 'react-icons/ri'
import { useNavigate } from 'react-router-dom'
import { ButtonPill, ButtonStandard } from '../Buttons'
import { SettingType } from '../Property/DBProperty'

export type BatterySettingsProps = {
  plantId?: string
  activeSetting?: SettingType
  isGosolrManaged: boolean
  isLoading: boolean
  handleSetActiveSettings: (setting: SettingType) => void
} & FlexProps

const BatterySettings: FC<BatterySettingsProps> = ({
  plantId,
  activeSetting,
  isGosolrManaged,
  isLoading,
  handleSetActiveSettings,
  ...rest
}) => {
  const [currActiveSetting] = useState<SettingType>(activeSetting as SettingType)
  const [confirmStepActive, setConfirmStepActive] = useState<boolean>(false)
  const [selectedSetting, setSelectedSetting] = useState<SettingType>(activeSetting as SettingType)
  const navigate = useNavigate()

  const handleSetSelectedSetting = (setting: SettingType) => {
    setSelectedSetting(setting)
  }
  //  Stage 2 / 3 Loadshedding
  const handlegoBackToSettings = () => {
    setConfirmStepActive(false)
  }
  const handleGoToConfirmSettings = () => {
    if (selectedSetting.name !== currActiveSetting.name) setConfirmStepActive(true)
  }

  const checkIfNewSetting = () => {
    return (
      selectedSetting?.name?.toLowerCase() === currActiveSetting?.name?.toLowerCase() &&
      !isGosolrManaged
    )
  }

  const settings = [
    {
      icon: <RiDashboard3Line size={30} />,
      name: 'Gosolr managed (recommended): ',
      description:
        'The rules on battery usage will change dynamically depending on the loadshedding situation.'
    },
    {
      icon: <RiShieldFlashLine size={30} />,
      name: 'Max Safety',
      description:
        'Battery is kept at 95% full at any given time. Grid charge is allowed to top-up the battery should the sun not be sufficient to do the job. Suitable for loadshedding level 4 or above.'
    },
    {
      icon: <RiPulseLine size={30} />,
      name: 'Balanced',
      description:
        'Battery is charged during the day, kept nearly full during the evening and discharged before the next morning to make space for excess energy from the panels. Suitable for loadshedding level 1 or 2.'
    },
    {
      icon: <RiRefund2Line size={30} />,
      name: 'Economical',
      description:
        'No limit is put on the battery usage. Battery will be used whenever there is a need for it. This may leave you at risk should loadshedding hit as the battery may be empty when this happens.'
    }
  ]

  return (
    <>
      {!confirmStepActive ? (
        <Flex
          {...rest}
          w="full"
          h="full"
          gap={'25px'}
          my={'25px'}
          direction={'column'}
          flex={1}
          alignItems={'center'}
          justifyContent={'center'}
        >
          {settings.map((item, index) => (
            <Flex
              key={`${item.name}-${index}`}
              w={'full'}
              justifyContent="space-between"
              alignItems={'center'}
            >
              <Flex
                direction={'column'}
                alignItems={'start'}
                justifyContent={'center'}
                gap={'15px'}
              >
                <Flex alignItems={'center'} gap={'15px'}>
                  {item.icon}
                  <Text>{item.name}</Text>
                </Flex>
                <Text w="full" maxW={'225px'} fontSize={'11px'}>
                  {item.description}
                </Text>
              </Flex>
              <Flex direction={'column'} gap={25} justifyContent={'center'} alignItems={'center'}>
                {((item.name.toLowerCase() === currActiveSetting?.name?.toLowerCase() &&
                  !isGosolrManaged) ||
                  (item.name.toLowerCase().includes('gosolr managed') && isGosolrManaged)) && (
                  <Text
                    fontSize={'x-small'}
                    bg={'brand.secondary'}
                    color={'brand.primary'}
                    py={'2px'}
                    px={'4px'}
                    rounded="4px"
                    letterSpacing={'wider'}
                  >
                    Currently Active
                  </Text>
                )}
                <ButtonPill
                  onClick={(e) => handleSetSelectedSetting(item)}
                  isActive={
                    item.name.toLowerCase() === selectedSetting?.name?.toLowerCase() ||
                    (item.name.toLowerCase().includes('gosolr managed') && isGosolrManaged)
                  }
                  title={
                    item.name.toLowerCase() === selectedSetting?.name?.toLowerCase() ||
                    (item.name.toLowerCase().includes('gosolr managed') && isGosolrManaged)
                      ? 'Active'
                      : 'Set Active'
                  }
                />
              </Flex>
            </Flex>
          ))}
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <ButtonStandard mt="25px" onClick={() => navigate('/user/support')} w="auto">
              Custom settings
            </ButtonStandard>
            <Button
              onClick={handleGoToConfirmSettings}
              disabled={checkIfNewSetting()}
              variant={'dark'}
              mt={'25px'}
              alignSelf={'flex-end'}
              _disabled={{
                opacity: 0.5,
                bg: 'brand.secondary',
                _hover: { opacity: 0.5, bg: 'brand.secondary' }
              }}
              _hover={{ opacity: checkIfNewSetting() ? 0.5 : 1, bg: 'brand.secondary' }}
            >
              Update
            </Button>
          </Flex>
        </Flex>
      ) : (
        <ConfirmBatterySetting
          currActiveSetting={currActiveSetting}
          selectedSetting={selectedSetting}
          handlegoBackToSettings={handlegoBackToSettings}
          handleSetActiveSettings={handleSetActiveSettings}
          isLoading={isLoading}
        />
      )}
    </>
  )
}

export default BatterySettings

export type BatterySettingType = {
  currActiveSetting: SettingType
  selectedSetting: SettingType
  isLoading: boolean
  handlegoBackToSettings: () => void
  handleSetActiveSettings: (setting: SettingType) => void
}

export const ConfirmBatterySetting: FC<BatterySettingType> = ({
  currActiveSetting,
  selectedSetting,
  isLoading,
  handlegoBackToSettings,
  handleSetActiveSettings
}) => {
  const highLightTextStyle = { fontWeight: 'bolder', color: '#FFD015' }
  return (
    <Flex
      direction={'column'}
      flex={1}
      alignItems={'center'}
      justifyContent={'center'}
      gap={['200px', '25px', '25px', '25px']}
      zIndex={6555556}
      mt={'25px'}
    >
      <Flex direction={'column'} gap={['50px', '25px', '25px', '25px']}>
        <Text fontSize={'14px'}>
          Please Confirm that you want to switch from the “
          <span style={highLightTextStyle}>{currActiveSetting?.name}</span>” battery settings to the
          “<span style={highLightTextStyle}>{selectedSetting?.name}</span>” battery settings
        </Text>
        <Flex
          w="full"
          p="12px"
          justifyContent={'center'}
          alignItems={'center'}
          gap={'15px'}
          bgColor={'brand.secondary'}
          rounded={'4px'}
          color={'brand.text.light'}
        >
          <Flex alignItems={'center'} gap={'10px'}>
            {currActiveSetting.icon}
            <Text fontSize={'16px'} textTransform={'capitalize'}>
              {currActiveSetting.name}
            </Text>
          </Flex>
          <FiArrowRight size={25} stroke={'#FFD635'} />
          <Flex alignItems={'center'} gap={'10px'}>
            {selectedSetting.icon}
            <Text fontSize={'16px'} textTransform={'capitalize'}>
              {selectedSetting.name}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      <Flex w="full" justifyContent={'flex-end'} gap={'25px'}>
        <Button
          onClick={handlegoBackToSettings}
          variant={'outline'}
          h={'auto'}
          rounded={'full'}
          fontWeight={400}
        >
          back
        </Button>
        <Button
          isLoading={isLoading}
          onClick={(e) => handleSetActiveSettings(selectedSetting)}
          variant={'dark'}
        >
          Confirm
        </Button>
      </Flex>
    </Flex>
  )
}
