import { Box, Button, Center, Flex, Heading, Highlight, Spinner, Text } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction } from 'react'
import { MdOutlineMarkEmailUnread } from 'react-icons/md'
import { useAuthContext } from '../../../context/AuthProvider'

export type RegisterModalTypes = {
  userEmail: string
  description: string
  isSending: boolean
  error: string | null
  success: string | null
  setIsSending: Dispatch<SetStateAction<boolean>>
  setSuccess: Dispatch<SetStateAction<string | null>>
  setError: Dispatch<SetStateAction<string | null>>
}

const RegisteredModal: FC<RegisterModalTypes> = ({
  userEmail,
  description,
  isSending,
  error,
  success,
  setIsSending,
  setSuccess,
  setError
}) => {
  const { resendVerificationEmail } = useAuthContext()
  return (
    <Box w="full" maxW="500px">
      <Center bgColor={'brand.secondary'} rounded="8px" w="full" h="50px" mb="25px">
        <Heading as="h3" color={'brand.text.yellow'} fontSize="20px" fontWeight={500}>
          {'Verify your account'}
        </Heading>
      </Center>

      <Flex
        direction={'column'}
        gap={['50px', '25px', '25px', '25px']}
        alignItems="center"
        justifyContent={'center'}
        px="25px"
      >
        <Text color={'brand.text.primary'} w="full" textAlign={'center'}>
          <Highlight
            query={['check your email', userEmail.toLowerCase()]}
            styles={{ fontWeight: 'bold' }}
          >
            {description}
          </Highlight>
        </Text>
        <Center
          color={'brand.secondary'}
          bgColor={'brand.primary'}
          rounded="full"
          // p={4}
          w="125px"
          h="125px"
        >
          {isSending ? <Spinner size={'xl'} /> : <MdOutlineMarkEmailUnread size={75} />}
        </Center>
        {error && (
          <Text fontSize={'13px'} color={'brand.text.danger'}>
            {error}
          </Text>
        )}
        {success && (
          <Text fontSize={'13px'} color={'brand.text.success'}>
            {success}
          </Text>
        )}

        <Text fontSize={'13px'} color={'brand.text.primary'}>
          Didn't get the mail?{' '}
          <Button
            variant={'unstyled'}
            fontSize={'13px'}
            textDecoration={'underline'}
            onClick={async () => {
              setIsSending(true)
              setSuccess(null)
              setError(null)
              try {
                resendVerificationEmail && (await resendVerificationEmail(userEmail))
                setSuccess('Please check your email for the verification link')
                setIsSending(false)
              } catch (e) {
                let errorMessage = ''
                setIsSending(false)
                if (typeof e === 'string') {
                  errorMessage = e.toUpperCase()
                } else if (e instanceof Error) {
                  errorMessage = e.message

                  setError(errorMessage)
                }
              }
            }}
          >
            Send it again
          </Button>
        </Text>
      </Flex>
    </Box>
  )
}

export default RegisteredModal
