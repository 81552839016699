import { extendTheme, type ThemeConfig } from '@chakra-ui/react'

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false
}

const theme = extendTheme({
  config,
  fonts: {
    body: 'Poppins',
    mono: 'Poppins',
    heading: 'Poppins'
  },
  colors: {
    brand: {
      body: '#e8eaec',
      primary: '#FFD015',
      'primary-highlight': '#ffd944',
      secondary: '#171717',
      hippo: '#1f262e',
      'hippo-green': '#a7af39',
      neutral: '#FFFF',
      input: '#EDF2F7',
      yellow: '#FFD015',
      danger: '#b91c1c',
      success: '#22c55e',
      'input-border': '#d5d9de',
      text: {
        primary: '#202020',
        secondary: '#9E9E9E',
        light: '#FFFF',
        yellow: '#FFD015',
        danger: '#b91c1c',
        success: '#22c55e'
      }
    }
  },
  components: {
    Radio: {
      variants: {
        'brand-yellow': {
          control: {
            borderRadius: '12px', // change the border radius
            borderColor: 'brand.input-border', // change the border color
            // backgroundColor: 'brand.primary' // change the border color
            _checked: {
              borderColor: 'brand.primary',
              outline: 'none',
              backgroundColor: 'brand.primary',
              _hover: {
                borderColor: 'brand.primary',
                outline: 'none',
                backgroundColor: 'brand.primary'
              }
            },
            _hover: {
              borderColor: 'brand.primary',
              outline: 'none',
              backgroundColor: 'brand.primary'
            }
          }
        }
      }
    },
    Button: {
      variants: {
        hippo: {
          height: '35px',
          bg: '#1f262e',
          color: 'brand.neutral',
          rounded: 'full',
          shadow: 'md',
          fontSize: 'sm',
          fontWeight: 300,
          px: '24px',
          py: '7px',
          _hover: {
            bgColor: '#3f4d5d',
            _disabled: {
              bgColor: 'brand.secondary'
            }
          },
          _active: {
            transform: 'scale(0.95)',
            bgColor: '#3f4d5d'
          }
        },
        dark: {
          height: '35px',
          bg: 'brand.secondary',
          color: 'brand.neutral',
          rounded: 'full',
          shadow: 'md',
          fontSize: 'sm',
          fontWeight: 300,
          px: '24px',
          py: '7px',
          _hover: {
            bgColor: '#282828',
            _disabled: {
              bgColor: 'brand.secondary'
            }
          },
          _active: {
            transform: 'scale(0.95)',
            bgColor: '#252525'
          }
        },
        yellow: {
          height: '35px',
          bg: 'brand.primary',
          color: 'brand.secondary',
          rounded: 'full',
          shadow: 'md',
          fontSize: 'sm',
          fontWeight: 500,
          letterSpacing: 'wide',
          px: '24px',
          py: '12px',
          _hover: {
            bgColor: 'brand.primary-highlight',
            _disabled: {
              bgColor: 'brand.primary'
            }
          },
          _active: {
            transform: 'scale(0.95)',
            bgColor: '#252525'
          }
        },
        light: {
          bg: 'brand.neutral',
          color: 'brand.secondary',
          rounded: 'full',
          shadow: 'md',
          _hover: {
            bgColor: '#F0F0F0'
          },
          _active: {
            transform: 'scale(0.95)',
            bgColor: '#E7E7E7'
          }
        }
      }
    }
  }
})

export default theme
