import { DefaultModal } from '../../../Modals'
import * as yup from 'yup'
import MailingSubscribe from '../../../MailingSubscribe'
import { DwellingType } from '../../../../types'

type UnsupportedDwellingTypeProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
  dwellingType: string
}

export const SubscribeValidation = yup.object().shape({
  //Subscribe CHECK

  email: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required')
})

const dwellingMessage = (values: DwellingType) => {
  const dwellingType = values
  let text =
    'Your dwelling type is currently not supported. Join our mailing list and we will inform you as soon as we have an option for you.'
  if (dwellingType === 'apartment block') {
    text =
      'GoSolr want to assist all residents in South Africa. That being said, an individual solar solution in an individual ' +
      dwellingType +
      ' is often not recommend. We can however assist with centralized solutions. Please reach out to hello@gosolr.co.za or 010 880 3948 for further information.'
  }
  return text
}

function UnsupportedDwellingTypeModal({
  isOpen,
  onClose,
  heading,
  dwellingType
}: UnsupportedDwellingTypeProps) {
  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <MailingSubscribe
        showSubscribeElements={true}
        negativeText={dwellingMessage(dwellingType)}
        reasonForSubscribing={dwellingType + ' no supported'}
        onClose={onClose}
      />
    </DefaultModal>
  )
}

export default UnsupportedDwellingTypeModal
