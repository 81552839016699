import React, { FC, useContext, useEffect, useState } from 'react'
import { Flex, useMediaQuery } from '@chakra-ui/react'
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom'
import SideBar from '../components/Sidebar'
import { SideBarContext } from '../context/SideBarContext'
import { PageHeading } from '../components/Headings'
import MobileNavbar from '../components/MobileNav'

import { UserStorage } from '../types'
import { useAuthContext } from '../context/AuthProvider'

export type LayoutProps = {
  title: string
  isAuthenticated?: boolean
  isAuthenticating?: boolean
  busyOnboarding?: boolean
}

export type UserContextType = UserStorage | null

const PrivateLayout: FC<LayoutProps> = ({
  title,
  isAuthenticated,
  isAuthenticating,
  busyOnboarding = false
}) => {
  const { user } = useAuthContext()
  const [showTitle, setShowTitle] = useState(true)
  const { sideBarIsOpen, sbOpenWidth, sbclosedWidth, isMobileSize } = useContext(SideBarContext)
  const [sideBarPaddingLeft, setSideBarPaddingLeft] = useState<string>(sbclosedWidth as string)
  let navElement = <SideBar />
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const pathName: string = location?.pathname
    if (pathName.includes('/user/') && !user) {
      // console.log('User is Authenticated: ', isAuthenticated)

      navigate('/login')
    }
  }, [])

  useEffect(() => {
    if (location.pathname.includes('user/onboarding/')) {
      setShowTitle(false)
    }

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [location.pathname])

  useEffect(() => {
    if (!busyOnboarding && !isMobileSize && !sideBarIsOpen)
      setSideBarPaddingLeft(sbclosedWidth as string)
    else if (busyOnboarding && isMobileSize && sideBarIsOpen)
      setSideBarPaddingLeft(sbOpenWidth as string)
    else if (!busyOnboarding && isMobileSize) setSideBarPaddingLeft('0')
    else setSideBarPaddingLeft('0')
  }, [isMobileSize, sideBarIsOpen])

  if (!user) return <Navigate to={'/login'} />

  return (
    <Flex
      as="main"
      position={'relative'}
      direction={isMobileSize ? 'row' : 'column'}
      bg={'brand.body'}
      minH={'100vh'}
    >
      {!isMobileSize ? (
        <SideBar busyOnboarding={busyOnboarding} />
      ) : (
        <MobileNavbar busyOnboarding={busyOnboarding} />
      )}

      <Flex
        as="section"
        flex={1}
        justifyContent="center"
        pl={
          isMobileSize || (isMobileSize && busyOnboarding)
            ? '0'
            : sideBarIsOpen
            ? (sbOpenWidth as string)
            : (sbclosedWidth as string)
        }
        transition={'all 0.3s ease-in-out'}
      >
        <Flex
          w="100%"
          maxW={'1300px'}
          h="100%"
          direction={'column'}
          justifyContent={'center'}
          alignItems="start"
          // px={isMobileSize ? '15px' : '75px'}
          px={['10px', '30px', '50px', '75px']}
          pt={isMobileSize ? '75px' : '40px'}
          pb={'75px'}
        >
          {showTitle && <PageHeading title={title as string} />}

          <Flex
            w="100%"
            h="100%"
            direction={'column'}
            justifyContent={'start'}
            alignItems="start"
            mt={'50px'}
            gap="20px"
          >
            {/* The outlet will contain our page dynamic page content */}
            <Outlet />
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PrivateLayout
