import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { Flex, Link, Avatar, Image } from '@chakra-ui/react'
import { RiMenuFill } from 'react-icons/ri'

export type Props = {
  mobileNavIsOpen: Boolean
  handleToggleMobileNav: () => void
}

const MobileNavHeader = ({ mobileNavIsOpen, handleToggleMobileNav }: Props): JSX.Element => {
  return (
    <Flex
      as="nav"
      bg={'#171717'}
      pos="fixed"
      alignItems={'center'}
      justifyContent={'space-between'}
      top="0"
      left="0"
      w={'100%'}
      py={'10px'}
      px={'25px'}
      color={'#ffff'}
      transition={'all 0.3s ease-in-out'}
      shadow={mobileNavIsOpen ? 'none' : 'md'}
      zIndex={2}
    >
      <Link
        as={RouterLink}
        to={'dashboard'}
        color="white"
        transition={'all 0.2s ease-in-out'}
        _active={{ color: '#FFD015', transform: 'scale(0.95)' }}
      >
        <Image
          // as={'button'}
          h={'auto'}
          w="40px"
          // color={'black'}
          // name="Go Solr"
          src="/images/gosolr-logo-new.svg"
          alt="GoSolr logo"
          transition={'all 0.2s ease-in-out'}
        />
      </Link>
      <button onClick={(e) => handleToggleMobileNav()} name="mobile-nav-button">
        <RiMenuFill size={25} pointerEvents={'none'} />
      </button>
    </Flex>
  )
}

export default MobileNavHeader
