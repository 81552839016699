import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'
import * as yup from 'yup'

type UnsupportedRoofTypeProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
}

export const SubscribeValidation = yup.object().shape({
  //Subscribe CHECK

  email: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required')
})

function FlatRoofModal({ isOpen, onClose, heading }: UnsupportedRoofTypeProps) {
  const initValues = {
    firstName: '',
    lastName: '',
    email: ''
  }

  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Solar panels mounted on flat corrugated roof will be mounted flat (or with a small
            angle) for safety reasons. This will impact the production in winter (reduced) and
            summer (increased) compared to panels mounted at an angle.
          </Text>
        </>

        <Text textAlign={'center'} color={'brand.text.primary'}>
          Feel free to contact us at{' '}
          <Link href={`mailto: hello@gosolr.co.za?subject = Support Request`} color={'#3b82f6'}>
            hello@gosolr.co.za
          </Link>{' '}
          or give us a call at{' '}
          <Link href={`tel:010 880 3948`} color={'#3b82f6'}>
            010 880 3948
          </Link>{' '}
          if you require more information.
        </Text>
        <Button alignSelf={'flex-end'} variant={'dark'} onClick={onClose}>
          Got it
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default FlatRoofModal
