import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Button, Flex, Link, List, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DefaultModal } from '../../../Modals'
import RequiredDocumentContianer from '../Documents/RequiredDocumentContianer'
import { ConsentMessageItemType } from '../../../../types'
import { FiArrowLeft } from 'react-icons/fi'
import { useUpdateDynamoUserMutation, useUpdateUserMutation } from '../../../../generated/graphql'
import FileUploader from '../Documents/FileUploader'
import { Form, Formik } from 'formik'
type CreditCheckConsentModalType = {
  isOpen: boolean
  onClose: () => void
  recordId: string
  consentItem: ConsentMessageItemType
  route: string
  email: string
  entityType: string
  businessConsentDocument?: File | null
  loadingBusinessConsentDocument?: boolean | false
}
type InitialValues = {
  businessConsentDocument: File | null
}

const CreditCheckConsentModal: FC<CreditCheckConsentModalType> = ({
  isOpen,
  onClose,
  recordId,
  consentItem,
  route,
  email,
  entityType,
  businessConsentDocument,
  loadingBusinessConsentDocument
}) => {
  const INITIAL_VALUES: InitialValues = {
    businessConsentDocument: businessConsentDocument || null
  }
  const navigate = useNavigate()
  const [updateUser] = useUpdateUserMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const [loading, setLoading] = useState<boolean>(false)
  const handleConsent = async () => {
    setLoading(true)
    try {
      await updateUser({
        variables: {
          email: email,
          input: {
            recordId,
            entityType: entityType
          }
        }
      })

      await updateDynamoUser({
        variables: {
          email,
          input: {
            onboardingProgressCompletedStep: 6
          }
        }
      })
      navigate(route)
    } catch (error) {
      console.log('Consent Item error')
    }
  }

  return (
    <DefaultModal isOpen={isOpen} onClose={onClose} heading={'Credit check consent required'}>
      <Text>In order to perform a credit check, we need your consent to do the following:</Text>
      <UnorderedList justifyContent="left" alignItems="left">
        {consentItem.requirments.map((item) => (
          <ListItem key={item} fontSize={'xs'} mt={2}>
            {item}
          </ListItem>
        ))}
      </UnorderedList>
      <Flex direction={'row'} justifyContent={'space-between'} width={'full'} alignItems={'center'}>
        <Button onClick={onClose} variant="outline" rounded="full">
          No thanks
        </Button>
        <Button onClick={() => handleConsent()} variant={'dark'} isLoading={loading}>
          I agree
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default CreditCheckConsentModal
