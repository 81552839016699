import { FC } from 'react'
import { Flex, FlexProps, Image, Text } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { FiCircle } from 'react-icons/fi'

type AddonWrapperProps = FlexProps & {
  onClick: () => void
  isSelected: boolean
  heading: string
  description: string
  price: string
  imgUrl: string
}

const AddonWrapper: FC<AddonWrapperProps> = ({
  isSelected,
  onClick,
  heading,
  description,
  price,
  imgUrl
}) => {
  return (
    <Flex alignItems="center" justifyContent={'center'}>
      <Flex
        cursor="pointer"
        alignItems="center"
        justifyContent={'space-between'}
        onClick={onClick}
        color={isSelected ? 'brand.text.light' : 'brand.text.primary'}
        w="full"
        // maxW={'400px'}
        minH={'140px'}
        backgroundColor={isSelected ? 'brand.secondary' : 'brand.input'}
        py="15px"
        px="20px"
        rounded="8px"
        transition={'all 0.25s ease-in-out'}
        _hover={{ transform: 'scale(1.010)' }}
        _active={{ transform: 'scale(0.98)' }}
      >
        <Flex alignItems="center" justifyContent={'center'} gap="25px">
          <Image
            // width={'100%'}
            src={imgUrl}
            filter={
              isSelected
                ? 'invert(95%) sepia(12%) saturate(5495%) hue-rotate(338deg) brightness(98%) contrast(108%)'
                : ''
            }
          />

          <Flex direction={'column'} gap="15px">
            <Flex alignItems={'center'} justify="start" gap="10px">
              <Text fontWeight={'bold'}>{heading}</Text>
              {isSelected ? <FaCheckCircle fill="#FFD015" size={25} /> : <FiCircle size={25} />}
            </Flex>
            <Text fontSize={12} w="full">
              {description}
            </Text>
            <Text fontSize={12} fontWeight={'bold'} w="full">
              Price: {price}
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AddonWrapper
