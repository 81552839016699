import React, { useState } from 'react'
import { PropertyInvoiceType } from '../../types'
import { Form, Formik } from 'formik'
import TextInput from '../FormElements/TextInput'
import SubmitButton from '../FormElements/SubmitButton'
import { Button, Flex, Text } from '@chakra-ui/react'
import { ButtonPill } from '../Buttons'
import * as yup from 'yup'
import { ContainerHeading } from '../Headings'
import axios from 'axios'
import { API_HOST } from '../../constants'
import { getJWT } from '../../helpers/auth'

type EmailInvoiceFormPropsType = {
  inv: PropertyInvoiceType
  email?: string | null
  altEmail?: string | null
  handleCloseModal: () => void
}

const EmailInvoiceForm = ({
  inv,
  email,
  altEmail,
  handleCloseModal
}: EmailInvoiceFormPropsType) => {
  const [activeEmail, setActiveEmail] = useState(email || altEmail || '')
  const [isCustom, setIsCustom] = useState(false)
  const [isSuccess, setIsSuccess] = useState(false)
  const [hasErrors, setHasErrors] = useState(false)

  const handleSelectEmailOption = (
    event: React.MouseEvent<HTMLElement>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    event.stopPropagation()
    const target = event.target as HTMLInputElement
    setActiveEmail(target?.name)

    setFieldValue('email', target?.name)
    setIsCustom(false)
  }

  const handleSendEmail = async (email: string, invoiceID: string, invoiceNumber: string) => {
    // make an axios post request to send the email
    setHasErrors(false)
    setIsSuccess(false)
    await axios
      .post(
        `${API_HOST}/email-invoice`,
        {
          email,
          invoiceID,
          invoiceNumber
        },
        {
          headers: {
            Authorization: getJWT()
          }
        }
      )
      .then((response) => {
        if (response.data.status === 'success') {
          setIsSuccess(true)
          //   handleCloseModal()
          return
        }
        if (response.data.status === 'failed') {
          setIsSuccess(false)
          setHasErrors(true)
          return
        }
      })
      .catch((error) => {
        console.error('Error sending email:', error)
        setIsSuccess(false)
        setHasErrors(true)
      })
  }

  return (
    <Flex direction={'column'} w={'full'} gap={'25px'}>
      <ContainerHeading title="Email Invoice" />
      {isSuccess && (
        <Flex direction={'column'} gap={'25px'}>
          <Text>Invoice sent successfully</Text>
          <Text>Check your email for the invoice</Text>
          <Button onClick={handleCloseModal} variant={'dark'}>
            close
          </Button>
        </Flex>
      )}
      {hasErrors && !isSuccess && (
        <Flex direction={'column'} gap={'25px'}>
          <Text>Something went wrong/ Please try again later</Text>
          <Button onClick={handleCloseModal} variant={'dark'}>
            close
          </Button>
        </Flex>
      )}
      {!hasErrors && !isSuccess && (
        <>
          <Text fontSize={'sm'} color={'brand.text.primary'}>
            Select an email to send the invoice to or enter a custom email address
          </Text>

          <Formik
            initialValues={{ email: email || '' }}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email('This is not a valid email address. Please use a valid email address.')
                .required('Email is required')
            })}
            onSubmit={async (values, { setSubmitting }) => {
              await handleSendEmail(values.email, inv.invoiceID, inv.invoiceNumber)
              setSubmitting(false)
            }}
          >
            {({ isSubmitting, values, setFieldValue, errors, isValid }) => (
              <Form>
                <Flex direction={'column'} gap={'25px'}>
                  <Flex direction={'row'} justifyContent={'space-between'} gap={'25px'}>
                    {email && (
                      <Flex direction={'column'}>
                        <Text>Default</Text>
                        <ButtonPill
                          title={email}
                          isActive={activeEmail === email && !isCustom}
                          onClick={(e) => handleSelectEmailOption(e, setFieldValue)}
                        />
                      </Flex>
                    )}
                    {altEmail && (
                      <Flex direction={'column'}>
                        <Text>Alt contact</Text>
                        <ButtonPill
                          title={altEmail}
                          isActive={activeEmail === altEmail && !isCustom}
                          onClick={(e) => handleSelectEmailOption(e, setFieldValue)}
                        />
                      </Flex>
                    )}
                  </Flex>
                  <ButtonPill
                    title={'Custom'}
                    isActive={isCustom}
                    onClick={() => {
                      setIsCustom(true)
                      setFieldValue('email', '')
                    }}
                  />
                  {isCustom && (
                    <>
                      <TextInput name="email" placeholder="Alternate Email" />
                    </>
                  )}

                  {
                    <Button
                      type={'submit'}
                      isLoading={isSubmitting}
                      isDisabled={isSubmitting || !isValid}
                      variant={'dark'}
                    >
                      Send
                    </Button>
                  }
                </Flex>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Flex>
  )
}

export default EmailInvoiceForm
