import React, { FC } from 'react'
import {
  Flex,
  Text,
  StyleProps,
  ButtonProps,
  FlexProps,
  Box,
  BoxProps,
  Button
} from '@chakra-ui/react'

export type SettingsNavigationItemProps = StyleProps &
  ButtonProps &
  BoxProps &
  FlexProps & {} & {
    title: string
    isActive?: boolean
    icon?: React.ReactNode
    handleSelectMenu: (event: React.MouseEvent<Element, MouseEvent>) => void
  }

const SettingsNavigationItem: FC<SettingsNavigationItemProps> = ({
  title,
  isActive,
  icon,
  handleSelectMenu,
  ...rest
}) => {
  return (
    <Button
      variant={'unstyled'}
      name={title}
      w="full"
      onClick={(e) => handleSelectMenu(e)}
      _hover={{ cursor: 'pointer' }}
    >
      <Flex
        pointerEvents={'none'}
        alignItems={'center'}
        justifyContent="flex-start"
        gap="10px"
        color={isActive ? 'brand.text.yellow' : 'brand.text.light'}
      >
        {icon}
        <Text>{title}</Text>
      </Flex>
    </Button>
  )
}

export default SettingsNavigationItem
