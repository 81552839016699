import React, { FC } from 'react'
import { Box, Button, Center, Flex, Img, Text } from '@chakra-ui/react'
import { UserProperty } from '../../generated/graphql'
import { FlexContainer } from '../containers/Global'
import { ContainerHeading } from '../Headings'
import { IoMdCheckmarkCircle, IoMdCloseCircle } from 'react-icons/io'
import { DateTime } from 'luxon'
import { capitalizeFirstLetter } from '../../helpers'
import { useNavigate } from 'react-router-dom'

type PropertyType = {
  property: UserProperty
  orderNumber?: string | number
}

const PropertyHeader: FC<PropertyType> = ({ property }) => {
  const {
    suburb,
    subscription,
    province,
    streetAddress,
    city,
    description,
    fullAddress,
    installationDate,
    postalCode,
    active,
    recordId
  } = property

  // console.log(recordId)

  const navigate = useNavigate()

  const streetAddr = `${fullAddress}, `

  return (
    <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
      {/* Property Details Container */}
      <Flex
        bgColor={'brand.neutral'}
        w={['100%', '100%', '100%', '50%']}
        h="auto"
        maxH={'auto'}
        gap={'25px'}
        direction="column"
        rounded={'8px'}
        shadow={'md'}
        p={'25px'}
        color={'brand.text.primary'}
      >
        <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
          <ContainerHeading title={`Location`} />
        </Flex>
        <Flex w="full" gap={'25px'} alignItems={'center'} justifyContent={'flex-start'}>
          {/* <Center w="100px" h="100" rounded={'full'} overflow="hidden" bgColor={'brand.input'}>
            <Img src="/images/gosolr-home-box.png" objectFit={'cover'} w="100px" h="100px" />
          </Center> */}
          <Flex direction={'column'} gap={'5px'}>
            <Text fontWeight={700}>{description ? description : `${suburb} property`}</Text>
            <Text>{streetAddress},</Text>
            <Text>
              {suburb}, {city},
            </Text>
            <Text>
              {province} , {postalCode}
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* Property Subnscription Details */}
      <Flex
        bgColor={'brand.secondary'}
        w={['100%', '100%', '100%', '50%']}
        gap={'25px'}
        direction="column"
        rounded={'8px'}
        shadow={'md'}
        p={'25px'}
        color={'brand.text.primary'}
      >
        <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
          <ContainerHeading title={'Subscription'} headingColor={'brand.primary'} />
        </Flex>
        <Flex
          w="full"
          h={'100%'}
          direction={'column'}
          justifyContent="space-between"
          alignItems={'center'}
          color={'brand.text.light'}
        >
          <Flex
            w="full"
            h={'100%'}
            direction={'column'}
            justifyContent="center"
            alignItems={'center'}
            gap={'5px'}
            color={'brand.text.light'}
          >
            <Flex
              color={active ? 'brand.primary' : 'brand.danger'}
              gap={'15px'}
              alignItems="center"
              justifyContent={'center'}
            >
              <Text color={'brand.text.light'}>
                {active ? 'Active account' : ' Account not active'}
              </Text>
              {active ? <IoMdCheckmarkCircle size={25} /> : <IoMdCloseCircle size={25} />}
            </Flex>
            <Text color={'brand.primary'}>{`${capitalizeFirstLetter(
              subscription?.productName as string
            )} subscription`}</Text>
            {installationDate && (
              <Text fontSize={'12px'} color={'brand.text.secondary'} letterSpacing={'wide'}>
                Since {DateTime.fromISO(installationDate as string).toFormat('dd LLLL yyyy')}
              </Text>
            )}
          </Flex>
          <Button
            variant={'yellow'}
            mt={'25px'}
            onClick={() => navigate(`/user/manage-subscription/${recordId}`)}
          >
            Manage subscription
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default PropertyHeader
