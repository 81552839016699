import { Flex, Heading, Box } from '@chakra-ui/react'
import { capitalizeFirstLetter } from '../../helpers'

type TitleType = {
  title: string
  color?: string
}
const PageHeading = ({ title, color = '#171717' }: TitleType): JSX.Element => {
  return (
    <Flex w="100%" direction={'column'} gap={'5px'}>
      <Heading as="h1" color={color} fontSize={'20px'} fontWeight={500}>
        {capitalizeFirstLetter(title)}
      </Heading>
      <Box w="25px" h="3px" rounded={'full'} bg={color} />
    </Flex>
  )
}

export default PageHeading
