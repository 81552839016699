import { RiPulseLine, RiRefund2Line, RiShieldFlashLine } from 'react-icons/ri'
import {
  BalancedSolarmanData,
  EconomicalSolarmanData,
  MaxSafetySolarmanData
} from '../../constants/solarman'

export type SettingType = {
  plantID?: string
  name?: string
  description?: string
  img_url?: string
  icon?: React.ReactNode
}

export const batterySettingsArr = [
  {
    icon: <RiShieldFlashLine size={30} />,
    name: 'Max Safety',
    description:
      'Battery is kept at 95% full at any given time. Grid charge is allowed to top-up the battery should the sun not be sufficient to do the job. Suitable for loadshedding level 4 or above.'
  },
  {
    icon: <RiPulseLine size={30} />,
    name: 'Balanced',
    description:
      'Battery is charged during the day, kept nearly full during the evening and discharged before the next morning to make space for excess energy from the panels. Suitable for loadshedding level 1 or 2.'
  },
  {
    icon: <RiRefund2Line size={30} />,
    name: 'Economical',
    description:
      'No limit is put on the battery usage. Battery will be used whenever there is a need for it. This may leave you at risk should loadshedding hit as the battery may be empty when this happens.'
  }
]

export const PowerUsageProfile = (value: string) => {
  switch (value.toLocaleUpperCase()) {
    case 'balanced':
      return BalancedSolarmanData
    case 'economical':
      return EconomicalSolarmanData
    case 'max safety':
      return MaxSafetySolarmanData
    default:
      return BalancedSolarmanData
  }
}

export const checkIfNewSetting = (selectedSetting: SettingType, currActiveSetting: SettingType) => {
  return selectedSetting?.name?.toLowerCase() === currActiveSetting?.name?.toLowerCase()
}

const loadActiveSettingFromStorage = async (recordId: string) => {
  return await localStorage.getItem(`battery-settings-${recordId}`)
}
