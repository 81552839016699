import { FC } from 'react'
import {
  Button,
  Flex,
  Input,
  InputLeftAddon,
  InputGroup,
  Textarea,
  FormControl,
  Box,
  Text
} from '@chakra-ui/react'

import TextInput from '../../FormElements/TextInput'

export type ManageSubscriptionInputsProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const ManageSubscriptionInputs: FC<ManageSubscriptionInputsProps> = ({ setFieldValue }) => {
  return (
    <Flex direction={'column'} gap="25px">
      <FormControl isRequired>
        <InputGroup>
          <InputLeftAddon
            children="Subject"
            bgColor={'brand.secondary'}
            color={'brand.text.light'}
          />
          <Input
            type="text"
            name="subject"
            placeholder="Enter your subject here"
            focusBorderColor="brand.primary"
            onChange={(e) => {
              setFieldValue('subject', e.target.value)
            }}
          />
          {/* <TextInput name="subject" placeholder="subject"/> */}
        </InputGroup>
      </FormControl>
      <Flex
        direction={'column'}
        position="relative"
        border="1px solid"
        borderColor={'brand.input'}
        rounded="6px"
        overflow={'hidden'}
      >
        <Box
          bgColor={'brand.secondary'}
          w="min-content"
          py="4px"
          px="8px"
          borderBottomRightRadius={'6px'}
          mb={'25px'}
        >
          <Text color="brand.text.light" fontWeight={'300'}>
            Description
          </Text>
        </Box>
        <FormControl isRequired>
          <Textarea
            name="description"
            placeholder="Enter request description"
            size="sm"
            resize={'none'}
            focusBorderColor="none"
            h={'200px'}
            border="none"
            onChange={(e) => {
              setFieldValue('description', e.target.value)
            }}
          />
        </FormControl>
      </Flex>
    </Flex>
  )
}

export default ManageSubscriptionInputs
