import { Flex } from '@chakra-ui/react'

import OnboardingFormProvider from '../../context/intro/OnboardingContext'
import { useCookies } from 'react-cookie'
import OnboardingFormStepsContainer from '../../components/Onboarding/Intro/OnboardingFormStepsContainer'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import GoogleProvider from '../../context/GoogleProvider'
import InterestedPageHeader from './pageheader'

const InterestedPage = (): JSX.Element => {
  const [searchParams] = useSearchParams()
  const [usedPromoCode, setUsedPromoCode] = useState<string>('')
  const [cookies, setCookie] = useCookies()

  useEffect(() => {
    const promoCode = searchParams.get('promocode')
    // If the url contains a promo code
    if (promoCode) {
      let expires = new Date()
      expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000)
      setCookie('promocode', promoCode, { path: '/', expires })
      setUsedPromoCode(promoCode)
    }
    // Else check if we have a cookie saved with that promocode
    if (cookies.promocode) {
      setUsedPromoCode(cookies.promocode)
    }
  }, [])

  useEffect(() => {
    localStorage.setItem('onboarding_property_data', '')
    localStorage.setItem('lastLocation', '')
  }, [])

  return (
    <GoogleProvider>
      <OnboardingFormProvider>
        <Flex as="main" position={'relative'} direction="row" w={'100%'} minH={'100vh'}>
          <Flex
            direction={'column'}
            w={'100%'}
            maxW={'100%'}
            h={'100%'}
            minH={'100vh'}
            px={['25px', '50px', '100px', '100px']}
            py={'50px'}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <InterestedPageHeader promoCode={usedPromoCode} />
            <OnboardingFormStepsContainer promoCode={usedPromoCode} />
          </Flex>
        </Flex>
      </OnboardingFormProvider>
    </GoogleProvider>
  )
}

export default InterestedPage
