import React, { useEffect, useState } from 'react'
import { Flex, Grid, GridItem, Text, Button } from '@chakra-ui/react'
import IconBlock from '../Icons/IconBlock'
import { FiClock, FiHome, FiPlus } from 'react-icons/fi'
import { Maybe, UserProperty } from '../../generated/graphql'

type PropertiesHeroType = {
  activeSubCount?: number
  inProgressCount?: number
}
const PropertiesHeroContainer: React.FC<PropertiesHeroType> = ({
  inProgressCount,
  activeSubCount
}) => {
  return (
    <>
      <Grid
        w="100%"
        templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
        gap={'25px'}
      >
        <GridItem
          w="100%"
          h="125px"
          bg={'brand.secondary'}
          rounded={'6px'}
          overflow={'hidden'}
          shadow="md"
        >
          <Flex h={'100%'} w="100%" alignItems={'center'} justifyContent="center" gap={'10px'}>
            <IconBlock icon={<FiClock size={30} />} />
            <Flex direction={'column'}>
              <Text color={'brand.primary'}>{inProgressCount}</Text>
              <Text color={'brand.text.light'} fontSize={'12px'}>
                Application(s) In Progress
              </Text>
            </Flex>
          </Flex>
        </GridItem>
        <GridItem
          w="100%"
          h="125px"
          bg={'brand.secondary'}
          rounded={'6px'}
          overflow={'hidden'}
          shadow="md"
        >
          <Flex h={'100%'} w="100%" alignItems={'center'} justifyContent="center" gap={'10px'}>
            <IconBlock icon={<FiHome size={30} />} />
            <Flex direction={'column'}>
              <Text color={'brand.primary'}>{activeSubCount}</Text>
              <Text color={'brand.text.light'} fontSize={'12px'}>
                Active Subscriptions
              </Text>
            </Flex>
          </Flex>
        </GridItem>
      </Grid>
    </>
  )
}

export default PropertiesHeroContainer
