import {
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  StyleProps,
  Text
} from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'
import { FiCheck } from 'react-icons/fi'

import { formatIdNumber, validateIdNumber } from '../../../utils'

type ConnectedFormGroupProps = StyleProps & {
  placeholder?: string
  label?: string | React.ReactNode
  name: string
  formatInput?: (value: string) => string
  moreInfo?: string | JSX.Element
  bgColor?: string
  onChange?: (value?: string) => void
  onBlur?: () => void
  disabled?: boolean
  type?: string
  testId?: string
}

const ConnectedIDNumberInput: React.FC<ConnectedFormGroupProps> = ({ label, ...rest }) => {
  const [field, meta, helpers] = useField(rest.name)
  const [valid, setValid] = React.useState(false)
  const handleClick = () => setValid(!valid)

  const validIdNumber = field.value?.length === 17 && validateIdNumber(field.value)

  if (!validIdNumber) {
    meta.error = 'Invalid ID Number'
  }

  return (
    <Flex flexDirection="column" width="100%" mr={rest.mr} ml={rest.ml} mt={rest.mt} mb={rest.mb}>
      <FormControl isInvalid={meta.touched && !!meta.error}>
        {label && (
          <FormLabel 
          mb={'2px'} 
          fontWeight="normal" 
          fontSize={"small"} 
          htmlFor={field.name}>
            {label}
          </FormLabel>
        )}
        <InputGroup size="md">
          <Input
            fontSize={'sm'}
            borderColor={'brand.input'}
            placeholder="XXXXXX XXXX X X X"
            _placeholder={{ color: 'neutral.100' }}
            focusBorderColor="brand.primary"
            {...field}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
              const formattedValue = formatIdNumber(value)
              helpers.setValue(formattedValue)
              rest.onChange && rest.onChange(formattedValue)
            }}
            id={field.name}
          />
          {validIdNumber && (
            <InputRightElement>
              <Icon size="20px" onClick={handleClick} as={FiCheck} color="success.500" />
            </InputRightElement>
          )}
        </InputGroup>
        {meta.touched && meta.error ? (
          <Text fontSize="10px" color="red.500" textAlign="left">
            {meta.error}
          </Text>
        ) : null}
      </FormControl>
    </Flex>
  )
}

export default ConnectedIDNumberInput

ConnectedIDNumberInput.defaultProps = {
  mb: 2,
  type: 'text'
}
