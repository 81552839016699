import React, { FC, useContext } from 'react'
import { Button, Flex, Grid, GridItem, Link, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'
import FileUploader from './FileUploader'
import BackButton from '../../../FormElements/BackButton'
import SubmitButton from '../../../FormElements/SubmitButton'
import RequiredDocumentContainer from './RequiredDocumentContianer'
import { InitialBillingFormValueTypes } from '../../../../types'
import {
  useAddBankMutation,
  useUpdateDynamoUserMutation,
  useUpdateUserMutation,
  useUpdateUserPropertyMutation
} from '../../../../generated/graphql'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import RequiredDocumentContianer from './RequiredDocumentContianer'
import BillingFrom from '../Billing/billingForm'
import { useAuthContext } from '../../../../context/AuthProvider'
import { bankAccountValidation } from '../../../../formValidation'

type InitialValues = {
  businessRegistrationDocument: File | null
  taxClearanceDocument: File | null
  proofOfOwnershipDocument: File | null
  bankStatementDocument_01: File | null
  bankStatementDocument_02: File | null
  bankStatementDocument_03: File | null
  loadingBankStatement1?: boolean | false
  loadingBankStatement2?: boolean | false
  loadingBankStatement3?: boolean | false
  loadingBusinessRegistration?: boolean | false
  loadingTaxClearance?: boolean | false
  loadingProofOfOwnership?: boolean | false
  loadingBusinessSurety?: boolean | false
  businessSuretyshipDocument?: File | null
  businessConsentDocument?: File | null
  loadingBusinessConsentDocument?: boolean | false
} & InitialBillingFormValueTypes

type FormProps = {
  recordId: string
  proofOfOwnershipDocument?: File | null
  taxClearanceDocument?: File | null
  businessRegistrationDocument?: File | null
  bankStatementDocument_01?: File | null
  bankStatementDocument_02?: File | null
  bankStatementDocument_03?: File | null
  debitOrderDate?: string | ''
  bankName?: string | ''
  memberName?: string | ''
  bankAccountType?: string | ''
  bankAccountNumber?: string | ''
  branchCode?: string | ''
  loadingBankStatement1?: boolean | false
  loadingBankStatement2?: boolean | false
  loadingBankStatement3?: boolean | false
  loadingBusinessRegistration?: boolean | false
  loadingTaxClearance?: boolean | false
  loadingProofOfOwnership?: boolean | false
  loadingBusinessSurety?: boolean | false
  businessSuretyshipDocument?: File | null
  businessConsentDocument?: File | null
  loadingBusinessConsentDocument?: boolean | false
}

const docInfo = {
  businessRegistration: {
    title: 'Business registration',
    description:
      'Please upload your business registration documents to validate the business entity.'
  },
  bankStatement1: {
    title: 'Latest 3 months bank statements',
    description:
      'Please upload your bank statements for the last 3 months in a single file or as 3 separate files.'
  },
  proofOfOwnership: {
    title: 'Proof of property ownership',
    description:
      'Please upload a proof of ownership so we can cross check your property information - title deed, mortgage bond certificate, or transfer of ownership deed.'
  },
  taxClearance: {
    title: 'Optional - Tax clearance document',
    description: 'Please upload a copy of your tax clearance document. '
  },
  bankingDetails: {
    title: 'Banking details',
    description: 'Please input your banking details.'
  },
  businessSurety: {
    title: 'Optional - Deed of suretyship',
    description: 'Please download the link, sign it, scan, then upload your deed of suretyship.'
  },
  businessConsent: {
    title: 'Consent Form',
    description: 'Please upload your completed consent form.'
  }
}

const BusinessDocumentUploadForm: FC<FormProps> = ({
  proofOfOwnershipDocument,
  taxClearanceDocument,
  businessRegistrationDocument,
  recordId,
  bankStatementDocument_01,
  bankStatementDocument_02,
  bankStatementDocument_03,
  bankName,
  memberName,
  bankAccountType,
  bankAccountNumber,
  branchCode,
  debitOrderDate,
  loadingBankStatement1,
  loadingBankStatement2,
  loadingBankStatement3,
  loadingBusinessRegistration,
  loadingTaxClearance,
  loadingProofOfOwnership,
  loadingBusinessSurety,
  businessSuretyshipDocument,
  businessConsentDocument,
  loadingBusinessConsentDocument
}) => {
  const navigate = useNavigate()
  const [updateUserProperty] = useUpdateUserPropertyMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const [updateUser] = useUpdateUserMutation()
  const [addBank] = useAddBankMutation()
  const { isUserFirstProperty } = useContext(OnboardingFormContext)
  const { user } = useAuthContext()

  const INITIAL_VALUES: InitialValues = {
    businessRegistrationDocument: businessRegistrationDocument || null,
    taxClearanceDocument: taxClearanceDocument || null,
    proofOfOwnershipDocument: proofOfOwnershipDocument || null,
    bankStatementDocument_01: bankStatementDocument_01 || null,
    bankStatementDocument_02: bankStatementDocument_02 || null,
    bankStatementDocument_03: bankStatementDocument_03 || null,
    debitOrderDate: debitOrderDate || '26th',
    bankName: bankName || '',
    memberName: memberName || '',
    bankAccountType: bankAccountType || '',
    bankAccountNumber: bankAccountNumber || '',
    branchCode: branchCode || '',
    businessSuretyshipDocument: businessSuretyshipDocument || null,
    businessConsentDocument: businessConsentDocument || null
  }

  return (
    <Flex direction="column" w="100%" padding="5px">
      <Formik
        initialValues={INITIAL_VALUES}
        validationSchema={bankAccountValidation}
        enableReinitialize
        onSubmit={async (
          {
            businessRegistrationDocument,
            taxClearanceDocument,
            bankStatementDocument_01,
            bankStatementDocument_02,
            bankStatementDocument_03,
            debitOrderDate,
            bankName,
            memberName,
            bankAccountType,
            bankAccountNumber,
            branchCode
          },
          { setStatus, setFieldError }
        ) => {
          try {
            let fieldError = {
              field: '',
              message: ''
            }
            if (!businessRegistrationDocument) {
              return setFieldError(
                'businessRegistrationDocument',
                'Business registration document is required'
              )
            }
            // if (!taxClearanceDocument) {
            //   return setFieldError('taxClearanceDocument', 'Tax clearance document is required')
            // }

            if (!bankStatementDocument_01) {
              fieldError = {
                field: 'bankStatementDocument_01',
                message: docInfo.bankStatement1.title
              }
            }
            if (fieldError.field && fieldError.message) {
              return setFieldError(fieldError.field, `${fieldError.message} is required`)
            }

            await updateDynamoUser({
              variables: {
                email: user?.info?.email as string,
                input: {
                  onboardingProgressCompletedStep: 8.1
                }
              }
            })

            await updateUser({
              variables: {
                email: user?.info?.email as string,
                input: {
                  recordId,
                  debitOrderDate: debitOrderDate
                }
              }
            })

            await addBank({
              variables: {
                email: user?.info?.email as string,
                recordId,
                input: {
                  bankName,
                  branchCode,
                  memberName,
                  bankAccountType,
                  bankAccountNumber
                }
              }
            })

            navigate(`/user/onboarding/agreement/${recordId}`)
          } catch (error) {
            let message
            if (error instanceof Error) message = error.message
            else message = String(error)
            setStatus(message)
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors }) => {
          return (
            <Flex direction="column" w="full" gap={'25px'}>
              <Form style={{ width: '100%' }}>
                <Grid
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(2, 1fr)'
                  ]}
                  gap={'15px'}
                >
                  {/* Banking Details */}
                  <GridItem colSpan={1}>
                    <Flex direction={'column'}>
                      <RequiredDocumentContianer
                        title={docInfo.bankingDetails.title}
                        description={docInfo.bankingDetails.description}
                      >
                        {/* <OnBoardingBillingCopy recordId={'58347'}></OnBoardingBillingCopy> */}
                        <BillingFrom
                          setFieldValue={setFieldValue}
                          isSubmitting={isSubmitting}
                          values={values}
                          errors={errors}
                        />
                      </RequiredDocumentContianer>
                    </Flex>
                  </GridItem>

                  {/* Bank Statements */}
                  <GridItem colSpan={[1]}>
                    <RequiredDocumentContianer
                      title={docInfo.bankStatement1.title}
                      description={docInfo.bankStatement1.description}
                    >
                      <Flex
                        direction={'column'}
                        flex={1}
                        w="full"
                        h={'full'}
                        gap={15}
                        justifyContent={'space-between'}
                      >
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 1 / 3 months bank statements</Text>
                          <FileUploader
                            name="bankStatementDocument_01"
                            recordId={recordId}
                            loading={loadingBankStatement1}
                            destination="property"
                          />
                        </Flex>
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 2</Text>
                          <FileUploader
                            name="bankStatementDocument_02"
                            recordId={recordId}
                            loading={loadingBankStatement2}
                            destination="property"
                          />
                        </Flex>
                        <Flex direction="column" w="full" alignItems={'left'}>
                          <Text>Month 3</Text>
                          <FileUploader
                            name="bankStatementDocument_03"
                            recordId={recordId}
                            loading={loadingBankStatement3}
                            destination="property"
                          />
                        </Flex>
                      </Flex>
                    </RequiredDocumentContianer>
                  </GridItem>
                  {/* Business registration*/}
                  <GridItem shadow={'md'}>
                    <RequiredDocumentContainer
                      title={docInfo.businessRegistration.title}
                      description={docInfo.businessRegistration.description}
                    >
                      <FileUploader
                        name="businessRegistrationDocument"
                        recordId={recordId}
                        loading={loadingBusinessRegistration}
                        destination="property"
                      />
                    </RequiredDocumentContainer>
                  </GridItem>
                  {/* Proof of Ownership */}
                  <GridItem shadow={'md'}>
                    <RequiredDocumentContainer
                      title={docInfo.proofOfOwnership.title}
                      description={docInfo.proofOfOwnership.description}
                    >
                      <FileUploader
                        name="proofOfOwnershipDocument"
                        recordId={recordId}
                        loading={loadingProofOfOwnership}
                        destination="property"
                      />
                    </RequiredDocumentContainer>
                  </GridItem>

                  {/* Tax Clearance*/}
                  <GridItem shadow={'md'}>
                    <RequiredDocumentContainer
                      title={docInfo.taxClearance.title}
                      description={docInfo.taxClearance.description}
                    >
                      <FileUploader
                        name="taxClearanceDocument"
                        recordId={recordId}
                        loading={loadingTaxClearance}
                        destination="property"
                      />
                    </RequiredDocumentContainer>
                  </GridItem>
                  <GridItem>
                    <RequiredDocumentContianer
                      title={'Consent form'}
                      description={
                        'Please download and complete the consent form. You can upload this below once complete.'
                      }
                    >
                      <Flex
                        direction={'column'}
                        alignItems={'start'}
                        justifyContent={'center'}
                        width={'100%'}
                      >
                        <Link
                          color="blue"
                          href="https://gosolr-business-public-docs.s3.eu-west-1.amazonaws.com/BusinessConsentForm.docx"
                        >
                          Download consent form
                        </Link>
                        <FileUploader
                          name="businessConsentDocument"
                          recordId={recordId}
                          loading={loadingBusinessConsentDocument}
                          destination="property"
                        />
                      </Flex>
                    </RequiredDocumentContianer>
                  </GridItem>
                  {/* Business Deed of suretyship*/}
                  <GridItem shadow={'md'} colSpan={[1, 1, 2, 2]}>
                    {/* S3 link to public location of the surety template to be signed */}
                    <RequiredDocumentContainer
                      title={docInfo.businessSurety.title}
                      description={docInfo.businessSurety.description}
                    >
                      <Flex
                        direction={'column'}
                        alignItems={'start'}
                        justifyContent={'center'}
                        width={'100%'}
                      >
                        <Link
                          color="blue" /*TODO: Why is this color not taking affect? */
                          href="https://gosolr-business-public-docs.s3.eu-west-1.amazonaws.com/gosolr-Business+Suretyship.doc"
                        >
                          Download template: Business deed of surety
                        </Link>
                        <FileUploader
                          name="businessSuretyshipDocument"
                          recordId={recordId}
                          loading={loadingBusinessSurety}
                          destination="property"
                        />
                      </Flex>
                    </RequiredDocumentContainer>
                  </GridItem>
                </Grid>

                <Grid
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(2, 1fr)'
                  ]}
                  mt={'15px'}
                  gap={'15px'}
                ></Grid>

                <Flex
                  w="full"
                  justifyContent={'space-between'}
                  alignItems="center"
                  mt={'25px'}
                  bgColor={'brand.neutral'}
                  p={'25px'}
                  rounded={'8px'}
                  shadow={'md'}
                >
                  <BackButton
                    callback={() => {
                      navigate(`/user/onboarding/business-credit-check/${recordId}`)
                    }}
                  />

                  <Button
                    variant={'dark'}
                    type={'submit'}
                    isLoading={isSubmitting}
                    isDisabled={
                      !values.proofOfOwnershipDocument ||
                      !values.businessRegistrationDocument ||
                      !values.bankStatementDocument_01 ||
                      !values.businessConsentDocument
                    }
                  >
                    Next
                  </Button>
                </Flex>
              </Form>
            </Flex>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default BusinessDocumentUploadForm
