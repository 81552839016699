import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react'
import { User, UserProperty } from '../../generated/graphql'
import { useAuthContext } from '../AuthProvider'
import { getJWT } from '../../helpers/auth'
import axios from 'axios'
import { PropertyAndTransactionsType, PropertyInvoiceType } from '../../types'
import { API_HOST } from '../../constants'

// Context Type
type TransactionContextType = {
  propertyTransactionList: PropertyAndTransactionsType[] | null
  loading: boolean
  selectedInv: PropertyInvoiceType | null
  setSelectedInv: Dispatch<SetStateAction<PropertyInvoiceType | null>>
  isInvoiceModalOpen: boolean
  setIsInvoiceModalOpen: Dispatch<SetStateAction<boolean>>
  setIsSendToEmailActive: Dispatch<SetStateAction<boolean>>
  isSendToEmailActive: boolean
  email: string | null
  altEmail: string | null
} | null

type InvoiceResponseType = {
  invoices: PropertyInvoiceType[]
  status: 'failed' | 'success'
  count: number
}

const TransactionContext = createContext<TransactionContextType>(null)

const TransactionProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useAuthContext()

  const userData = user?.info as User
  const properties = userData?.properties as UserProperty[]

  const [propertyTransactionList, setPropertyTransactionList] = useState<
    PropertyAndTransactionsType[] | null
  >(null)
  const [loading, setLoading] = useState<boolean>(false)
  const [isSendToEmailActive, setIsSendToEmailActive] = useState<boolean>(false)
  const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState<boolean>(false)
  const [selectedInv, setSelectedInv] = useState<PropertyInvoiceType | null>(null)

  // Get all the user properties
  useEffect(() => {
    const getTransaction = async () => {
      const propertyAndTransactoinArr: PropertyAndTransactionsType[] = []

      for (const property of properties || []) {
        // Get the transactions for each property
        const url = `${API_HOST}/property-invoices`
        setLoading(true)
        const { recordId, orderNumber, suburb, description, streetAddress } = property
        try {
          const { data } = await axios.post<InvoiceResponseType>(
            url,
            {
              orderNumber: +orderNumber!,
              recordId: +recordId!
            },
            {
              headers: {
                Authorization: getJWT()
              }
            }
          )
          if (data.status !== 'success') {
            throw new Error('No Transaction Data Found!')
          }

          const propertyAndTransactions: PropertyAndTransactionsType = {
            recordId,
            orderNumber,
            suburb,
            description,
            streetAddress,
            invoices: data.invoices
          }

          propertyAndTransactoinArr.push(propertyAndTransactions)
          setLoading(false)
        } catch (error) {
          console.log('Error: ', error)
        }
      }
      setPropertyTransactionList(propertyAndTransactoinArr)
    }
    getTransaction()
  }, [user])

  const expose: TransactionContextType = {
    propertyTransactionList,
    isInvoiceModalOpen,
    selectedInv,
    loading,
    setIsInvoiceModalOpen,
    setSelectedInv,
    isSendToEmailActive,
    setIsSendToEmailActive,
    email: userData?.email || '',
    altEmail: userData?.alternateContact?.email || ''
  }

  return <TransactionContext.Provider value={expose}>{children}</TransactionContext.Provider>
}

export const useTransactionsContext = () => {
  const context = useContext(TransactionContext)
  if (!context) {
    throw new Error('useTransactionsContext must be used within a TransactionProvider')
  }
  return context
}

export default TransactionProvider
