import React, { useContext, useState } from 'react'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'
import GoogleRoofMap from '../../../FormElements/GoogleMapInput/GoogleRoofMap'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import GoogleMapInput from '../../../FormElements/GoogleMapInput'
import { Maybe, PropertyLocation } from '../../../../types'

type MapModalType = {
  isOpen: boolean
  onClose: () => void
  heading: string
 
}


const setFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => {
  console.log('field',field)
  console.log('value',value)
  console.log('shouldValidate',shouldValidate)
}

function MapModal({
  isOpen,
  onClose,
  heading,

}: MapModalType) {
  // const { formData, handleSetFormData, handleNextStepIndex, handlePrevStepIndex } =
  //   useContext(OnboardingFormContext)

  const [toggle, setToggle] = useState(false)
  const [addressConfirmed, setAddressConfirmed] = useState(false)
  const [address, setAddress] = useState<Maybe<PropertyLocation>>()

  
  return (
    
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        
     
        <Text textAlign={'center'} color={'brand.text.primary'}>
        hello
        </Text>
{/* 
        <GoogleMapInput
          displayMap={true}
          onLocationReset={() => setAddressConfirmed(false)}
          onConfirmLocation={(locationDetails: PropertyLocation) => {
            // locationDetails.object.Keys()
            setAddress(locationDetails)
            setAddressConfirmed(true)

            setToggle((prev) => !prev)
          } } onChangeLocation={function (locationDetails: PropertyLocation): void {
            throw new Error('Function not implemented.')
          } }   />      */}
      
        
          <Button alignSelf={'flex-end'} variant={'dark'} onClick={onClose}>
            Got it
          </Button>
      </Flex>
    </DefaultModal>
  )
}

export default MapModal