/* istanbul ignore file */
import React, {
  useState,
  useEffect,
  createContext,
  useContext,
  FC,
  Dispatch,
  SetStateAction
} from 'react'
import { useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_API_KEY } from '../../constants'
import { Maybe } from '../../types'

type GoogleProviderProps = {
  googlePlacesService: Maybe<google.maps.places.AutocompleteService>
  googleGeocoder: Maybe<google.maps.Geocoder>
  mapsApiIsLoaded: boolean
  setSelectedAddress: Dispatch<SetStateAction<SelectedAddressProps>>
  selectedAddress: SelectedAddressProps
}

type MapProviderType = {
  children?: React.ReactNode
} & {}

export type SelectedAddressProps = {
  streetName: string
  streetNumber: string
  city: string
  country: string
  fullAddress: string
  suburb: string
  municipality: string
  province: string
  postalCode: string
  lat: number
  lng: number
  what3Words?: string
}

const initialSelectedAddressValues = {
  streetName: '',
  streetNumber: '',
  city: '',
  country: '',
  fullAddress: '',
  suburb: '',
  municipality: '',
  province: '',
  postalCode: '',
  lat: 0,
  lng: 0,
  what3Words: ''
}

export const GoogleContext = createContext<Partial<GoogleProviderProps>>({})

export const useGoogleContext = (): Partial<GoogleProviderProps> => useContext(GoogleContext)

// Need to pass a static object to the js-api-loader. It reloads unintentionally when passing a new array.
const libraries = ['places', 'geometry'] as (
  | 'drawing'
  | 'geometry'
  | 'localContext'
  | 'places'
  | 'visualization'
)[]

const GoogleProvider: FC<MapProviderType> = ({ children }) => {
  // Check if the maps API has loaded
  const [mapsApiIsLoaded, setMapsApiIsLoaded] = useState(false)
  // State the Google Maps Places Services
  const [googlePlacesService, setGooglePlacesService] =
    useState<Maybe<google.maps.places.AutocompleteService>>()
  // State for loading ing Google Maps Geocoder
  const [googleGeocoder, setGoogleGeocoder] = useState<Maybe<google.maps.Geocoder>>()
  // Initialize Google Maps Loader and extract the isLoading property
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: GOOGLE_API_KEY as string,
    libraries
  })
  // State for saving the selected address
  const [selectedAddress, setSelectedAddress] = useState<SelectedAddressProps>(
    initialSelectedAddressValues
  )

  useEffect(() => {
    const init = async () => {
      try {
        if (isLoaded && !googlePlacesService) {
          setGooglePlacesService(new google.maps.places.AutocompleteService())
          setGoogleGeocoder(new google.maps.Geocoder())
          setMapsApiIsLoaded(true)
        }
      } catch (e) {
        console.error('FAILED TO LOAD GOOGLE SERVICES', e)
      }
    }

    init()
  }, [isLoaded])

  return (
    <GoogleContext.Provider
      value={{
        googlePlacesService,
        googleGeocoder,
        mapsApiIsLoaded,
        setSelectedAddress,
        selectedAddress
      }}
    >
      {children}
    </GoogleContext.Provider>
  )
}

export default GoogleProvider
