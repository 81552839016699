import React, { Dispatch, FC, SetStateAction, useState } from 'react'
import { Button, Flex, Link, List, ListItem, Text, UnorderedList } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import { DefaultModal } from '../../../Modals'
import { ConsentMessageItemType } from '../../../../types'
type AreYouSureModalType = {
  isOpen: boolean
  onClose: () => void
}

const AreYouSureModal: FC<AreYouSureModalType> = ({ isOpen, onClose }) => {
  return (
    <DefaultModal isOpen={isOpen} onClose={onClose} heading={'Note'}>
      <Flex direction="column" gap={15}>
        <Text textAlign={'center'} color={'brand.text.primary'}>Your consent is needed in order to continue with the onboarding process.</Text>
        <Text textAlign={'center'} color={'brand.text.primary'}>
          Feel free to contact us at{' '}
          <Link href={`mailto: hello@gosolr.co.za?subject = Support Request`} color={'#3b82f6'}>
            hello@gosolr.co.za
          </Link>{' '}
          or give us a call at{' '}
          <Link href={`tel:010 880 3948`} color={'#3b82f6'}>
            010 880 3948
          </Link>{' '}
          if you require more information.
        </Text>
      </Flex>

      <Flex
        direction={['column', 'column', 'row', 'row']}
        justifyContent="right"
        alignItems="right"
        width={'full'}
      >
        <Button onClick={onClose} variant="dark" rounded="full">
          Understood
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default AreYouSureModal
