import React, { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  Exact,
  User,
  UserProperty,
  useGetUserPropertiesQuery,
  useGetUserQuery
} from '../../generated/graphql'
import { validateRecordIdPath } from '../../helpers/auth'

type OnboardingPathValidationProviderPropsType = {
  children: ReactNode
}

type ContextType = {
  recordId: string | undefined
  isPathValid: boolean
  user: User | undefined
  property: UserProperty | undefined
  loading: boolean
}

const OnboardingPathValidatorContext = createContext<ContextType>({
  recordId: undefined,
  isPathValid: false,
  user: undefined,
  property: undefined,
  loading: true
})

const OnboardingPathValidationProvider = ({
  children,
  customerNumber,
  email
}: {
  children: ReactNode
  customerNumber: string
  email: string
}) => {
  const { recordId } = useParams()
  const [user, setUser] = useState<User>()
  const [property, setProperty] = useState<UserProperty>()
  const [isPathValid, setIsPathValid] = useState<boolean>(false)
  const [loadingProperties, setLoadingProperties] = useState(true)
  const location = useLocation()

  // Get user
  const { refetch: getUser } = useGetUserQuery({
    skip: true
  })

  // Get user properties
  const { refetch: fetchUserProperties } = useGetUserPropertiesQuery({
    variables: {
      customerNumber: customerNumber!
    }
  })

  useEffect(() => {
    const retrieveUserProperties = async () => {
      setLoadingProperties(true)

      const { data: userData, loading: loadingUser } = await getUser({ email, public: false })
      const currentUser = userData?.getUser

      if (currentUser?.__typename === 'ErrorResponse') {
        setIsPathValid(false)
        throw Error(
          'An error occured when trying to fetch user from OnboardingPathValidationProvider'
        )
      }
      if (currentUser?.__typename === 'User') {
        setIsPathValid(false)
        setUser(currentUser)
      }

      const { data, loading: loadingProperty } = await fetchUserProperties(
        customerNumber as Partial<Exact<{ customerNumber: string }>>
      )

      const propertiesResponse = data.getUserProperties

      if (propertiesResponse?.__typename === 'ErrorResponse') {
        console.log('there was an error retrieving user properties')
      }

      if (propertiesResponse?.__typename === 'UserProperties') {
        const userProperty = await validateRecordIdPath(recordId, propertiesResponse?.properties)
        // If userProperty is false
        if (userProperty === undefined || userProperty.length === 0) {
          setIsPathValid(false)
        }

        if (userProperty && userProperty.length > 0) {
          setProperty(userProperty[0] as UserProperty)

          setIsPathValid(true)
        }
      }
      if (!loadingUser && !loadingProperty) setLoadingProperties(false)
    }
    retrieveUserProperties()
  }, [location])

  const expose = {
    recordId,
    isPathValid,
    user,
    property,
    loading: loadingProperties
  }

  return (
    <OnboardingPathValidatorContext.Provider value={expose}>
      {children}
    </OnboardingPathValidatorContext.Provider>
  )
}

export const useGetValidatedUserAndProperty = () => {
  let context = useContext(OnboardingPathValidatorContext)

  if (context === undefined) {
    throw new Error('useValidatePath must be used inside OnboardingPathValidationProvider')
  }

  return context
}

export default OnboardingPathValidationProvider
