import React from 'react'
import { Flex } from '@chakra-ui/react'
import { ContainerHeading } from '../../Headings'
import AccIDVerification from '../../Account/AccIDVerification'
import { FlexContainer } from '../Global'

const AccIDVerificationContainer = (): JSX.Element => {
  return (
    <FlexContainer
      w={['100%', '100%', '100%', '100%', '50%']}
      h={'250px'}
      bgColor={'brand.neutral'}
    >
      <ContainerHeading title="Identification document" />
      <AccIDVerification />
    </FlexContainer>
  )
}

export default AccIDVerificationContainer
