import numeral from 'numeral'

numeral.register('locale', 'za-custom', {
  delimiters: {
    thousands: ' ',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal: () => {
    return ''
  },
  currency: {
    symbol: 'R'
  }
})

const localData = numeral.localeData('za-custom')

export default (
  amount: number | string | null | undefined,
  digitFormat = '0,0.00',
  includeSpaceAfterPrefix = true,
  prefixCurrency = true
): string => {
  try {
    if (amount) {
      let inputString = digitFormat

      if (prefixCurrency) {
        const currency = localData.currency.symbol
        inputString = `${includeSpaceAfterPrefix ? currency + ' ' : currency}${digitFormat}`
      }

      return numeral(amount).format(inputString)
    }
  } catch (e) {
    return ''
  }

  return ''
}
