import React from 'react'
import { Text } from '@chakra-ui/react'

export const NoDataFound: React.FC<{ message?: string }> = ({ message }) => {
  return (
    <Text
      w={'full'}
      textAlign={'center'}
      px="4"
      py="2px"
      rounded="6px"
      bgColor={'blackAlpha.900'}
      userSelect="none"
      color={'#FFFF'}
      fontSize={'sm'}
      //   outline="1px solid black"
      shadow="md"
    >
      {message ? message : 'No data found'}
    </Text>
  )
}
