import { Flex, useMediaQuery, Text, Center, Spinner } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DocumentUploadForm from '../../../components/Onboarding/Dashboard/Documents/DocumentUploadForm'

import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  File as CustomFile,
  useGetFileQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { convertBase64ToFile } from '../../../utils'
import axios from 'axios'
// import ReCAPTCHA from "react-google-recaptcha";

type ProofOfAddressProps = {
  stepper: React.ReactNode
}

const OnBoardingDocumentsPage: FC = () => {
  const { recordId } = useParams() //

  const { user } = useAuthContext()

  const [file, setFile] = useState<File>()
  const [idFile, setIDFile] = useState<File>()
  const [bankStatement_01, setBankStatement_01] = useState<File>()
  const [bankStatement_02, setBankStatement_02] = useState<File>()
  const [bankStatement_03, setBankStatement_03] = useState<File>()
  const [bankName, setBankName] = useState<string>('')
  const [accountHolderName, setAccountHolderName] = useState<string>('')
  const [accountType, setAccountType] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [debitOrderDate, setDebitOrderDate] = useState<string>('')
  const [branchCode, setBranchCode] = useState<string>('')
  const [loadingProofOfAddress, setLoadingProofOfAddress] = useState<boolean>(false)
  const [loadingIdPassportDocument, setLoadingIdPassportDocument] = useState<boolean>(false)
  const [loadingBankStatement1, setLoadingBankStatement1] = useState<boolean>(false)
  const [loadingBankStatement2, setLoadingBankStatement2] = useState<boolean>(false)
  const [loadingBankStatement3, setLoadingBankStatement3] = useState<boolean>(false)

  const { data, loading } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = data?.getUser as User

  const { refetch: getFile } = useGetFileQuery({
    skip: true
  })

  useEffect(() => {
    const init = async () => {
      if (recentUserData) {
        if (recentUserData?.debitOrderDate) {
          setDebitOrderDate(recentUserData?.debitOrderDate)
        }
        // // console.log(recentUserData)
        const property = (recentUserData?.properties as UserProperty[]).find(
          (p) => p.recordId === recordId
        )

        if (property?.proofOfAddressS3Key) {
          setLoadingProofOfAddress(true)
        }
        // Id Passport document
        if (recentUserData?.idPassportDocumentS3Key) {
          setLoadingIdPassportDocument(true)
        }
        // Bank Statement 01
        // @ts-ignore
        if (property?.bankStatementDocument_01S3Key) {
          setLoadingBankStatement1(true)
        }
        // Bank Statement 02
        // @ts-ignore
        if (property?.bankStatementDocument_02S3Key) {
          setLoadingBankStatement2(true)
        }
        // // Bank Statement 03
        // // @ts-ignore

        if (property?.bankStatementDocument_03S3Key) {
          setLoadingBankStatement3(true)
        }

        if (recentUserData?.banks) {
          const bankDetails = recentUserData.banks[0]
          if (bankDetails?.bankName) {
            setBankName(bankDetails?.bankName)
          }
          if (bankDetails?.memberName) {
            setAccountHolderName(bankDetails?.memberName)
          }
          if (bankDetails?.bankAccountType) {
            setAccountType(bankDetails?.bankAccountType)
          }
          if (bankDetails?.bankAccountNumber) {
            setAccountNumber(bankDetails?.bankAccountNumber)
          }
          if (bankDetails?.branchCode) {
            setBranchCode(bankDetails?.branchCode)
          }
        }

        // Id Passport document
        if (recentUserData?.idPassportDocumentS3Key) {
          const s3File = await getFile({
            key: recentUserData.idPassportDocumentS3Key as string
          })
          const splitKey = recentUserData?.idPassportDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setIDFile(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        // Bank Statement 01
        // @ts-ignore
        if (property?.bankStatementDocument_01S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_01S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_01S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_01(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        // Bank Statement 02
        // @ts-ignore
        if (property?.bankStatementDocument_02S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_02S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_02S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_02(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        // // Bank Statement 03
        // // @ts-ignore
        if (property?.bankStatementDocument_03S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_03S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_03S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_03(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
      }
    }

    init()
  }, [recentUserData])

  useEffect(() => {
    if (file) {
      setLoadingProofOfAddress(false)
    }
    if (idFile) {
      setLoadingIdPassportDocument(false)
    }
    if (bankStatement_01) {
      setLoadingBankStatement1(false)
    }
    if (bankStatement_02) {
      setLoadingBankStatement2(false)
    }
    if (bankStatement_03) {
      setLoadingBankStatement3(false)
    }
  }, [file, idFile, bankStatement_01, bankStatement_02, bankStatement_03])

  return (
    <Flex w="100%" gap="50px" direction={'column'} alignItems={'center'} p={25}>
      <OnBoardingStepper />

      {loading && (
        <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
          <Flex gap="25px" alignItems={'center'} justifyContent="center">
            <Center>
              <Spinner size={'lg'}></Spinner>
            </Center>
            <Text>Loading document upload form</Text>
          </Flex>
        </Center>
      )}
      <DocumentUploadForm
        recordId={recordId as string}
        file={file}
        idFile={idFile}
        loadingFile={loadingProofOfAddress}
        loadingIdFile={loadingIdPassportDocument}
        loadingBankStatement1={loadingBankStatement1}
        loadingBankStatement2={loadingBankStatement2}
        loadingBankStatement3={loadingBankStatement3}
        bankStatementDocument_01={bankStatement_01}
        bankStatementDocument_02={bankStatement_02}
        bankStatementDocument_03={bankStatement_03}
        debitOrderDate={debitOrderDate}
        bankAccountNumber={accountNumber}
        bankName={bankName}
        memberName={accountHolderName}
        bankAccountType={accountType}
        branchCode={branchCode} // payslipsDocument={payslips}
      />
    </Flex>
  )
}

export default OnBoardingDocumentsPage
