import React, { useEffect, useState } from 'react'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import ContainerHeading from '../Headings/ContainerHeading'
import EnergyGauge from './EnergyGauge'
import ProducedItem from './ProducedItem'
import BatteryPowerItem from './ChargeItem'
import ConsumedItem from './ConsumedItem'
import { useLocation, useNavigate } from 'react-router-dom'
import { FlexContainer } from '../containers/Global'
import { ButtonStandard } from '../Buttons'
import { KPIDataType } from '../../types'

export type KPIStatsContainerType = {
  kpiData: KPIDataType
  isLoading: boolean
}

const KPIStatsContainer: React.FC<KPIStatsContainerType> = ({ kpiData, isLoading }) => {
  const [isPropertyPage, setIsPropertPage] = useState<Boolean>(false)

  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname.includes('user/property/')) {
      setIsPropertPage(true)
    }
  }, [location.pathname])

  return (
    <FlexContainer
      w={['100%', '100%', '100%', '50%']}
      h={kpiData ? '100%' : '300px'}
      maxH={kpiData ? '100%' : '300px'}
      p={'25px'}
      borderRightRadius="8px"
      borderRadius={isPropertyPage ? '8px' : ['0, 8px 8px, 0']}
      bgColor={'#171717'}
      direction={'column'}
    >
      <ContainerHeading title="System performance" headingColor="#FFD635" />
      <Flex
        direction={'column'}
        w="100%"
        h="100%"
        maxH={'100%'}
        alignItems={'center'}
        gap={10}
        justifyContent="center"
      >
        {isLoading && <Spinner size="lg" color="brand.primary" />}
        {!kpiData && !isLoading && (
          <Flex
            direction={'column'}
            gap={'25px'}
            alignItems="center"
            justifyContent="space-between"
            h="100%"
            maxH={'100%'}
            mt="25px"
          >
            <Text color={'brand.text.light'}>No data available at this point in time</Text>
            <Text color={'brand.text.light'}>Please refresh the page.</Text>
            <ButtonStandard
              variant={'yellow'}
              backgroundColor={'brand.primary'}
              color="black"
              height="40px"
              onClick={() => navigate('/user/support')}
              w="auto"
            >
              Ask support
            </ButtonStandard>
          </Flex>
        )}
        {!isLoading && kpiData && <SystemPerformance data={kpiData} />}
      </Flex>
    </FlexContainer>
  )
}

export default KPIStatsContainer

export const SystemPerformance = ({ data }: { data: KPIDataType }) => {
  return (
    <>
      <Flex
        direction={['column', 'row', 'row', 'row']}
        w="100%"
        h="100%"
        maxH={'100%'}
        alignItems={'center'}
        justifyContent="space-between"
        gap={10}
      >
        <EnergyGauge percentage={+data.batteryCharge || 0} />
        <BatteryPowerItem batteryPower={data?.currentProduction || '0'} />
      </Flex>
      <Flex
        direction={['column', 'row', 'row', 'row']}
        w="100%"
        alignItems={'center'}
        justifyContent="center"
        h="100%"
        maxH={'100%'}
        gap={10}
      >
        <ProducedItem produced={data?.dailyProduction || '0'} />
        <ConsumedItem consumed={data?.dailyConsumption || '0'} />
      </Flex>
    </>
  )
}
