import { Flex, useMediaQuery, Text, Center, Spinner } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DocumentUploadForm from '../../../components/Onboarding/Dashboard/Documents/DocumentUploadForm'

import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  File as CustomFile,
  useGetFileQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { convertBase64ToFile } from '../../../utils'
import BusinessDocumentUploadForm from '../../../components/Onboarding/Dashboard/Documents/BusinessDocumentUploadForm'
import axios from 'axios'

const BusinessDocumentsPage: FC = () => {
  const { recordId } = useParams()
  const { user } = useAuthContext()

  const [proofOfOwnership, setProofOfOwnership] = useState<File>()
  const [businessRegistration, setBusinessRegistration] = useState<File>()
  const [taxClearance, setTaxClearance] = useState<File>()
  const [bankStatement_01, setBankStatement_01] = useState<File>()
  const [bankStatement_02, setBankStatement_02] = useState<File>()
  const [bankStatement_03, setBankStatement_03] = useState<File>()
  const [businessSuretyship, setBusinessSuretyship] = useState<File>()
  const [businessConsent, setBusinessConsent] = useState<File>()
  const [bankName, setBankName] = useState<string>('')
  const [accountHolderName, setAccountHolderName] = useState<string>('')
  const [accountType, setAccountType] = useState<string>('')
  const [accountNumber, setAccountNumber] = useState<string>('')
  const [debitOrderDate, setDebitOrderDate] = useState<string>('')
  const [branchCode, setBranchCode] = useState<string>('')
  const [loadingBankStatement1, setLoadingBankStatement1] = useState<boolean>(false)
  const [loadingBankStatement2, setLoadingBankStatement2] = useState<boolean>(false)
  const [loadingBankStatement3, setLoadingBankStatement3] = useState<boolean>(false)
  const [loadingProofOfOwnership, setLoadingProofOfOwnership] = useState<boolean>(false)
  const [loadingBusinessRegistration, setLoadingBusinessRegistration] = useState<boolean>(false)
  const [loadingTaxClearance, setLoadingTaxClearance] = useState<boolean>(false)
  const [loadingBusinessSurety, setLoadingBusinessSurety] = useState<boolean>(false)
  const [loadingBusinessConsent, setLoadingBusinessConsent] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const { data, loading } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = data?.getUser as User

  const { refetch: getFile } = useGetFileQuery({
    skip: true
  })

  useEffect(() => {
    //Get the files uploaded to S3
    const init = async () => {
      if (recentUserData) {
        const property = (recentUserData?.properties as UserProperty[]).find(
          (p) => p.recordId === recordId
        )
        if (property?.bankStatementDocument_01S3Key) {
          setLoadingBankStatement1(true)
        }
        if (property?.bankStatementDocument_02S3Key) {
          setLoadingBankStatement2(true)
        }
        if (property?.bankStatementDocument_03S3Key) {
          setLoadingBankStatement3(true)
        }
        if (property?.proofOfOwnershipDocumentS3Key) {
          setLoadingProofOfOwnership(true)
        }
        if (property?.businessRegistrationDocumentS3Key) {
          setLoadingBusinessRegistration(true)
        }
        if (property?.taxClearanceDocumentS3Key) {
          setLoadingTaxClearance(true)
        }
        if (property?.businessSuretyshipDocumentS3Key) {
          setLoadingBusinessSurety(true)
        }
        if (property?.businessConsentDocumentS3Key) {
          setLoadingBusinessConsent(true)
        }

        // Get bank details
        if (recentUserData?.debitOrderDate) {
          setDebitOrderDate(recentUserData?.debitOrderDate)
        }
        if (recentUserData?.banks) {
          const bankDetails = recentUserData.banks[0]
          if (bankDetails?.bankName) {
            setBankName(bankDetails?.bankName)
          }
          if (bankDetails?.memberName) {
            setAccountHolderName(bankDetails?.memberName)
          }
          if (bankDetails?.bankAccountType) {
            setAccountType(bankDetails?.bankAccountType)
          }
          if (bankDetails?.bankAccountNumber) {
            setAccountNumber(bankDetails?.bankAccountNumber)
          }
          if (bankDetails?.branchCode) {
            setBranchCode(bankDetails?.branchCode)
          }
        }

        // Bank Statement 01
        // @ts-ignore
        if (property?.bankStatementDocument_01S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_01S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_01S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_01(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        // Bank Statement 02
        // @ts-ignore
        if (property?.bankStatementDocument_02S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_02S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_02S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_02(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        // // Bank Statement 03
        // // @ts-ignore
        if (property?.bankStatementDocument_03S3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.bankStatementDocument_03S3Key as string
          })
          // @ts-ignore
          const splitKey = property.bankStatementDocument_03S3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBankStatement_03(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        //Business Registration
        if (property?.businessRegistrationDocumentS3Key) {
          const s3File = await getFile({
            key: property.businessRegistrationDocumentS3Key as string
          })
          const splitKey = property?.businessRegistrationDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBusinessRegistration(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        //proof of ownership
        if (property?.proofOfOwnershipDocumentS3Key) {
          const s3File = await getFile({
            key: property.proofOfOwnershipDocumentS3Key as string
          })
          const splitKey = property?.proofOfOwnershipDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setProofOfOwnership(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        // Tax Clearance
        if (property?.taxClearanceDocumentS3Key) {
          const s3File = await getFile({
            key: property.taxClearanceDocumentS3Key as string
          })
          const splitKey = property?.taxClearanceDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setTaxClearance(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        // Business surety
        if (property?.businessSuretyshipDocumentS3Key) {
          const s3File = await getFile({
            key: property.businessSuretyshipDocumentS3Key as string
          })
          const splitKey = property?.businessSuretyshipDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBusinessSuretyship(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        if (property?.businessConsentDocumentS3Key) {
          const s3File = await getFile({
            key: property.businessConsentDocumentS3Key as string
          })
          const splitKey = property?.businessConsentDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setBusinessConsent(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
      }
    }

    init()
  }, [recentUserData])

  useEffect(() => {
    if (proofOfOwnership) {
      setLoadingProofOfOwnership(false)
    }
    if (taxClearance) {
      setLoadingTaxClearance(false)
    }
    if (businessRegistration) {
      setLoadingBusinessRegistration(false)
    }
    if (bankStatement_01) {
      setLoadingBankStatement1(false)
    }
    if (bankStatement_02) {
      setLoadingBankStatement2(false)
    }
    if (bankStatement_03) {
      setLoadingBankStatement3(false)
    }
    if (businessSuretyship) {
      setLoadingBusinessSurety(false)
    }
    if (businessConsent) {
      setLoadingBusinessConsent(false)
    }
  }, [
    proofOfOwnership,
    taxClearance,
    bankStatement_01,
    bankStatement_02,
    bankStatement_03,
    businessRegistration,
    businessSuretyship,
    businessConsent
  ])

  return (
    <Flex
      w="100%"
      gap="50px"
      direction={'column'}
      alignItems={'center'}
      // bgColor={'brand.input'}
      // rounded={'8px'}
      p={25}
    >
      <OnBoardingStepper />

      {isLoading && (
        <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
          <Flex gap="25px" alignItems={'center'} justifyContent="center">
            <Center>
              <Spinner size={'lg'}></Spinner>
            </Center>
            <Text>Loading document upload form</Text>
          </Flex>
        </Center>
      )}
      <BusinessDocumentUploadForm
        recordId={recordId as string}
        //recordId={recordId as string}
        proofOfOwnershipDocument={proofOfOwnership}
        businessRegistrationDocument={businessRegistration}
        taxClearanceDocument={taxClearance}
        bankStatementDocument_01={bankStatement_01}
        bankStatementDocument_02={bankStatement_02}
        bankStatementDocument_03={bankStatement_03}
        debitOrderDate={debitOrderDate}
        bankAccountNumber={accountNumber}
        bankName={bankName}
        memberName={accountHolderName}
        bankAccountType={accountType}
        branchCode={branchCode}
        loadingBankStatement1={loadingBankStatement1}
        loadingBankStatement2={loadingBankStatement2}
        loadingBankStatement3={loadingBankStatement3}
        loadingBusinessRegistration={loadingBusinessRegistration}
        loadingTaxClearance={loadingTaxClearance}
        loadingProofOfOwnership={loadingProofOfOwnership}
        businessSuretyshipDocument={businessSuretyship}
        loadingBusinessSurety={loadingBusinessSurety}
        businessConsentDocument={businessConsent}
        loadingBusinessConsentDocument={loadingBusinessConsent}
      />
    </Flex>
  )
}

export default BusinessDocumentsPage
