import React from 'react'
import { Flex, Button } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import { NoDataFound } from '../Misc/NoDataFound'
import SubscriptionList from '../Subscriptions/SubscriptionsList'
import { Maybe, UserProperty } from '../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { ONBOARDING_URL, STAGING_ENVIRONMENT, AUTH_STORAGE_KEY } from '../../constants'
import { useCookies } from 'react-cookie'
import LZString from 'lz-string'
import { USER_STORAGE_KEY } from '../../constants'
import { getDomain } from '../../helpers'

type PropertiesListType = {
  userProperties: UserProperty[]
  onboardingStepsCompleted: Maybe<number> | undefined
}

const PropertiesListContainer: React.FC<PropertiesListType> = ({
  userProperties,
  onboardingStepsCompleted
}) => {
  const navigate = useNavigate()
  const [cookies, setCookie] = useCookies([AUTH_STORAGE_KEY])
  const canAddProperty = onboardingStepsCompleted && onboardingStepsCompleted >= 9 ? false : true

  const handleClick = () => {
    const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
      sessionStorage.getItem(USER_STORAGE_KEY)) as string
    const user = userStorage ? JSON.parse(userStorage) : {}

    //console.log(`user: AUTH`, user?.auth)

    const cookieData = {
      email: user?.info?.email || '',
      recordId: user?.info?.recordId || '',
      accessToken: user?.auth?.accessToken || ''
    }

    const domain = getDomain()
    const secure = domain === '.localhost' ? false : true
    let expires = new Date()
    expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000)
    console.log(`cookieData: `, cookieData)

    //remove thi

    // Set tokens in cookies
    //document.cookie = `idToken=${user?.auth?.idToken?.jwtToken}; domain=.localhost; path=/; secure; SameSite=None`
    document.cookie = `${AUTH_STORAGE_KEY}=${LZString.compressToBase64(
      JSON.stringify(user?.auth?.accessToken)
    )}; domain=${domain}; path=/; secure=${secure}; SameSite=None`
    //document.cookie = `refreshToken=${user?.auth?.refreshToken}; domain=.localhost; path=/; secure; SameSite=None`

    //debugger
    //window.location.href = `${ONBOARDING_URL}/interested`
    // console.log(`${ONBOARDING_URL}/interested`)
    window.open(`${ONBOARDING_URL}/interested/address`, '_blank')
  }
  return (
    <Flex
      direction={'column'}
      w="100%"
      bg="#ffff"
      gap="25px"
      rounded={'6px'}
      p={'25px'}
      shadow="md"
    >
      <Flex w="full">
        <ContainerHeading title="Properties" />
        <Button variant={'dark'} onClick={handleClick} isDisabled={canAddProperty}>
          Add new
        </Button>
      </Flex>
      {userProperties.length <= 0 ? (
        <NoDataFound />
      ) : (
        <SubscriptionList
          subscriptions={userProperties}
          onboardingStepsCompleted={onboardingStepsCompleted}
        />
      )}
    </Flex>
  )
}

export default PropertiesListContainer
