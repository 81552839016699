import React, { FC, useEffect, useState } from 'react'
import { Box, Button, Center, Flex, Grid, Highlight, Spinner, Text } from '@chakra-ui/react'
import DropDownInput from '../../../FormElements/DropDownInput'
import TextInput from '../../../FormElements/TextInput'
import {
  Formik,
  FormikErrors,
  FormikHelpers,
  FormikState,
  FormikValues,
  useField,
  useFormikContext
} from 'formik'

import { InitialBillingFormValueTypes } from '../../../../types'
import { useGetBanksQuery } from '../../../../generated/graphql'

const debitOrderDates = [
  {
    name: '1st of each month',
    value: '1st'
  },
  {
    name: '26th of each month',
    value: '26th'
  },
  {
    name: '28th of each month',
    value: '28th'
  }
]

type Bank = {
  bankName: string
  branchCode: string
}

type BillingFormPropsType = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  isSubmitting: boolean
  values: InitialBillingFormValueTypes
  errors: FormikErrors<InitialBillingFormValueTypes>
}

const BillingFrom: FC<BillingFormPropsType> = ({ setFieldValue, isSubmitting, values, errors }) => {
  const [branchCode, setBranchCode] = useState<string>('')
  const [banks, setBanks] = useState<Bank[]>([])

  const { refetch: getBanks, loading: loadingBanks } = useGetBanksQuery({
    skip: true
  })

  useEffect(() => {
    const init = async () => {
      const response = await getBanks()

      // @ts-ignore
      const allBanks = [...response?.data?.getBanks?.banks]

      const soretedBanks = await allBanks.sort((a: any, b: any) => {
        let fa = a.bankName.toLowerCase(),
          fb = b.bankName.toLowerCase()

        if (fa < fb) {
          return -1
        }
        if (fa > fb) {
          return 1
        }
        return 0
      })

      // @ts-ignore
      setBanks(soretedBanks)
    }

    init()
  }, [])

  return (
    <Flex w={'full'} direction={'column'} gap={25}>
      <BillingObserver setBranchCode={setBranchCode} banks={banks} />
      <Text fontSize={16} fontWeight={700} mb={4}>
        Select a debit order date
      </Text>
      <Flex gap={15} direction={'column'} w="full">
        
          <Flex direction = {'column'} gap={15} w='full' alignItems={'center'} justifyContent={'center'}>
            
            {debitOrderDates.map(({ name, value }, i) => (
              <Button
                key={`${name}-${value}-${i}`}
                rounded={'full'}
                px={5}
                variant={values.debitOrderDate === value ? 'solid' : 'outline'}
                border={'2px solid'}
                borderColor={
                  values.debitOrderDate === value ? 'brand.primary' : 'brand.text.primary'
                }
                bgColor={values.debitOrderDate === value ? 'brand.primary' : 'brand.neutral'}
                onClick={() => {
                  setFieldValue('debitOrderDate', value)
                }}
                _active={{ transform: 'scale(0.95)' }}
                _hover={{ transform: 'scale(1.01)' }}
                w="full"
              >
                <Text letterSpacing={'normal'} fontWeight={'normal'} fontSize={14}>
                  <Highlight query={value} styles={{ fontWeight: 'black' }}>
                    {name}
                  </Highlight>
                </Text>
              </Button>
            ))}
         
          </Flex>
    

        <Flex w="full" direction={'column'} gap="25px">
          <DropDownInput
            name="bankName"
            label="Bank"
            placeholder="Select bank"
            options={banks.map((b) => ({
              label: b.bankName,
              value: b.bankName
            }))}
          />
          <TextInput
            name="memberName"
            placeholder="Account holder name"
            label="Enter account holder name"
          />
          <DropDownInput
            name="bankAccountType"
            label="Account type"
            placeholder="Select account type"
            options={[
              { label: 'Cheque/Current', value: 'Cheque/Current' },
              { label: 'Savings', value: 'Savings' },
              { label: 'Transmission', value: 'Transmission' }
            ]}
          />
          <TextInput
            name="bankAccountNumber"
            label="Account number"
            placeholder="Enter account number"
            maxLength={50}
          />
          <Text>Branch code: {values.branchCode}</Text>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BillingFrom

type BillingObserverType = {
  setBranchCode: (value: string) => void
  banks: Bank[]
}

const BillingObserver: React.FC<BillingObserverType> = ({ setBranchCode, banks }) => {
  const { setFieldValue } = useFormikContext<FormikState<FormikValues>>()
  const [field, helpers] = useField('bankName')

  useEffect(() => {
    const bank = banks.find((b) => b.bankName === field.value)
    setFieldValue('branchCode', bank?.branchCode)
    setBranchCode(bank?.branchCode as string)
  }, [helpers.value])

  return null
}
