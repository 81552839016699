import React, { FC, useState, useEffect, useContext } from 'react'
import { useSearchParams } from 'react-router-dom'
import { FiUser } from 'react-icons/fi'
import { RiBankLine } from 'react-icons/ri'
import { SettingsContext } from '../../context/SettingsContext'

import { Flex } from '@chakra-ui/react'
import SettingsNavigationItem from './SettingsNavigationItem'

const SettingsNavigation: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeMenuItem, SetActiveMenuItem] = useState('Account settings')

  const { handleSetActiveTab } = useContext(SettingsContext)

  const q: string = 'Account settings'

  const settingsMenuItems = [
    { title: 'Account settings', icon: <FiUser size={25} /> }
    // { title: 'Bank Settings', icon: <RiBankLine size={25} /> }
  ]

  const handleSetActiveMenuItem = (event: React.MouseEvent<Element, MouseEvent>) => {
    const target = event.target as HTMLInputElement

    SetActiveMenuItem(target.name)
    if (target.name) {
      const name = target.name
      // @ts-ignore
      handleSetActiveTab(name)
    }
    // console.log(activeMenuItem)
  }

  useEffect(() => {
    // console.log(q)
    switch (q) {
      case 'user':
        SetActiveMenuItem('Account settings')
        break
      case 'bank':
        SetActiveMenuItem('Bank settings')
        break

      default:
        SetActiveMenuItem('Account settings')
        break
    }
  }, [])
  return (
    <Flex
      direction={'column'}
      w="100%"
      maxW={['100%', '100%', '100%', '280px']}
      h={'100%'}
      maxHeight={'700px'}
      bg="brand.secondary"
      rounded="6px"
      shadow={'md'}
      p="25px"
      gap={'25px'}
    >
      {settingsMenuItems.map((item, index) => (
        <SettingsNavigationItem
          name={item.title}
          isActive={item.title === activeMenuItem}
          title={item.title}
          icon={item.icon}
          handleSelectMenu={handleSetActiveMenuItem}
          key={index}
        />
      ))}
    </Flex>
  )
}

export default SettingsNavigation
