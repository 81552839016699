/* istanbul ignore file */
import { Checkbox, CheckboxProps, Flex, Text } from '@chakra-ui/react'
import { useField } from 'formik'
import * as React from 'react'

export type ConnectedCheckboxProps = CheckboxProps & {
  label?: string | JSX.Element
  name: string
}

const ConnectedCheckbox: React.FC<ConnectedCheckboxProps> = ({
  label,
  width,
  mb,
  mt,
  mr,
  ml,
  position,
  top,
  bottom,
  ...rest
}) => {
  const [field, meta] = useField(rest.name)
  return (
    <Flex
      mb={mb}
      ml={ml}
      mr={mr}
      mt={mt}
      width={width}
      position={position || 'unset'}
      top={top || 'unset'}
      bottom={bottom || 'unset'}
      justify={rest.justifyContent}
    >
      <Checkbox
        borderRadius="2px"
        borderColor="brand.500"
        size={rest.size || 'lg'}
        colorScheme="cyan"
        {...field}
        id={field.name}
        {...rest}
      >
        {label}
      </Checkbox>
      {meta.touched && meta.error ? (
        <Text color="red.500" textAlign="right">
          {meta.error}
        </Text>
      ) : null}
    </Flex>
  )
}

export default ConnectedCheckbox

ConnectedCheckbox.defaultProps = {
  mb: 2,
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%'
}
