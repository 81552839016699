import React from 'react';
import { Flex, Heading, Text, Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

const NotFoundPage = (): JSX.Element => {
	return (
		<Flex
			direction='column'
			w='100vw'
			h={'100vh'}
			alignItems='center'
			justifyContent={'center'}
			padding={4}
			gap={50}
		>
			<Heading>Page Not Found</Heading>
			<Text>The page that you requested could not be found.</Text>
			<Link as={RouterLink} to='/'>
				Home
			</Link>
		</Flex>
	);
};

export default NotFoundPage;
