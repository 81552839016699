import * as yup from 'yup'
import YupPassword from 'yup-password'
import {
  PROMO_CODE_REGEX,
  SA_PHONE_NUMBER_REGEX,
  TEXT_ONLY_REGEX,
  CUSTOMER_TYPE_OPTIONS,
  ELECTRICITY_PROVIDERS_TYPE_OPTIONS,
  ELECTRICITY_SPEND_OPTIONS,
  HOMEOWNERS_INSURANCE_OPTIONS,
  METER_TYPE_OPTIONS,
  ROOF_TYPE_OPTIONS,
  DWELLING_TYPE_REGEX,
  POSTAL_CODE_REGEX,
  ID_NUMBER_REGEX,
  ROOF_TYPE_VALIDATION_OPTIONS,
  EMAIL_REGEX
} from '../constants'

YupPassword(yup)

type objArr = {
  value: string
  label: string
}

const objArrToIndexArr = (objArr: objArr[]) => {
  let newArr = objArr.map((obj) => obj.value)
  return newArr
}

export const emailResendVerification = yup.object().shape({
  userResendEmail: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required')
})

export const LoginValidation = yup.object().shape({
  email: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required'),
  password: yup
    .string()
    .min(8, 'Password must have a minimum length of 8 characters')
    .minLowercase(1, 'password must contain at least 1 lower case letter')
    .minUppercase(1, 'password must contain at least 1 upper case letter')
    .minNumbers(1, 'password must contain at least 1 number')
    .minSymbols(1, 'password must contain at least 1 special character')
    .required('Password is required')
})
export const RegisterValidation = yup.object().shape({
  email: yup
    .string()
    .email('Please use a valid email address.')
    .matches(EMAIL_REGEX, 'Please use a valid email address.')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('Full name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),
  lastName: yup
    .string()
    .required('Last name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),
  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Phone number is required.')
    .min(10)
    .max(13),
  password: yup
    .string()
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Minimum length of 8 characters. Must contain capital letters, numbers and special characters'
    )
    .required('Password is required'),
  confirmPassword: yup
    .string()
    .label('confirm password')
    .required()
    .oneOf([yup.ref('password'), ''], 'Passwords must match'),
  termsAndConditions: yup.string().required('You must accept terms and conditions')
})

export const PersonalDetailsValidation = yup.object().shape({
  email: yup.string().email('Please enter a valid email address').required('Email required'),
  firstName: yup
    .string()
    .required('First name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),
  lastName: yup
    .string()
    .required('Last name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),

  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Phone number is required.'),
  promoCode: yup
    .string()
    .max(6, 'Referral code can only be 6 characters long')
    .matches(PROMO_CODE_REGEX.test, PROMO_CODE_REGEX.message)
})

export const PropertyDetailsValidation = yup.object().shape({
  roofType: yup.mixed().oneOf(ROOF_TYPE_VALIDATION_OPTIONS).required('Select a roof type'),
  customerType: yup.mixed().required('Select if you own or rent'),
  electricityProvider: yup
    .mixed()
    .oneOf(objArrToIndexArr(ELECTRICITY_PROVIDERS_TYPE_OPTIONS))
    .required('Select your electricity provider'),
  meterType: yup
    .mixed()
    .oneOf(objArrToIndexArr(METER_TYPE_OPTIONS))
    .required('Select your meter type'),
  homeOwnersInsurance: yup
    .mixed()
    .oneOf(objArrToIndexArr(HOMEOWNERS_INSURANCE_OPTIONS))
    .required('Select your insurance options'),
  monthlyElectricitySpend: yup
    .mixed()
    .oneOf(objArrToIndexArr(ELECTRICITY_SPEND_OPTIONS))
    .required('Select an estimated monthly electricity usage.')
})

export const LocationValidation = yup.object().shape({
  addressConfirmed: yup.boolean().oneOf([true]),
  streetAddress: yup.string().required('Street address is required.'),
  suburb: yup.string().required('Suburb is required.'),
  city: yup.string().required('City is required.'),
  province: yup.string().required('Province is required.'),
  country: yup.string().required('Countryis required.'),
  dwellingType: yup
    .string()
    .required('Select a dewelling type required.')
    .matches(DWELLING_TYPE_REGEX, 'Valid postal code required'),
  complexBuildingName: yup.string().when('dwellingType', {
    is: 'Complex' || 'Townhouse' || 'Apartment block',
    then: () => yup.string().required('Complex/Estate/Building name is required.')
  }),
  unitNumber: yup.string().when('dwellingType', {
    is: 'Complex' || 'Townhouse' || 'Apartment block',
    then: () => yup.string().required('Unit number name is required.')
  })
})

export const PaymentInfoFormValidation = yup.object().shape({
  idNumber: yup
    .string()
    .min(13, 'ID numbers require a minimum of 13 digits')
    .matches(ID_NUMBER_REGEX, { message: 'Please enter a valid ID number' }),
  passportNumber: yup.string(),
  bankName: yup.string().required('Bank is required.'),
  memberName: yup
    .string()
    .required('Account holder name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field '),
  bankAccountType: yup.string().required('Account type is required.'),
  bankAccountNumber: yup
    .string()
    .required('Account number is required.')
    .matches(/\W*\d{8,17}\b/, 'Invalid number'),
  branchCode: yup.string().required('Branch code is required.'),
  debitOrderDate: yup.string().required('Debit order date is required.')
})
export const DocumentUploadFormValidation = yup.object().shape({
  bankName: yup.string().required(),
  memberName: yup.string().required(),
  bankAccountType: yup.string().required(),
  bankAccountNumber: yup.string().required(),
  branchCode: yup.string().required('Branch code is required.'),
  debitOrderDate: yup.string().required('Debit order date is required.')
})

export const bankAccountValidation = yup.object().shape({
  bankName: yup.string().required('Name of the bank is required'),
  memberName: yup
    .string()
    .required('Account holder name is required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field '),
  bankAccountType: yup.string().required('Type of bank account is required'),
  bankAccountNumber: yup
    .string()
    .required('Account number is required')
    .min(8, 'Invalid bank account number')
    .max(11, 'Invalid bank account number')
    .matches(/^\d+$/, 'Invalid number')
})

// export const OptionalDocumentUploadFormValidation = yup.object().shape({
//   electricalDistributionBoardPhoto: yup.object().nullable(),
//   installationLocationPhoto: yup.object().nullable(),
//   roofPhoto: yup.object().nullable(),
//   additionalNotes: yup.string().nullable()
// })
export const CreditCheckValidation = yup.object().shape({
  selectedInput: yup.string().required(),
  idNumber: yup.string().when('selectedInput', {
    is: 'id',
    then: () =>
      yup
        .string()
        .min(17, 'ID numbers require a minimum of 13 digits')
        .max(17, 'ID numbers require a maximum of 13 digits')
        .matches(ID_NUMBER_REGEX, { message: 'Please enter a valid ID number' })
        .required()
  }),
  passportNumber: yup.string(),
  firstName: yup
    .string()
    .required('Full Name required')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field'),
  lastName: yup
    .string()
    .required()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field')
})

export const PersonalInfoFormValidation = yup.object().shape({
  firstName: yup
    .string()
    .required('First name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field'),
  lastName: yup
    .string()
    .required('Last name is required.')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field'),
  email: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required'),
  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Number is required.'),
  alternateNumber: yup.string().matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid'),
  initials: yup
    .string()
    .matches(/^[aA-zZ\s]+$/, 'Only alphabet characters are allowed for this field')
})
