import { useContext, useEffect, useState } from 'react'
import { OnboardingContainer } from '../../../containers/Global'
import { PreSignUpPropertyType } from '../../../../types'
import { Flex, Text } from '@chakra-ui/layout'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import { Button } from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import { useLocation, useNavigate } from 'react-router-dom'
import { DefaultModal } from '../../../Modals'
import PreSignUpContractSummary from '../contractSummary'
import ProductsOptionsForm from './subscriptionForm'
import { useAddUserPropertyMutation, useGetUserQuery } from '../../../../generated/graphql'
import { useAuthContext } from '../../../../context/AuthProvider'

const ProductsOptions = () => {
  const { handlePrevStepIndex, formData } = useContext(OnboardingFormContext)
  const [addProperty] = useAddUserPropertyMutation()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [viewContractSummary, setViewContractSummary] = useState<boolean>(false)
  const [finalSubmitData, setFinalSubmitData] = useState<PreSignUpPropertyType | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const { user } = useAuthContext()

  const onSubmit = async (): Promise<void> => {
    try {
      setIsSubmitting(true)
      await localStorage.setItem('onboarding_property_data', JSON.stringify(finalSubmitData))

      // await handleSetFormData(data)

      setIsSubmitting(false)
      navigate('/register')
    } catch (e) {
      console.error(e)
    }
  }

  const createAdditionalProperty = async () => {
    setIsSubmitting(true)
    try {
      // setIsSubmitting(true)
      // await localStorage.setItem('onboarding_property_data', JSON.stringify(finalSubmitData))
      if (finalSubmitData) {
        const resp = await addProperty({
          variables: {
            email: user?.info.email!,
            input: {
              propertyLat: finalSubmitData.propertyLat
                ? (finalSubmitData.propertyLat as number)
                : (finalSubmitData.roofLat as number),
              propertyLng: finalSubmitData.propertyLng
                ? (finalSubmitData.propertyLng as number)
                : (finalSubmitData.roofLng as number),
              propertyW3w: finalSubmitData.propertyW3w,
              fullAddress: finalSubmitData.fullAddress,
              streetAddress: finalSubmitData.streetAddress,
              streetNumber: finalSubmitData.streetNumber,
              streetName: finalSubmitData.streetName,
              suburb: finalSubmitData.suburb,
              city: finalSubmitData.city,
              postalCode: finalSubmitData.postalCode,
              province: finalSubmitData.province,
              country: finalSubmitData.country,
              dwellingType: finalSubmitData.dwellingType,
              complexBuildingName: finalSubmitData.complexBuildingName,
              roofLat: finalSubmitData.roofLat ? (finalSubmitData.roofLat as number) : 0,
              roofLng: finalSubmitData.roofLng ? (finalSubmitData.roofLng as number) : 0,
              roofW3w: finalSubmitData.roofW3w,
              roofLocationConfirmed: true,
              unitNumber: finalSubmitData.unitNumber,
              customerType: finalSubmitData.customerType,
              monthlyElectricitySpend: finalSubmitData.monthlyElectricitySpend,
              electricityPhaseType: finalSubmitData.electricityPhaseType,
              backupPowerNeeds: finalSubmitData.backupPowerNeeds,
              roofType: finalSubmitData.roofType,
              subscription: finalSubmitData.subscription,
              isRoofFlat: finalSubmitData.isRoofFlat,
              insurance: finalSubmitData.insurance
            }
          }
        })
        if (resp.data?.addUserProperty?.__typename === 'ErrorResponse') {
          throw Error('There was an issue creating the additional property lead')
        }
        if (resp.data?.addUserProperty?.__typename === 'UserProperty') {
          const newLeadRecordId = resp.data.addUserProperty.recordId
          navigate(`/user/onboarding/checkout-summary/${newLeadRecordId}`)
        }
      }

      // await handleSetFormData(data)

      setIsSubmitting(false)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <OnboardingContainer maxH={'100vh'} overflow="visible" gap="50px" pb="100px">
      <DefaultModal
        isOpen={viewContractSummary}
        onClose={() => setViewContractSummary(false)}
        heading={'Contract summary'}
        size="5xl"
      >
        <PreSignUpContractSummary finalSubmitData={finalSubmitData} />
        <Button variant={'dark'} onClick={() => setViewContractSummary(false)}>
          Close
        </Button>
      </DefaultModal>
      <ProductsOptionsForm setFinalSubmitData={setFinalSubmitData} property={formData} />

      <Flex justifyContent={'space-between'} pb={25}>
        <Button
          variant={'none'}
          bg="brand.neutral"
          color="brand.text.primary"
          _active={{ transform: 'scale(0.95)' }}
          type={'button'}
          onClick={() => handlePrevStepIndex()}
        >
          <Flex alignItems={'center'}>
            <FiArrowLeft />
            <Text pl={'2px'} _groupHover={{ pl: '5px' }} transition={'padding 0.2s ease-in-out'}>
              Back
            </Text>
          </Flex>
        </Button>
        <Button
          variant={'outline'}
          borderColor={'brand.secondary'}
          rounded={'full'}
          bg="brand.neutral"
          _hover={{ backgroundColor: 'transparent', borderColor: 'brand.primary' }}
          color="brand.text.primary"
          _active={{ transform: 'scale(0.95)' }}
          type={'button'}
          onClick={() => setViewContractSummary(true)}
          display={['none', 'flex']}
        >
          Preview Agreement
        </Button>
        <Button
          variant={'outline'}
          borderColor={'brand.secondary'}
          rounded={'full'}
          bg="brand.neutral"
          _hover={{ backgroundColor: 'transparent', borderColor: 'brand.primary' }}
          color="brand.text.primary"
          _active={{ transform: 'scale(0.95)' }}
          type={'button'}
          onClick={() => setViewContractSummary(true)}
          display={['flex', 'none']}
          fontSize={'sm'}
        >
          Preview
        </Button>

        <Button
          variant="dark"
          isDisabled={!finalSubmitData}
          isLoading={isSubmitting}
          onClick={
            location.pathname.includes('user/new-subscription')
              ? () => createAdditionalProperty()
              : () => onSubmit()
          }
        >
          {location.pathname.includes('user/new-subscription') ? 'Next' : 'Sign up'}
        </Button>
      </Flex>
    </OnboardingContainer>
  )
}

export default ProductsOptions
