import React, { FC } from 'react'
import { ContainerHeading } from '../Headings'
import { Flex, Text } from '@chakra-ui/react'
import { NoDataFound } from '../Misc/NoDataFound'
// import { transactions } from '../../MockData/transactionsMockData'
import { transactionsData } from '../../DummyData'
import TransactionList from '../Transactions/TransactionList'

const DBTransactions: FC = () => {
  return (
    <>
      <ContainerHeading title="Recent transactions" />
      {transactionsData.length <= 0 && (
        <Flex w="100%" justifyContent="center" alignItems="center" mt={'25px'}>
          <NoDataFound />
        </Flex>
      )}
      <TransactionList transactions={transactionsData} />
    </>
  )
}

export default DBTransactions
