import React, { useState, createContext } from 'react'

type Props = {
  children?: React.ReactNode
}

export type Expose = {
  activeSettingsTab?: String

  handleSetActiveTab?: (setting: string | null | undefined) => void
}
// Create our sidebar Context with some default values
export const SettingsContext = createContext<Expose>({
  activeSettingsTab: 'Account Settings'
})

const SettingsProvider: React.FC<Props> = ({ children }) => {
  const [activeSettingsTab, setActiveSettingsTab] = useState<string>('Account Settings')

  const handleSetActiveTab = (setting: string | null | undefined): void => {
    //@ts-ignore
    setActiveSettingsTab(setting)
  }

  const expose: Expose = {
    activeSettingsTab,
    handleSetActiveTab
  }
  return <SettingsContext.Provider value={expose}>{children}</SettingsContext.Provider>
}

export default SettingsProvider
