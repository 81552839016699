import { Button, Flex, Image, Link } from '@chakra-ui/react'
import React, { useContext, useState } from 'react'
import { OnboardingFormContext } from '../../context/intro/OnboardingContext'
import BreadCrumbs from '../../components/BreadCrumbs'
import CallMeModal from '../../components/Onboarding/Pre-singup-flow/CallMe/CallMeModal'

function InterestedPageHeader({ promoCode }: { promoCode: string }) {
  const { activeStep, onboardingSteps } = useContext(OnboardingFormContext)
  const [callModalIsOpen, setCallModalIsOpen] = useState<boolean>(false)
  const [modalHeading, setModalHeading] = useState<string>('Please Note!')
  const [locationUnsupported, setLocationUnsupported] = useState<boolean>(false)

  return (
    <>
      <Flex
        w={'full'}
        marginBottom={'50px'}
        alignItems={'center'}
        justify={'space-between'}
        display={['none', 'none', 'none', 'flex']}
      >
        <CallMeModal
          isOpen={callModalIsOpen}
          isUnsupportedLocation={locationUnsupported}
          onClose={() => setCallModalIsOpen(false)}
          heading={modalHeading}
        />
        {promoCode.toLowerCase() === 'hippo' ? (
          <Image src={'/brand/hippo.svg'} w={'auto'} h={'62px'} />
        ) : (
          <Image src={'/images/gosolr-logo-new.svg'} w={'auto'} h={'62px'} />
        )}
        {/* To do Replace with Pre signup flow Breadcrumbs components */}
        <Flex ml={['auto', '100px', '25px', '25px']}>
          {activeStep?.stepCount !== undefined && (
            <>
              <BreadCrumbs
                promoCode={promoCode}
                stepCount={activeStep?.stepCount}
                stepDetails={activeStep.stepDetails!}
                stepLabel={activeStep.stepLabel!}
                steps={onboardingSteps}
              />
            </>
          )}
        </Flex>
        <Flex
          justify={'center'}
          alignItems={'center'}
          gap={25}
          marginBottom={'5px'}
          direction={['column', 'column', 'row', 'row']}
        >
          <Button
            variant={promoCode.toLowerCase() === 'hippo' ? 'hippo' : 'dark'}
            type={'submit'}
            onClick={() => {
              setCallModalIsOpen(true)
              setModalHeading('Call me')
              setLocationUnsupported(true)
            }}
          >
            Call me
          </Button>

          <Link
            href={
              process.env.NODE_ENV === 'development'
                ? 'http://localhost:3000/login'
                : process.env.REACT_APP_CLIENT_HOST + '/login'
            }
          >
            Log in
          </Link>
        </Flex>
      </Flex>
      <Flex
        w={'full'}
        direction={'column'}
        marginBottom={'50px'}
        alignItems={'center'}
        justify={'center'}
        display={['flex', 'flex', 'flex', 'none']}
      >
        <Flex w="full" alignItems={'center'} justify={'space-between'} mb={25}>
          {promoCode.toLowerCase() === 'hippo' ? (
            <Image src={'/brand/hippo.svg'} w={'auto'} h={'62px'} />
          ) : (
            <Image src={'/images/gosolr-logo-new.svg'} w={'auto'} h={'62px'} />
          )}

          <Flex
            justify={'center'}
            alignItems={'center'}
            gap={25}
            marginBottom={'5px'}
            direction={['row']}
          >
            <Button
              variant={promoCode.toLowerCase() === 'hippo' ? 'hippo' : 'dark'}
              type={'submit'}
              onClick={() => {
                setCallModalIsOpen(true)
                setModalHeading('Call me')
                setLocationUnsupported(true)
                console.log('Open modal')
              }}
            >
              Call me
            </Button>

            <Link
              href={
                process.env.NODE_ENV === 'development'
                  ? 'http://localhost:3000/login'
                  : process.env.REACT_APP_CLIENT_HOST + '/login'
              }
            >
              Log in
            </Link>
          </Flex>
        </Flex>
        {/* To do Replace with Pre signup flow Breadcrumbs components */}
        <Flex
          w="full"
          ml={['auto', 'auto', 'auto', '125px']}
          alignItems={'center'}
          justify={'center'}
        >
          {activeStep?.stepCount !== undefined && (
            <>
              <BreadCrumbs
                promoCode={promoCode}
                stepCount={activeStep?.stepCount}
                stepDetails={activeStep.stepDetails!}
                stepLabel={activeStep.stepLabel!}
                steps={onboardingSteps}
              />
            </>
          )}
        </Flex>
      </Flex>
    </>
  )
}

export default InterestedPageHeader
