import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import App from './App'
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react'
import '@fontsource/poppins'
import theme from './theme'
import { css, Global } from '@emotion/react'
import './index.css'
import reportWebVitals from './reportWebVitals'
import SidebarProvider from './context/SideBarContext'
import { ApolloProvider } from '@apollo/client'
import client from './apollo'
import AuthProvider from './context/AuthProvider'
import { InitGTM } from './utils/Google-Tag-Manager'
import TransactionProvider from './context/TransactionProvider'

const root = ReactDOM.createRoot(document.getElementById('root')!)
InitGTM('GTM-MTH8XDC', 'PageDataLayer')
InitGTM('GTM-W956XQVG')

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ApolloProvider client={client}>
        <ChakraProvider theme={theme}>
          <AuthProvider>
            <SidebarProvider>
              <ColorModeScript initialColorMode={theme.config.initialColorMode} />
              <Global
                styles={css`
                  * {
                    font-family: ${theme.fonts.body};
                  }
                `}
              />
              <TransactionProvider>
                <App />
              </TransactionProvider>
            </SidebarProvider>
          </AuthProvider>
        </ChakraProvider>
      </ApolloProvider>
    </BrowserRouter>
  </React.StrictMode>
)

reportWebVitals()
