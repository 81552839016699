import { Dispatch, FC, SetStateAction, useState } from 'react'
import { DefaultModal } from '../../../Modals'
import ProductsOptionsForm from '../../Pre-singup-flow/ProductsOptions/subscriptionForm'
import { PreSignUpPropertyType, Product } from '../../../../types'
import { Button, Flex, Text } from '@chakra-ui/react'

type SubscriptionOptionModalType = {
  isOpen: boolean
  onClose: () => void
  setPropertyItem: Dispatch<SetStateAction<PreSignUpPropertyType | null>>
  propertyItem: PreSignUpPropertyType
  setCurrentSubscription: Dispatch<SetStateAction<Product | null>>
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  setShowProductUpdateModal: any // type must change
  previousPhase: any
}

const SubscriptionOptionModal: FC<SubscriptionOptionModalType> = ({
  isOpen,
  onClose,
  setPropertyItem,
  propertyItem,
  setCurrentSubscription,
  setFieldValue,
  setShowProductUpdateModal,
  previousPhase
}) => {
  const [confirmUpdate, setConfirmUpdate] = useState(false)

  const handleConfirmUpdate = () => {
    //TODO: Add validation to check whether the phase is correct
    setCurrentSubscription(propertyItem?.subscription)
    setFieldValue('subscription', propertyItem?.subscription, true)
    setShowProductUpdateModal(false)
  }
  const handleCancel = () => {
    setFieldValue('electricityPhaseType', previousPhase, true)
    setShowProductUpdateModal(false)
    setConfirmUpdate(false)
    onClose()
  }

  return (
    <DefaultModal
      isOpen={isOpen}
      onClose={onClose}
      heading={'Edit your subscription'}
      size={confirmUpdate ? 'full' : 'lg'}
    >
      <div id="ShowConfirmationRequest" hidden={confirmUpdate}>
        <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Are you sure you want to update your subscription? This may incur additional costs.
          </Text>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Note if your home has a 3 phase system, A single phase product such as the small, medium
            or large will be sufficient for most homes. It will require to rewire your electric
            board and some appliances may not benefit from the solution in some cases. These will be
            3-phase appliances (uncommon in South Africa) as well as certain power-hungry appliances
            depending on the electrical set up in your home.
          </Text>
          <Flex w="full" justifyContent={'right'}>
            <Button variant={'ghost'} onClick={() => handleCancel()}>
              Cancel
            </Button>
            <Button variant={'dark'} onClick={() => setConfirmUpdate(true)}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </div>

      {/* Only show the product list once the user confirmed they want to update their product */}
      <Flex
        id="ShowProducts"
        hidden={!confirmUpdate}
        maxW={'4xl'}
        direction={'column'}
        justifyContent={'center'}
      >
        <ProductsOptionsForm setFinalSubmitData={setPropertyItem} property={propertyItem} />
        <Flex w="full" justifyContent={'right'} backdropBlur={true} direction={'column'} gap={25}>
          <Flex w="full" justifyContent={'right'} gap={25}>
            <Button variant={'dark'} onClick={handleConfirmUpdate}>
              Confirm
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </DefaultModal>
  )
}

export default SubscriptionOptionModal
