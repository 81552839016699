import React from 'react'
import { Flex, Text, Highlight } from '@chakra-ui/react'
import Socials from '../Socials'
import { ContainerHeading } from '../Headings'

type PromoCode = {
  referralCode: string
}
const AccPromoCode: React.FC<PromoCode> = ({ referralCode }) => {
  return (
    <>
      <ContainerHeading title="Referral code" headingColor={'brand.primary'} />
      <Flex
        direction={'column'}
        w="100%"
        h="100%"
        gap={'25px'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text color={'brand.text.light'} fontSize={'14px'} fontWeight={300}>
          <Highlight
            query="solr"
            styles={{
              color: '#fff',
              fontWeight: 700
            }}
          >
            Cash in on the sun! If you refer someone who signs up with GoSolr, you get R1000 off
            your monthly subscription the next month!
          </Highlight>
        </Text>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          bgColor={'#FFD635'}
          w={'100%'}
          maxW={'270px'}
          height="40px"
          px="25px"
          py={'4px'}
          rounded={'full'}
        >
          <Text fontWeight={700} fontSize={'14px'} letterSpacing={'widest'}>
            {referralCode}
          </Text>
        </Flex>
        <Socials color={'#ffff'} />
      </Flex>
    </>
  )
}

export default AccPromoCode
