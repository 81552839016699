import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import { UserProperty } from '../../generated/graphql'
import PropertyBatterySettings from '../BatterySettings/PropertyBatterySettings'
import { KPIDataType } from '../../types'

export type Props = {
  property: UserProperty
  kpiData: KPIDataType
}

export type SettingType = {
  plantID?: string
  name?: string
  description?: string
  img_url?: string
  icon?: React.ReactNode
}

const DBProperty: FC<Props> = ({ property, kpiData }) => {
  const { suburb, description } = property
  const { activeBatterySetting, gsmStatus, settingsChangeStatus } = kpiData
  const [propertyName, setPropertyName] = useState<string>()

  useEffect(() => {
    setPropertyName(description ? (description as string) : (suburb as string))
  }, [])

  return (
    <>
      <Flex
        bgColor={'brand.neutral'}
        w={['100%', '100%', '100%', '50%']}
        h="auto"
        maxH={'auto'}
        gap={'25px'}
        direction="column"
        rounded={'8px'}
        shadow={'md'}
        p={'25px'}
        color={'brand.text.primary'}
      >
        <ContainerHeading title={propertyName as string} />
        <PropertyBatterySettings
          property={property}
          activeSetting={activeBatterySetting || ''}
          settingsChangeStatus={settingsChangeStatus ? settingsChangeStatus : 'completed'}
          isGsm={gsmStatus === 'true' ? true : false}
        />
      </Flex>
    </>
  )
}

export default DBProperty
