import { Flex, useMediaQuery, Text, Center, Spinner } from '@chakra-ui/react'
import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import DocumentUploadForm from '../../../components/Onboarding/Dashboard/Documents/DocumentUploadForm'

import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  File as CustomFile,
  useGetFileQuery,
  useGetUserQuery,
  useGetUserPropertyQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { convertBase64ToFile } from '../../../utils'
import OptionalUpload from '../../../components/Onboarding/Dashboard/Documents/OptionalUploads'
import axios from 'axios'

type ProofOfAddressProps = {
  stepper: React.ReactNode
}
type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const OptionalUploadsPage: FC<Props> = ({ setTitle }) => {
  const { recordId } = useParams()

  const { user } = useAuthContext()

  const [file, setFile] = useState<File>()
  const [idFile, setIDFile] = useState<File>()
  const [utilityBill, setUtilityBill] = useState<File>()
  const [electricalDistributionBoardPhotoFile, setElectricalDistributionBoardPhotoFile] =
    useState<File>()
  const [installationLocationPhotoFile, setInstallationLocationPhotoFile] = useState<File>()
  const [roofPhotoFile, setRoofPhotoFile] = useState<File>()

  const [
    isLoadingElectricalDistributionBoardPhotoFile,
    setIsLoadingElectricalDistributionBoardPhotoFile
  ] = useState<boolean>(false)
  const [isLoadingInstallationLocationPhotoFile, setIsLoadingInstallationLocationPhotoFile] =
    useState<boolean>(false)
  const [isLoadingRoofPhotoFile, setIsLoadingRoofPhotoFile] = useState<boolean>(false)
  const [loadingUtilityBill, setLoadingUtilityBill] = useState<boolean>(false)
  const [additionalNotes, setAdditionalNotes] = useState<string>('')
  const [entityType, setEntityType] = useState<string>('')
  const { data, loading } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  // const { data, loading } = useGetUserPropertyQuery({
  //   variables: {
  //     recordId:recordId as string
  //   }
  // })

  const recentUserData = data?.getUser as User

  // const recentUserPropertyData = propertyData?.getUserProperty as UserProperty
  const { refetch: getFile } = useGetFileQuery({
    skip: true
  })

  useEffect(() => {
    const init = async () => {
      if (recentUserData) {
        if (recentUserData?.entityType) {
          if (recentUserData?.entityType === 'trust') {
            setEntityType('private')
          } else setEntityType(recentUserData?.entityType)
        }

        const property = (recentUserData?.properties as UserProperty[]).find(
          (p) => p.recordId === recordId
        )
        if (property?.utilityBillDocumentS3Key) {
          setLoadingUtilityBill(true)
        }
        if (property?.electricalDistributionBoardPhotoS3Key) {
          setIsLoadingElectricalDistributionBoardPhotoFile(true)
        }
        if (property?.installationLocationPhotoS3Key) {
          setIsLoadingInstallationLocationPhotoFile(true)
        }
        if (property?.roofPhotoS3Key) {
          setIsLoadingRoofPhotoFile(true)
        }

        if (property?.additionalNotes) {
          setAdditionalNotes(property.additionalNotes)
        }

        if (property?.electricalDistributionBoardPhotoS3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.electricalDistributionBoardPhotoS3Key as string
          })

          // @ts-ignore
          const splitKey = property.electricalDistributionBoardPhotoS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setElectricalDistributionBoardPhotoFile(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        // Bank Statement 02
        // @ts-ignore
        if (property?.installationLocationPhotoS3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.installationLocationPhotoS3Key as string
          })

          // @ts-ignore
          const splitKey = property.installationLocationPhotoS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setInstallationLocationPhotoFile(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
        if (property?.utilityBillDocumentS3Key) {
          const s3File = await getFile({
            key: property.utilityBillDocumentS3Key as string
          })
          const splitKey = property?.utilityBillDocumentS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setUtilityBill(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }

        // Bank Statement 03
        // @ts-ignore
        if (property?.roofPhotoS3Key) {
          const s3File = await getFile({
            // @ts-ignore
            key: property.roofPhotoS3Key as string
          })

          // @ts-ignore
          const splitKey = property.roofPhotoS3Key.split('/')
          const fileName = splitKey[splitKey.length - 1]
          setRoofPhotoFile(
            convertBase64ToFile((s3File?.data?.getFile as CustomFile)?.base64 as string, fileName)
          )
        }
      }
    }

    init()
  }, [recentUserData])

  useEffect(() => {
    // console.log(recentUserData)
    if (electricalDistributionBoardPhotoFile) {
      setIsLoadingElectricalDistributionBoardPhotoFile(false)
    }
    if (installationLocationPhotoFile) {
      setIsLoadingInstallationLocationPhotoFile(false)
    }
    if (roofPhotoFile) {
      setIsLoadingRoofPhotoFile(false)
    }
    if (utilityBill) {
      setLoadingUtilityBill(false)
    }
  }, [electricalDistributionBoardPhotoFile, installationLocationPhotoFile, roofPhotoFile, utilityBill])

  return (
    <Flex w="100%" gap="50px" direction={'column'} alignItems={'center'} >
      {/* <OnBoardingStepper /> */}

      {loading && (
        <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
          <Flex gap="25px" alignItems={'center'} justifyContent="center">
            <Center>
              <Spinner size={'lg'}></Spinner>
            </Center>
            <Text>Loading document upload form</Text>
          </Flex>
        </Center>
      )}
      {!loading && (
        <OptionalUpload
          recordId={recordId as string}
          file={file}
          idFile={idFile}
          loadingUtilityBill={loadingUtilityBill}
          utilityBill={utilityBill}
          electricalDistributionBoardPhoto={electricalDistributionBoardPhotoFile}
          installationLocationPhoto={installationLocationPhotoFile}
          roofPhoto={roofPhotoFile}
          loading={loading}
          additionalNotes={additionalNotes}
          loadingElectricalDistributionBoardPhoto={isLoadingElectricalDistributionBoardPhotoFile}
          loadingInstallationLocationPhoto={isLoadingInstallationLocationPhotoFile}
          loadingRoofPhoto={isLoadingRoofPhotoFile}
          entityType={entityType}
        />
      )}
    </Flex>
  )
}

export default OptionalUploadsPage
