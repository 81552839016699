import { Flex, Grid, GridItem, Highlight, Text } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { ContainerHeading } from '../../../Headings'
import { FormData } from '../../../../context/intro/OnboardingContext'
import { PreSignUpPropertyType, Product } from '../../../../types'

const PreSignUpContractSummary = ({
  finalSubmitData
}: {
  finalSubmitData: PreSignUpPropertyType | null
}) => {
  const [product, setProduct] = useState<Product | null>(null)

  useEffect(() => {
    if (finalSubmitData) {
      setProduct(finalSubmitData?.subscription as Product)
    }
  }, [finalSubmitData])

  const modalRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const timer = setTimeout(() => {
      modalRef?.current && modalRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 100)

    return () => clearTimeout(timer)
  }, [product, finalSubmitData])

  return (
    <>
      <Flex
        w="full"
        gap="25px"
        direction={'column'}
        bg={'brand.body'}
        color={'brand.text.primary'}
        p={25}
        ref={modalRef}
      >
        {/* Contract Summary */}
        <Flex
          w="full"
          gap="25px"
          direction={'column'}
          p={'25px'}
          rounded="8px"
          bgColor={'brand.neutral'}
        >
          <ContainerHeading title={'Contract summary'} />
          <Text ref={modalRef} w={'100%'} textAlign={'center'} color={'brand.text.primary'}>
            This is our standard customer contract for your GoSolr{' '}
            {product?.productName?.replace('2023_', '')} subscription.
          </Text>
        </Flex>
        {/* Subscription and Installation */}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Subscription'} />
              <Flex
                direction={'row'}
                w="full"
                gap="25px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontWeight={'bold'} fontSize={13}>
                  GoSolr {product?.productName?.replace('2023_', '')} subscription
                </Text>
                <Flex
                  direction={'column'}
                  gap="10px"
                  justifyContent={'center'}
                  alignItems={'center'}
                  w="full"
                  textAlign={'right'}
                >
                  <Text w={'full'} fontSize={13}>
                    {product?.batterySpec}{' '}
                  </Text>
                  <Text w={'full'} fontSize={13}>
                    1 x {product?.inverterSpec}{' '}
                  </Text>
                  <Text w={'full'} fontSize={13}>
                    {product?.panelCount} x {product?.panelSpec}
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              w="full"
              h={'full'}
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Administration fee'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Flex gap={'10px'} w="full" justifyContent={'space-between'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Once-off administration fee:
                  </Text>
                  <Text fontSize={13}>R{product?.onceOffFee} (including VAT)</Text>
                </Flex>
                <Flex gap={'10px'} w="full" justifyContent={'space-between'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Flat roof fee:
                  </Text>
                  <Text fontSize={13}>
                    R
                    {finalSubmitData?.isRoofFlat.toLowerCase() === 'yes' ? product?.flatRoofFee : 0}{' '}
                    (including VAT)
                  </Text>
                </Flex>
                <Text fontSize={13} mt={25}>
                  Once-off payment via EFT
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
        {/* Monthly Fee and Escaltion */}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Monthly fee'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontWeight={'bold'} fontSize={13}>
                  R{product?.subscriptionFee} (including VAT) per month via monthly debit order
                </Text>
                <Text fontSize={13} textAlign="center">
                  Payable by the Customer to the Seller in advance, for the duration of the Term
                  within 7 days of the date of invoice therefor.
                </Text>
                {/* <Text fontSize={13}>
                  Debit order date set to the <b>{debitOrderDate}</b> of each month{' '}
                </Text> */}
              </Flex>
            </Flex>
          </GridItem>
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Annual escalation '} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
                textAlign="center"
              >
                <Text fontSize={13}>
                  <b> The Fixed Monthly Fee will:</b>
                </Text>
                <Text fontSize={13}>
                  - from July <b>{2024}</b>, escalate annually on the first day of July each year
                  (capped at CPI)
                </Text>

                <Text fontSize={13}>
                  The <b>De-Installation Fee</b> will not escalate, unless notified in writing by
                  the Seller to the Customer on no less than 30 days’ written notice, in line with
                  CPI.
                </Text>
                <Text fontSize={13}>
                  <b>The Upgrade Fees will: </b>
                </Text>
                <Text fontSize={13}>
                  - from July <b>{2024}</b>, escalate annually on the first day of July each year
                  (capped at CPI)
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
        {/* Fees and Termination*/}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Other fees'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Upgrade fees:
                  </Text>
                  <Text fontSize={13}>
                    Additional upgrade fees as per the final subscription agreement.
                  </Text>
                </Flex>
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    De-installation fee:
                  </Text>
                  <Text fontSize={13}>
                    <b>R{product?.de_installation_fee_after_year_three}</b> (including VAT). The
                    De-Installation Fee is the amount payable by the Customer to the Seller for the
                    de-installation of the Solar System, following cancellation of this Agreement
                    and where the Customer has not elected to purchase the Solar System.
                  </Text>
                </Flex>
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Call out fee:
                  </Text>
                  <Text fontSize={13}>
                    The call out Fee is initially <b>R650</b> (including VAT) as at the Signature
                    Date and will be escalated or varied from time to time by the Seller and
                    communicated to the Customer by way of written notice.
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          </GridItem>
          <GridItem shadow={'md'} rounded={'md'}>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'start'}
            >
              <ContainerHeading title={'Termination'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontSize={13} textAlign={'center'}>
                  <Highlight query={'20 days'} styles={{ fontWeight: 'bold' }}>
                    The Customer is entitled to terminate this Agreement at any time on 20 days'
                    prior notice to the Seller.
                  </Highlight>
                </Text>
                <Text fontSize={13} textAlign={'center'}>
                  In the event of an early termination of this Agreement by the Customer, the
                  Customer is liable to pay to the Seller, within <b>10 days</b> of the Termination
                  Date, the De-installation Fee and any and all other amounts previously accrued
                  under this Agreement and then owed by the Customer to the Seller.
                </Text>
              </Flex>
            </Flex>
          </GridItem>
        </Grid>
        {product?.productName?.toLowerCase().includes('small') && (
          <Flex
            w="full"
            gap="25px"
            direction={'column'}
            p={'25px'}
            rounded="8px"
            bgColor={'brand.neutral'}
          >
            <ContainerHeading title={'Technical Acknowledgment'} />
            <Flex
              direction={'column'}
              w="full"
              gap="10px"
              justifyContent={'center'}
              alignItems={'center'}
              textAlign="center"
            >
              <Text fontSize={13}>
                Should the grid be unavailable, the GoSolr system will be limited to the throughput
                of the inverter. For the GoSolr Small, this will be limited to 3,6kW. Should
                consumption in the home exceed this when the grid is unavailable, the system will
                trip.
              </Text>
            </Flex>
          </Flex>
        )}

        <Flex
          w="full"
          gap="25px"
          direction={'column'}
          p={'25px'}
          rounded="8px"
          bgColor={'brand.neutral'}
        >
          <ContainerHeading title={'Purchase of the system'} />
          <Flex
            direction={'column'}
            w="full"
            gap="10px"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign="center"
          >
            <Text fontSize={13}>
              The Customer has the option at any time during the Term following the third
              anniversary of the Signature Date to purchase the Solar System (and all its component
              parts, specifically excluding the Solar Software) from the Seller ("Purchase Option").
              The option may be exercised by the Customer at any time during the Term by the
              Customer giving written notice thereof to the Seller. If the Purchase Option is
              exercised, the purchase price of the Solar System shall be equivalent to the Purchase
              Price at the proposed date of purchase.
            </Text>
            <Text fontSize={13}>
              Purchase price is set on a sliding scale basis, outlined in the final Subscription
              Agreement.
            </Text>
          </Flex>
        </Flex>
        <Flex
          w="full"
          gap="25px"
          direction={'column'}
          p={'25px'}
          rounded="8px"
          bgColor={'brand.neutral'}
          shadow={'md'}
        >
          <ContainerHeading title={'Insuring party'} />
          <Flex
            direction={'column'}
            w="full"
            gap="10px"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign="center"
          >
            <Text fontSize={13}>
              The Customer, unless the Customer gives notice in writing to the Seller requesting
              that the Seller be the Insuring Party and the Seller expressly notifies the Customer
              that it elects to be the Insuring Party pursuant to such request.
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default PreSignUpContractSummary
