import { Center, Flex, Spinner, Text } from '@chakra-ui/react'
import React from 'react'

type LoadingType = {
  loadingText: string
}

const LoadingComponent: React.FC<LoadingType> = ({ loadingText }) => {
  return (
    <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
      <Flex gap="25px" alignItems={'center'} justifyContent="center">
        <Center>
          <Spinner size={'lg'}></Spinner>
        </Center>
        <Text>{loadingText}</Text>
      </Flex>
    </Center>
  )
}

export default LoadingComponent
