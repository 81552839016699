import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Flex, Text } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import { useAuthContext } from '../../context/AuthProvider'
import {
  UserProperty,
  useGetUserQuery,
  User,
  useGetUserPropertyQuery
} from '../../generated/graphql'

type userBank = {
  bankAccountNumber?: string | null
  bankAccountType?: string | null
  bankName?: string | null
  branchCode?: string | null
}

const AccBankingDetails: React.FC = () => {
  const { user } = useAuthContext()
  const [bank, setBank] = useState<userBank>({})
  const info = user?.info || {}
  useEffect(() => {
    if (info !== null || info !== undefined) {
      if (info.properties) {
        if (info?.properties[0] !== null || info?.properties[0] !== undefined) {
          const bankObj = {
            bankAccountNumber: info?.properties[0]?.bankAccountNumber,
            bankAccountType: info?.properties[0]?.bankAccountType,
            bankName: info?.properties[0]?.bankName,
            branchCode: info?.properties[0]?.branchCode
          }
          setBank(bankObj)
        }
      }
    }
  }, [])

  const navigate = useNavigate()

  const handleGoToBankingSettings = () => {
    navigate(`/user/support`)
  }

  return (
    <>
      <Flex w={'100%'} alignItems={'center'}>
        <ContainerHeading title="Banking details" />
        <Button onClick={handleGoToBankingSettings} variant={'dark'} w="auto">
          Edit
        </Button>
      </Flex>
      <Flex direction={'column'} h={'100%'} alignItems={'start'} justifyContent={'center'}>
        <Flex gap={'5px'}>
          <Text fontWeight={600} fontSize={'sm'}>
            Account Holder:
          </Text>
          <Text fontSize={'sm'}>
            {user?.info.firstName && user?.info.firstName + ' ' + user?.info.lastName}
          </Text>
        </Flex>
        <Flex gap={'5px'}>
          <Text fontWeight={600} fontSize={'sm'}>
            Account Number:
          </Text>
          <Text fontSize={'sm'}>{bank.bankAccountNumber}</Text>
        </Flex>
        <Flex gap={'5px'}>
          <Text fontWeight={600} fontSize={'sm'}>
            Account Type:
          </Text>
          <Text fontSize={'sm'}>{bank.bankAccountType}</Text>
        </Flex>
        <Flex gap={'5px'}>
          <Text fontWeight={600} fontSize={'sm'}>
            Bank Name:
          </Text>
          <Text fontSize={'sm'}>{bank.bankName}</Text>
        </Flex>
        <Flex gap={'5px'}>
          <Text fontWeight={600} fontSize={'sm'}>
            Branch Code:
          </Text>
          <Text fontSize={'sm'}>{bank.branchCode}</Text>
        </Flex>
      </Flex>
    </>
  )
}

export default AccBankingDetails
