import { FC, useEffect, useRef, useState } from 'react'
import {
  Flex,
  Button,
  Text,
  Link,
  Box,
  Alert,
  AlertIcon,
  AlertDescription,
  useDimensions
} from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import TextInput from '../../FormElements/TextInput'
import SubmitButton from '../../FormElements/SubmitButton'

import { ContainerHeading } from '../../Headings'
import { RegisterValidation } from '../../../formValidation'
import { useAuthContext } from '../../../context/AuthProvider'
import ConnectedCheckbox from '../../FormElements/ConnectedCheckbox'
import { NOT_ONBOARDED } from '../../../constants'
import { DefaultModal } from '../../Modals'
import RegisteredModal from './RegisteredModal'
import { useNavigate } from 'react-router-dom'
import { FormData } from '../../../context/intro/OnboardingContext'
import axios from 'axios'
import PhoneInput from '../../FormElements/PhoneInput'
import { useCookies } from 'react-cookie'

export type ResponseType = {
  status: string
  title: string
  description: string
  type?: string
}

export type UserDetailsType = {
  email: string
  firstName: string
  lastName: string
  contactNumber: string
  promoCode: string
}

type RegisterType = {
  password: string
  confirmPassword: string
  termsAndConditions: string
} & UserDetailsType

const initResponse = {
  status: '',
  title: '',
  description: ''
}

const initialFormValues = {
  email: '',
  password: '',
  confirmPassword: '',
  firstName: '',
  lastName: '',
  contactNumber: '',
  termsAndConditions: '',
  promoCode: ''
}
const RegisterContainer: FC = () => {
  const { register, signUpError, setSignUpError } = useAuthContext()
  const [preSignupFlowError, setPreSignupFlowError] = useState<boolean>(false)
  const [customerTypeError, setCutomerTypeError] = useState<boolean>(false)
  const [insuranceError, setInsuranceError] = useState<boolean>(false)
  const [userEmail, setUserEmail] = useState<string>('')
  const [isError, setIsError] = useState<boolean>(false)
  const [isRegistered, setIsRegistered] = useState<boolean>(false)
  const [response, setResponse] = useState<ResponseType>(() => initResponse)
  const [leadData, setLeadData] = useState<FormData | null>(null)
  const [isSending, setIsSending] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [alertHeight, setAlertHeight] = useState<number>(0)
  const alertBoxRef = useRef<HTMLDivElement | null>(null)
  const [initValues, setInitValues] = useState<RegisterType>(() => initialFormValues)
  const dimensions = useDimensions(alertBoxRef, true)
  const navigate = useNavigate()
  const [cookies] = useCookies()
  const { promocode } = cookies

  useEffect(() => {
    setInitValues({
      ...initValues,
      promoCode: promocode ? promocode : 'SeptemberSaver'
    })
  }, [promocode])

  useEffect(() => {
    if (alertBoxRef.current) setAlertHeight(alertBoxRef.current.offsetHeight)
  }, [dimensions?.borderBox.height, dimensions?.borderBox.width, isError, signUpError])

  useEffect(() => {
    const handleCheckPreSignUpData = async () => {
      const preSignUpDataJsonString = await localStorage.getItem('onboarding_property_data')
      const preSignUpData = preSignUpDataJsonString ? JSON.parse(preSignUpDataJsonString) : null

      if (preSignUpData && preSignUpData !== '') {
        setLeadData(preSignUpData)
        // Use this data object to check if any required values are missing
        const data: Partial<FormData> = { ...preSignUpData }

        const { subscription: product } = data

        // Check if product is empty
        if (product && Object.keys(product).length === 0) {
          setPreSignupFlowError(true)
          setLeadData(null)
          return
        }

        const shouldCheckDwellingType = preSignUpData.dwellingType !== 'Free standing house'

        // Check if we should confirm if unitNumber and complexBuildingName are present
        if (shouldCheckDwellingType && (!data.unitNumber || !data.complexBuildingName)) {
          setPreSignupFlowError(true)
          setLeadData(null)
        }

        if (data.customerType === 'Tenant') {
          setCutomerTypeError(true)
        }
        if (data.customerType === 'Tenant' || data.insurance === 'No') {
          setInsuranceError(true)
        }

        console.log(data)

        delete data.isRoofFlat

        //  Remove the already checked keys
        delete data.unitNumber
        delete data.complexBuildingName
        delete data.propertyLat
        delete data.propertyLng
        delete data.propertyW3w

        // Check if the other properties are empty
        Object.keys(data).forEach((key) => {
          if (!data[key as keyof typeof data]) {
            setPreSignupFlowError(true)
            setLeadData(null)
          }
        })
      } else {
        setPreSignupFlowError(true)
        setLeadData(null)
      }
    }

    handleCheckPreSignUpData()
  }, [])

  // Close Error Alert
  const handleCloseError = () => {
    setIsError(false)
  }

  const handleCreateLead = async ({
    email,
    firstName,
    lastName,
    contactNumber,
    promoCode
  }: UserDetailsType) => {
    setSignUpError && setSignUpError('')
    // Check if there was any missing data, and if we have lead data
    if (preSignupFlowError || leadData === null) {
      throw Error("User can't sign up when there is missing onboarding data")
    }
    // Check that we have a valid email address
    if (!email) {
      throw Error('A valid email is required for creating a lead')
    }
    setUserEmail(email)
    const leadDataExtra = {
      ...leadData,
      originalCoCAvailable: false
    };
    const bodyData = {
      user: { email, firstName, lastName, contactNumber, promoCode },
      property: leadDataExtra
    }
    console.log('Creating lead', bodyData)

    const url = `${process.env.REACT_APP_API_HOST}/create-new-lead`
    await axios
      .post(url, bodyData)
      .then(function (response) {
        if (response.data.status === 'failed') {
          const msg = response.data.message as string
          const isUnknown = msg.toLowerCase().includes('unknown')
          if (isUnknown) setSignUpError && setSignUpError('An error occured. Please try again.')
          else {
            setSignUpError && setSignUpError('User already exists')
          }
          throw Error(response.data.message)
        }
      })
      .catch(function (error) {
        console.log(error)
        throw Error('Could not create new user')
      })
  }

  const handleSubmit = async (
    values: RegisterType,
    { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
  ) => {
    try {
      // Set submitting to true
      setSubmitting(true)
      // Reset our errors
      setIsError(false)
      const { email, firstName, lastName, contactNumber, promoCode } = values
      // Create our lead

      await handleCreateLead({
        email: email.toLowerCase(),
        firstName,
        lastName,
        contactNumber,
        promoCode
      })

      // If handleCreateLead does not cause any errors
      register && (await register(email.toLowerCase(), values.password))

      // Set is Registered to true
      setIsRegistered(true)
      // Create our success response
      setResponse({
        status: 'success',
        title: 'Success',
        description: `You're all signed up! Please check your email at ${values.email.toLowerCase()} for the verification link. Once verified you'll be able to login`,
        type: ''
      })

      // Set Submitting to false to stop the loading spinner
      setSubmitting(false)
    } catch (e) {
      setIsError(true)
      setIsRegistered(false)
      let errorMessage = ''
      setSubmitting(false)

      if (typeof e === 'string') {
        errorMessage = e.toUpperCase()
      } else if (e instanceof Error) {
        if (e.message.includes('not confirmed')) {
          e.message =
            'Email address not verified. Please verify your email address by clicking the link in the verification email sent to you'
        }

        errorMessage =
          e.message === 'Failed to fetch' ? 'Please complete your onboarding process' : e.message
      }

      setResponse({
        status: 'error',
        title: 'Error',
        description: errorMessage,
        type: errorMessage === 'Please complete your onboarding process' ? NOT_ONBOARDED : ''
      })
    }
  }

  return (
    <>
      {isRegistered && (
        <RegisteredModal
          userEmail={userEmail}
          description={response.description}
          isSending={isSending}
          error={error}
          success={success}
          setIsSending={setIsSending}
          setSuccess={setSuccess}
          setError={setError}
        />
      )}
      <DefaultModal isOpen={preSignupFlowError} heading="Oops!" onClose={() => {}}>
        <Flex direction="column" gap={25}>
          <Text>
            {' '}
            You have not completed your onboarding flow. Please complete this flow before you can
            register
          </Text>
          <Flex justifyContent={'end'}>
            <Button variant={'dark'} onClick={() => navigate('/')}>
              Complete onboarding
            </Button>
          </Flex>
        </Flex>
      </DefaultModal>
      <DefaultModal
        isOpen={Boolean(customerTypeError || insuranceError)}
        heading="Please note"
        onClose={() => {}}
      >
        <Flex direction="column" gap={25}>
          {customerTypeError && (
            <Flex direction="column" gap={25} p={2} bgColor={'brand.body'} rounded={'md'}>
              <ContainerHeading title="Homeowner" />
              <Text fontSize={'sm'}>
                Please note that we require the homeowner to sign the GoSolr subscription agreement.
              </Text>
            </Flex>
          )}
          {insuranceError && (
            <Flex direction="column" gap={25} p={2} bgColor={'brand.body'} rounded={'md'}>
              <ContainerHeading title="Insurance" />
              <Text fontSize={'sm'}>
                Please note that we require the GoSolr solution to be insured. In case you do not
                have an insurance, GoSolr will be able to advise on insurance options.
              </Text>
            </Flex>
          )}
          <Flex justifyContent={'end'}>
            <Link
              href="https://hippo.gosolr.co.za/"
              target="_blank"
              fontWeight={'bold'}
              textDecoration={'underline'}
            >
              View options
            </Link>
          </Flex>
        </Flex>
      </DefaultModal>

      {!isRegistered && (
        <Flex
          direction={'column'}
          w="full"
          maxW={'600px'}
          gap={'25px'}
          alignItems="center"
          justifyContent={'center'}
        >
          <Flex direction={'column'} w="full" gap="25px">
            <ContainerHeading title="Register: Provide your personal details" />
          </Flex>

          <Box
            w="full"
            height={isError ? `${alertHeight}px` : '0'}
            overflow="hidden"
            transition={'all 0.2s ease-in-out'}
          >
            <Alert status="error" gap="25px" rounded="8px" ref={alertBoxRef}>
              <AlertIcon />
              <AlertDescription fontSize={'sm'} w="full">
                {signUpError === 'User already exists' ? (
                  <Text>
                    {signUpError}, go to{' '}
                    <Link
                      href={`${process.env.REACT_APP_CLIENT_HOST}/login`}
                      fontWeight="bold"
                      textDecor={'underline'}
                    >
                      Login
                    </Link>
                  </Text>
                ) : (
                  <Text>{signUpError}</Text>
                )}
              </AlertDescription>
            </Alert>
          </Box>
          <Formik
            initialValues={initValues}
            enableReinitialize
            validateOnMount
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={RegisterValidation}
            onSubmit={async (values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
          >
            <Form onChange={handleCloseError} style={{ width: '100%' }}>
              <Flex
                direction={'column'}
                alignItems={'center'}
                justifyContent={'center'}
                w={'full'}
                gap={'25px'}
              >
                <Flex w={'full'} direction={['column', 'column', 'row', 'row']} gap={25}>
                  <TextInput
                    name="firstName"
                    label="Legal name (As shown on your ID)"
                    placeholder="First name (and middle name)"
                    type="text"
                  />
                </Flex>
                <TextInput name="lastName" label="Last name" placeholder="Last name" type="text" />
                <Flex w={'full'} direction={['column', 'column', 'row', 'row']} gap={25}>
                  <TextInput name="email" label="Email" placeholder="Email address" />
                  <PhoneInput
                    name="contactNumber"
                    label="Phone number"
                    placeholder="Phone number"
                  />
                </Flex>
                <Flex w={'full'} direction={['column', 'column', 'row', 'row']} gap={25}>
                  <TextInput
                    name="password"
                    label="Password"
                    placeholder="Create a password"
                    type="password"
                  />
                  <TextInput
                    name="confirmPassword"
                    label="Confirm password"
                    placeholder="Confirm password"
                    type="password"
                  />
                </Flex>
                <Flex w={'full'} gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                  <TextInput
                    name="promoCode"
                    label="Referral code"
                    placeholder="Referral code"
                    value={initValues.promoCode}
                    maxLength={20}
                  />
                </Flex>
                <ConnectedCheckbox
                  name="termsAndConditions"
                  colorScheme="blackAlpha"
                  borderColor="black"
                  _active={{ outlineColor: 'black' }}
                  position="relative"
                  top="3px"
                  label={
                    <Text fontSize="14px" fontWeight={400}>
                      Accept the{' '}
                      <Link
                        href="https://gosolr-static-files.s3.eu-west-1.amazonaws.com/gosolr_website_terms.pdf"
                        color="black"
                        target={'_blank'}
                        fontWeight="bold"
                        textDecor={'underline'}
                      >
                        terms & conditions
                      </Link>
                      {' and '}
                      <Link
                        href="https://gosolr-static-files.s3.eu-west-1.amazonaws.com/gosolr_privacy_policy.pdf"
                        color="black"
                        target={'_blank'}
                        fontWeight="bold"
                        textDecor={'underline'}
                      >
                        privacy policy
                      </Link>
                    </Text>
                  }
                />
                {!preSignupFlowError && !customerTypeError && !insuranceError && (
                  <SubmitButton title="Register" />
                )}
              </Flex>
            </Form>
          </Formik>
        </Flex>
      )}
    </>
  )
}

export default RegisterContainer
