import { Flex } from '@chakra-ui/react'
import React from 'react'
import OnboardingFormStepsContainer from '../../../components/Onboarding/Intro/OnboardingFormStepsContainer'
import OnboardingFormProvider from '../../../context/intro/OnboardingContext'
import GoogleProvider from '../../../context/GoogleProvider'

const NewSubscriptionPage = () => {
  return (
    <OnboardingFormProvider>
      <Flex
        bg={'white'}
        direction={'column'}
        alignItems={'center'}
        justifyContent={'flex-start'}
        position={'relative'}
        w={'100%'}
        minH={'100vh'}
        p={25}
        rounded={'md'}
        shadow={'md'}
        overflowY={'auto'}
        className="scrollbar"
      >
        <GoogleProvider>
          <OnboardingFormStepsContainer />
        </GoogleProvider>
      </Flex>
    </OnboardingFormProvider>
  )
}

export default NewSubscriptionPage
