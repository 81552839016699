import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Box, Center, Flex, Spinner, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import {
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { useAuthContext } from '../../../context/AuthProvider'
import { ordinal } from '../../../utils'

type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const DebitOrderMandatePage = ({ setTitle }: Props): JSX.Element => {
  const { user } = useAuthContext()
  const { data: propertyData, loading: loadingProperties } = useGetUserPropertyQuery({
    variables: {
      recordId: user?.info.recordId as string
    }
  })
  const property = propertyData?.getUserProperty as UserProperty
  const [subscribedProperties, setSubscribedProperties] = useState<UserProperty[]>([])
  const [mostRecentProperty, setMostRecentProperty] = useState<UserProperty>()
  const [onboardingStepCompleted, setOnboardingStepCompleted] = useState(0)
  const [showInitModal, setShowInitModal] = useState(false)
  const [loadingData, setLoadingData] = useState(true)
  const [requiredDocsReceived, setRequiredDocsReceived] = useState('No')
  const [signedDateAndPlace, setSignedDateAndPlace] = useState(() => ({
    signedAt: 'GO SOLR Web App',
    signedDay: '',
    signedMonthAndYear: '',
    timestamp: ''
  }))

  const { data: userData } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = userData?.getUser as User

  useEffect(() => {
    const getCurrentTimeData = async () => {
      const currentDate = await new Date()
      const nameOfMonth = await new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date()
      )

      let currDay: number = currentDate.getDate()
      let signedDay = ordinal(currDay)

      const signedDate = {
        signedAt: 'GO SOLR Web App',
        signedDay: signedDay,
        signedMonthAndYear: `${nameOfMonth} ${currentDate.getFullYear()}`,
        timestamp: currentDate.toLocaleTimeString()
      }

      setSignedDateAndPlace(signedDate)
    }

    getCurrentTimeData()

    const loadData = async () => {
      // console.log(user)
      // console.log(property?.subscription?.batterySpec)
      // console.log(subscribedProperties)
      // console.log(recentUserData)

      // console.log('subscribed properties: ', subscribedProperties)
      if (recentUserData) {
        const allProperties = (
          (recentUserData.properties?.length && (recentUserData.properties as UserProperty[])) ||
          []
        )
          .slice()
          .sort((a: any, b: any) => {
            return Number(b.recordId) - Number(a.recordId)
          })

        const subscriptions = allProperties.filter((p: any) => p.installationProcessInitiated)

        await setMostRecentProperty(allProperties[0])
        await setSubscribedProperties(subscriptions)
        await setOnboardingStepCompleted(
          (recentUserData?.onboardingProgressCompletedStep as number) || 0
        )
        if (property?.proofOfAddressS3Key && user?.info.idPassportDocumentS3Key) {
          await setRequiredDocsReceived('Yes')
        } else {
          await setRequiredDocsReceived('No')
        }

        setLoadingData(false)
        // console.log(recentUserData.properties)

        if (recentUserData.properties) {
          if (
            recentUserData?.properties[0]?.installationProcessInitiated === false ||
            recentUserData?.properties[0]?.installationProcessInitiated === null ||
            recentUserData?.properties[0]?.installationProcessInitiated === undefined
          ) {
            setShowInitModal(true)
          }
        }
      }
    }
    loadData()
  }, [recentUserData, propertyData, userData])

  useEffect(() => {
    setTitle(`Debit order mandate`)
  }, [])

  const currentDate = new Date()
  const data = {
    accHolderName: property?.memberName,
    accID: recentUserData?.idPassportNumber,
    address: property?.fullAddress,
    bankName: property?.bankName,
    branchName: 'Universal',
    branchCode: property?.branchCode,
    accNumber: property?.bankAccountNumber,
    accType: property?.bankAccountType,
    debitOrderDate: recentUserData?.debitOrderDate,
    contactNumber: recentUserData?.contactNumber,
    amount: property?.subscription?.subscriptionFee,
    benifeciaryName: 'GO SOLR Proprietary Limited',
    benifeciaryAddress: '27 Geneva Drive, Camps Bay, Cape Town, 8005',
    shortName: 'GO SOLR',
    contractReferenceNumber: property?.recordId,
    ...signedDateAndPlace,
    orderNumber: 123456
  }

  if (loadingData || loadingProperties) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Generating your debit order mandate document</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex w="100%" gap="25px" direction={'column'} bgColor="brand.neutral" p="25px" rounded="8px">
        {/* User Details */}
        <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
          <Text fontWeight={'bold'}>A. Authority/Mandate: Electronic</Text>
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Table w={'100%'}>
              <Tbody>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Given by (Account holder):{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.accHolderName}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Address:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.address}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
        {/* Bankl Acc Details */}
        <Box>
          <Text fontWeight={'bold'}>Bank Account Details:</Text>
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Table w={'100%'}>
              <Tbody>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Bank Name:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.bankName}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Branch Name and Town:
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.branchName}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Branch Number:
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.branchCode}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Account Number:
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.accNumber}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Type of Account:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.accType}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Date:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.debitOrderDate}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Contact Number:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    {data.contactNumber}
                  </Td>
                </Tr>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    Amount:{' '}
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    w="50%"
                    border={'1px solid black'}
                  >
                    R{data.amount}
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
        {/* Seller Details */}
        <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
          <Table w={'100%'}>
            <Tbody>
              <Tr>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  To (Name of Beneficiary):{' '}
                </Td>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  {data.benifeciaryName}
                </Td>
              </Tr>
              <Tr>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  Address:
                </Td>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  {data.benifeciaryAddress}
                </Td>
              </Tr>
              <Tr>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  Abbreviated short name to be used:
                </Td>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  {data.shortName}
                </Td>
              </Tr>
              <Tr>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  Refer to contract reference number:
                </Td>
                <Td
                  padding={'8px'}
                  fontSize={['11px', '12px', '13px', '14px']}
                  w="50%"
                  border={'1px solid black'}
                >
                  {data.orderNumber}
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </Box>
      </Flex>
      <Flex w="100%" gap="25px" direction={'column'} bgColor="brand.neutral" p="25px" rounded="8px">
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          I/We hereby authorise Netcash (Pty) Ltd to issue and deliver payment instructions to your
          banker for collection against my/our abovementioned account at my/our abovementioned bank
          on condition that the sum of such payment instructions will not differ from my/our
          obligations as agreed to in the Subscription Agreement, as escalated.
        </Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          The individual payment instructions so authorised must be issued and delivered on the date
          when the obligation in terms of the Agreement is due and the amount of each individual
          payment instruction may not differ as agreed to in terms of the Agreement.
        </Text>

        <Text fontSize={['11px', '12px', '12px', '12px']}>
          The payment instructions so authorised to be issued must carry the Contract Reference
          Number, included in the said payment instructions, and must be provided to identify the
          specific contract. The said Contract Reference Number should be added to this form in
          section E before the issuing of any payment instruction and communicated directly after
          having been completed.
        </Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          I/We agree that the payments instruction falls on the <b>{data.debitOrderDate}</b> of each
          month.
        </Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          If however, the date of the payment instruction falls on a non-processing day (weekend or
          public holiday) I agree that the payment instruction may be debited against my account on
          the following business day; or
        </Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          Subsequent payment instructions will continue to be delivered in terms of this authority
          until the obligations in terms of the Agreement have been paid or until this authority is
          cancelled by me/us by giving you notice in writing of not less than the interval (as
          indicated in the previous clause) and sent by prepaid registered post or delivered to your
          address indicated above.
        </Text>
        <Box marginTop="25px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>B. MANDATE:</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            I/We acknowledge that all payment instructions issued by you will be treated by my/our
            abovementioned bank as if the instructions had been issued by me/us personally.
          </Text>
        </Box>
        <Box marginTop="25px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>C. CANCELLATION:</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            I/We agree that although this authority and mandate may be cancelled by me/us, such
            cancellation will not cancel the Agreement.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            I/We also understand that I/we cannot reclaim amounts, which have been withdrawn from
            my/our account (paid) in terms of this authority and mandate if such amounts were
            legally owing to you.
          </Text>
        </Box>
        <Box marginTop="25px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>D. ASSIGNMENT:</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            I/We acknowledge that this authority may be ceded or assigned to a third party if the
            Agreement is also ceded or assigned to that third party.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'50px'}>
            Signed by: <b>{data.accHolderName}</b> with ID/Passport number <b>{data.accID}</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
            <b>
              {data.signedMonthAndYear}, {data.timestamp}
            </b>
          </Text>
        </Box>
        <Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>E. Agreement Reference Number: </b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Agreement Reference Number is: <b>{data.contractReferenceNumber}</b>
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export default DebitOrderMandatePage
