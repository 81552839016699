import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react'
import { Addons, PreSignUpPropertyType, Product } from '../../../../types'
import { Flex, Grid, GridItem } from '@chakra-ui/layout'
import {
  canSetSubscriptionOption,
  findRecommendedOption,
  getDescriptionDetails
} from '../../../../helpers/productOptions'
import RecommendedOptionHeader from './recommendedOptionHeader'
import ProductLoadingGrid from './prodcutLoadingGrid'
import SubscriptionOption from './SubscriptionOption'
import { gosolr_product_catalogue } from '../../../../data/products'
import { ContainerHeading } from '../../../Headings'
import AddonWrapper from './AddOnWrapper'
import WhatsIncluded from './WhatsIncluded'
import SubscriptionSummarry from './SubscriptionSummarry'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import StageModal from './StageModal'

type SubscriptionOptionsProps = {
  property: PreSignUpPropertyType
  setFinalSubmitData: Dispatch<SetStateAction<PreSignUpPropertyType | null>>
}

const ProductsOptionsForm: FC<SubscriptionOptionsProps> = ({ setFinalSubmitData, property }) => {
  const [selectedSubscription, setSelectedSubscription] = useState<Product>()
  const {
    formData: { backupPowerNeeds }
  } = useContext(OnboardingFormContext)

  const [isLoading, setIsLoading] = useState(true)
  const [aboveStage4ModalActive, setAboveStage4ModalActive] = useState(false)

  // All of our base products
  const [baseSubscriptionOptions, setBaseSubscriptionOptions] = useState<Product[]>([])
  // The base product without any add-ons
  const [baseSubscriptionOption, setBaseSubscriptionOption] = useState<Product>()
  // What Gosolr recommends
  const [recommendedOption, setRecommendedOption] = useState<Product>()

  const [selectedAddons, setSelectedAddons] = useState<Addons>({
    two_panels: false,
    four_panels: false,
    battery: false
  })

  const [addonsSubscriptionOption, setAddonsSubscriptionOption] = useState<Product>()

  useEffect(() => {
    const productCatalogue = async () => {
      setIsLoading(true)
      const options = gosolr_product_catalogue.filter(
        (s: any) =>
          s.productName === 'Small' ||
          s.productName === 'Medium' ||
          s.productName === 'Large' ||
          s.productName === 'Extra Large' ||
          s.productName === 'Large 3phase'
      ) as Product[]

      // Set our base options users can choose from
      const recommendedOption = findRecommendedOption(
        options,
        property?.electricityPhaseType,
        property?.monthlyElectricitySpend
      )

      // Set The base options
      setBaseSubscriptionOptions(options)
      setBaseSubscriptionOption(recommendedOption)
      // Set our recommend by gosolr option
      setRecommendedOption(recommendedOption)
      setSelectedSubscription(recommendedOption)
      setIsLoading(false)

      if (
        backupPowerNeeds &&
        backupPowerNeeds === 'Above stage 4' &&
        !property?.electricityPhaseType.toLowerCase().includes('unknown') &&
        !property?.monthlyElectricitySpend.toLowerCase().includes("i don't know") &&
        !recommendedOption.productName.toLowerCase().includes('small')
      ) {
        setAboveStage4ModalActive(true)

        setSelectedAddons({
          two_panels: false,
          four_panels: false,
          battery: true
        })
      } else {
        setSelectedAddons({
          two_panels: false,
          four_panels: false,
          battery: false
        })
        setAboveStage4ModalActive(false)
      }
    }

    productCatalogue()
  }, [property?.electricityPhaseType, property?.monthlyElectricitySpend])

  useEffect(() => {
    if (baseSubscriptionOption) {
      const { two_panels, four_panels, battery } = selectedAddons

      if (!two_panels && !four_panels && !battery) {
        setAddonsSubscriptionOption(undefined)
        setSelectedSubscription(baseSubscriptionOption)

        return
      }

      let productName = baseSubscriptionOption.productName as string

      if (two_panels) {
        productName = `${baseSubscriptionOption.productName}${two_panels ? ' + 2 panels' : ''}`
      }

      if (four_panels && battery) {
        productName = `${baseSubscriptionOption.productName}${four_panels ? ' + 4 panels' : ''}`
      }

      productName = battery ? productName + ' + battery' : productName

      const subscription = gosolr_product_catalogue.find(
        (s: any) => s.productName.toLowerCase() === productName.toLowerCase()
      ) as Product

      setAddonsSubscriptionOption(subscription)
      setSelectedSubscription(subscription)
    }
  }, [selectedAddons, baseSubscriptionOption, selectedSubscription])

  useEffect(() => {
    onValuesChange()
  }, [selectedAddons, baseSubscriptionOption, selectedSubscription])

  const handleSetAsActiveSubscription = (s: Product) => {
    setBaseSubscriptionOption(s)
    setSelectedSubscription(s)
    setAddonsSubscriptionOption(undefined)
    setSelectedAddons({
      two_panels: false,
      four_panels: false,
      battery: false
    })
  }

  // Get Subscription fee for our selected product and addons
  const getSubscriptionFee = useCallback(
    (subscription: Product): number => {
      let fee = Number(subscription.subscriptionFee)

      if (baseSubscriptionOption && addonsSubscriptionOption) {
        if (subscription.recordId === baseSubscriptionOption?.recordId) {
          fee = Number(addonsSubscriptionOption.subscriptionFee)
        }
      }

      return fee
    },
    [baseSubscriptionOption, addonsSubscriptionOption]
  )

  const getDescription = useCallback(
    (subscription: Product): string => {
      let description = ''

      if (baseSubscriptionOption && addonsSubscriptionOption) {
        if (subscription.recordId === baseSubscriptionOption?.recordId) {
          description = 'Plus extra ' + (addonsSubscriptionOption.addOns || []).join(' and ')
        }
      }

      return description
    },

    [baseSubscriptionOption, addonsSubscriptionOption]
  )

  const checkIfIsRecommendedOption = (o: Product) => {
    const isRecommended =
      recommendedOption?.productName?.toLowerCase() === o.productName?.toLowerCase()

    return isRecommended
  }

  function onValuesChange() {
    try {
      if (baseSubscriptionOption) {
        // setIsSubmitting(true)
        const subscription: Product = Object.assign(
          {},
          addonsSubscriptionOption || baseSubscriptionOption
        )
        const data = { ...property, subscription }

        setFinalSubmitData(data)
      }
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <Flex direction={'column'} gap={25} mt={25} pb={50}>
      {/* <StageModal
        isOpen={aboveStage4ModalActive}
        onClose={() => setAboveStage4ModalActive(false)}
      /> */}
      <RecommendedOptionHeader optionName={recommendedOption?.productName || ''} />
      {!isLoading ? (
        <Grid
          w="full"
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap="15px"
        >
          {baseSubscriptionOptions.map((o, i) => (
            <GridItem
              key={`product-option-${i}`}
              colSpan={[1, 1, i === 4 ? 2 : 1, i === 4 ? 2 : 1]}
            >
              <SubscriptionOption
                onClick={() => {
                  if (canSetSubscriptionOption(o, property.electricityPhaseType)) {
                    handleSetAsActiveSubscription(o)
                  }
                }}
                canSetSubscription={canSetSubscriptionOption(o, property.electricityPhaseType)}
                name={o.productName as string}
                price={getSubscriptionFee(o)}
                selected={o.recordId === baseSubscriptionOption?.recordId}
                description={getDescription(o)}
                isReconmended={checkIfIsRecommendedOption(o)}
                subscriptionDetails={getDescriptionDetails(o, property.electricityPhaseType)}
                isSinglePhase={!property.electricityPhaseType?.toLowerCase().includes('three')}
              />
            </GridItem>
          ))}
        </Grid>
      ) : (
        <ProductLoadingGrid />
      )}
      <WhatsIncluded selectedSubscription={selectedSubscription} />
      {!selectedSubscription?.productName?.toLowerCase().includes('small') && (
        <Flex
          direction={'column'}
          gap="25px"
          border={'1px solid '}
          borderColor={'brand.secondary'}
          p={'25px'}
          rounded={'8px'}
        >
          <ContainerHeading title={'Additional add-ons'} />
          {selectedSubscription?.productName?.toLowerCase().includes('large') &&
            !selectedSubscription?.productName?.toLowerCase().includes('3phase') &&
            !selectedSubscription?.productName?.toLowerCase().includes('extra') && (
              <AddonWrapper
                id="panels-addon"
                heading={'4 x 455W Tier 1 Solar panels'}
                description={'Adding more solar panels will increase your electricity production'}
                price={'R460 (including VAT) per month'}
                imgUrl={'/images/solarpanel-icon.png'}
                onClick={() =>
                  setSelectedAddons({
                    ...selectedAddons,
                    two_panels: false,
                    four_panels: !selectedAddons.four_panels,
                    battery: !selectedAddons.four_panels
                  })
                }
                isSelected={selectedAddons.four_panels}
              />
            )}

          <AddonWrapper
            id="panels-addon"
            heading={'2 x 455W Tier 1 Solar panels'}
            description={'Adding more solar panels will increase your electricity production'}
            price={'R230 (including VAT) per month'}
            imgUrl={'/images/solarpanel-icon.png'}
            onClick={() =>
              setSelectedAddons({
                ...selectedAddons,
                two_panels: !selectedAddons.two_panels,
                four_panels: false
              })
            }
            isSelected={selectedAddons.two_panels}
          />

          <AddonWrapper
            id="battery-addon"
            heading={'Minimum 5kWh Lithium battery'}
            description={
              'Adding more battery capacity will give you even more protection during loadshedding and unplanned power cuts'
            }
            price={'R690  (including VAT) per month'}
            imgUrl={'/images/battery-icon.png'}
            onClick={() => {
              setSelectedAddons({
                ...selectedAddons,
                battery: !selectedAddons.battery,
                four_panels: false
              })
            }}
            isSelected={selectedAddons.battery}
          />
        </Flex>
      )}

      <SubscriptionSummarry selectedSubscription={selectedSubscription} formData={property} />
    </Flex>
  )
}

export default ProductsOptionsForm
