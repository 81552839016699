import { Flex, Select, Input, FormLabel, FormControl, Button } from '@chakra-ui/react'
import React from 'react'
import { ContainerHeading } from '../Headings'

type Props = {}

const USerBankSettings = (props: Props) => {
  return (
    <>
      <ContainerHeading title="Bank Settings " />
      <Flex w="full" direction={'column'} gap="25px" mb="25px">
        <Flex
          direction={['column', 'column', 'row', 'row']}
          justifyContent={'space-between'}
          gap={['25px', '25px', '25px', '10%']}
        >
          <FormControl isRequired>
            <FormLabel fontSize={'14px'} mb="0px">
              Bank Name
            </FormLabel>
            <Select placeholder="Select Bank">
              <option value="absa">Absa </option>
              <option value="fnb">FNB </option>
              <option value="standard-bank">Standard Bank</option>
              <option value="nedbank">Nedbank</option>
              <option value="tymbank">TymBank</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize={'14px'} mb="0px">
              Branch Code
            </FormLabel>
            <Input focusBorderColor="brand.primary" type="number" placeholder="Branch Code" />
          </FormControl>
        </Flex>
        <Flex
          direction={['column', 'column', 'row', 'row']}
          justifyContent={'space-between'}
          gap={['25px', '25px', '25px', '10%']}
        >
          <FormControl isRequired>
            <FormLabel fontSize={'14px'} mb="0px">
              Acc Number
            </FormLabel>
            <Input focusBorderColor="brand.primary" type="number" placeholder="Acc Number" />
          </FormControl>

          <FormControl isRequired>
            <FormLabel fontSize={'14px'} mb="0px">
              Acc Type
            </FormLabel>
            <Select placeholder="Select Type">
              <option value="check">Check </option>
              <option value="savings">Savings </option>
            </Select>
          </FormControl>
        </Flex>
        <Flex
          direction={['column', 'column', 'row', 'row']}
          justifyContent={'space-between'}
          gap={['25px', '25px', '25px', '10%']}
        >
          <FormControl isRequired>
            <FormLabel fontSize={'14px'} mb="0px">
              Acc Holder
            </FormLabel>
            <Input focusBorderColor="brand.primary" type="text" placeholder=" Acc Holder" />
          </FormControl>

          <Flex w="100%" h="100%" alignItems={'center'} justifyContent="center">
            <Button variant={'dark'} type="submit" mt="15px">
              Save Changes
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

export default USerBankSettings
