import { FC, useContext, useState } from 'react'
import { Box, Button, Flex, Grid, GridItem, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import { useNavigate } from 'react-router-dom'
import FileUploader from './FileUploader'
import RequiredDocumentContianer from './RequiredDocumentContianer'
import MultiLineTextInput from '../../../FormElements/MultiLineTextInput'
// import { OptionalDocumentUploadFormValidation } from '../../../../formValidation'
import axios from 'axios'
import { ErrorModal } from '../../../Modals'
import { error } from 'console'

type InitialValues = {
  proofOfAddress: File | null
  idPassportDocument: File | null
  electricalDistributionBoardPhoto: File | null
  installationLocationPhoto: File | null
  roofPhoto: File | null
  utilityBillDocument: File | null
  additionalNotes: string
  loadingElectricalDistributionBoardPhoto: boolean
  loadingInstallationLocationPhoto: boolean
  loadingRoofPhoto: boolean
}

type FormProps = {
  recordId: string
  file?: File | null
  idFile?: File | null
  electricalDistributionBoardPhoto?: File | null
  installationLocationPhoto?: File | null
  roofPhoto?: File | null
  loading?: boolean
  additionalNotes: string
  utilityBill?: File | null
  loadingUtilityBill?: boolean | false
  loadingElectricalDistributionBoardPhoto: boolean
  loadingInstallationLocationPhoto: boolean
  loadingRoofPhoto: boolean
  entityType: string
}

const docInfo = {
  proofOfAddress: {
    title: 'Proof of address',
    description:
      'Please upload proof of address so we can cross-check your property information with your identity information. This can be in the form of a utility bill or bank statement.'
  },
  utilityBill: {
    title: 'Latest utility bill',
    description: 'Please upload your latest utility bill.'
  },
  idPassport: {
    title: 'ID/Passport document',
    description:
      'Please upload a copy of your ID document or passport for reference. This helps us cross-check your details with our credit check process.'
  },

  electricalDistributionBoardPhoto: {
    title: 'Photo of electric distribution board',
    description:
      'Please upload a photo of your main distribution board. This will help the installer plan the electrical integration to your house.'
  },

  installationLocationPhoto: {
    title: 'Photo of installation location',
    description:
      'Please take a photo of ideal placement of the inverter and battery in your house. The area should be minimum 2mx1m.'
      // For second part of text, refer to RequiredDocumentContainer
  },

  roofPhoto: {
    title: 'Photo of roof',
    description:
      'Please upload a photo of your roof. This will help the installer plan where the solar panels will be installed.'
  },

  additionalNotes: {
    title: 'Additional notes',
    description:
      'Add any additional notes, or comments about your property that you would like to bring to the attention of the installers.'
  },

  payslips: {
    title: 'Latest 3 months payslips',
    description: 'Please upload your payslips for the latest 3 months'
  },
  bankingDetails: {
    title: 'Banking details',
    description: 'Please input your banking details'
  }
}

const OptionalUpload: FC<FormProps> = ({
  file,
  idFile,
  recordId,
  electricalDistributionBoardPhoto,
  installationLocationPhoto,
  roofPhoto,
  additionalNotes,
  loadingElectricalDistributionBoardPhoto,
  loadingInstallationLocationPhoto,
  loadingRoofPhoto,
  utilityBill,
  loadingUtilityBill,
  entityType
}) => {
  const navigate = useNavigate()
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)
  const [errorHeading, setErrorHeading] = useState<string>('Oops!')
  const [errorMessage, setErrorMessage] = useState<string>('Something went wrong.')
  const INITIAL_VALUES: InitialValues = {
    proofOfAddress: file || null,
    idPassportDocument: idFile || null,
    utilityBillDocument: utilityBill || null,
    electricalDistributionBoardPhoto: electricalDistributionBoardPhoto || null,
    installationLocationPhoto: installationLocationPhoto || null,
    roofPhoto: roofPhoto || null,
    additionalNotes: additionalNotes || '',
    loadingElectricalDistributionBoardPhoto: false,
    loadingInstallationLocationPhoto: false,
    loadingRoofPhoto: false
    
  }

  return (
    <Flex direction="column" w="100%">
      <Formik
        initialValues={INITIAL_VALUES}
        // validationSchema={OptionalDocumentUploadFormValidation}
        enableReinitialize
        onSubmit={async ({ additionalNotes }, { setStatus, setFieldError }) => {
          try {
            axios
              .put(`${process.env.REACT_APP_API_HOST}/set-optional-info-fields`, {
                recordId,
                additionalNotes
              })
              .then((response) => {
                // console.log('!!!', response)
              })

            navigate(`/user/dashboard`)
          } catch (error) {
            let message
            let name
            if (error instanceof Error) {
              message = error.message
              name = error.name
            } else message = String(error)
            setStatus(message)
            setErrorMessage(message)
            name && setErrorHeading(name)
            setShowErrorModal(true)
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors }) => {
          return (
            <Flex direction="column" w="full" gap={'25px'}>
              <ErrorModal
                errorTitle={errorHeading}
                errorMsg={errorMessage}
                isOpen={showErrorModal}
                onClose={() => {
                  setShowErrorModal(false)
                }}
              />
              <Form style={{ width: '100%' }}>
                <Grid
                  templateColumns={[
                    'repeat(1, 1fr)',
                    'repeat(1, 1fr)',
                    'repeat(2, 1fr)',
                    'repeat(2, 1fr)'
                  ]}
                  gap={'15px'}
                >
                  {/*in 1*/}

                  {/* Photo of DV Board*/}
                  <GridItem colSpan={1}>
                    <RequiredDocumentContianer
                      title={docInfo.electricalDistributionBoardPhoto.title}
                      description={docInfo.electricalDistributionBoardPhoto.description}
                    >
                      <FileUploader
                        name="electricalDistributionBoardPhoto"
                        recordId={recordId}
                        loading={loadingElectricalDistributionBoardPhoto}
                        destination="property"
                      />
                    </RequiredDocumentContianer>
                  </GridItem>

                  {/* Upload photo of installation location */}
                  <GridItem colSpan={1}>
                    <RequiredDocumentContianer
                      title={docInfo.installationLocationPhoto.title}
                      description={docInfo.installationLocationPhoto.description}
                    >
                      <FileUploader
                        name="installationLocationPhoto"
                        recordId={recordId}
                        loading={loadingInstallationLocationPhoto}
                        destination="property"
                      />
                    </RequiredDocumentContianer>
                  </GridItem>

                  {/* Upload photo of roof */}
                  <GridItem colSpan={1}>
                    <RequiredDocumentContianer
                      title={docInfo.roofPhoto.title}
                      description={docInfo.roofPhoto.description}
                    >
                      <FileUploader
                        name="roofPhoto"
                        recordId={recordId}
                        loading={loadingRoofPhoto}
                        destination="property"
                      />
                    </RequiredDocumentContianer>
                  </GridItem>
                  <GridItem colSpan={1}>
                    <RequiredDocumentContianer
                      title={docInfo.utilityBill.title}
                      description={docInfo.utilityBill.description}
                    >
                      <FileUploader
                        name="utilityBillDocument"
                        recordId={recordId}
                        loading={loadingUtilityBill}
                        destination={'property'}
                      />
                    </RequiredDocumentContianer>
                  </GridItem>

                  {/* Additional Notes */}

                  <GridItem colSpan={1}>
                    <RequiredDocumentContianer
                      title={docInfo.additionalNotes.title}
                      description={docInfo.additionalNotes.description}
                    >
                      <MultiLineTextInput name="additionalNotes" />
                    </RequiredDocumentContianer>
                  </GridItem>
                </Grid>

                <Flex w="full" p={'25px'} rounded={'8px'} justifyContent="right" alignItems="right">
                  <Button variant={'dark'} type={'submit'} isLoading={isSubmitting}>
                    Done
                  </Button>
                </Flex>
              </Form>
            </Flex>
          )
        }}
      </Formik>
    </Flex>
  )
}

export default OptionalUpload
