import { Flex, Image, Box, useMediaQuery } from '@chakra-ui/react'
import OnboardingHeroContainer from '../../components/Onboarding/Intro/OnboardingHeroContainer'
import RegisterContainer from '../../components/Auth/Register'
import { useCookies } from 'react-cookie'

const RegisterPage = (): JSX.Element => {
  const mediaQuery = useMediaQuery('(max-width: 1024px)')
  const isMobile = mediaQuery[0]
  const [cookies] = useCookies()
  return (
    <Flex
      as="main"
      position={'relative'}
      direction="row"
      w={'100%'}
      minH={'100vh'}
      bgColor={'#F2F2F2'}
    >
      <Flex
        direction={'column'}
        bgColor={'brand.neutral'}
        w={'100%'}
        maxW={isMobile ? '100%' : '50%'}
        minW={'50%'}
        h={'100%'}
        minH={'100vh'}
        px={['25px', '50x', '100px', '100px']}
        py={'50px'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={25}
      >
        <Flex w={'full'} alignItems={'center'} justifyContent={'start'}>
          {cookies.promocode && cookies.promocode.toLowerCase() === 'hippo' ? (
            <Image src={'/brand/hippo.svg'} w={'auto'} h={'62px'} />
          ) : (
            <Image src={'/images/gosolr-logo-new.svg'} w={'auto'} h={'62px'} />
          )}
        </Flex>
        <Flex
          flex={1}
          direction={'column'}
          gap={25}
          w={'full'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <RegisterContainer />
        </Flex>
      </Flex>
      {!isMobile && <OnboardingHeroContainer />}
    </Flex>
  )
}

export default RegisterPage
