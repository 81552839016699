import { Flex } from '@chakra-ui/react'
import React from 'react'
import ManageSubscriptionContainer from '../../../components/Property/Manage/ManageSubscriptionContainer'

const ManageSubscriptionPage = (): JSX.Element => {
  return (
    <Flex w="100%" h={'100%'} alignItems="start" justifyContent={'center'}>
      <ManageSubscriptionContainer />
    </Flex>
  )
}

export default ManageSubscriptionPage
