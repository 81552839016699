import React, { useState, useEffect, createContext, type Dispatch } from 'react'
import { useLocation } from 'react-router-dom'
import { useMediaQuery } from '@chakra-ui/react'

type Props = {
  children?: React.ReactNode
}

export type Expose = {
  sideBarIsOpen?: Boolean
  mobileNavIsOpen?: Boolean
  handleToggleSidebar: (event: React.MouseEvent<HTMLElement>) => void
  handleToggleMobileNav: () => void
  isMobileSize?: Boolean
  sbclosedWidth?: String
  sbOpenWidth?: String
}
// Create our sidebar Context with some default values
export const SideBarContext = createContext<Expose>({
  handleToggleSidebar: () => {},
  handleToggleMobileNav: () => {}
})

const SidebarProvider: React.FC<Props> = ({ children }) => {
  const [mobileNavIsOpen, setMobileNavIsOpen] = useState<Boolean>(false)
  const [sideBarIsOpen, setSideBarIsOpen] = useState<Boolean>(false)

  //   Chekc if we are on mobile screen size
  const [isMobileSize] = useMediaQuery('(max-width: 610px)')

  // Sidebar open and closed widths
  const sbOpenWidth: String = '240px'
  const sbclosedWidth: String = '50px'

  // Keep track of current location
  const location = useLocation()

  // Close Mobile Navbar when location changes
  useEffect(() => {
    setMobileNavIsOpen(false)
  }, [location])

  //   Prevent scrolling when navbar is open
  useEffect(() => {
    if (typeof window != 'undefined' && window.document) {
      document.body.style.overflow = mobileNavIsOpen ? 'hidden' : 'auto'
    }
  }, [mobileNavIsOpen])

  const handleToggleSidebar = (event: React.MouseEvent<HTMLElement>) => {
    setSideBarIsOpen((prev) => !prev)
  }

  const handleToggleMobileNav = () => {
    setMobileNavIsOpen((prev) => !prev)
  }

  const expose: Expose = {
    sbOpenWidth,
    sbclosedWidth,
    mobileNavIsOpen,
    isMobileSize,
    sideBarIsOpen,
    handleToggleSidebar,
    handleToggleMobileNav
  }
  return <SideBarContext.Provider value={expose}>{children}</SideBarContext.Provider>
}

export default SidebarProvider
