import { Flex } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'

import TransactionList from './TransactionList'

const AccTransactionsContainer = (): JSX.Element => {
  return (
    <>
      <Flex
        direction={'column'}
        w={'100%'}
        h="auto"
        p="25px"
        bg={'#ffff'}
        rounded="6px"
        shadow="md"
        position={'relative'}
        overflow={'hidden'}
      >
        <ContainerHeading title="Recent transactions" />
        <TransactionList />
      </Flex>
    </>
  )
}

export default AccTransactionsContainer
