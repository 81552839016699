import React, { useContext, useState } from 'react'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'
import GoogleRoofMap from '../../../FormElements/GoogleMapInput/GoogleRoofMap'
import { OnboardingFormContext } from '../../../../context/intro/OnboardingContext'
import GoogleMapInput from '../../../FormElements/GoogleMapInput'
import { Maybe, PropertyLocation } from '../../../../types'
import CallMe from '../../Pre-singup-flow/CallMe/CallMe'
import BackButton from '../../../FormElements/BackButton'
import { useNavigate } from 'react-router'

type ElectricityPhaseUpdateModalType = {
  isOpen: boolean
  onClose: () => void
  heading: string
  resetProduct: () => void
  cancelUpdate: () => void
}

const setFieldValue = (field: string, value: any, shouldValidate?: boolean | undefined) => {
  console.log('field', field)
  console.log('value', value)
  console.log('shouldValidate', shouldValidate)
}

function ElectricityPhaseUpdateModal({
  isOpen,
  onClose,
  heading,
  resetProduct,
  cancelUpdate
}: ElectricityPhaseUpdateModalType) {
  const navigate = useNavigate()

  const handleClick = () => {
    /* Reset the current product */
    resetProduct()
    onClose()
  }

  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <Text textAlign={'center'} color={'brand.text.primary'}>
          Please note: After changing you electricity phase type you need to reselect your solar
          product.
        </Text>
        <Text textAlign={'center'} color={'brand.text.primary'}>
          Feel free to contact us at{' '}
          <Link href={`mailto: hello@gosolr.co.za?subject = Support Request`} color={'#3b82f6'}>
            hello@gosolr.co.za
          </Link>{' '}
          or give us a call at{' '}
          <Link href={`tel:010 880 3948`} color={'#3b82f6'}>
            010 880 3948
          </Link>{' '}
          if you require more information.
        </Text>

        <Flex w="full" justifyContent={'space-between'} mt={'25px'}>
          <BackButton
            callback={() => {
              cancelUpdate
                ? cancelUpdate()
                : console.error('failed to cancel phase update. cancelUpdate callback not defined')
              //onClose();
            }}
          />

          <Button
            onClick={() => {
              handleClick()
            }}
            variant={'dark'}
          >
            Confirm
          </Button>
        </Flex>
      </Flex>
    </DefaultModal>
  )
}

export default ElectricityPhaseUpdateModal
