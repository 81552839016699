import { Center, Grid, GridItem } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/spinner'
import React from 'react'

const ProductLoadingGrid = () => {
  return (
    <Grid
      w="full"
      templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
      gap="15px"
    >
      <GridItem>
        <Center w="full" bg={'brand.input'} height={'150px'} rounded={'8px'}>
          <Spinner />
        </Center>
      </GridItem>
      <GridItem>
        <Center w="full" bg={'brand.input'} height={'150px'} rounded={'8px'}>
          <Spinner />
        </Center>
      </GridItem>
      <GridItem>
        <Center w="full" bg={'brand.input'} height={'150px'} rounded={'8px'}>
          <Spinner />
        </Center>
      </GridItem>

      <GridItem>
        <Center w="full" bg={'brand.input'} height={'150px'} rounded={'8px'}>
          <Spinner />
        </Center>
      </GridItem>
    </Grid>
  )
}

export default ProductLoadingGrid
