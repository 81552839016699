import React, { FC, useState } from 'react'
import {
  Flex,
  Input,
  FormControl,
  Button,
  Text,
  Heading,
  FormLabel,
  useDisclosure,
  Box,
  Image
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { LoginInputs } from '../../types'
import { useAuthContext } from '../../context/AuthProvider'
import ModalDialog from '../../components/Modals/ModalDialog'
import { FiInfo } from 'react-icons/fi'
import TextInput from '../../components/FormElements/TextInput'
import SubmitButton from '../../components/FormElements/SubmitButton'

const PasswordResetValidationSchema = yup.object({
  email: yup.string().email('Please use a valid email address.').required('Email is required')
})

const ResetPassword = (): JSX.Element => {
  const { resetPassword } = useAuthContext()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [userEmail, setUserEmail] = useState<string>('')
  const {
    isOpen: passwordResetSuccessModalIsOpen,
    onOpen: passwordResetSuccessModalOpen,
    onClose: passwordResetSuccessModalClose
  } = useDisclosure()

  return (
    <>
      <Flex
        direction={'column'}
        gap="50px"
        w="100%"
        h="100vh"
        alignItems={'center'}
        justifyContent="center"
        color="brand.text.primary"
        position={'relative'}
      >
        <Box w={'full'} position={'fixed'} top={50} left={100}>
          <Image src={'/images/gosolr-logo-new.svg'} w={'auto'} h={'62px'} />
        </Box>
        <Flex
          w="full"
          direction={'column'}
          gap="50px"
          alignItems={'center'}
          justifyContent="center"
          color="brand.text.primary"
        >
          <Heading w="full" textAlign={'center'} maxW="500px">
            Reset password
          </Heading>
          <Text w="full" textAlign={'center'} maxW="500px">
            Enter the email address associated with your account and we'll send you a link to reset
            your password.
          </Text>
        </Flex>
        <Formik
          initialValues={{ email: '' } as LoginInputs}
          validateOnMount
          validateOnChange={true}
          validateOnBlur={true}
          validationSchema={PasswordResetValidationSchema}
          onSubmit={async ({ email }, { setSubmitting }) => {
            try {
              setSubmitting(true)
              setIsLoading(true)

              resetPassword && (await resetPassword(email))
              setUserEmail(email)
              setTimeout(() => {
                passwordResetSuccessModalOpen()
                setSubmitting(false)
                setIsLoading(false)
              }, 2000)
            } catch (e: any) {
              console.log(e.message as any)
              setIsLoading(false)
            }
          }}
        >
          {({ isValid, isSubmitting, values }) => (
            <Form>
              <Flex
                direction={'column'}
                gap="50px"
                w="full"
                minW={['250px', '300px', '300px', '300px']}
              >
                <TextInput name="email" label="Email" placeholder="Enter your email address" />
                <SubmitButton title="Send reset code" isLoading={isLoading} />
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      {/*** Reset Password Success Modal ***/}
      <ModalDialog
        status="success"
        title="Request sent"
        description={
          <Box textAlign="center">
            <Flex justifyContent="center" alignItems={'center'} color="#B1B2B1" mb="1rem">
              <FiInfo /> &nbsp;Check your inbox
            </Flex>
            <Text>
              Keep an eye out for a password reset email, follow the instructions to change your
              password
            </Text>
          </Box>
        }
        isOpen={passwordResetSuccessModalIsOpen}
        onClose={passwordResetSuccessModalClose}
        buttonTextSuccess="Enter reset code"
        successLink="/reset-password"
        linkState={{ email: userEmail }}
        hasCloseIcon={false}
      />
    </>
  )
}

export default ResetPassword
