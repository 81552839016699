import React, { useEffect, useState } from 'react'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import axios from 'axios'
import { Button, Flex, Text } from '@chakra-ui/react'
import SubmitButton from '../FormElements/SubmitButton'
import TextInput from '../FormElements/TextInput'

import RecaptchaComponent from '../FormElements/RecaptchaComponent'
import { FeelFreeToContactUs } from '../TextOnScreen'

interface MailingSubscribeProps {
  showSubscribeElements: boolean //If true response then show the subscribe options
  negativeText?: string //If something negative happened - requesting as the user to subscribed till it's solved
  reasonForSubscribing?: string //The reason for subscribing / showing the form. This is to be listed as the subscribe reason in QB
  comments?: string // Any comments/extra info on why the customer subscribed
  onClose?: () => void //Callback to close the form (if in a modal)
  phoneNumber?: string //If a phone number is available for save with the subscription
}
type InitValuesType = {
  firstName: string
  lastName: string
  email: string
  isHuman: boolean
}

export const SubscribeValidation = Yup.object().shape({
  firstName: Yup.string().required('First name is required'),
  lastName: Yup.string().required('Last name is required'),
  email: Yup.string().email('Invalid email address').required('Email is required'),
  isHuman: Yup.bool().oneOf([true], 'Please complete captcha')
})

/**
 * Create a Sign-up form for subscribing to the mailing list.
 * @param props Provide the initial values for the form.
 * @returns
 */
const MailingSubscribe: React.FC<MailingSubscribeProps> = ({
  showSubscribeElements,
  negativeText,
  onClose,
  reasonForSubscribing,
  comments,
  phoneNumber
}) => {
  const initValues = {
    firstName: '',
    lastName: '',
    email: '',
    isHuman: false
  }

  const handleSubmit = async (values: any) => {
    const data = JSON.stringify({
      ...values,
      reason: reasonForSubscribing,
      comments: comments,
      phone: phoneNumber
    })

    if (data) {
      try {
        await axios
          .post(process.env.REACT_APP_API_HOST + '/add-to-mailing-list', data)
          .then(function (response) {
            //Only close if the close function provided
            onClose && onClose()
          })
          .catch(function (error) {
            console.error('Error occurred while submitting for user subscription: ', error)
          })
      } catch (e) {
        console.error('Error occurred during user subscription: ', e)
      }
    }
  }

  return (
    <Formik
      initialValues={initValues}
      validateOnMount
      validationSchema={SubscribeValidation}
      onSubmit={async (values) => {
        await handleSubmit(values)
      }}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Flex
            direction={'column'}
            gap={'25px'}
            alignItems={'center'}
            justifyContent="center"
            fontSize={'sm'}
          >
            {showSubscribeElements && (
              <Text textAlign={'center'} color={'brand.text.primary'}>
                {negativeText}
              </Text>
            )}

            <FeelFreeToContactUs />

            {/*Only ask for the user details if the the modal is giving negative message */}
            {showSubscribeElements && (
              <>
                <Flex w={'full'} alignItems={'center'} gap={25}>
                  <TextInput name="firstName" label="First name" placeholder="First name" />
                  <TextInput name="lastName" label="Last name" placeholder="Last name" />
                </Flex>
                <TextInput name="email" label="Email" placeholder="Email address" />
                <IsHumanObserver values={values} setFieldValue={setFieldValue} />
                <Flex alignItems={'center'} justify={'space-between'} w={'full'}>
                  <Button variant={'outline'} onClick={onClose} rounded={'full'}>
                    Back
                  </Button>
                  <SubmitButton title="Subscribe" />
                </Flex>
              </>
            )}

            {/* If no subscribe is shown then only show back button */}
            {!showSubscribeElements && (
              <Flex alignItems={'center'} justify={'space-between'} w={'full'}>
                <Button alignSelf={'flex-end'} variant={'dark'} onClick={onClose}>
                  Back
                </Button>
              </Flex>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  )
}

export default MailingSubscribe

const IsHumanObserver = ({
  values,
  setFieldValue
}: {
  values: InitValuesType
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}) => {
  const [shouldShow, setShouldShow] = useState<boolean>(false)
  useEffect(() => {
    setShouldShow(values.isHuman)
  }, [values.isHuman])
  return <>{!shouldShow && <RecaptchaComponent setFieldValue={setFieldValue} />}</>
}
