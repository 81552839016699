import { Box, Flex, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import IdentificationIcon from '../Icons/IdentificationIcon'
import { IoMdCheckmark } from 'react-icons/io'
import { useAuthContext } from '../../context/AuthProvider'
import { MdBrandingWatermark } from 'react-icons/md'
import { FiX } from 'react-icons/fi'

const AccIDVerification: React.FC = () => {
  const { user } = useAuthContext()
  const [verified, setVeriefied] = useState<boolean | null | undefined>(true)
  const info = user?.info || {}
  useEffect(() => {
    // console.log(user?.info)
    if (info !== null || info !== undefined) {
      if (info.properties) {
        if (info?.properties[0] !== null || info?.properties[0] !== undefined) {
          // setVeriefied(info?.properties[0]?.creditCheckPassed)
          // console.log(info?.idPassportDocumentS3Key)
        }
      }
    }
  }, [])
  return (
    <Flex
      direction={'column'}
      gap={'20px'}
      h={'100%'}
      w={'100%'}
      alignItems={'center'}
      justifyContent={'center'}
    >
      <Flex gap={'25px'} alignItems={'center'}>
        <Box pos={'relative'}>
          <IdentificationIcon />
          <Flex
            alignItems={'center'}
            justifyContent={'center'}
            pos={'absolute'}
            bottom={-2}
            right={-2}
            h={'30px'}
            w={'30px'}
            rounded={'full'}
            shadow={'md'}
            bgColor={verified ? 'brand.primary' : 'brand.danger'}
            color={verified ? 'black' : 'white'}
          >
            {verified ? <IoMdCheckmark strokeWidth={'2px'} /> : <FiX strokeWidth={'2px'} />}
          </Flex>
        </Box>
        <Flex
          h={'40px'}
          w={'100%'}
          maxW={'160px'}
          px={'30px'}
          rounded={'4px'}
          bgColor={verified ? 'brand.primary' : 'transparent'}
          border={verified ? 'none' : '1px solid'}
          borderColor={verified ? 'none' : 'brand.secondary'}
          alignItems={'center'}
          justifyContent={'center'}
        >
          <Text fontSize={'sm'} fontWeight={500}>
            {verified ? ' Verified' : 'Not Verified'}
          </Text>
        </Flex>
      </Flex>
      <Text fontSize={'sm'} fontWeight={500}>
        {verified ? 'Identity Verified' : 'Identity Not Verified'}
      </Text>
    </Flex>
  )
}

export default AccIDVerification
