import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Box, Center, Flex, Spinner, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { ordinal } from '../../../utils'
import { useParams } from 'react-router-dom'

type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const TechnicalAcknowledgementPage = ({ setTitle }: Props): JSX.Element => {
  const { recordId } = useParams()
  const { user } = useAuthContext()
  const { data: propertyData, loading: loadingProperties } = useGetUserPropertyQuery({
    variables: {
      recordId: recordId!
    }
  })
  const property = propertyData?.getUserProperty as UserProperty

  const [loadingData, setLoadingData] = useState(true)

  const [signedDateAndPlace, setSignedDateAndPlace] = useState(() => ({
    signedAt: 'GO SOLR Web App',
    signedDay: '',
    signedMonthAndYear: '',
    timestamp: ''
  }))

  const { data: userData } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = userData?.getUser as User

  useEffect(() => {
    setTitle(`Technical Acknowledgment`)
  }, [property])

  useEffect(() => {
    const getCurrentTimeData = async () => {
      const currentDate = await new Date()
      const nameOfMonth = await new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date()
      )

      let currDay: number = currentDate.getDate()
      let signedDay = ordinal(currDay)

      const signedDate = {
        signedAt: 'GO SOLR Web App',
        signedDay: signedDay,
        signedMonthAndYear: `${nameOfMonth} ${currentDate.getFullYear()}`,
        timestamp: currentDate.toLocaleTimeString()
      }

      setSignedDateAndPlace(signedDate)
    }
    getCurrentTimeData()
    const loadData = async () => {
      if (recentUserData) {
        const allProperties = (
          (recentUserData.properties?.length && (recentUserData.properties as UserProperty[])) ||
          []
        )
          .slice()
          .sort((a: any, b: any) => {
            return Number(b.recordId) - Number(a.recordId)
          })

        setLoadingData(false)
        // console.log(recentUserData.properties)
      }
    }
    loadData()
  }, [recentUserData, propertyData, userData])

  const data = {
    accHolderName: user?.info.firstName + ' ' + user?.info.lastName,
    accID: recentUserData?.idPassportNumber,
    contractReferenceNumber: property?.recordId,
    ...signedDateAndPlace
  }

  if (loadingData || loadingProperties) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Generating your compliance acknowledgemnt document</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex
        w="100%"
        gap="25px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
        shadow={'md'}
      >
        {/* User Details */}
        <Text fontWeight={'bold'}>Technical Acknowledgmen:</Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          Should the grid be unavailable, the GoSolr system will be limited to the throughput of the
          inverter. For the GoSolr Small, this will be limited to 3,6kW. Should consumption in the
          home exceed this when the grid is unavailable, the system will trip.
        </Text>

        <Text fontSize={['11px', '12px', '12px', '12px']}>
          I acknowledge that I have read, understood, and accept the technical limitations of the
          gosolr Small product and that I am responsible for managing my household consumption when
          the grid is unavailable.
        </Text>
        <Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            Signed by: <b>{data.accHolderName}</b> with ID/Passport number <b>{data.accID}</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
            <b>
              {data.signedMonthAndYear}, {data.timestamp}
            </b>
          </Text>
        </Box>
        <Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>Agreement Reference Number: </b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Agreement Reference Number is: <b>{data.contractReferenceNumber}</b>
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export default TechnicalAcknowledgementPage
