import { Box, Button, Flex } from '@chakra-ui/react'
import { useContext, useEffect, useState } from 'react'
import GoogleMap from '../../GoogleMap'

import { useGoogleContext } from '../../../context/GoogleProvider'
import { getWhat3Words } from '../../../helpers/w3w'

type GoogleMapStandAloneProps = {
  handleConfirmLocation: () => void
  lat: string | number
  lng: string | number
  isDraggable?: boolean
  fullAddress: string
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const GoogleMapStandAlone = ({
  handleConfirmLocation,
  setFieldValue,
  lat,
  lng,
  fullAddress,
  isDraggable = false
}: GoogleMapStandAloneProps) => {
  const { googleGeocoder } = useGoogleContext()
  const [locationConfirmed, setLocationConfirmed] = useState<boolean>(false)

  const [locationCoordinates, setLocationCoordinates] = useState<{ lat: number; lng: number }>({
    lat: Number(lat),
    lng: Number(lng)
  })
  const [what3Word, setWhat3Words] = useState<string>('')

  const onLocationChange = async (map: google.maps.Map) => {
    const newCenter = map.getCenter() as google.maps.LatLng
    const newCenterCoords = newCenter.toJSON()
    setLocationCoordinates(newCenterCoords)
    setLocationConfirmed(false)
    // setFieldValue('roofLocationConfirmed', false)
    // get What3Words
    const w3w = await getWhat3Words(newCenterCoords)
    setWhat3Words(w3w)
  }

  useEffect(() => {
    const init = async () => {
      setLocationConfirmed(false)
      if (fullAddress) {
        await getCoordinatesFromAddress(fullAddress)
      }
    }
    if (!lat || !lng || !locationConfirmed) {
      init()
    }
  }, [fullAddress])

  const getCoordinatesFromAddress = async (address: string) => {
    try {
      if (googleGeocoder) {
        try {
          await googleGeocoder.geocode(
            {
              address,
              componentRestrictions: { country: 'za' }
            },
            async (results, status) => {
              if (status === 'OK') {
                if (results && results[0]) {
                  // Get the first result in the arr
                  const result = results[0]
                  // extract lat and long values from result
                  const coords = {
                    lat: result.geometry.location.lat(),
                    lng: result.geometry.location.lng()
                  }

                  setLocationCoordinates(coords)
                  // get What3Words
                  const w3w = await getWhat3Words(coords)
                  setWhat3Words(w3w)
                } else {
                  throw new Error('No results found')
                }
              } else {
                throw new Error('Geocoder failed due to: ' + status)
              }
            }
          )
        } catch (e) {
          console.error('FAILED TO PARSE GIVEN ADDRESS', e)
        }
      }
    } catch (e) {
      return
    }
  }

  return (
    <Flex
      id="google-map-input"
      backgroundColor="brand.neutral"
      borderRadius={'4px'}
      gap={locationConfirmed ? 0 : '25px'}
      direction="column"
      width="100%"
      data-group
    >
      <Flex
        position={'relative'}
        overflow={'hidden'}
        style={{
          height: locationConfirmed ? '0px' : '320px',
          opacity: locationConfirmed ? '0' : '1',
          transition: 'all 0.3s linear',
          transformOrigin: 'top',
          overflow: 'hidden'
        }}
      >
        <Box
          position={'absolute'}
          inset={0}
          bgColor={'blackAlpha.400'}
          zIndex={10}
          rounded={'lg'}
          pointerEvents={'none'}
          opacity={isDraggable ? '0' : '100'}
          //   backdropFilter={isDraggable ? '' : 'grayscale(100%) brightness(150%)'}
        />
        <GoogleMap
          draggable={isDraggable}
          coordinates={locationCoordinates}
          onLocationChange={onLocationChange}
          height={{ base: '15rem', md: '20rem', lg: '20rem' }}
          overflow="hidden"
          rounded={'4px'}
        />
      </Flex>
      {!locationConfirmed && isDraggable && (
        <Button
          variant="dark"
          isDisabled={locationConfirmed}
          mt={4}
          onClick={async () => {
            if (setFieldValue) {
              await setFieldValue('roofW3w', what3Word, true)
              await setFieldValue('roofLat', locationCoordinates.lat, true)
              await setFieldValue('roofLng', locationCoordinates.lng, true)
              await setFieldValue('roofLocationConfirmed', true, true)
            }
            handleConfirmLocation()
          }}
        >
          Confirm roof location
        </Button>
      )}
    </Flex>
  )
}

export default GoogleMapStandAlone
