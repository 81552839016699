export const SolarmanProfileTime = {
  time1: true,
  time2: true,
  time3: true,
  time4: true,
  time5: true,
  time6: true
}

export const SolarmanTime = {
  startTime1: '06:00',
  endTime1: '12:00',
  startTime2: '12:00',
  endTime2: '14:00',
  startTime3: '14:00',
  endTime3: '16:00',
  startTime4: '16:00',
  endTime4: '18:00',
  startTime5: '18:00',
  endTime5: '20:00',
  startTime6: '20:00',
  endTime6: '06:00'
}

export const SolarmanPower = {
  powerTime1: '5000',
  powerTime2: '5000',
  powerTime3: '5000',
  powerTime4: '5000',
  powerTime5: '5000',
  powerTime6: '5000'
}

// custom data
export const CustomBatteryData = {
  startTime1: '',
  endTime1: '',
  startTime2: '',
  endTime2: '',
  startTime3: '',
  endTime3: '',
  startTime4: '',
  endTime4: '',
  startTime5: '',
  endTime5: '',
  startTime6: '',
  endTime6: '',
  powerTime1: '',
  powerTime2: '',
  powerTime3: '',
  powerTime4: '',
  powerTime5: '',
  powerTime6: '',
  batteryTime1: undefined,
  batteryTime2: undefined,
  batteryTime3: undefined,
  batteryTime4: undefined,
  batteryTime5: undefined,
  batteryTime6: undefined,
  time1: false,
  time2: false,
  time3: false,
  time4: false,
  time5: false,
  time6: false
}

// TODO: refactor the grid charge to use function and return values
const gridChargeFalse = {
  gridCharge1: false,
  gridCharge2: false,
  gridCharge3: false,
  gridCharge4: false,
  gridCharge5: false,
  gridCharge6: false
}

const gridChargeTrue = {
  gridCharge1: true,
  gridCharge2: true,
  gridCharge3: true,
  gridCharge4: true,
  gridCharge5: true,
  gridCharge6: true
}

const BalancedSolarmanBattery = {
  batteryTime1: 0.3,
  batteryTime2: 0.5,
  batteryTime3: 0.8,
  batteryTime4: 0.85,
  batteryTime5: 0.7,
  batteryTime6: 0.6
}

// Balaned option
export const BalancedSolarmanData = {
  ...BalancedSolarmanBattery,
  ...SolarmanTime,
  ...SolarmanPower,
  ...SolarmanProfileTime,
  ...gridChargeFalse
}

// max-safety option
const MaxSafetySolarmanBattery = {
  batteryTime1: 0.95,
  batteryTime2: 0.95,
  batteryTime3: 0.95,
  batteryTime4: 0.95,
  batteryTime5: 0.95,
  batteryTime6: 0.95
}
export const MaxSafetySolarmanData = {
  ...MaxSafetySolarmanBattery,
  ...SolarmanTime,
  ...SolarmanPower,
  ...SolarmanProfileTime,
  ...gridChargeTrue
}

// economical option
const EconomicalBattery = {
  batteryTime1: 0.3,
  batteryTime2: 0.3,
  batteryTime3: 0.3,
  batteryTime4: 0.15,
  batteryTime5: 0.15,
  batteryTime6: 0.15
}
export const EconomicalSolarmanData = {
  ...EconomicalBattery,
  ...SolarmanTime,
  ...SolarmanPower,
  ...SolarmanProfileTime,
  ...gridChargeFalse
}
