import { Flex, Text, Heading, Button, Center, Spinner } from '@chakra-ui/react'
import { Form, Formik, FormikProps } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import { PersonalInfoFormValidation } from '../../formValidation'
import {
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty,
  useUpdateDynamoUserMutation,
  useUpdateUserMutation
} from '../../generated/graphql'
import ConnectedIDNumberInput from '../FormElements/ConnectedIDNumberInput'
import ConnectedPhoneNumberInput from '../FormElements/ConnectedPhoneNumberInput'
import TextInput from '../FormElements/TextInput'
import { ContainerHeading } from '../Headings'

type Props = {
  user?: User
}

type InitialValues = {
  firstName: string
  lastName: string
  initials: string
  email: string
  contactNumber: string
  alternateNumber: string
  // idNumber: string
}

const UserDetailsSettings: FC<Props> = ({ user }) => {
  const [initialValues, setInitialValues] = useState<InitialValues>(() => ({
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    initials: user?.initials || `${user?.firstName?.slice(0, 1)}${user?.lastName?.slice(0, 1)}`,
    email: user?.email || '',
    contactNumber: user?.contactNumber || '',
    alternateNumber: user?.alternateNumber || ''
    // idNumber: user?.idPassportNumber || ''
  }))

  const { data: userData, loading } = useGetUserQuery({
    variables: {
      email: user?.email as string
    }
  })
  const [recordId, setRecordId] = useState<string>(user?.recordId as string)
  const recentUserData = userData?.getUser as User
  useEffect(() => {
    if (recentUserData) {
      setInitialValues({
        firstName: recentUserData?.firstName || '',
        lastName: recentUserData?.lastName || '',
        initials:
          recentUserData?.initials ||
          `${recentUserData?.firstName?.slice(0, 1)}${recentUserData?.lastName?.slice(0, 1)}`,
        email: recentUserData?.email || '',
        contactNumber: recentUserData?.contactNumber || '',
        alternateNumber: recentUserData?.alternateNumber || ''
        // idNumber: recentUserData?.idPassportNumber || ''
      })
    }

    setRecordId(user?.recordId as string)
  }, [user, recentUserData])

  const [updateUser] = useUpdateUserMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const { data: propertyData } = useGetUserPropertyQuery({
    variables: {
      recordId
    }
  })
  const property = propertyData?.getUserProperty as UserProperty
  if (loading) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Loading your personal information</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <ContainerHeading title="Account settings " />
      <Flex w="full" direction={'column'} gap="25px" mb="25px">
        <Heading as="h3" fontSize="20px" fontWeight={500}>
          Personal information
        </Heading>
        <Formik
          validationSchema={PersonalInfoFormValidation}
          initialValues={initialValues}
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={async (
            { firstName, lastName, initials, contactNumber, alternateNumber },
            { setSubmitting, setStatus }
          ) => {
            try {
              // console.log(recordId)
              setStatus(null)
              setSubmitting(true)

              await updateUser({
                variables: {
                  email: user?.email as string,
                  input: {
                    recordId,
                    contactNumber,
                    firstName,
                    lastName,
                    initials,
                    alternateNumber
                  }
                }
              })

              await updateDynamoUser({
                variables: {
                  email: user?.email as string,
                  input: {
                    contactNumber,
                    firstName,
                    lastName,
                    initials
                  }
                }
              })

              setSubmitting(false)
            } catch (error: any) {
              setStatus(error.message)
            }
          }}
        >
          {({ isSubmitting }: FormikProps<InitialValues>) => {
            return (
              <Form style={{ width: '100%' }}>
                <Flex w="full" direction={'column'} gap="25px" mb="25px">
                  <Flex
                    direction={['column', 'column', 'row', 'row']}
                    justifyContent={'space-between'}
                    gap={['25px', '25px', '25px', '10%']}
                  >
                    <TextInput name="firstName" placeholder="First name" label="First name" />
                    <TextInput name="lastName" placeholder="Last name" label="Last name" />
                  </Flex>
                  <Flex
                    direction={['column', 'column', 'row', 'row']}
                    justifyContent={'space-between'}
                    gap={['25px', '25px', '25px', '10%']}
                  >
                    <TextInput name="initials" placeholder="Initials" label="Initials" />
                    <TextInput type={'email'} name="email" placeholder="Email" label="Email" />
                  </Flex>
                  <Flex
                    direction={['column', 'column', 'row', 'row']}
                    justifyContent={'space-between'}
                    gap={['25px', '25px', '25px', '10%']}
                  >
                    <ConnectedPhoneNumberInput
                      name="contactNumber"
                      label="Phone number"
                      placeholder="Enter phone number"
                      bg="transparent"
                      mb={[4, 8]}
                    />
                    <ConnectedPhoneNumberInput
                      name="alternateNumber"
                      label="Secondary phone number"
                      placeholder="Enter secondary phone number"
                      bg="transparent"
                      mb={[4, 8]}
                    />
                  </Flex>
                  <Flex
                    direction={['column', 'column', 'row', 'row']}
                    justifyContent={'space-between'}
                    gap={['25px', '25px', '25px', '10%']}
                  >
                    {/* <ConnectedIDNumberInput
                      name="idNumber"
                      label="ID/Passport"
                      placeholder=""
                      bg="transparent"
                      mb={[4, 8]}
                    /> */}

                    <Flex w="100%" h="100%" alignItems={'center'} justifyContent="flex-end">
                      <Button variant={'dark'} isLoading={isSubmitting} type="submit" mt="15px">
                        Save changes
                      </Button>
                    </Flex>
                  </Flex>
                </Flex>
              </Form>
            )
          }}
        </Formik>
      </Flex>
    </>
  )
}

export default UserDetailsSettings
