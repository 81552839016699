import { Flex } from '@chakra-ui/react'
import React, { useContext } from 'react'

import SettingsNavigation from '../../../components/Settings/SettingsNavigation'
import USerBankSettings from '../../../components/Settings/USerBankSettings'
import UserDetailsSettings from '../../../components/Settings/UserDetailsSettings'
import UserProfilePictureSettings from '../../../components/Settings/USerProfilePictureSettings'
import { useAuthContext } from '../../../context/AuthProvider'
import SettingsProvider from '../../../context/SettingsContext'
import { SettingsContext } from '../../../context/SettingsContext'

const SettingsPage = (): JSX.Element => {
  const { activeSettingsTab } = useContext(SettingsContext)
  const { user } = useAuthContext()

  return (
    <SettingsProvider>
      <Flex
        direction={['column', 'column', 'column', 'row']}
        w="100%"
        alignItems="start"
        justifyContent={'center'}
        gap="25px"
      >
        <SettingsNavigation />

        <Flex
          direction={'column'}
          w="100%"
          bg="#ffff"
          rounded="6px"
          shadow={'md'}
          p="25px"
          gap="25px"
        >
          {activeSettingsTab === 'Account Settings' && (
            <Flex direction={'column'} gap="25px" w="full">
              <UserProfilePictureSettings userData={user?.info} />
              <UserDetailsSettings user={user?.info} />
            </Flex>
          )}

          {activeSettingsTab === 'Bank Settings' && (
            <Flex direction={'column'} gap="25px" w="full">
              <USerBankSettings />
            </Flex>
          )}
        </Flex>
      </Flex>
    </SettingsProvider>
  )
}

export default SettingsPage
