import React, { FC, useEffect, useState } from 'react'
import { Box, Center, Flex, Heading, Image, Spinner, Text } from '@chakra-ui/react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthProvider'

const SignUpVerificationPage: FC = () => {
  const { confirm } = useAuthContext()
  const [searchParams, setSearchParams] = useSearchParams()
  const [error, setError] = useState<string | null>(null)
  const [success, setSuccess] = useState<string | null>(null)
  const [isTest, setiSTest] = useState(false)
  const navigate = useNavigate()

  const email = searchParams.get('email')
  const code = searchParams.get('code')

  useEffect(() => {
    setError(null)
    setSuccess(null)

    const getSearchParams = async () => {
      const baseUrl = process.env.REACT_APP_ONBOARDING_URL
      const isProd = process.env.REACT_APP_STAGE?.toLowerCase() === 'prod'

      try {
        if (!baseUrl) {
          throw new Error('Base URL not found')
        }

        if (email && code) {
          const { success, error } = await confirm!(email as string, code as string)
          console.log('Success: ', success)
          console.log('Error: ', error)

          if (success) {
            setSuccess('Account has been successfully verified')
            setError(null)
            setTimeout(() => {
              window.location.href = `${baseUrl}/login?verified=true&email=${email}`
            }, 2000)
            return
          }

          if (error) {
            // Special handling for 'User cannot be confirmed. Current status is CONFIRMED'
            if (error.message === 'User cannot be confirmed. Current status is CONFIRMED') {
              setSuccess('Account has already been verified')
              setError(null)
              setTimeout(() => {
                window.location.href = `${baseUrl}/login?verified=true&email=${email}`
              }, 2000)
              return
            }

            if (error.message === 'Invalid verification code provided, please try again.') {
              setError(
                'Invalid token provided, please try again. You will be redirected in 3 seconds'
              )
            } else if (error.message === 'Attempt limit exceeded, please try after some time.') {
              setError(
                'Attempt limit exceeded, please try after some time. You will be redirected in 3 seconds'
              )
            } else if (error.message === 'An unknown error occurred') {
              setError('Something went wrong. You will be redirected in 3 seconds')
            }

            setTimeout(() => {
              window.location.href = `${baseUrl}/login?verified=false&email=${email || ''}`
            }, 3000)
            return
          }
        } else {
          setError('Invalid token provided, please try again. You will be redirected in 3 seconds')
          setSuccess(null)
          setTimeout(() => {
            window.location.href = `${baseUrl}/login?verified=false&email=${email || ''}`
          }, 3000)
        }
      } catch (e: unknown) {
        let errorMessage = ''
        if (typeof e === 'string') {
          errorMessage = e.toUpperCase()
        } else if (e instanceof Error) {
          errorMessage =
            e.message === 'Failed to fetch' ? 'Something went wrong. Please try again' : e.message
        }
        console.log('Err: ', errorMessage)
        setError(`${errorMessage} You will be redirected in 3 seconds`)
        setTimeout(() => {
          window.location.href = `${baseUrl}/login?verified=false&email=${email || ''}`
        }, 3000)
      }
    }
    if (!isTest) {
      getSearchParams()
    }
  }, [])
  return (
    <Flex
      w={'full'}
      minH="100vh"
      direction={'column'}
      gap={'50px'}
      alignItems={'center'}
      justifyContent="center"
      bgColor={'#F2F2F2'}
    >
      <Image
        src={'/brand/GoSolr_website-logo.gif'}
        // h={['200px', '350px', '350px', '500px']}
        w={['90%']}
        maxW={['280px', '350px', '400px', '500px']}
        position={'absolute'}
        top={0}
      />
      <Heading zIndex={10} mt={[25, 25, 25, 150]} w={'full'} textAlign={'center'}>
        Verifying account
      </Heading>
      <Spinner size="lg" />
      {error && (
        <Text w={'full'} textAlign={'center'}>
          {error}
        </Text>
      )}
      {success && (
        <Text w={'full'} textAlign={'center'}>
          {success}
        </Text>
      )}
    </Flex>
  )
}

export default SignUpVerificationPage
