import { Product } from '../../types'

export const findRecommendedOption = (
  productArr: Product[],
  electricityPhaseType: string,
  monthlySpend: string
) => {
  let recommendedOption = 'Medium'
  // console.log(monthlySpend)
  if (
    electricityPhaseType?.toLowerCase() === 'single' ||
    electricityPhaseType?.toLowerCase() === 'unknown'
  ) {
    if (monthlySpend.trim() === 'Below R1500' || monthlySpend.trim() === "I don't know")
      recommendedOption = 'Small'
    else if (monthlySpend.trim() === 'R1500-R3000' || monthlySpend.trim() === "I don't know")
      recommendedOption = 'Medium'
    if (monthlySpend.trim() === 'R3000-R5000') recommendedOption = 'Large'
    if (monthlySpend.trim() === 'R5000+') recommendedOption = 'Large'
  } else {
    if (monthlySpend.trim() === 'Below R1500' || monthlySpend.trim() === 'R1500-R3000')
      recommendedOption = 'Large 3phase'
    if (monthlySpend.trim() === 'R3000-R5000') recommendedOption = 'Large 3phase'
    if (monthlySpend.trim() === 'R5000+') recommendedOption = 'Extra large'
  }

  const recommendedOptionIndex = productArr.findIndex((subscription: any) => {
    return subscription.subscriptionOption.toLowerCase() === recommendedOption.toLowerCase()
  })

  return productArr[recommendedOptionIndex]
}

export const canSetSubscriptionOption = (o: Product, electricityPhaseType: string): boolean => {
  let canSetSunscription = false
  if (
    electricityPhaseType?.toLowerCase() === 'single' ||
    electricityPhaseType?.toLowerCase() === 'unknown'
  ) {
    if (
      o.productName?.toLowerCase().includes('small') ||
      o.productName?.toLowerCase().includes('medium') ||
      o.productName?.toLowerCase() === 'large'
    ) {
      canSetSunscription = true
    }
  } else {
    if (
      // o.productName?.toLowerCase().includes('small') ||
      o.productName?.toLowerCase().includes('medium') ||
      o.productName?.toLowerCase().includes('large') ||
      o.productName?.toLowerCase().includes('large 3phase') ||
      o.productName?.toLowerCase().includes('extra large')
    ) {
      canSetSunscription = true
    }
  }
  return canSetSunscription
}

export const getDescriptionDetails = (o: Product, electricityPhaseType: string): string => {
  let description = ''

  if (o.productName?.toLowerCase().includes('medium')) {
    description = `Perfect for households spending between R1500-R3000 
          on electricity per month.`
  }
  if (
    electricityPhaseType?.toLowerCase() === 'single' ||
    electricityPhaseType?.toLowerCase() === 'unknown'
  ) {
    if (o.productName?.toLowerCase().includes('large')) {
      description = `Perfect for households spending between R3000-R5000 and R5000+
            on electricity per month.`
    } else if (o.productName?.toLowerCase().includes('small')) {
      description = `Perfect for households spending below R1500
            on electricity per month.`
    }
  } else {
    if (o.productName?.toLowerCase().includes('large')) {
      description = `Perfect for households spending between R3000-R5000
            on electricity per month.`
    }
  }
  if (o.productName?.toLowerCase().includes('small')) {
    description = `Perfect for households spending below R1500
            on electricity per month.`
  }

  if (o.productName?.toLowerCase().includes('large 3phase')) {
    description = `Perfect for households with three phase electricity spending between R3000-R5000 on electricity per month.`
  }
  if (o.productName?.toLowerCase().includes('extra large')) {
    description = `Perfect for households with three phase electricity spending more than R5000+ on electricity per month.`
  }

  return description
}
