import { Flex } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import SelectCreditType from '../../../components/Onboarding/Dashboard/CreditType/SelectCreditType'
import { User, useGetUserQuery } from '../../../generated/graphql'

const CreditCheckPage = () => {
  const { recordId } = useParams()
  const { user } = useAuthContext()
  const { data, loading } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })
  const recentUserData = data?.getUser as User
  const [entityType, setEntityType] = useState<string>('private')

  useEffect(() => {
    if (recentUserData) {
      if (recentUserData?.entityType) {
        setEntityType(recentUserData.entityType)
      }
    }
  }, [recentUserData])

  return (
    <Flex w="100%" gap="50px" direction={'column'} alignItems={'center'} justifyItems={'right'}>
      <OnBoardingStepper />
      <SelectCreditType entityType={entityType} recordId={recordId as string} />
    </Flex>
  )
}

export default CreditCheckPage
