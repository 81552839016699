import React, { SetStateAction, useEffect, useRef, useState } from 'react'
import PropertiesHeroContainer from '../../../components/Properties/PropertiesHeroContainer'
import PropertiesApplicationProgressContainer from '../../../components/Properties/PropertiesApplicationProgressContainer'
import PropertiesListContainer from '../../../components/Properties/PropertiesListContainer'
import { Maybe, User, UserProperty } from '../../../generated/graphql'
import ApplicationProgressBar from '../../../components/ApplicationProgress'

type PropertiesPageType = {
  user?: User
}

const PropertiesPage: React.FC<PropertiesPageType> = ({ user }) => {
  const [userProperties, setUserProperties] = useState<Maybe<UserProperty>[]>([])
  const [activeSubCount, setActiveSubCount] = useState<number>(0)
  const [inProgressCount, setInProgressSubCount] = useState<number>(0)
  const [apllicationsInProgress, setApplicationsInProgress] = useState<Maybe<UserProperty>[]>([])
  let effectRan = useRef<boolean>(false)

  useEffect(() => {
    // console.log(user?.onboardingProgressCompletedStep)
    if (!effectRan.current) {
      const properties = user?.properties
      properties ? setUserProperties(properties) : setUserProperties([])
      if (properties) {
        getActiveSubscriptionCount(properties)
      }
      effectRan.current = true
    }
  }, [])

  const getActiveSubscriptionCount = (properties: Maybe<UserProperty>[]) => {
    let propertyActiveCount = 0
    let propertyInProgressCount = 0

    properties?.forEach((property) => {
      if (property?.active && property?.installationComplete) {
        propertyActiveCount += 1
      }
      if (!property?.active && !property?.installationComplete) {
        propertyInProgressCount += 1
        setApplicationsInProgress((current) => [...current, property])
      }

      setActiveSubCount(propertyActiveCount)
      setInProgressSubCount(propertyInProgressCount)
    })
  }

  return (
    <>

      <PropertiesHeroContainer activeSubCount={activeSubCount} inProgressCount={inProgressCount} />
      {apllicationsInProgress &&
        apllicationsInProgress.map((property, index) => (
          <ApplicationProgressBar
            property={property as UserProperty}
            key={`${property?.propertyLat}-${index}-${property?.recordId}`}
            onboardingStepsCompleted={
              user?.onboardingProgressCompletedStep
                ? Number(user?.onboardingProgressCompletedStep)
                : 1
            }
          />
        ))}

      <PropertiesListContainer
        userProperties={userProperties as UserProperty[]}
        onboardingStepsCompleted={user?.onboardingProgressCompletedStep}
      />
    </>
  )
}

export default PropertiesPage
