import React, { FC, useState } from 'react'
import { Flex, Text, Button, Heading } from '@chakra-ui/react'
import { ButtonPill } from '../Buttons'

export type SupportContactMethodsProps = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
}

const SupportContactMethods: FC<SupportContactMethodsProps> = ({ setFieldValue }) => {
  const [activeContactMethod, setAcctiveContactMethod] = useState('Email')
  const contactMethods = ['Email', 'Phone']

  const handleSetContactMethod = (event: React.MouseEvent<HTMLElement>) => {
    const target = event.target as HTMLInputElement
    setFieldValue('preferredContactMethod', target?.name)
    setAcctiveContactMethod(target?.name)
  }

  return (
    <Flex direction={'column'} w="100%" gap={'15px'}>
      <Heading as={'h5'} fontSize={'16px'} fontWeight={'semibold'} color="brand.text.primary">
        Preferred contact method
      </Heading>
      <Flex flexWrap={'wrap'} gap={'10px'}>
        {contactMethods.map((item, index) => (
          <ButtonPill
            key={`${item}-${index}`}
            title={item}
            isActive={activeContactMethod === item}
            onClick={(e) => handleSetContactMethod(e)}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default SupportContactMethods
