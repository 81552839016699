import React, { useEffect, useState } from 'react'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import { BiCoin } from 'react-icons/bi'
import { BsCalendar3, BsHouseDown, BsPlusCircle } from 'react-icons/bs'
import { DateTime } from 'luxon'
import { PropertyInvoiceType, TransactionTypeEnum } from '../../types'

export type transactionItemType = {
  type: TransactionTypeEnum
  date: DateTime
  amount: number
  success: boolean
}
export type TransactionType = {
  item: PropertyInvoiceType
  setSelectedInv: React.Dispatch<React.SetStateAction<PropertyInvoiceType | null>>
  setIsInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const TransactionItem: React.FC<TransactionType> = ({
  item,
  setIsInvoiceModalOpen,
  setSelectedInv
}) => {
  const { invoiceNumber, date: invoiceDate, status, type } = item as PropertyInvoiceType
  const [paymentType, setPaymentType] = useState<string>('')

  const date = new Date(invoiceDate)

  const invMonth = date.toLocaleString('default', { month: 'short' })
  const invYear = date.getFullYear()

  useEffect(() => {
    if (type?.includes('Admin')) {
      setPaymentType('Admin Fee')
    }
    if (type?.includes('Flat') || type?.includes('roof')) {
      setPaymentType('Flat roof surcharge')
    }
    if (type?.includes('Fixed') || type?.includes('fixed') || type?.includes('monthly')) {
      setPaymentType('Fixed monthly fee')
    }
  }, [])

  return (
    <>
      <Flex
        direction={'row'}
        w="full"
        justifyContent={'space-between'}
        alignItems={'center'}
        border={'1px solid #686868'}
        rounded={'4px'}
        p={'8px'}
        userSelect="none"
      >
        <Flex direction={'row'} justifyContent={'space-between'} alignItems={'center'} gap={'10px'}>
          <Flex
            w={'45px'}
            h={'45px'}
            p={'2px'}
            shadow={'md'}
            alignItems="center"
            justifyContent={'center'}
            bgColor={'brand.primary'}
            rounded="8px"
          >
            {type === 'de_installation' && <BsHouseDown size={25} />}
            {type === 'upgrade' && <BsPlusCircle size={25} />}
            {type === 'subscription' && <BsCalendar3 size={25} />}
            {type === 'admin_fee' && <BiCoin size={25} />}
          </Flex>
          <Flex direction={'column'}>
            <Text fontWeight={700} fontSize={'sm'} color={'brand.text.primary'}>
              {paymentType}
            </Text>
            <Flex gap={'5px'} fontSize={'xs'} direction={'column'}>
              <Text fontWeight={'bold'}>
                {invMonth} {invYear}
              </Text>

              <Text>#{invoiceNumber}</Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex direction={'row'} alignItems="center" justifyContent={'center'}>
          <Box rounded={'full'} px={'8px'} py={'2px'} bgColor={'brand.secondary'}>
            <Text color={'brand.text.yellow'} fontSize={'xs'}>
              {status}
            </Text>
          </Box>
          <Button
            onClick={() => {
              setSelectedInv(item)
              setIsInvoiceModalOpen(true)
            }}
            variant={'dark'}
            size={'sm'}
            ml={'10px'}
          >
            view
          </Button>
        </Flex>
      </Flex>
    </>
  )
}

export default TransactionItem
