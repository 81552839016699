import { Flex, Link, Button, Box, Heading, RadioGroup, Stack, Radio, Text } from '@chakra-ui/react'
import { Form, Formik } from 'formik'

import React, { FC, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { setPriority } from 'os'
import BackButton from '../../../FormElements/BackButton'
import { FiArrowLeft } from 'react-icons/fi'
import { useUpdateDynamoUserMutation, useUpdateUserMutation } from '../../../../generated/graphql'
import { useAuthContext } from '../../../../context/AuthProvider'
import CreditCheckConscentModal from './CreditCheckConsentModal'
import { CREDIT_CHECK_CONSENT_OBJECTS } from '../../../../constants'
import { ConsentMessageItemType } from '../../../../types'
import AreYouSureModal from './AreYouSureModal'
import { ContainerHeading } from '../../../Headings'

type Props = {
  recordId: string
  entityType: string | ''
}

const SelectCreditType: FC<Props> = (inputProps) => {
  const recordId = inputProps.recordId
  const entityType = inputProps.entityType
  const [showConscentModal, setShowConscentModal] = React.useState<boolean>(false)
  const [showAreYouSureModal, setShowAreYouSureModal] = React.useState<boolean>(false)
  const [conscentMessage, setConscentMessage] = React.useState<ConsentMessageItemType>(
    CREDIT_CHECK_CONSENT_OBJECTS[0]
  )
  const [route, setRoute] = React.useState<string>(`/user/onboarding/credit-check/${recordId}`)
  const [value, setValue] = React.useState('private') //
  const [updateUser] = useUpdateUserMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const { user } = useAuthContext()
  const navigation = useNavigate()

  const handleCloseModal = () => {
    setShowConscentModal(false)
    setShowAreYouSureModal(true)
  }

  const handleNext = async () => {
    setShowConscentModal(true)
    const selectedTypeMessage = CREDIT_CHECK_CONSENT_OBJECTS.find((item) => item.type === value)
    selectedTypeMessage && setConscentMessage(selectedTypeMessage)

    if (value === 'business' || value === 'trust') {
      setRoute(`/user/onboarding/business-credit-check/${recordId}`)
    }
    await updateDynamoUser({
      variables: {
        email: user?.info?.email as string,
        input: {
          onboardingProgressCompletedStep: 6
        }
      }
    })
    await updateUser({
      variables: {
        email: user?.info?.email as string,
        input: {
          recordId,
          entityType: value
        }
      }
    })
  }

  useEffect(() => {
    setValue(entityType)
  }, [entityType])

  return (
    <Flex direction={'column'} gap={'1em'}>
      <CreditCheckConscentModal
        isOpen={showConscentModal}
        onClose={handleCloseModal}
        recordId={recordId}
        consentItem={conscentMessage}
        route={route}
        email={user?.info?.email as string}
        entityType={value}
      />
      <AreYouSureModal isOpen={showAreYouSureModal} onClose={() => setShowAreYouSureModal(false)} />

      <Flex
        direction={'column'}
        gap="25px"
        bgColor={'brand.neutral'}
        p={25}
        rounded={'8px'}
        w="100%"
        h={'full'}
        // justifyContent={'space-between'}
        shadow={'md'}
      >
        <ContainerHeading title="Credit Check" />

        <Text maxWidth={900} noOfLines={[3, 4, 5]}>
          Our solutions are installed at our customers’ properties, at GoSolr’s cost. 
          We do a credit check of all our customers. This is done through a third party service provider.
        </Text>

        <RadioGroup onChange={setValue} value={value} gap={3} variant={'brand-yellow'}>
          <Stack direction="column" alignSelf="right">
            <Radio size="md" value="private">
              Private - This is for a private property
            </Radio>
            <Radio size="md" value="business">
              Business - This is for a business property
            </Radio>
            {/* <Radio size="md" value="trust" isDisabled={true}>
              Trust - This is for a trust owned property
            </Radio> */}
          </Stack>
        </RadioGroup>

        <Flex width={'full'} justifyContent={'space-between'}>
          <Button
            onClick={() => navigation(`/user/onboarding/checkout-summary/${recordId}`)}
            variant={'none'}
            bg="brand.neutral"
            color="brand.text.primary"
            _active={{ transform: 'scale(0.95)' }}
            type={'button'}
          >
            <Flex alignItems={'center'}>
              <FiArrowLeft />
              <Text pl={'2px'} _groupHover={{ pl: '5px' }} transition={'padding 0.2s ease-in-out'}>
                Back
              </Text>
            </Flex>
          </Button>
          <Button onClick={handleNext} variant={'dark'}>
            Next
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SelectCreditType


