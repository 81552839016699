import { Flex } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import AgreementInfo from '../../../components/Onboarding/Dashboard/Agreement/AgreementInfo'
import DocumentSigning from '../../../components/Onboarding/Dashboard/Agreement/DocumentSigning'
import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  Subscription,
  useGetSingleSubscriptionsQuery,
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import {
  buyoutFees as productProductFees,
  upgradeCosts,
  UpgradeFeesType
} from '../../../data/buyoutFees'
import { AgreementDataType } from '../../../types'
import { ordinal } from '../../../utils'
interface InputValue {
  [key: string]: Object | Object[]
}
const AgreementPage = () => {
  const { recordId } = useParams()
  const [reftechData, setRefetch] = useState<boolean>(false)
  const { user } = useAuthContext()
  const [loadingData, setLoadingData] = useState(true)
  const [entityType, setEntityType] = useState<string>('')

  const [upgradeFees, setUpgradeFees] = useState<UpgradeFeesType | undefined>(() => ({
    from: '',
    upgradeTo: '',
    flatRoofFee: '',
    battery: 'R690',
    panels: 'R210',
    upgrade: '',
    upgrade_plus_battery: '',
    upgrade_plus_panels: '',
    upgrade_plus_battery_plus_panels: '',
    upgrade_plus_battery_plus_four_panels: ''
  }))

  const handleRefethUserData = () => {
    setRefetch((prev) => !prev)
  }

  const {
    data: userData,
    loading: userDataLoading,
    refetch: getUserData
  } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = userData?.getUser as User

  const { data: propertyData, loading } = useGetUserPropertyQuery({
    variables: {
      recordId: recordId as string
    }
  })

  const property = propertyData?.getUserProperty as UserProperty
  // console.log(property)
  let subscriptionAgreementData = useRef<AgreementDataType>({})

  const getCurrentTimeData = () => {
    const currentDate = new Date()
    const nameOfMonth = new Intl.DateTimeFormat('en-US', { month: 'long' }).format(new Date())

    let currDay: number = currentDate.getDate()
    let signedDay = ordinal(currDay)

    const signedDate = {
      signedAt: 'GO SOLR Web App',
      signedDay: signedDay,
      signedMonthAndYear: `${nameOfMonth} ${currentDate.getFullYear()}`,
      timestamp: currentDate.toLocaleTimeString()
    }

    return signedDate
  }

  useEffect(() => {
    getCurrentTimeData()
    // console.log(user?.info)
    // console.log(recentUserData)
    const loadData = async () => {
      setLoadingData(true)
      if (recentUserData) {
        const allProperties = (
          (recentUserData.properties?.length && (recentUserData.properties as UserProperty[])) ||
          []
        )
          .slice()
          .sort((a: any, b: any) => {
            return Number(b.recordId) - Number(a.recordId)
          })
        if (recentUserData?.entityType) {
          if (recentUserData?.entityType === 'trust') {
            setEntityType('private')
          } else setEntityType(recentUserData?.entityType)
        }

        // console.log(recentUserData)
        const subscriptions = allProperties[0]
        const selectedSubscription = allProperties[0].subscription?.productName as string

        if (selectedSubscription?.toLowerCase().includes('medium')) {
          // console.log('Upgrade fees for medium')
          const upgradeCostObj = {
            ...upgradeCosts?.meduimToLarge,
            from: selectedSubscription
          }
          setUpgradeFees(upgradeCostObj as UpgradeFeesType)
        }
        if (
          selectedSubscription?.toLowerCase().includes('large 3phase') ||
          selectedSubscription?.toLowerCase().includes('large 3-phase')
        ) {
          // console.log('Upgrade fees for Large 3 phase')
          const upgradeCostObj = {
            ...upgradeCosts?.largeThreePhaseToExtraLarge,
            from: selectedSubscription
          }
          setUpgradeFees(upgradeCostObj as UpgradeFeesType)
        }

        setLoadingData(false)
      } else {
        await getUserData()
      }
    }
    loadData()
  }, [recentUserData, propertyData, userData, reftechData])

  useEffect(() => {
    // console.log('Recent USer Data: ', recentUserData)
    // console.log('Property Data: ', property)

    if (recentUserData && property) {
      const allProperties = (
        (recentUserData.properties?.length && (recentUserData.properties as UserProperty[])) ||
        []
      )
        .slice()
        .sort((a: any, b: any) => {
          return Number(b.recordId) - Number(a.recordId)
        })

      // console.log(recentUserData)
      const property: UserProperty = allProperties[0]
      const selectedSubscription = allProperties[0].subscription?.productName as string
      // console.log(selectedSubscription)
      const signedDateAndPlace = getCurrentTimeData()

      let upgradeFees: UpgradeFeesType = {
        from: '',
        upgradeTo: '',
        flatRoofFee: '',
        battery: 'R690',
        panels: 'R230',
        upgrade: '',
        upgrade_plus_battery: '',
        upgrade_plus_panels: '',
        upgrade_plus_battery_plus_panels: '',
        upgrade_plus_battery_plus_four_panels: ''
      }

      if (selectedSubscription?.toLowerCase().includes('medium')) {
        // console.log('Upgrade fees for medium')
        const upgradeCostObj = {
          ...upgradeCosts?.meduimToLarge,
          from: selectedSubscription
        }

        upgradeFees = upgradeCostObj as UpgradeFeesType
      }
      if (selectedSubscription?.toLowerCase().includes('large 3phase')) {
        // console.log('Upgrade fees for Large 3 phase')
        const upgradeCostObj = {
          ...upgradeCosts?.largeThreePhaseToExtraLarge,
          from: selectedSubscription
        }
        upgradeFees = upgradeCostObj as UpgradeFeesType
      }

      const productFees = productProductFees.find((item) => {
        if (selectedSubscription) {
          return item.product.toLowerCase() === (selectedSubscription.toLowerCase() as string)
        } else {
          return item.product.toLowerCase() === selectedSubscription
        }
      })
      let solarSystemInstalledCapacity = ''

      // Calculate SolarSystem installed Capacity
      if (property?.subscription?.panelCount) {
        const numberOfPanels: number = parseInt(property?.subscription?.panelCount) as number
        const installedCapacity = numberOfPanels * 455
        solarSystemInstalledCapacity = `${installedCapacity}Wp`
      }

      const data: AgreementDataType = {
        seller: {
          name: 'Go Solr Pty Ltd',
          registrationNumber: '2021/912191/07',
          registeredAddress: '1 Sturdee Ave, Rosebank, Johannesburg, 2196',
          email: 'hello@gosolr.co.za'
        },
        customer: {
          name: recentUserData?.firstName + ' ' + recentUserData?.lastName,
          idNumber: recentUserData?.idPassportNumber || '',
          contactPerson: recentUserData?.firstName + ' ' + recentUserData?.lastName,
          address: property?.fullAddress,
          telNo: recentUserData?.contactNumber,
          email: recentUserData?.email,
          customerCode: recentUserData?.customerNumber,
          ownership: property?.customerType,
          premisesOwner: recentUserData?.firstName + ' ' + recentUserData?.lastName
        },
        subscriptionName: selectedSubscription?.toLowerCase()?.replace('2023_', ''),
        adminFee: property?.subscription?.onceOffFee,
        additionalFlatRoofFee: property?.subscription?.flatRoofFee,
        upgradeFees: {
          upgradeFrom: upgradeFees?.from?.toLowerCase()?.replace('2023_', ''),
          upgradeTo: upgradeFees?.upgradeTo,
          flatRoofFee: upgradeFees?.flatRoofFee,
          battery: upgradeFees?.battery,
          panels: upgradeFees?.panels,
          upgrade: upgradeFees?.upgrade,
          upgrade_plus_battery: upgradeFees?.upgrade_plus_battery,
          upgrade_plus_panels: upgradeFees?.upgrade_plus_panels,
          upgrade_plus_battery_plus_panels: upgradeFees?.upgrade_plus_battery_plus_panels,
          upgrade_plus_battery_plus_four_panels: upgradeFees?.upgrade_plus_battery_plus_four_panels
        },
        fixedMonthlyFee: property?.subscription?.subscriptionFee,
        // todo Remove the halving after 3 years
        deInstallationFee: property?.subscription?.de_installation_fee_after_year_three as number,
        reducedDeInstallationFee: productFees?.de_installation_fee_then,
        callOutFee: 650,
        annualEscalationYear: 2024,
        sellerBank: {
          bank: 'Standard Bank',
          branchCode: '051001',
          electronicAccNumber: '281046581'
        },
        customerBank: {
          customerName: property?.memberName,
          bank: property?.bankName,
          accNumber: property?.bankAccountNumber,
          accType: property?.bankAccountType,
          branchCode: property?.branchCode,
          debitOrderDate: recentUserData?.debitOrderDate
        },
        // todo update to use 2023 pricing
        purchasePrices: {
          one: '-',
          two: '-',
          three: property?.subscription?.buyout_year_three as number,
          four: property?.subscription?.buyout_year_four as number,
          five: property?.subscription?.buyout_year_five as number,
          six: property?.subscription?.buyout_year_six as number,
          seven: property?.subscription?.buyout_year_seven as number,
          eight: 'As notified by the Seller to the Customer'
        },
        solarSystemSpecs: {
          location: 'See Schedule 3',
          capacity: solarSystemInstalledCapacity,
          expectedStructure: 'Roof mount',
          additionalMaterials:
            'Aluminium A-Frames as required if a flat-roof installation is deemed necessary by the Seller',
          premisesAndLayout: 'See Schedule 3'
        },
        batterySpecs: {
          location: 'See Schedule 3',
          capacity: property?.subscription?.batterySpec,
          description: property?.subscription?.batterySpec,
          premisesAndLayout: 'See Schedule 3'
        },
        inverterSpecs: {
          location: 'See Schedule 3',
          description: property?.subscription?.inverterSpec,
          premisesAndLayout: 'See Schedule 3'
        },
        ...signedDateAndPlace
      }
      subscriptionAgreementData.current = data
    }
  }, [recentUserData, property, upgradeFees, reftechData])

  const [inputValue, setInputValue] = useState<InputValue>({
    subscriptionBox: false,
    installationFee: false,
    monthlyFee: false,
    annualEscalation: false,
    otherFees: false,
    termination: false,
    purchaseOfSystem: false,
    insurance: false,
    technicalAcknowledgment: false
  })

  return (
    <Flex
      w="100%"
      gap="50px"
      direction={['column', 'column', 'column', 'column']}
      alignItems={'center'}
    >
      <OnBoardingStepper />
      <Flex w="full" gap="25px" direction={'column'} p={'25px'} rounded="8px">
        <AgreementInfo
          recordId={recordId as string}
          user={user}
          inputValue={inputValue}
          subscriptionAgreementData={subscriptionAgreementData.current}
          setInputValue={setInputValue}
        />

        {property && (
          <DocumentSigning
            recordId={recordId as string}
            orderNumber={+property?.orderNumber!}
            subscriptionName={property?.subscription?.productName as string}
            user={user}
            inputValue={inputValue}
            setInputValue={setInputValue}
            subscriptionAgreementData={subscriptionAgreementData.current}
            handleRefethUserData={handleRefethUserData}
            entityType={entityType}
          />
        )}
      </Flex>
    </Flex>
  )
}

export default AgreementPage
