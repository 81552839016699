import { useCallback, useContext, useEffect, useState } from 'react'
import { OnboardingContainer } from '../../../containers/Global'
import { Form, Formik, FormikState, FormikValues, useFormikContext } from 'formik'
import { OnboardingFormContext, FormData } from '../../../../context/intro/OnboardingContext'
import { Button, Flex, Text, useMediaQuery } from '@chakra-ui/react'
import RoofTypeRadioGroup from '../../../FormElements/RoofTypeRadioGroup'
import DropDownInput from '../../../FormElements/DropDownInput'
import {
  DWELLING_TYPES,
  ROOF_ANGLE_OPTIONS,
  ROOF_TYPE_DROPDOWN_OPTIONS
} from '../../../../constants'
import GoogleRoofMap from '../../../FormElements/GoogleMapInput/GoogleRoofMap'
import BackButton from '../../../FormElements/BackButton'

import ConnectedFormGroup from '../../../FormElements/ConnectedFormGroup'
import { DwellingType, RoofType } from '../../../../types'
import { RoofTypeValidation } from '../../../../formValidation/preSignUpFlow'
import UnsupportedRoofTypeModal from './UnsupportedRoofTypeModal'
import FlatRoofModal from './FlatRoofModal'
import UnsupportedDwellingTypeModal from './UnsupportedDwellingTypeModal'

type RoofTypeInitialValuesProps = {
  roofType: RoofType
  dwellingType: DwellingType
  complexBuildingName: string
  unitNumber: string
  roofLocationConfirmed: boolean
  isRoofFlat: string
}

function PropertyRoof() {
  const [showRoofTypeOptions, setShowRoofTypeOptions] = useState<boolean>(false)
  const { formData, handleSetFormData, handleNextStepIndex, handlePrevStepIndex } =
    useContext(OnboardingFormContext)

  const mediaQuery = useMediaQuery('(max-width: 768px)')
  const isMobile = mediaQuery[0]

  const initialValues = {
    roofType: formData?.roofType,
    dwellingType: formData.dwellingType,
    complexBuildingName: formData.complexBuildingName || '',
    unitNumber: formData.unitNumber || '',
    roofLocationConfirmed: formData.roofLocationConfirmed,
    isRoofFlat: formData.isRoofFlat || ''
  }

  const handleSubmit = async (values: RoofTypeInitialValuesProps) => {
    const { roofType, dwellingType, complexBuildingName, unitNumber, isRoofFlat } = values

    let isRoofFlatType = false

    if (isRoofFlat === 'Yes') {
      isRoofFlatType = true
    }

    const data: FormData = {
      ...formData,
      roofType,
      isRoofFlat,
      dwellingType,
      complexBuildingName,
      unitNumber
    }

    if (data) {
      handleSetFormData(data)
      await localStorage.setItem('onboarding_property_data', JSON.stringify(data))
      handleNextStepIndex()
    }
  }

  return (
    <OnboardingContainer
      height={'100%'}
      maxH={'100%'}
      overflow="visible"
      gap={['15px', '25px', '25px', '25px']}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={RoofTypeValidation}
        validateOnBlur={true}
        validateOnChange={true}
        onSubmit={(values) => handleSubmit(values)}
      >
        {({ errors, values, isSubmitting, isValid, setFieldValue }) => {
          return (
            <>
              <Form style={{ height: '100%' }}>
                <GoogleRoofMap
                  setFieldValue={setFieldValue}
                  setShowRoofTypeOptions={setShowRoofTypeOptions}
                />
                {showRoofTypeOptions && (
                  <>
                    <RoofTypeObserver />
                    <DwellingTypeObserver />
                    <RoofAngleObserver />
                    <Flex direction={'column'} w="full" gap={['15px', '25px', '25px', '25px']}>
                      {!isMobile && (
                        <Flex width={'100%'} direction={'column'} mt={'25px'}>
                          <RoofTypeRadioGroup name="roofType" />
                          {errors.roofType && (
                            <Text fontSize={'small'} color={'brand.text.danger'}>
                              Asbestos and Thatched roofs are not accepted by the department of
                              labour. Please choose an alternative roof type if available.
                            </Text>
                          )}
                        </Flex>
                      )}
                      {isMobile && (
                        <Flex width={'100%'} direction={'column'} mt={'25px'}>
                          <DropDownInput
                            name="roofType"
                            label="Select your roof type:"
                            options={ROOF_TYPE_DROPDOWN_OPTIONS}
                            placeholder={'Select your roof type'}
                          />
                          {errors.roofType && (
                            <Text fontSize={'small'} color={'brand.text.danger'}>
                              Asbestos and Thatched roofs are not accepted by the department of
                              labour. Please choose an alternative roof type if available.
                            </Text>
                          )}
                        </Flex>
                      )}

                      <DropDownInput
                        name="isRoofFlat"
                        label="Is your roof flat?"
                        options={ROOF_ANGLE_OPTIONS}
                        placeholder={'Yes / No'}
                      />
                      <DropDownInput
                        name="dwellingType"
                        label="Dwelling type"
                        options={DWELLING_TYPES}
                        placeholder={'Select dwelling type'}
                      />

                      {values.dwellingType === 'Apartment block' && (
                        <Text fontSize={'small'} color={'brand.text.danger'}>
                          Currently we do not support solar installation on apartment blocks.
                        </Text>
                      )}

                      <Flex
                        gap={'25px'}
                        hidden={
                          formData.dwellingType === 'Free standing house' ||
                          formData.dwellingType === '' ||
                          formData.dwellingType === 'Apartment block'
                        }
                      >
                        <ConnectedFormGroup
                          name="complexBuildingName"
                          label="Complex/Building name"
                          placeholder="Complex/Building name"
                          handleChange={(value?: string) =>
                            setFieldValue('complexBuildingName', value?.toString())
                          }
                        />
                        <ConnectedFormGroup
                          name="unitNumber"
                          label="Unit number"
                          placeholder="Unit number"
                          handleChange={(value?: string) =>
                            setFieldValue('unitNumber', value?.toString())
                          }
                        />
                      </Flex>
                    </Flex>
                  </>
                )}
                <Flex justifyContent={'space-between'} mt={'25px'}>
                  <BackButton
                    callback={() => {
                      handlePrevStepIndex()
                    }}
                  />

                  <Button
                    type="submit"
                    variant={'dark'}
                    isLoading={isSubmitting}
                    disabled={!isValid}
                  >
                    Next
                  </Button>
                </Flex>
              </Form>
            </>
          )
        }}
      </Formik>
    </OnboardingContainer>
  )
}

export default PropertyRoof

function RoofAngleObserver() {
  const { values }: FormikState<FormikValues> = useFormikContext()
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false)
  useEffect(() => {
    if (values.isRoofFlat === 'Yes' && values.roofType === 'Corrugated') {
      setmodalIsOpen(true)
    }
  }, [values.isRoofFlat, values.roofType])
  return (
    <FlatRoofModal
      isOpen={modalIsOpen}
      onClose={() => {
        setmodalIsOpen(false)
      }}
      heading={'Please note'}
    />
  )
}

function RoofTypeObserver() {
  const { values }: FormikState<FormikValues> = useFormikContext()
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (values.roofType === 'Thatched' || values.roofType === 'Asbestos') {
      setmodalIsOpen(true)
    }
  }, [values.roofType, values.customerType])

  return (
    <UnsupportedRoofTypeModal
      isOpen={modalIsOpen}
      onClose={() => {
        setmodalIsOpen(false)
      }}
      heading={'Roof Type Is Not Supported'}
      roofType={values.roofType.toLowerCase()}
    />
  )
}

function DwellingTypeObserver() {
  const { values }: FormikState<FormikValues> = useFormikContext()
  const { setFieldValue, setFieldTouched } = useFormikContext<FormikState<FormikValues>>()
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false)

  const handleSetAddress = useCallback(() => {
    setFieldValue('complexBuildingName', '')
    setTimeout(() => setFieldTouched('complexBuildingName', true))
  }, [])
  const handleUnitReset = useCallback(() => {
    setFieldValue('unitNumber', '')
    setTimeout(() => setFieldTouched('complexBuildingName', true))
  }, [])

  useEffect(() => {
    if (values.dwellingType === 'Apartment block') {
      setmodalIsOpen(true)
    }
    if (values.dwellingType === 'Free standing house') {
      handleUnitReset()
      handleSetAddress()
    }
  }, [values.dwellingType])

  return (
    <UnsupportedDwellingTypeModal
      isOpen={modalIsOpen}
      onClose={() => {
        setmodalIsOpen(false)
      }}
      heading={'Dwelling Type Is Not Supported'}
      dwellingType={values.dwellingType.toLowerCase()}
    />
  )
}
