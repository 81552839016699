import React, { FC, useContext } from 'react'
import { Flex, Image, Heading, Text, Box } from '@chakra-ui/react'
import { OnboardingFormContext } from '../../../context/intro/OnboardingContext'
import { backgroundColor } from 'styled-system'

export type HeroContainerType = {
  imageUrl?: string
  isMobile?: boolean
}
const OnboardingHeroContainer: FC<HeroContainerType> = ({
  imageUrl = '/images/gosolr-jump.png',
  isMobile
}) => {
  const { activeStepName } = useContext(OnboardingFormContext)
  const sunGif = '/brand/GoSolr_website-logo.gif'
  const solarHouseURL = '/images/gosolr-house.png'

  if (isMobile === true) return null
  return (
    <Flex
      backgroundColor={'#000'}
      w="full"
      alignItems={'center'}
      justifyItems={'center'}
      direction={'column'}
    >
      <Flex
        bg={'#FFD200'}
        w="full"
        h={'100%'}
        alignItems={'center'}
        justifyItems={'center'}
        direction={'column'}
        position={'relative'}
        zIndex={0}
      >
        <Image minH={'600px'} maxH={'600px'} objectFit={'cover'} src={sunGif} />
        <Flex
          flex={1}
          direction={'column'}
          justifyContent={'center'}
          alignItems={'center'}
          gap={25}
          px={25}
          position={'absolute'}
          top={'350px'}
          zIndex={3}
        >
          <Text fontSize={'6xl'} textAlign={'left'} w="full">
            <span style={{ fontWeight: 'bolder' }}>
              Rays your <br /> savings
            </span>
          </Text>
          <Text fontSize={'3xl'} textAlign={'left'} w="full">
            SA's most affordable solar solution{' '}
            <span style={{ fontWeight: 900 }}> from just R1399</span>
          </Text>
        </Flex>
        <Flex
          w="full"
          direction={'column'}
          pos={'relative'}
          flex={1}
          zIndex={2}
          overflow={'hidden'}
        >
          <Box w={'full'} backgroundColor={'#000'} height={'full'}>
            <svg
              id="svg"
              viewBox="0 0 1440 500"
              xmlns="http://www.w3.org/2000/svg"
              style={{ zIndex: 0 }}
            >
              <path
                d="M 0,700 C 0,700 0,350 0,350 C 246.5,304.5 493,259 733,259 C 973,259 1206.5,304.5 1440,350 C 1440,350 1440,700 1440,700 Z"
                stroke="none"
                style={{ zIndex: 0 }}
                strokeWidth="0"
                fill="#ffd200"
                fillOpacity="1"
                transform="rotate(-180 720 350)"
              ></path>
            </svg>
            <Image
              zIndex={2}
              position={'absolute'}
              bottom={-2}
              w={'100%'}
              maxH={'300px'}
              objectFit={'contain'}
              style={{
                transform: 'scaleX(-1)'
              }}
              src={solarHouseURL}
            />
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default OnboardingHeroContainer
