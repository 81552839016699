import React, { Dispatch, FC, SetStateAction, useEffect, useState } from 'react'
import { Box, Center, Flex, Spinner, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  Municipality,
  useGetMunicpalitiesQuery,
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { ordinal } from '../../../utils'
import { useParams } from 'react-router-dom'

type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const ComplianceAgreementPage = ({ setTitle }: Props): JSX.Element => {
  const { recordId } = useParams()
  const { user } = useAuthContext()
  const { data: propertyData, loading: loadingProperties } = useGetUserPropertyQuery({
    variables: {
      recordId: recordId!
    }
  })
  const property = propertyData?.getUserProperty as UserProperty
  const [subscribedProperties, setSubscribedProperties] = useState<UserProperty[]>([])
  const [mostRecentProperty, setMostRecentProperty] = useState<UserProperty>()
  const [loadingData, setLoadingData] = useState(true)
  const [requiredDocsReceived, setRequiredDocsReceived] = useState('No')
  const [municipalities, setMunicipailites] = useState<Municipality[]>([])
  const [signedDateAndPlace, setSignedDateAndPlace] = useState(() => ({
    signedAt: 'GO SOLR Web App',
    signedDay: '',
    signedMonthAndYear: '',
    timestamp: ''
  }))

  const { data: municipalityData } = useGetMunicpalitiesQuery()
  const { data: userData } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = userData?.getUser as User

  useEffect(() => {
    if (municipalityData?.getMunicipalities?.__typename === 'Municipalities') {
      const municipalitiesArr = municipalityData?.getMunicipalities?.municipalities
      if (municipalitiesArr) setMunicipailites(municipalitiesArr as Municipality[])
    }
    const getCurrentTimeData = async () => {
      const currentDate = await new Date()
      const nameOfMonth = await new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date()
      )

      let currDay: number = currentDate.getDate()
      let signedDay = ordinal(currDay)

      const signedDate = {
        signedAt: 'GO SOLR Web App',
        signedDay: signedDay,
        signedMonthAndYear: `${nameOfMonth} ${currentDate.getFullYear()}`,
        timestamp: currentDate.toLocaleTimeString()
      }

      setSignedDateAndPlace(signedDate)
    }
    getCurrentTimeData()
    const loadData = async () => {
      if (recentUserData) {
        const allProperties = (
          (recentUserData.properties?.length && (recentUserData.properties as UserProperty[])) ||
          []
        )
          .slice()
          .sort((a: any, b: any) => {
            return Number(b.recordId) - Number(a.recordId)
          })

        const subscriptions = allProperties.filter((p: any) => p.installationProcessInitiated)

        await setMostRecentProperty(allProperties[0])
        await setSubscribedProperties(subscriptions)

        if (property?.proofOfAddressS3Key && user?.info.idPassportDocumentS3Key) {
          await setRequiredDocsReceived('Yes')
        } else {
          await setRequiredDocsReceived('No')
        }

        setLoadingData(false)
        // console.log(recentUserData.properties)
      }
    }
    loadData()
  }, [recentUserData, propertyData, userData])

  useEffect(() => {
    setTitle(`Compliance acknowledgement`)
  }, [])

  const data = {
    accHolderName: user?.info.firstName + ' ' + user?.info.lastName,
    accID: recentUserData?.idPassportNumber,
    contractReferenceNumber: property?.recordId,
    ...signedDateAndPlace
  }

  if (loadingData || loadingProperties) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Generating your compliance acknowledgemnt document</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex w="100%" gap="25px" direction={'column'} bgColor="brand.neutral" p="25px" rounded="8px">
        {/* User Details */}
        <Text fontWeight={'bold'}>Compliance Acknowledgement:</Text>
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          Eskom and municipalities have different requirements and limitations when it comes to the
          size of a solar PV system, the application process for registering the solar installation,
          as well as the implications for your meter and tariff. The differences are summarized
          below.
        </Text>

        <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Table w={'100%'}>
              <Tbody>
                <Tr>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    border={'1px solid black'}
                    textAlign="center"
                  >
                    For customers in an Estate:
                  </Td>
                  <Td
                    padding={'8px'}
                    fontSize={['11px', '12px', '13px', '14px']}
                    border={'1px solid black'}
                  >
                    If you are in an Estate and getting power from the Estate directly, GoSolr will
                    follow the process defined by your Estate. There are a large number of Estates
                    in South Africa, and rules are not always publicly available. Please assist
                    GoSolr by enquiring about the current rules in your Estate and make us aware of
                    those. Note that additional studies required by your Estate, but uncommon
                    otherwise, or additional measures will need to be covered by you.
                  </Td>
                </Tr>
                {municipalities.map((item) => (
                  <Tr>
                    <Td
                      key={item.municipality}
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                      textAlign="center"
                    >
                      {item.municipality}
                    </Td>
                    <Td
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      {item.legal_text}
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Flex>
      <Flex w="100%" gap="25px" direction={'column'} bgColor="brand.neutral" p="25px" rounded="8px">
        <Text fontSize={['11px', '12px', '12px', '12px']}>
          I acknowledge that I have read, understood, and accept the solar registration, meter and
          tariff implications of the electricity supplier relevant to me as outline above.
        </Text>
        <Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            Signed by: <b>{data.accHolderName}</b> with ID/Passport number <b>{data.accID}</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
            <b>
              {data.signedMonthAndYear}, {data.timestamp}
            </b>
          </Text>
        </Box>
        <Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>Agreement Reference Number: </b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Agreement Reference Number is: <b>{data.contractReferenceNumber}</b>
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export default ComplianceAgreementPage
