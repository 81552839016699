import { Flex, Grid, GridItem, Highlight, Text } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { RiHailLine, RiPlantLine } from 'react-icons/ri'
import { GiCoalPile } from 'react-icons/gi'
import { ContainerHeading } from '../../Headings'
import { SolarmanDeviceCurrentDataItem } from '../../../generated/graphql'
import LoadingComponent from '../../Loading'
import { NoDataFound } from '../../Misc/NoDataFound'

type EnvironmentalEconomicBenefitsType = {
  sytemDataLoading?: boolean
  systemData?: SolarmanDeviceCurrentDataItem[] | undefined
}

const EnvironmentalEconomicBenefits: React.FC<EnvironmentalEconomicBenefitsType> = ({
  systemData,
  sytemDataLoading: loading
}) => {
  const [treesPlanted, setTreesPlanted] = useState<string>('0')
  const [coalSaved, setCoalSaved] = useState<string>('0')
  const [cO2Prevention, setCO2Prevention] = useState<string>('0')

  useEffect(() => {
    if (systemData) {
      const totalProduction = systemData.find((item) => item.key === 'Et_ge0')
      const tp = parseFloat(totalProduction?.value as string)
      tp.toFixed(2)
      setTreesPlanted(calculateTreesSaved(tp))
      setCoalSaved(calculateCoalSaved(tp))
      setCO2Prevention(calculateCo2Prevention(tp))
    }
  }, [systemData])

  const calculateTreesSaved = (totalProduction: number) => {
    const constantValue = 0.997 / 18.3
    const treesPlanted = constantValue * totalProduction
    return treesPlanted.toFixed(2)
  }

  const calculateCoalSaved = (totalProduction: number) => {
    const constantValue = 0.0004
    const coalSaved = constantValue * totalProduction
    return coalSaved.toFixed(2)
  }

  const calculateCo2Prevention = (totalProduction: number) => {
    const constantValue = 0.000581
    const co2Prevention = constantValue * totalProduction
    return co2Prevention.toFixed(2)
  }

  return (
    <Flex
      bgColor={'brand.neutral'}
      w={['100%', '100%', '100%', '100%']}
      h="auto"
      maxH={'auto'}
      gap={'25px'}
      direction="column"
      rounded={'8px'}
      shadow={'md'}
      p={'25px'}
      color={'brand.text.primary'}
    >
      <ContainerHeading title={`Environmental & economic benefits`} />
      {loading && <LoadingComponent loadingText="Loading system fun facts" />}
      {!loading && systemData && (
        <Grid
          w="full"
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(3, 1fr)']}
          gap={'25px'}
        >
          <FactsItem
            label={'Trees planted'}
            value={treesPlanted}
            unit={'Tree(s)'}
            icon={<RiPlantLine size={25} />}
          />
          <FactsItem
            label={'CO2 prevention'}
            value={cO2Prevention}
            unit={'T'}
            icon={<RiHailLine size={25} />}
          />
          <FactsItem
            colSpan={[1, 1, 2, 1]}
            label={'Coal saved'}
            value={coalSaved}
            unit={'T'}
            icon={<GiCoalPile size={25} />}
          />
        </Grid>
      )}
      {!loading && !systemData && (
        <Flex
          direction={['column', 'column', 'column', 'column']}
          gap="25px"
          alignItems={'center'}
          justifyContent="space-between"
          w="full"
        >
          <NoDataFound />
        </Flex>
      )}
    </Flex>
  )
}

export default EnvironmentalEconomicBenefits

type FactItem = {
  icon: React.ReactNode
  label: String
  value: string
  unit: string
  colSpan?: number[]
}
const FactsItem: React.FC<FactItem> = ({ label, value, unit, icon, colSpan }) => {
  return (
    <GridItem
      colSpan={colSpan}
      backgroundColor={'brand.secondary'}
      color="brand.text.light"
      p={4}
      rounded={'8px'}
      w="full"
    >
      <Flex direction={'column'} gap="10px" w="full" alignItems={'center'}>
        <Flex alignItems={'center'} gap="10px">
          {icon}
          <Flex direction={'column'} letterSpacing={'wider'}>
            {label.toLowerCase().includes('co2') ? (
              <Text fontWeight="bold">
                CO<Text as={'sub'}>2</Text> prevention
              </Text>
            ) : (
              <Text fontWeight="bold">{label}</Text>
            )}
            <Text>
              <Highlight query={value} styles={{ color: 'brand.text.yellow', fontWeight: 'bold' }}>
                {value + ' ' + unit}
              </Highlight>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </GridItem>
  )
}
