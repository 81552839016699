import React, { FC, useEffect, useState } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { Gauge, GaugeConfig } from '@ant-design/plots'

export type Props = {
  percentage: number | undefined
}

const EnergyGauge: FC<Props> = ({ percentage }) => {
  const [gaugeConfig, setguageConfig] = useState<GaugeConfig>(() => ({
    width: 100,
    height: 100,
    // autoFit: true,
    padding: 0,
    percent: 0.5,
    range: {
      color: '#ffd635',
      width: 7
    },
    startAngle: Math.PI,
    endAngle: 2 * Math.PI,
    indicator: false
  }))

  useEffect(() => {
    const convertedPercentage: number = percentage ? percentage / 100 : 0
    setguageConfig((prev) => ({ ...prev, percent: convertedPercentage }))
  }, [percentage])

  return (
    <Flex direction={'column'} alignItems="center" w={['100%', '100%', '50%', '50%']}>
      <Flex pos={'relative'} alignContent="center" justifyContent={'center'}>
        <Gauge {...gaugeConfig} />
        <Text pos={'absolute'} bottom="15px" color={'brand.text.light'}>
          {percentage}%
        </Text>
      </Flex>
      <Text
        w="full"
        textAlign={'center'}
        color={'brand.text.light'}
        // fontSize={'sm'}
        fontWeight={300}
      >
        Battery charge
      </Text>
    </Flex>
  )
}

export default EnergyGauge
