import React, { FC, useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import SocialItem from './SocialItem'
import { ONBOARDING_URL } from '../../constants'

export type Props = {
  color?: string
  referralCode?: string | null | undefined
}
const Socials: FC<Props> = ({ color = '#171717', referralCode = '' }) => {
  const SocialList = ['link', 'email', 'whatsapp', 'facebook', 'twitter']
  const [referralLink, setReferralLink] = useState<string>('')

  useEffect(() => {
    const link = `${ONBOARDING_URL}/interested/address?promocode=${referralCode}`

    setReferralLink(link)
  }, [])

  return (
    <Flex gap="25px">
      {SocialList.map((item, index) => (
        <SocialItem key={`${item}-${index}`} color={color} name={item} url={referralLink} />
      ))}
    </Flex>
  )
}

export default Socials
