import { Flex, Heading, Spinner, Text } from '@chakra-ui/react'
import { NoDataFound } from '../Misc/NoDataFound'
import { PropertyAndTransactionsType, PropertyInvoiceType } from '../../types'
import TransactionItem from './TransactionItem'

type PropertyTransactionContainerPropsType = {
  property: PropertyAndTransactionsType
  setSelectedInv: React.Dispatch<React.SetStateAction<PropertyInvoiceType | null>>
  setIsInvoiceModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const PropertyTransactionContainer = ({
  property,
  setSelectedInv,
  setIsInvoiceModalOpen
}: PropertyTransactionContainerPropsType) => {
  const { suburb, streetAddress, description, invoices } = property!

  return (
    <>
      <Flex
        direction={'column'}
        borderColor={'brand.secondary'}
        border={'1px solid'}
        rounded={'md'}
        p={'15px'}
      >
        <Heading as={'h3'} fontSize={'20px'} color={'brand.text.'}>
          {description ? description : streetAddress + ', ' + suburb}
        </Heading>
        <Flex w={'full'} direction={'column'} gap={'15px'} mt={'25px'} px={'4px'}>
          {invoices.length <= 0 && (
            <NoDataFound message={'No transaction history for this property could be found'} />
          )}
          {invoices.length > 0 &&
            invoices.map((transaction) => (
              // <Text key={`${transaction.invoiceNumber}`} color={'brand.text.primary'} fontSize={13}>
              <TransactionItem
                key={`${transaction.invoiceNumber}`}
                item={transaction}
                setIsInvoiceModalOpen={setIsInvoiceModalOpen}
                setSelectedInv={setSelectedInv}
              />
            ))}
        </Flex>
      </Flex>
    </>
  )
}

export default PropertyTransactionContainer
