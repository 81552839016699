import { FC } from 'react'
import { ContainerHeading } from '../Headings'
import { Flex, Button } from '@chakra-ui/react'
import { NoDataFound } from '../Misc/NoDataFound'
import SubscriptionList from '../Subscriptions/SubscriptionsList'
import { getDomain } from '../../helpers/'
import LZString from 'lz-string'
import { Maybe, UserProperty } from '../../generated/graphql'
import { useNavigate } from 'react-router-dom'
import { AUTH_STORAGE_KEY, ONBOARDING_URL, USER_STORAGE_KEY } from '../../constants'

export type SubscriptionsType = {
  subscribedProperties: UserProperty[]
  onboardingInprogress?: Maybe<boolean> | undefined
  onboardingStepsCompleted: Maybe<number> | undefined
}
const DBSubscriptions: FC<SubscriptionsType> = ({
  subscribedProperties,
  onboardingInprogress,
  onboardingStepsCompleted
}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
      sessionStorage.getItem(USER_STORAGE_KEY)) as string
    const user = userStorage ? JSON.parse(userStorage) : {}

    //console.log(`user: AUTH`, user?.auth)

    const cookieData = {
      email: user?.info?.email || '',
      recordId: user?.info?.recordId || '',
      accessToken: user?.auth?.accessToken || ''
    }

    const domain = getDomain()
    const secure = domain === '.localhost' ? false : true
    console.log(`secure: `, secure)

    let expires = new Date()
    expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000)
    console.log(`cookieData: `, cookieData)

    //remove thi
    console.log(`Domain ---> ${domain}`)

    // Set tokens in cookies
    //document.cookie = `idToken=${user?.auth?.idToken?.jwtToken}; domain=.localhost; path=/; secure; SameSite=None`
    document.cookie = `${AUTH_STORAGE_KEY}=${LZString.compressToBase64(
      JSON.stringify(user?.auth?.accessToken)
    )}; domain=${domain}; path=/; secure=${secure}; SameSite=None`
    //document.cookie = `refreshToken=${user?.auth?.refreshToken}; domain=.localhost; path=/; secure; SameSite=None`

    // console.log(`${ONBOARDING_URL}/interested`)
    // debugger
    //window.location.href = `${ONBOARDING_URL}/interested/address`
    window.open(`${ONBOARDING_URL}/interested/address`, '_blank')
  }

  const canAddProperty = onboardingStepsCompleted && onboardingStepsCompleted >= 9 ? false : true

  return (
    <>
      <Flex w="full" justifyContent={'space-between'}>
        <ContainerHeading title=" Subscriptions" />
        <Button variant={'dark'} onClick={handleClick} isDisabled={canAddProperty}>
          Add new
        </Button>
      </Flex>
      <Flex w="100%" h="100%" justifyContent="center" alignItems="start" mt={'25px'}>
        {subscribedProperties.length <= 0 ? (
          <NoDataFound />
        ) : (
          <SubscriptionList
            subscriptions={subscribedProperties}
            onboardingStepsCompleted={onboardingStepsCompleted}
          />
        )}
      </Flex>
    </>
  )
}

export default DBSubscriptions
