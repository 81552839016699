import { Flex, Image, Box, useMediaQuery } from '@chakra-ui/react'
import OnboardingHeroContainer from '../../components/Onboarding/Intro/OnboardingHeroContainer'
import LoginContainer from '../../components/Auth/Login'

const LoginPage = (): JSX.Element => {
  const mediaQuery = useMediaQuery('(max-width: 1024px)')
  const isMobile = mediaQuery[0]

  return (
    <Flex
      as="main"
      position={'relative'}
      direction="row"
      w={'100%'}
      minH={'100vh'}
      bgColor={'#F2F2F2'}
    >
      <Flex
        direction={'column'}
        w={'100%'}
        maxW={isMobile ? '100%' : '50%'}
        h={'100%'}
        minH={'100vh'}
        px={['25px', '50x', '100px', '100px']}
        py={'50px'}
        alignItems={'center'}
        justifyContent={'center'}
        bgColor={'brand.neutral'}
      >
        <Box w={'full'} mb="50px">
          <Image src={'/images/gosolr-logo-new.svg'} w={'auto'} h={'62px'} />
        </Box>
        <Flex
          flex={1}
          direction={'column'}
          w={'full'}
          // maxW={'560px'}
          alignItems={'center'}
          justifyContent={'center'}
          gap={'50px'}
          overflow="visible"
        >
          <LoginContainer />
        </Flex>
      </Flex>
      {!isMobile && <OnboardingHeroContainer />}
    </Flex>
  )
}

export default LoginPage
