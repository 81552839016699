import React, { FC } from 'react'
import { ContainerHeading } from '../../../Headings'
import { Box, Flex, Text } from '@chakra-ui/react'

import formatMoneyValue from '../../../../utils/formatMoneyValue'
import { FormData } from '../../../../context/intro/OnboardingContext'
import { PreSignUpPropertyType, Product } from '../../../../types'

const SubscriptionSummarry: FC<{
  selectedSubscription?: Product
  formData: PreSignUpPropertyType
}> = ({ selectedSubscription, formData }) => {
  const roofType = formData.isRoofFlat
  return (
    <Flex
      direction={'column'}
      gap="25px"
      border={'1px solid '}
      borderColor={'brand.secondary'}
      p={'25px'}
      rounded={'8px'}
    >
      <ContainerHeading title={'Subscription summary'} />
      <Flex
        direction={['column', 'column', 'column', 'row']}
        alignItems="center"
        justifyContent={'space-between'}
        color={'brand.text.primary'}
        gap={'25px'}
      >
        <Flex direction={'column'} gap="10px">
          <Flex gap={'10px'}>
            {' '}
            <Text fontSize={14} fontWeight={'bold'}>
              Subscription:
            </Text>
            <Text fontSize={14}>
              GoSolr {selectedSubscription?.subscriptionOption?.replace('2023_', '')}
            </Text>
          </Flex>
          <Flex gap={'5px'} direction={'column'}>
            <Flex fontSize={14} gap="10px">
              <Text fontWeight={'bold'}>Add ons: </Text>
              {!selectedSubscription?.addOns?.length && <Text fontWeight={'normal'}>none</Text>}
            </Flex>
            {selectedSubscription?.addOns?.includes('2 panels') && (
              <Text fontSize={14}>2 x {selectedSubscription?.panelSpec}</Text>
            )}
            {selectedSubscription?.addOns?.includes('4 panels') && (
              <Text fontSize={14}>4 x {selectedSubscription?.panelSpec}</Text>
            )}
            {selectedSubscription?.addOns?.includes('battery') && (
              <Text fontSize={14}>1 x Minimum 5kWh Lithium battery</Text>
            )}
          </Flex>
        </Flex>
        <Flex
          gap="15px"
          border={'1px solid '}
          borderColor={'brand.secondary'}
          py={'12px'}
          px={'25px'}
          rounded={'8px'}
          direction={'column'}
          alignItems="left"
          justifyContent={'center'}
        >
          <Flex gap="15px" alignItems={'center'}>
            <Text flex={1} fontSize={18}>
              <b>Monthly fee:</b>
              {''}
            </Text>
            <Text fontSize={18}>
              {' '}
              <b>R{formatMoneyValue(selectedSubscription?.subscriptionFee, '0,0')}</b>
            </Text>
          </Flex>
          <Box w="100%" height={'1px'} bgColor={'brand.text.primary'}></Box>
          <Flex direction={'column'}>
            <Flex gap="15px" alignItems={'center'}>
              <Text flex={1} fontSize={18} textDecoration="underline">
                {roofType?.toLowerCase() === 'yes' ? 'Once off fees:' : 'Once off fee:'}
                {''}
              </Text>
            </Flex>
            <Flex gap="15px" alignItems={'center'}>
              <Text flex={1} fontSize={18}>
                Administration fee:{''}
              </Text>
              <Text fontSize={18}>
                {' '}
                <b>R{formatMoneyValue(selectedSubscription?.onceOffFee, '0,0')}</b>
              </Text>
            </Flex>

            {roofType?.toLowerCase() === 'yes' && (
              <>
                <Flex gap="15px" alignItems={'center'}>
                  <Text flex={1} fontSize={18}>
                    Flat roof fee:{''}
                  </Text>
                  <Text fontSize={18}>
                    <b>R{formatMoneyValue(selectedSubscription?.flatRoofFee as number, '0,0')}</b>
                  </Text>
                </Flex>
                <Box w="100%" height={'1px'} bgColor={'brand.text.primary'} mt={'15px'}></Box>
                <Flex gap="15px" alignItems={'center'} mt={'5px'}>
                  <Text flex={1} fontSize={18}>
                    <b>Total once off fee :</b>
                    {''}
                  </Text>
                  <Text fontSize={18}>
                    <b>
                      R
                      {formatMoneyValue(
                        (Number(selectedSubscription?.flatRoofFee) +
                          Number(selectedSubscription?.onceOffFee)) as number,
                        '0,0'
                      )}
                    </b>
                  </Text>
                </Flex>
              </>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default SubscriptionSummarry
