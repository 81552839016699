import React, { FC, useEffect } from 'react'
import './App.css'
import Navigation from './navigation'

import ReactPixel from 'react-facebook-pixel'
import { useSearchParams } from 'react-router-dom'

const options = {
  autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
  debug: false // enable logs
}

const App: FC = () => {
  const [searchParams] = useSearchParams()

  useEffect(() => {
    const promoCode = searchParams.get('promocode')
    ReactPixel.init('6519571341438720', undefined, options)
    if (promoCode === 'gowoththeflow') {
      ReactPixel.fbq('track', 'PageView')
    }
  }, [searchParams])

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
  }, [searchParams])

  return (
    <>
      <Navigation />
    </>
  )
}

export default App
