/* istanbul ignore file */
/**
 * Ignore commented lines.
 * They will be uncommented later when we implement their proper usage.
 * They remain here for now as reference.
 */
import { ApolloClient, InMemoryCache, HttpLink, ApolloLink } from '@apollo/client'
import { API_HOST, API_KEY, API_SECRET, USER_STORAGE_KEY } from './constants'
import { ApiAuthHeaders, UserStorage } from './types'

// import { fetchJwt } from './utils'

const httpLink = new HttpLink({
  uri: API_HOST + '/graphql'
})

const authMiddleware = new ApolloLink((operation, forward) => {
  // add authorization to header
  const publicAccessAllowed = operation?.variables?.public
  const clientAppApiToken = API_KEY as string

  const headers: ApiAuthHeaders = {
    Authorization: clientAppApiToken
  }

  if (!publicAccessAllowed) {
    // console.log({ user: sessionStorage.getItem('user') })
    const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
      sessionStorage.getItem(USER_STORAGE_KEY)) as string
    const user = userStorage ? JSON.parse(userStorage) : {}
    // console.log({ user })
    headers.Authorization = (user as UserStorage)?.auth?.accessToken
  } else {
    headers['X-Api-Key'] = API_SECRET as string
  }

  operation.setContext({ headers })
  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authMiddleware.concat(httpLink)
})

export default client
