import { FC } from 'react'
import { Maybe, UserProperty } from '../../generated/graphql'
import ApplicationProgressBar from '../ApplicationProgress'
import { PropertyMainContainer } from '../Property/PropertyMainContainer'

export type SubscriptionsType = {
  subscribedProperties: UserProperty[]
  onboardingStepsCompleted: Maybe<number> | undefined
}

const DBProperties: FC<SubscriptionsType> = ({
  subscribedProperties,
  onboardingStepsCompleted
}) => {
  return (
    <>
      {/* All the Active Subscriptions */}
      {subscribedProperties.map((property, index) => {
        const propertyView = !property.installationComplete ? (
          <ApplicationProgressBar
            property={property}
            key={`${property.propertyLat}-${index}-${property.recordId}`}
            onboardingStepsCompleted={
              onboardingStepsCompleted ? Number(onboardingStepsCompleted) : 1
            }
          />
        ) : (
          <PropertyMainContainer property={property} key={`${property.propertyLat}-${index}`} />
        )

        return propertyView
      })}
    </>
  )
}

export default DBProperties
