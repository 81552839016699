import { Flex, Text } from '@chakra-ui/react'

import { useParams } from 'react-router-dom'
import { useAuthContext } from '../../../context/AuthProvider'
import CheckoutSummary from '../../../components/Onboarding/Dashboard/CheckoutSummary'
import GoogleProvider from '../../../context/GoogleProvider'

const CheckoutSummaryPage = () => {
  const { recordId } = useParams()
  const { user: authUser } = useAuthContext()

  // todo navigate to logout
  if (!recordId || !authUser) {
    return (
      <Flex>
        <Text>
          Something went wrong. Please reload the page and, if the problem persists please contact
          support
        </Text>
      </Flex>
    )
  }

  return (
    <Flex
      w="100%"
      gap="50px"
      direction={['column', 'column', 'column', 'column']}
      alignItems={'center'}
    >
      {/* <OnBoardingStepper /> */}
      <GoogleProvider>
        <CheckoutSummary recordId={recordId} authUser={authUser} />
      </GoogleProvider>
    </Flex>
  )
}

export default CheckoutSummaryPage
