import { Alert, AlertIcon, Button, Flex, useDisclosure } from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { CreditCheckConstraints } from '../../../../constants'
import { CreditScoreOutcome, ExperianCreditCheckInput, UserStorage } from '../../../../types'
import { ContainerHeading } from '../../../Headings'
import {
  CreditCheckInput,
  CreditCheckResponse,
  ErrorResponse,
  User,
  useGetCreditScoreQuery,
  useGetUserQuery,
  useUpdateDynamoUserMutation,
  useUpdateUserMutation,
  useUpdateUserPropertyMutation,
  useUploadCreditCheckDocumentMutation
} from '../../../../generated/graphql'
import { Form, Formik } from 'formik'
import SubmitButton from '../../../FormElements/SubmitButton'
import BackButton from '../../../FormElements/BackButton'
import { useNavigate } from 'react-router-dom'
import { CreditCheckValidation } from '..//../../../formValidation'
import TextInput from '../../../FormElements/TextInput'
import { formatIdNumber, validateIdNumber } from '../../../../utils'
import ConnectedIDNumberInput from '../../../FormElements/ConnectedIDNumberInput'
import ConnectedFormGroup from '../../../FormElements/ConnectedFormGroup'
import ModalDialog from '../../../Modals/ModalDialog'

type CreditCheckFormType = {
  user?: UserStorage
  recordId: string
}

type InputSelected = 'id' | 'passport'

const CreditCheckForm: FC<CreditCheckFormType> = ({ user, recordId }) => {
  const navigate = useNavigate()
  const [formError, setFormError] = useState<string>()
  const [performCreditCheck, setPerformCreditCheck] = useState(false)
  const [inputSelected, setInputSelected] = useState<InputSelected>('id')
  const {
    isOpen: isPassedModalOpen,
    onOpen: openPassedModal,
    onClose: closePassedModal
  } = useDisclosure()
  const {
    isOpen: isFailedModalOpen,
    onOpen: openFailedModal,
    onClose: closeFailedModal
  } = useDisclosure()
  const {
    isOpen: isOnHoldModalOpen,
    onOpen: openOnHoldModal,
    onClose: closeOnHoldModal
  } = useDisclosure()

  const [updateUser] = useUpdateUserMutation()
  const [updateDynamoUser] = useUpdateDynamoUserMutation()
  const [updateUserProperty] = useUpdateUserPropertyMutation()
  const { refetch: getCreditScore } = useGetCreditScoreQuery({
    skip: true
  })
  const [uploadCreditCheckDocument] = useUploadCreditCheckDocumentMutation()

  const { data } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })
  const [loading, setLoading] = useState<boolean>(false)

  useEffect(() => {
    const optionSelected = localStorage.getItem('idPassportSelection')
    optionSelected === 'passport' ? setInputSelected('passport') : setInputSelected('id')
  }, [performCreditCheck])

  const recentUserData = data?.getUser as User

  const successLinkNavigation: string = `/user/onboarding/documents/${recordId}`

  const initialValuesWithID = {
    selectedInput: inputSelected,
    idNumber: recentUserData?.idPassportNumber
      ? formatIdNumber(recentUserData?.idPassportNumber as string)
      : recentUserData?.idPassportNumber || '',
    passportNumber: recentUserData?.idPassportNumber
      ? formatIdNumber(recentUserData?.idPassportNumber as string)
      : recentUserData?.idPassportNumber || '',

    firstName: recentUserData?.firstName || '',
    lastName: recentUserData?.lastName || ''
  }

  const handleSwitchIDPassport = (setFieldValue: any) => {
    if (inputSelected === 'id') {
      setInputSelected('passport')
      setFieldValue('selectedInput', 'passport')
    }
    if (inputSelected === 'passport') {
      setInputSelected('id')
      setFieldValue('selectedInput', 'id')
    }
  }

  return (
    <Flex
      w="full"
      maxW={'650px'}
      gap="25px"
      direction={'column'}
      color={'brand.text.primary'}
      shadow={'md'}
    >
      <Flex
        w="full"
        gap="25px"
        direction={'column'}
        p={'25px'}
        rounded="8px"
        bgColor={'brand.neutral'}
      >
        <ContainerHeading title={'Credit check '} />
        <Formik
          validateOnMount
          initialValues={initialValuesWithID}
          validationSchema={CreditCheckValidation}
          validateOnChange={true}
          enableReinitialize
          onSubmit={async (
            { firstName, lastName, idNumber, passportNumber },
            { setSubmitting, setStatus }
          ) => {
            setSubmitting(true)
            setLoading(true)
            setStatus(null)

            const initials = `${firstName[0]}${lastName[0]}`

            if (!idNumber && !passportNumber) {
              return setFormError('ID Number or Passport Number required.')
            }

            if (inputSelected === 'id' && idNumber && !validateIdNumber(idNumber)) {
              return setFormError('Please enter a valid ID Number')
            }

            try {
              let openModal: () => void = () => {}

              if (inputSelected === 'id') {
                await updateUser({
                  variables: {
                    email: user?.info?.email as string,
                    input: {
                      recordId,
                      idPassportNumber: idNumber && idNumber.replace(/\W/gi, ''),
                      initials
                    }
                  }
                })

                const creditCheckInput = {
                  idPassportNumber: idNumber && idNumber.replace(/\W/gi, ''),
                  usePassportNumber: false,
                  firstName,
                  lastName,
                  recordId
                } as ExperianCreditCheckInput

                // get credit score
                const creditCheckResponse = await getCreditScore({
                  input: creditCheckInput
                })

                const { error, message } = creditCheckResponse?.data
                  ?.getCreditScore as ErrorResponse

                if (error) {
                  throw Error(message || 'An error occured on credit check')
                }

                const { score } = creditCheckResponse?.data?.getCreditScore as CreditCheckResponse

                // upload credit check document
                // await uploadCreditCheckDocument({
                //   variables: {
                //     recordId,
                //     score: score as number,
                //     input: creditCheckInput as CreditCheckInput
                //   }
                // })

                let orderStatus
                let validateConstraint = false
                let creditCheckPassed = false

                if (score) {
                  // @ts-ignore
                  for (const [index, constraint] of CreditCheckConstraints.entries()) {
                    if (
                      (score >= constraint.value &&
                        typeof CreditCheckConstraints[index + 1] !== 'undefined' &&
                        score < CreditCheckConstraints[index + 1].value) ||
                      typeof CreditCheckConstraints[index + 1] === 'undefined'
                    ) {
                      validateConstraint = true
                    }

                    if (validateConstraint) {
                      switch (constraint.outcome as CreditScoreOutcome) {
                        case CreditScoreOutcome.FAIL:
                          orderStatus = 'Credit Check Failed'
                          openModal = openFailedModal
                          break
                        case CreditScoreOutcome.ON_HOLD:
                          orderStatus = 'On-Hold'
                          openModal = openOnHoldModal
                          break
                        case CreditScoreOutcome.PASS:
                          creditCheckPassed = true
                          orderStatus = 'Validating'
                          openModal = openPassedModal
                          break
                        default:
                          break
                      }
                      break
                    }
                  }
                }

                if (orderStatus) {
                  // update order status
                  await updateUserProperty({
                    variables: {
                      recordId,
                      input: {
                        creditCheckPassed,
                        orderStatus
                      }
                    }
                  })
                }
                // Update user on DynamoDB
                if (orderStatus !== 'Credit Check Failed') {
                  await updateDynamoUser({
                    variables: {
                      email: user?.info?.email as string,
                      input: {
                        onboardingProgressCompletedStep: 7.0,
                        initials
                      }
                    }
                  })
                }

                openModal()
              }
              setLoading(false)
              if (inputSelected === 'passport') {
                const creditCheckInput = {
                  idPassportNumber: passportNumber,
                  usePassportNumber: true,
                  firstName,
                  lastName,
                  recordId
                } as ExperianCreditCheckInput

                const creditCheckResponse = await getCreditScore({
                  input: creditCheckInput
                })

                const { score } = creditCheckResponse?.data?.getCreditScore as CreditCheckResponse

                let orderStatus = 'On-Hold'
                let creditCheckPassed = false
                let validateConstraint = false

                if (Number(score) === 0) {
                  orderStatus = 'On-Hold'
                  openModal = openOnHoldModal
                } else if (score && score > 1) {
                  // @ts-ignore
                  for (const [index, constraint] of CreditCheckConstraints.entries()) {
                    if (
                      (score >= constraint.value &&
                        typeof CreditCheckConstraints[index + 1] !== 'undefined' &&
                        score < CreditCheckConstraints[index + 1].value) ||
                      typeof CreditCheckConstraints[index + 1] === 'undefined'
                    ) {
                      validateConstraint = true
                    }
                    console.log("Credit check constraint.outcome=",constraint.outcome)
                    if (validateConstraint) {
                      switch (constraint.outcome as CreditScoreOutcome) {
                        case CreditScoreOutcome.FAIL:
                          orderStatus = 'Credit Check Failed'
                          openModal = openFailedModal
                          break
                        case CreditScoreOutcome.ON_HOLD:
                          orderStatus = 'On-Hold'
                          openModal = openOnHoldModal
                          break
                        case CreditScoreOutcome.PASS:
                          creditCheckPassed = true
                          orderStatus = 'Validating'
                          openModal = openPassedModal
                          break
                        default:
                          break
                      }
                      break
                    }
                  }
                }

                await updateUser({
                  variables: {
                    email: user?.info?.email as string,
                    input: {
                      recordId,
                      idPassportNumber: passportNumber,
                      initials
                    }
                  }
                })
                await updateUserProperty({
                  variables: {
                    recordId,
                    input: {
                      creditCheckPassed,
                      orderStatus
                    }
                  }
                })
                // Update user on DynamoDB
                if (orderStatus !== 'Credit Check Failed') {
                  await updateDynamoUser({
                    variables: {
                      email: user?.info?.email as string,
                      input: {
                        onboardingProgressCompletedStep: 7.0,
                        initials
                      }
                    }
                  })
                }

                setLoading(false)
                openModal()
              }
              setLoading(false)
              setSubmitting(false)
              setPerformCreditCheck(true)
            } catch (error) {
              let message
              if (error instanceof Error) message = error.message
              else message = String(error)
              setStatus(message)
            }
          }}
        >
          {({ setFieldValue }) => {
            return (
              <Form>
                <Flex w="full" gap="25px" direction={'column'} color={'brand.text.primary'}>
                  <Flex justifyContent={'flex-end'} w="full">
                    <Button variant={'dark'} onClick={() => handleSwitchIDPassport(setFieldValue)}>
                      {inputSelected === 'id' ? 'Use passport number' : 'Use ID number'}
                    </Button>
                  </Flex>
                  <Flex direction={'column'} w="full" gap={'25px'}>
                    {inputSelected === 'id' && (
                      <>
                        <ConnectedIDNumberInput
                          name="idNumber"
                          label="South African ID number"
                          onChange={() => {
                            setFormError(undefined)
                          }}
                        />
                      </>
                    )}
                    {inputSelected === 'passport' && (
                      <ConnectedFormGroup
                        name="passportNumber"
                        label="Passport number"
                        handleChange={() => {
                          setFormError(undefined)
                        }}
                      />
                    )}

                    <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                      <TextInput
                        name="firstName"
                        label="Legal name (As shown in your ID)"
                        placeholder="First name (and middle name)"
                      />
                      <TextInput name="lastName" label="Last name" placeholder="Last name" />
                    </Flex>
                  </Flex>

                  <Flex w="full" justifyContent={'space-between'} alignItems="center" mt={'25px'}>
                    <BackButton
                      callback={() => {
                        navigate(`/user/onboarding/credit-type/${recordId}`)
                      }}
                    />

                    <SubmitButton isLoading={loading} />
                  </Flex>
                </Flex>
                {formError && (
                  <Alert fontSize={12} variant="left-accent" status="warning" mt={4}>
                    <AlertIcon />
                    {formError}
                  </Alert>
                )}
              </Form>
            )
          }}
        </Formik>
      </Flex>

      <ModalDialog
        status="success"
        title="Credit check passed"
        description="Congrats! Your credit validation check has passed our criteria."
        isOpen={isPassedModalOpen}
        onClose={closePassedModal}
        successLink={successLinkNavigation}
        buttonTextSuccess="Next"
        buttonTextError="Cancel"
        hasCloseIcon={false}
      />

      <ModalDialog
        status="warning"
        title="Credit check requires further investigation"
        description="Your check returned a score that requires some further assessment on our side. We have put your application on hold and will get in touch with you shortly to discuss next steps."
        isOpen={isOnHoldModalOpen}
        onClose={closeOnHoldModal}
        successLink={successLinkNavigation}
        buttonTextSuccess="Next"
        hasCloseIcon={false}
      />

      <ModalDialog
        status="error"
        title="Credit check failed"
        description="Unfortunately your credit check score did not meet our requirements and we will not be able to continue with your application process."
        isOpen={isFailedModalOpen}
        onClose={closeFailedModal}
        buttonTextError="Ok"
        hasCloseIcon={false}
      />
    </Flex>
  )
}

export default CreditCheckForm
