import { FC } from 'react'
import { Flex } from '@chakra-ui/react'
import { Form, Formik } from 'formik'
import TextInput from '../../../FormElements/TextInput'
import BackButton from '../../../FormElements/BackButton'
import PhoneInput from '../../../FormElements/PhoneInput'
import SubmitButton from '../../../FormElements/SubmitButton'
import { Link } from 'react-router-dom'
import axios from 'axios'
import { CallMeValidation } from '../../../../formValidation/CallMe'
import DropDownInput from '../../../FormElements/DropDownInput'
import { SA_PROVINCE_OPTIONS } from '../../../../constants'

type InitialValuesType = {
  firstName: string
  lastName: string
  email: string
  contactNumber: string
  provinces: string
  streetName: string
  streetNumber: string
  city: string
  suburb: string
  postalCode: string
  productOfInterest: string
}

const initValues: InitialValuesType = {
  firstName: '',
  lastName: '',
  contactNumber: '',
  email: '',
  provinces: '',
  streetName: '',
  streetNumber: '',
  city: '',
  suburb: '',
  postalCode: '',
  productOfInterest: ''
}
const CallMe: FC = () => {
  return (
    <Flex>
      <Formik
        initialValues={initValues}
        validateOnMount
        validateOnBlur={false}
        enableReinitialize
        validationSchema={CallMeValidation}
        onSubmit={async (values, { setSubmitting, setStatus }) => {
          // if (isRecaptchaVerified) {
          //   // Submit the form or perform any other action
          //   console.log('Form submitted!')
          // } else {
          //   // reCAPTCHA is not verified, show an error message or take appropriate action
          //   console.log('Please verify CAPTCHA!')
          // }

          const data = values

          if (data) {
            //axios post request to backend
            try {
              await axios
                .post(process.env.REACT_APP_API_HOST + '/post-call-me-input', data)
                .then(function (response) {
                  //onSubmit async function. so we wait till axios request is finished
                })
                .catch(function (error) {
                  console.log(error)
                }) //then function returns endpoints response
            } catch (e) {
              console.log('error occurred: ', e)
            }
          }
          // Go to next step (use   const navigate = useNavigate())
        }}
      >
        <Form>
          <Flex direction={'column'} w="full" gap={'25px'}>
            <div>Personal Information</div>
          </Flex>

          <Flex direction={'column'} w="full" gap={'15px'}>
            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <TextInput name="firstName" label="First name" placeholder="First Name" />
              <TextInput name="lastName" label="Last name" placeholder="Last Name" />
            </Flex>
            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <PhoneInput name="contactNumber" label="Phone number" placeholder="Phone Number" />
              <TextInput name="email" label="Email" placeholder="Email Address" />
            </Flex>
            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <TextInput name="streetName" label="Street Name" placeholder="Street Name" />
              <TextInput name="streetNumber" label="Street Number" placeholder="Street Number" />
            </Flex>

            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <TextInput name="city" label="City" placeholder="City" />

              <DropDownInput
                name="provinces"
                label="Province"
                options={SA_PROVINCE_OPTIONS}
                placeholder={'Select a province'}
              />
            </Flex>
            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <TextInput name="suburb" label="Suburb" placeholder="Suburb" />
              <TextInput name="postalCode" label="Postal Code" placeholder="Postal Code" />
            </Flex>
            <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
              <TextInput
                name="productOfInterest"
                label="Product of Interest"
                placeholder="Product of interest"
              />
            </Flex>

            <Flex justifyContent={'space-between'}>
              <Link to="/">
                <BackButton
                  callback={() => {
                    // handlePrevStepIndex()
                  }}
                />
              </Link>

              <SubmitButton />
            </Flex>
          </Flex>
        </Form>
      </Formik>
    </Flex>
  )
}

export default CallMe

// const RecaptchaObserver = () => {
//   const [isRecaptchaVerified, setIsRecaptchaVerified] = useState(false)
//   const [modalIsOpen, setModalIsOpen] = useState<boolean>(true)
//   type RecaptchaType = string | null

//   const handleRecaptchaChange = async (token: RecaptchaType) => {
//     console.log(token)
//     const response = await axios
//       .post('http://localhost:4040/uat/get-recaptcha', { token })
//     console.log(response.data.status)
//     setIsRecaptchaVerified(response.data.status)
//   }

//   useEffect(() => {
//     if (isRecaptchaVerified) {
//       setModalIsOpen(false)
//     }

//   }, [isRecaptchaVerified])

//   return (
//     <RecaptchaModal
//       isOpen={modalIsOpen}
//       onClose={() => {
//         if (isRecaptchaVerified) {
//           setModalIsOpen(false)
//         }
//       }}
//       heading={'No Robots Allowed!'}
//       onChange={handleRecaptchaChange}
//     />
//   )
// }
