//TODO
//Check Date Validation??

import * as yup from 'yup'
import YupPassword from 'yup-password'
import {
  BUSINESS_NUMBER_REGEX,
  SA_PHONE_NUMBER_REGEX,
  ID_NUMBER_REGEX,
  VAT_NUMBER_REGEX,
  DATE_REGEX,
  DATE_REGEX_SHORT,
  BUSINESS_ENTITY_TYPES,
  BUSINESS_INDUSTRIES
} from '../../constants'
import { mapRegistrationNumberToEntity } from '../../utils'
YupPassword(yup)

type objArr = {
  value: string
  label: string
}

const objArrToIndexArr = (objArr: objArr[]) => {
  let newArr = objArr.map((obj) => obj.value)
  return newArr
}

export const CreditCheckValidation = yup.object().shape({
  //CREDIT CHECK
  businessName: yup
    .string()
    .min(1, 'Registered business name required')
    .max(255, 'Your name is too long')
    .trim()
    .required(),
  registeredBusinessNumber: yup
    .string()
    .max(50, 'Maximum length exceeded')
    // .matches(BUSINESS_NUMBER_REGEX, { message: 'Please enter a valid Business Registration Number' })
    .required(),
  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Phone number is required.')
    ,
  // email: yup
  //   .string()
  //   .email('This is not a valid email address. Please use a valid email address.')
  //   .required('Email is required'),
  // iDPassportNumber: yup
  //   .string()
  //   .min(13, 'ID numbers require a minimum of 13 digits')
  //   .matches(ID_NUMBER_REGEX, { message: 'Please enter a valid ID number' }),
  vatNumber: yup
    .string()
    .required("Please supply your VAT number")
    .min(10, 'VAT numbers require a 10 digits'),
  businessIndustry:yup
    .string()
    .oneOf(objArrToIndexArr(BUSINESS_INDUSTRIES))
    .required("Please specify your business industry"),
  firstName:yup
    .string()
    .required("Please enter your first name"),
  lastName:yup
    .string()
    .required("Please enter your last name"),
  tradingName:yup
    .string()
    .required("Please enter your business trading name"),
  businessDesignation:yup
    .string()
    .required("Please enter your business designation")
})
