import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { FiZap } from 'react-icons/fi'

export type Props = {
  produced: string
}

const ProducedItem: FC<Props> = ({ produced }) => {
  return (
    <Flex
      direction={'column'}
      h="100%"
      gap={'5px'}
      alignItems="center"
      justifyContent="center"
      w={['100%', '100%', '50%', '50%']}
    >
      <Flex alignItems={'center'} justifyContent="center" color={'brand.primary'} gap={'10px'}>
        <FiZap size={25} strokeWidth={1} style={{ strokeWidth: '1px' }} />
      </Flex>
      <Text color={'brand.text.light'} fontWeight={700} fontSize={'xl'}>
        {produced ? produced : 0} kWh
      </Text>
      <Text color={'brand.text.light'} fontWeight={300} textAlign="center">
        Daily production
      </Text>
    </Flex>
  )
}

export default ProducedItem
