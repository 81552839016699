import { Flex } from '@chakra-ui/react'
import React from 'react'
import { IconType } from 'react-icons'

type Props = {
  icon: React.ReactNode
}

const IconBlock = ({ icon }: Props) => {
  return (
    <Flex
      w={'45px'}
      h={'45px'}
      p={'2px'}
      shadow={'md'}
      alignItems="center"
      justifyContent={'center'}
      bgColor={'brand.primary'}
      rounded="8px"
    >
      {icon}
    </Flex>
  )
}

export default IconBlock
