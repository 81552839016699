import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, FlexProps, StyleProps } from '@chakra-ui/react'

import { ContainerHeading } from '../Headings'
import { ButtonStandard } from '../Buttons'

export type Props = StyleProps & FlexProps & {}

const DBSupport: FC<Props> = () => {
  const navigate = useNavigate()

  const handleNavigateToSupport = () => {
    navigate('/user/support')
  }

  return (
    <>
      <ContainerHeading title="Need help?" />
      <Flex w={'100%'} h={'100%'} justifyContent={'center'} alignItems={'center'}>
        <ButtonStandard height="40px" onClick={() => handleNavigateToSupport()} w="auto">
          Ask support
        </ButtonStandard>
      </Flex>
    </>
  )
}

export default DBSupport
