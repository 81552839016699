import { Flex } from '@chakra-ui/react'
import React from 'react'
import { useParams } from 'react-router-dom'
import OnBoardingStepper from '../../../components/Onboarding/Dashboard/OnBoardingStepper'
import { useAuthContext } from '../../../context/AuthProvider'
import CreditCheckLegacy from '../../../components/Onboarding/Dashboard/Private/CreditCheckLegacy'

const CreditCheckPage = () => {
  const { recordId } = useParams()
  const { user } = useAuthContext()
  return (
    <Flex
      w="100%"
      gap="50px"
      direction={['column', 'column', 'column', 'column']}
      alignItems={'center'}
    >
      <OnBoardingStepper />
      <CreditCheckLegacy recordId={recordId as string} user={user} />
    </Flex>
  )
}

export default CreditCheckPage
