import { Flex } from '@chakra-ui/react';
import FAQ from '../../components/Onboarding/Dashboard/Faq';
import { User, useGetUserQuery } from '../../generated/graphql';
import axios, { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
const FaqPage = () => {
    // const { recordId } = useParams()
    //  const { user } = useAuthContext()
    const initial = [{
        question: "",
        answer: ""

    }]
    const [faqItems, setFaqItems] = useState<any>(initial)
    useEffect(() => {
        try {
            const getFaqItems = async () => {
                axios.get("http://localhost:4040/uat/get-faq").then((items) => {

                    setFaqItems(items.data.message)
                }
                )
            }
            getFaqItems()
            //  setFaqItems(dummyData())
        } catch (e) {
            console.log("error has occured")
        }

    }, [])
    return (
        <div>
            <h1>FAQ Component</h1>
            <FAQ faqItems={faqItems} />
        </div>
    );
};

export default FaqPage

