import { error } from 'console'
import { USER_STORAGE_KEY } from '../../constants'
import { UserProperty } from '../../generated/graphql'
import { Maybe, UserStorage } from '../../types'

export const getJWT = (): string => {
  const userStorage = (localStorage.getItem(USER_STORAGE_KEY) ||
    sessionStorage.getItem(USER_STORAGE_KEY)) as string
  const user = userStorage ? JSON.parse(userStorage) : {}
  return (user as UserStorage)?.auth?.accessToken
}

export const validateRecordIdPath = async (
  recordId: string | undefined,
  properties: Maybe<Maybe<UserProperty>[]> | undefined
): Promise<Maybe<UserProperty>[] | undefined> => {
  if (recordId === undefined) {
    throw Error('Record ID must be defined')
  }

  if (properties === undefined) {
    throw Error('Property must be defined')
  }

  if (properties?.length === 0) {
    throw Error('Properties array cannot be empty')
  }

  const isValidRecordId = properties?.filter((item) => {
    if (item?.recordId === recordId) {
      return true
    }
    return false
  })

  return isValidRecordId
}
