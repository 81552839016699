import React from 'react'
import { Flex, Highlight, Text } from '@chakra-ui/react'
import { ContainerHeading } from '../Headings'
import Socials from '../Socials'

export type PromoCodeType = {
  referralCode: string | null
}

const DBPromoCode: React.FC<PromoCodeType> = ({ referralCode = '' }) => {
  return (
    <>
      <ContainerHeading title="Referral code" />
      <Flex
        direction={'column'}
        w="100%"
        h="100%"
        gap={'25px'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Text color={'#171717'} fontSize={'14px'} fontWeight={500}>
          Cash in on the sun!
        </Text>
        <Text color={'#171717'} fontSize={'14px'} fontWeight={500}>
          If you refer someone who signs up with GoSolr, you get <strong>R1000 off</strong> your
          monthly subscription the next month!
        </Text>
        <Flex
          justifyContent={'center'}
          alignItems={'center'}
          bgColor={'#FFD635'}
          w={'100%'}
          maxW={'270px'}
          height="40px"
          px="25px"
          py={'4px'}
          rounded={'full'}
        >
          <Text fontWeight={700} fontSize={'14px'} letterSpacing={'widest'}>
            {referralCode}
          </Text>
        </Flex>
        <Socials referralCode={referralCode} />
      </Flex>
    </>
  )
}

export default DBPromoCode
