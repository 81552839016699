import React from 'react'
import { Button, StyleProps, ButtonProps, Flex, Text, Box } from '@chakra-ui/react'
import { ImCheckmark } from 'react-icons/im'
export type Props = StyleProps & ButtonProps & {}

export const ButtonStandard: React.FC<Props> = ({
  children,
  w = '100%',
  maxW = '100%',
  type = 'button',
  variant = 'dark',
  ...rest
}) => {
  return (
    <Button
      type={type}
      variant={variant}
      // bgColor={bgColor}
      // color={color}
      // rounded={rounded}
      w={w}
      maxW={maxW}
      px="50px"
      py="4px"
      fontWeight={400}
      {...rest}
    >
      {children ? children : 'Button'}
    </Button>
  )
}

export type ButtonPillProps = StyleProps & ButtonProps & {} & { isActive?: boolean; title: string }
export const ButtonPill: React.FC<ButtonPillProps> = ({
  children,
  title = 'Button',
  isActive = false,
  ...rest
}) => {
  return (
    <Button
      name={title}
      type={'button'}
      color={'brand.text.primary'}
      bgColor={isActive ? 'brand.primary' : 'none'}
      h="auto"
      w={'min-content'}
      pl="15px"
      pr="15px"
      py="4px"
      rounded={'full'}
      fontWeight={isActive ? 500 : 400}
      _hover={{ bgColor: 'brand.primary' }}
      {...rest}
    >
      <Flex alignItems="center" gap="5px" userSelect={'none'} pointerEvents="none">
        <Text fontSize={'sm'}>{title}</Text>
        <Flex
          hidden={!isActive}
          alignItems="center"
          justifyContent="center"
          bgColor={'brand.secondary'}
          w="15px"
          h="15px"
          rounded={'full'}
          color="brand.primary"
        >
          <ImCheckmark size={10} />
        </Flex>
        {/* {isActive && (

        )} */}
      </Flex>
    </Button>
  )
}
