import { FC, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import { SolarmanDeviceCurrentDataItem, UserProperty } from '../../generated/graphql'

import EnvironmentalEconomicBenefits from './Stats/EnvironmentalEconomicBenefits'

import { PropertyMainContainer } from './PropertyMainContainer'

type PropertyType = {
  property: UserProperty
}

const PropertyStatsContainer: FC<PropertyType> = ({ property }) => {
  const [systemData, setSystemData] = useState<SolarmanDeviceCurrentDataItem[]>()
  const [sytemDataLoading, setSystemDataLoading] = useState<boolean>(true)
  return (
    <>
      <Flex
        w="100%"
        h="100%"
        maxH={'100%'}
        gap="20px"
        direction={['column', 'column', 'column', 'row']}
      ></Flex>
      <PropertyMainContainer property={property} />
      <EnvironmentalEconomicBenefits systemData={systemData} sytemDataLoading={sytemDataLoading} />
      {/* <GridStats systemData={systemData} sytemDataLoading={sytemDataLoading} isDark={false} />
      <BatteryStats systemData={systemData} sytemDataLoading={sytemDataLoading} isDark={false} />
      <ProductionStats systemData={systemData} sytemDataLoading={sytemDataLoading} isDark={false} />
      <ConsumptionStats
        systemData={systemData}
        sytemDataLoading={sytemDataLoading}
        isDark={false}
      /> */}
    </>
  )
}

export default PropertyStatsContainer
