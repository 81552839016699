import { Outlet, useLocation, useParams } from 'react-router-dom'
import { Exact, UserProperty, useGetUserPropertiesQuery } from '../../../generated/graphql'
import { useEffect, useState } from 'react'
import { validateRecordIdPath } from '../../../helpers/auth'
import { Flex, Image, Text } from '@chakra-ui/react'
import OnboardingPathValidationProvider from '../../../context/OnboardingPathValidationProvider/OnboardingPathValidationProvider'

const OnboardingPathValidator = ({
  customerNumber,
  email
}: {
  customerNumber: string
  email: string
}) => {
  // const { recordId } = useParams()
  // const [property, setProperty] = useState<UserProperty>()
  // const [isPathValid, setIsPathValid] = useState<boolean>(false)
  // const [loadingProperties, setLoadingProperties] = useState(true)
  // const location = useLocation()

  // const { refetch: fetchUserProperties } = useGetUserPropertiesQuery({
  //   variables: {
  //     customerNumber: customerNumber!
  //   }
  // })

  // useEffect(() => {
  //   const retrieveUserProperties = async () => {
  //     setLoadingProperties(true)
  //     const { data, loading } = await fetchUserProperties(
  //       customerNumber as Partial<Exact<{ customerNumber: string }>>
  //     )
  //     setLoadingProperties(false)
  //     const propertiesResponse = data.getUserProperties

  //     if (propertiesResponse?.__typename === 'ErrorResponse') {
  //       console.log('there was an error retrieving user properties')
  //     }
  //     if (propertiesResponse?.__typename === 'UserProperties') {
  //       const isValidPath = await validateRecordIdPath(recordId, propertiesResponse?.properties)
  //       if (isValidPath) {
  //         const userProperty = propertiesResponse?.properties?.filter(
  //           (item) => item?.recordId === recordId
  //         )
  //         if (userProperty && userProperty.length > 0) {
  //           setProperty(userProperty[0] as UserProperty)
  //         }
  //       }
  //       setIsPathValid(Boolean(isValidPath))
  //     }
  //   }
  //   retrieveUserProperties()
  // }, [])

  // if (loadingProperties) {
  //   const sunGif = '/brand/GoSolr_website-logo.gif'
  //   return (
  //     <Flex
  //       bg={'#FFD200'}
  //       alignItems={'center'}
  //       justifyItems={'center'}
  //       direction={'column'}
  //       position={'absolute'}
  //       inset={0}
  //       zIndex={0}
  //     >
  //       <Image minH={'600px'} maxH={'600px'} objectFit={'cover'} src={sunGif} />
  //       <Text fontSize={'3xl'} textAlign={'center'} w="full">
  //         <span style={{ fontWeight: 900 }}>Loading...</span>
  //       </Text>
  //     </Flex>
  //   )
  // }

  // if (!loadingProperties && !isPathValid) {
  //   return (
  //     <Flex
  //       direction={'column'}
  //       gap={25}
  //       bg={'brand.neutral'}
  //       shadow={'md'}
  //       rounded={'lg'}
  //       padding={4}
  //     >
  //       You are not authorised to view this page
  //     </Flex>
  //   )
  // }

  return (
    <>
      <OnboardingPathValidationProvider customerNumber={customerNumber} email={email}>
        <Outlet />
      </OnboardingPathValidationProvider>
    </>
  )
}

export default OnboardingPathValidator
