import React, { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { Flex, Text, Button, Image, Center } from '@chakra-ui/react'

const OnboardingCompleted: FC = () => {
  const handleNavigateToMainPage = () => {
    window.open('https://www.gosolr.co.za')
  }

  return (
    <Flex
      width={'100%'}
      height={'100vh'}
      position={'absolute'}
      top={0}
      left={0}
      direction={'column'}
      gap="50px"
      alignItems={'center'}
      justifyContent="center"
      color={'brand.text.primary'}
      backgroundColor={'#FBBD30'}
      zIndex={2}
    >
      <Center w="full" maxW={'500px'} top={0} left={0}>
        <Image
          w="full"
          maxW={'500px'}
          objectFit={'cover'}
          src={'/images/you-have-the-power-transparent.png'}
        />
      </Center>
      <Flex
        direction={'column'}
        gap="25px"
        alignItems={'center'}
        justifyContent="center"
        zIndex={2}
      >
        <Text>You are one step closer to engergy independence.</Text>
        <Text>Our friendly consultants will be in touch to help you get setup.</Text>
      </Flex>
      <Button
        variant="dark"
        onClick={(e) => {
          handleNavigateToMainPage()
        }}
      >
        Got it!
      </Button>
    </Flex>
  )
}

export default OnboardingCompleted
