import React, { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { Box, Center, Flex, Spinner, Text, Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react'
import { ContainerHeading } from '../../../components/Headings'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  useGetUserPropertyQuery,
  useGetUserQuery,
  User,
  UserProperty
} from '../../../generated/graphql'
import { ordinal } from '../../../utils'
import {
  buyoutFees as productProductFees,
  upgradeCosts,
  UpgradeFeesType
} from '../../../data/buyoutFees'
import { ProductFeesType } from '../../../types'
import { AgreementsContext } from '../../../context/Agreements/AgreementsContext'
import { useParams } from 'react-router-dom'

type Props = {
  setTitle: Dispatch<SetStateAction<string>>
}

const SubscriptionAgreementPage: React.FC<Props> = ({ setTitle }) => {
  const { recordId } = useParams()
  const { user } = useAuthContext()
  const { data: propertyData, loading: loadingProperties } = useGetUserPropertyQuery({
    variables: {
      recordId: recordId!
    }
  })
  const property = propertyData?.getUserProperty as UserProperty
  const [solarSystemInstalledCapacity, setSolarSystemInstalledCapacity] = useState<string>()
  const [selectedSubscriptionName, setSelectedSubscriptionName] = useState<string>()
  const [loadingData, setLoadingData] = useState(true)
  const [productFees, setProductFees] = useState<ProductFeesType>({})
  const [upgradeFees, setUpgradeFees] = useState<UpgradeFeesType | undefined>(() => ({
    from: '',
    upgradeTo: '',
    flatRoofFee: '',
    battery: '',
    panels: '',
    upgrade: '',
    upgrade_plus_battery: '',
    upgrade_plus_panels: '',
    upgrade_plus_battery_plus_panels: '',
    upgrade_plus_battery_plus_four_panels: ''
  }))

  const [signedDateAndPlace, setSignedDateAndPlace] = useState(() => ({
    signedAt: 'GO SOLR Web App',
    signedDay: '',
    signedMonthAndYear: '',
    timestamp: ''
  }))

  const { data: userData } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  const recentUserData = userData?.getUser as User

  useEffect(() => {
    const getCurrentTimeData = async () => {
      const currentDate = await new Date()
      const nameOfMonth = await new Intl.DateTimeFormat('en-US', { month: 'long' }).format(
        new Date()
      )

      let currDay: number = currentDate.getDate()
      let signedDay = ordinal(currDay)

      const signedDate = {
        signedAt: 'GO SOLR Web App',
        signedDay: signedDay,
        signedMonthAndYear: `${nameOfMonth} ${currentDate.getFullYear()}`,
        timestamp: currentDate.toLocaleTimeString()
      }

      setSignedDateAndPlace(signedDate)
    }
    getCurrentTimeData()
    const loadData = async () => {
      if (property && recentUserData) {
        const selectedSubscription = property?.subscription?.productName as string

        // Calculate SolarSystem installed Capacity
        if (property?.subscription?.panelCount) {
          const numberOfPanels: number = parseInt(property?.subscription?.panelCount) as number
          const installedCapacity = numberOfPanels * 455
          setSolarSystemInstalledCapacity(`${installedCapacity}Wp`)
        }

        setSelectedSubscriptionName(selectedSubscription?.toLowerCase())
        if (selectedSubscription?.toLowerCase().includes('small')) {
          const upgradeOption = {
            ...upgradeCosts?.smallToMedium,
            from: selectedSubscription.toLowerCase().replace('2023_', '')
          }
          setUpgradeFees(upgradeOption as UpgradeFeesType)
        }
        if (selectedSubscription?.toLowerCase().includes('medium')) {
          const upgradeOption = {
            ...upgradeCosts?.meduimToLarge,
            from: selectedSubscription.toLowerCase().replace('2023_', '')
          }
          setUpgradeFees(upgradeOption as UpgradeFeesType)
        }
        if (
          selectedSubscription?.toLowerCase().includes('large 3phase') ||
          selectedSubscription?.toLowerCase().includes('large 3-phase')
        ) {
          const upgradeOption = {
            ...upgradeCosts?.largeThreePhaseToExtraLarge,
            from: selectedSubscription.toLowerCase().replace('2023_', '')
          }
          setUpgradeFees(upgradeOption as UpgradeFeesType)
        }

        setLoadingData(false)
      }
    }
    loadData()
  }, [recentUserData, propertyData, userData])

  const data = {
    seller: {
      name: 'Go Solr Pty Ltd',
      registrationNumber: '2021/912191/07',
      registeredAddress: '1 Sturdee Ave, Rosebank, Johannesburg, 2196',
      email: 'hello@gosolr.co.za'
    },
    customer: {
      name: recentUserData?.firstName + ' ' + recentUserData?.lastName,
      idNumber: recentUserData?.idPassportNumber || '',
      contactPerson: recentUserData?.firstName + ' ' + recentUserData?.lastName,
      address: property?.fullAddress,
      telNo: recentUserData?.contactNumber,
      email: recentUserData?.email,
      customerCode: recentUserData?.customerNumber,
      ownership: property?.customerType,
      premisesOwner: recentUserData?.firstName + ' ' + recentUserData?.lastName
    },
    subscriptionName: selectedSubscriptionName?.toLowerCase(),
    adminFee: property?.subscription?.onceOffFee,
    additionalFlatRoofFee: property?.subscription?.flatRoofFee,
    upgradeFees: {
      upgradeFrom: upgradeFees?.from,
      upgradeTo: upgradeFees?.upgradeTo,
      flatRoofFee: upgradeFees?.flatRoofFee,
      battery: upgradeFees?.battery,
      panels: upgradeFees?.panels,
      upgrade: upgradeFees?.upgrade,
      upgrade_plus_battery: upgradeFees?.upgrade_plus_battery,
      upgrade_plus_panels: upgradeFees?.upgrade_plus_panels,
      upgrade_plus_battery_plus_panels: upgradeFees?.upgrade_plus_battery_plus_panels,
      upgrade_plus_battery_plus_four_panels: upgradeFees?.upgrade_plus_battery_plus_four_panels
    },
    fixedMonthlyFee: property?.subscription?.subscriptionFee,
    deInstallationFee: property?.subscription?.de_installation_fee_after_year_three as number,
    reducedDeInstallationFee: productFees?.de_installation_fee_then,
    callOutFee: 650,
    annualEscalationYear: 2024,
    sellerBank: {
      bank: 'Standard Bank',
      branchCode: '051001',
      electronicAccNumber: '281046581'
    },
    customerBank: {
      customerName: property?.memberName,
      bank: property?.bankName,
      accNumber: property?.bankAccountNumber,
      accType: property?.bankAccountType,
      branchCode: property?.branchCode,
      debitOrderDate: recentUserData?.debitOrderDate
    },
    purchasePrices: {
      one: '-',
      two: '-',
      three: property?.subscription?.buyout_year_three as number,
      four: property?.subscription?.buyout_year_four as number,
      five: property?.subscription?.buyout_year_five as number,
      six: property?.subscription?.buyout_year_six as number,
      seven: property?.subscription?.buyout_year_seven as number,
      eight: 'As notified by the Seller to the Customer'
    },
    solarSystemSpecs: {
      location: 'See Schedule 3',
      capacity: solarSystemInstalledCapacity,
      expectedStructure: 'Roof mount',
      additionalMaterials:
        'Aluminium A-Frames as required if a flat-roof installation is deemed necessary by the Seller',
      premisesAndLayout: 'See Schedule 3'
    },
    batterySpecs: {
      location: 'See Schedule 3',
      capacity: property?.subscription?.batterySpec,
      description: property?.subscription?.batterySpec,
      premisesAndLayout: 'See Schedule 3'
    },
    inverterSpecs: {
      location: 'See Schedule 3',
      description: property?.subscription?.inverterSpec,
      premisesAndLayout: 'See Schedule 3'
    },
    ...signedDateAndPlace
  }

  if (loadingData || loadingProperties) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Generating your subscription agreement document</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Seller" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Name:</b> {data.seller.name}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Registration number:</b> {data.seller.registrationNumber}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Registered address:</b> {data.seller.registeredAddress}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>E-mail:</b> {data.seller.email}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Customer" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Name:</b> {data.customer.name}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Identity number/registration number:</b> {data.customer.idNumber}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Contact person:</b> {data.customer.name}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Registered address:</b> {data.customer.address}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Tel. no:</b> {data.customer.telNo}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>E-mail:</b> {data.customer.email}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Premises Ownership */}
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Premises" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              Premises as detailed in Schedule 3 (Premises).
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Premises ownership" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              Customer <b>{data.customer.ownership === 'Home owner' ? 'owns' : 'leases'} </b>the
              Premises.
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              List owner of the Premises, if different from Customer:{' '}
              <b>{data.customer.ownership === 'lease' ? data.customer.premisesOwner : ''}</b>
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Term & Termination */}
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Term" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              The Agreement shall commence on the Signature Date and shall endure indefinitely until
              terminated in accordance with the terms hereof.
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Termination on customer notice" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              The Customer is entitled to terminate this Agreement at any time on 20 days' prior
              written notice to the Seller.
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              In the event of an early termination of this Agreement by the Customer, the Customer
              is liable to pay to the Seller, within 10 days of the Termination Date, the
              De-Installation Fee and any and all other amounts previously accrued under this
              Agreement and then owed by the Customer to the Seller.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Admin Fee & Additional Fees */}
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Administration fee" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>R{data.adminFee}</b> (including VAT) once off via electronic funds transfer (EFT)
              Payable by the Customer to the Seller on the Signature Date.
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Additional installation fee" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              Additional once off amount equal to
              <b> R{data.additionalFlatRoofFee}</b> (including VAT) where the Customer is deemed to
              have a flat roof installation where additional installation materials (as may be
              specified in Schedule 2 - Specifications) are required.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Upgrade Fees*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Upgrade fees" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            Additional fees ("Upgrade Fees") will be invoiced to the Customer by the Seller in
            respect of any upgrade to the Solar System following its installation at a Customer's
            Premises.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            These Upgrade Fees will correspond to the Administration Fees to the new solution
            selected by the Customer as follows -
          </Text>

          <Flex direction={'column'} mt={'10px'} gap="10px">
            {!selectedSubscriptionName?.toLowerCase().trim().includes('battery') &&
              !selectedSubscriptionName?.toLowerCase().includes('small') && (
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  1 additional 100Ah lithium battery – <b>R690</b> (including VAT)
                </Text>
              )}
            {/* <Text fontSize={['11px', '12px', '12px', '12px']}>
              2 additional 455W solar panels – <b>R210</b> (including VAT)
            </Text> */}
            {(selectedSubscriptionName?.toLowerCase().includes('medium') ||
              selectedSubscriptionName?.toLowerCase().includes('small') ||
              selectedSubscriptionName?.toLowerCase().includes('large 3phase')) && (
              <>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  {`[upgrade from ${data.upgradeFees.upgradeFrom} to ${data.upgradeFees.upgradeTo} installation]`}{' '}
                  – <b>{data.upgradeFees.upgrade}</b> (including VAT)
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  {`[upgrade from ${data.upgradeFees.upgradeFrom} to ${data.upgradeFees.upgradeTo} + battery installation]`}{' '}
                  – <b>{data.upgradeFees.upgrade_plus_battery}</b> (including VAT)
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  {`[upgrade from ${data.upgradeFees.upgradeFrom} to ${data.upgradeFees.upgradeTo} + 2 panels installation]`}{' '}
                  – <b>{data.upgradeFees.upgrade_plus_panels}</b> (including VAT)
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  {`[upgrade from ${data.upgradeFees.upgradeFrom} to ${data.upgradeFees.upgradeTo} + 2 panels installation + battery]`}{' '}
                  – <b>{data.upgradeFees.upgrade_plus_battery_plus_panels}</b> (including VAT)
                </Text>
                {selectedSubscriptionName?.toLowerCase().includes('medium') && (
                  <Text fontSize={['11px', '12px', '12px', '12px']}>
                    {`[upgrade from ${data.upgradeFees.upgradeFrom} to ${data.upgradeFees.upgradeTo} + 4 panels installation + battery]`}{' '}
                    – <b>{data.upgradeFees.upgrade_plus_battery_plus_four_panels}</b> (including
                    VAT)
                  </Text>
                )}
              </>
            )}
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              or such other fees as may be quoted by the Seller from time to time in respect of
              upgrades not specified above.
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Fixed monthly fee*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Fixed monthly fee" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>R{data.fixedMonthlyFee}</b> (including VAT) per month via debit order.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            Payable by the Customer to the Seller in advance, for the duration of the Term within 7
            days of the date of invoice therefor.
          </Text>
        </Flex>
      </Flex>
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Additional amount to be added to Fixed Monthly Fee stated above where Seller has elected to be the Insuring Party" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            R50 (excl VAT) or such other amount as agreed between the parties.
          </Text>
        </Flex>
      </Flex>
      {/* De-Installation fee*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="De-Installation fee" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>R{data.deInstallationFee}</b> (including VAT) (or such lesser amount as may be
            notified by the Seller to the Customer). The De-Installation Fee is the amount payable
            by the Customer to the Seller for the de-installation of the Solar System, following
            cancellation of this Agreement and where the Customer has not elected to purchase the
            Solar System.
          </Text>
        </Flex>
      </Flex>
      {/* Call out fee*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Call out fee" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The call out Fee is initially <b>R{data.callOutFee}</b> (including VAT) as at the
            Signature Date and will be escalated or varied from time to time by the Seller and
            communicated to the Customer by way of written notice.
          </Text>
        </Flex>
      </Flex>
      {/* Annual escalation*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Annual escalation" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>
              {' '}
              The Fixed Monthly Fee (including any additional amount added where Seller elects to be
              the Insuring Party) will:
            </b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            - from July <b>{data.annualEscalationYear}</b>, escalate annually on the first day of
            July each year (capped at CPI)
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The <b>De-Installation Fee</b> will not escalate, unless notified in writing by the{' '}
            Seller to the Customer on no less than 30 days’ written notice, in line with CPI.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>The Upgrade Fees will: </b>
          </Text>

          <Text fontSize={['11px', '12px', '12px', '12px']}>
            - from July <b>{data.annualEscalationYear}</b>, escalate annually on the first day of
            July each year (capped at CPI)
          </Text>
        </Flex>
      </Flex>
      {/* Insuring party*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Insuring party" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Customer, unless the Customer gives notice in writing to the Seller requesting that
            the Seller be the Insuring Party and the Seller expressly notifies the Customer that it
            elects to be the Insuring Party pursuant to such request.
          </Text>
        </Flex>
      </Flex>
      {/* Seller’s Bank Account & Customers Bank Account*/}
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Seller’s bank account" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Bank: </b> {data.sellerBank.bank}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Branch Code: </b> {data.sellerBank.branchCode}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Electronic Account No: </b>
              {data.sellerBank.electronicAccNumber}
            </Text>
          </Flex>
        </Flex>
        <Flex
          w={['100%', '100%', '100%', '50%']}
          gap="20px"
          direction={'column'}
          bgColor="brand.neutral"
          p="25px"
          rounded="8px"
        >
          <ContainerHeading title="Customers bank account" />
          <Flex w="full" direction={'column'} gap="8px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Name: </b> {data.customerBank.customerName}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Bank: </b> {data.customerBank.bank}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Account Number: </b> {data.customerBank.accNumber}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Account Type: </b> {data.customerBank.accType}
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Branch Code: </b> {data.customerBank.branchCode}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      {/* Debit Order & Date of Customer’s Bank Account Debit*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Debit order" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Customer hereby instructs and authorises the Seller to draw direct debits against
            the Customer's bank account for an amount equal to the amounts owing by the Customer
            under this Agreement as invoiced for each month in advance. The Customer shall complete
            and sign a debit order mandate in the form specified by the Seller from time to time for
            purposes of authorising the Seller to such draw direct debits.
          </Text>
        </Flex>
      </Flex>
      {/* Date of Customer’s Bank Account Debit*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Date of Customer’s bank account debit" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>Debit order date: </b>
            {data.customerBank.debitOrderDate}
          </Text>
        </Flex>
      </Flex>
      {/* Purchase of the Solar System*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Purchase of the solar system" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            The Customer has the option at any time during the Term following the third anniversary
            of the Signature Date to purchase the Solar System (and all its component parts,
            specifically excluding the Solar Software) from the Seller ("Purchase Option"). The
            option may be exercised by the Customer at any time during the Term by the Customer
            giving written notice thereof to the Seller.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            If the Purchase Option is exercised, the purchase price of the Solar System shall be
            equivalent to the Purchase Price at the proposed date of purchase.
          </Text>
        </Flex>
      </Flex>
      {/* Purchase price*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Purchase price" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            In the event that the Customer elects to purchase the Solar System in accordance with
            the General Terms and Conditions of Contract, the Minimum Buyout Fee used to determine
            the Purchase Price is set out below:
          </Text>
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
              <Table w={'100%'} color={'brand.text.primary'}>
                <Thead>
                  <Tr>
                    <Th
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                      color={'brand.text.primary'}
                    >
                      Operations year (anniversary of the signature date)
                    </Th>
                    <Th
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                      color={'brand.text.primary'}
                    >
                      Minimum buyout fee (inclusive of VAT)
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      1
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.one}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      2
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.two}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      3
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.three}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      4
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.four}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      5
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.five}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      6
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.six}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      7
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.seven}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      8+
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.purchasePrices.eight}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Flex>
      </Flex>

      {/* Agreement*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Agreement" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1 AGREEMENT</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.1 </b> This Subscription Agreement is made up of the –
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.1.1 </b> The Contract Details; and
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.1.2 </b> The Schedules: Schedule 1: Special Terms, Schedule 2: Specifications,
            Schedule 3: Premises, Schedule 4: <br />
            Required Documents, Schedule 5: Form of Owner's Consent, Schedule 6: General Terms and
            Conditions, (together the “Agreement”), together with the privacy policy which may be
            accessed via the link – www.gosolr.co.za/privacy ("Privacy Policy").
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.2 </b> This Agreement records the terms and conditions governing the construction,
            financing, insurance of, operation and maintenance of the Solar System, and the billing
            of the Fixed Monthly Fee for use of the Solar System.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.3 </b> The Customer acknowledges that it has read and understood the General Terms
            and Conditions attached hereto as Schedule 6 (General Terms and Conditions) and that by
            signing this Agreement, the Customer agrees to be bound by the General Terms and
            Conditions.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.4 </b> Capitalised terms in the Contract Details shall, unless the context
            indicates otherwise, have the meaning given to them in the General Terms and Conditions.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.5 </b> The Customer acknowledges that the Contract Details is intended to be a
            summary of the key terms of the Agreement and is not intended to amend, replace,
            supersede or deviate from the General Terms and Conditions.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.6 </b> The Special Terms (if applicable) attached hereto as Schedule 1 ("Special
            Terms") are specific amendments to the Contract Details, General Terms and Conditions
            and/or the Schedules as agreed between the Seller and the Customer from time to time and
            only applicable if completed and signed by both Seller and the Customer in writing.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1.7 </b> This Agreement must be read together with the Privacy Policy which may be
            accessed via the Seller's website. Customer to please also read the Privacy Policy to
            understand the types of personal data the Seller collects about the Customer, where the
            Seller collects it from, with whom the Seller may need to share it, how long the Seller
            retains it for, and how the Customer can request access to and/or deletion of its
            personal data.
          </Text>
        </Flex>
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>2 SIGNATURE</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>2.1 </b> This Agreement is Signed by the Parties on the dates and at the places
            indicated below.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>2.2 </b> This Agreement may be executed in counterparts, each of which shall be
            deemed an original, and all of which together shall constitute one and the same
            Agreement as at the date of Signature of the Party last signing one of the counterparts.
          </Text>
        </Flex>

        <Box marginTop="25px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>FOR AND ON BEHALF OF THE CUSTOMER:</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed by: <b>{data.customer.name}</b> with ID/Passport number{' '}
            <b>{data.customer.idNumber}</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
            <b>
              {data.signedMonthAndYear}, {data.timestamp}
            </b>
          </Text>
        </Box>
      </Flex>
      {/* Schedule 2 - Specifications*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Schedule 2 - Specifications" />
        <Flex w="full" direction={'column'} gap="8px">
          {/* Solar System Specs */}
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
              <b>The specifications of the Solar System shall be:</b>
            </Text>
            <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
              <Table w={'100%'}>
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Solar system location:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.solarSystemSpecs.location}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Solar system installed capacity:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.solarSystemSpecs.capacity}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Expected structure:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.solarSystemSpecs.expectedStructure}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Additional installation materials:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.solarSystemSpecs.additionalMaterials}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Premises and Solar system layout:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.solarSystemSpecs.premisesAndLayout}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
          {/* Battery System Specs */}
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
              <b>The specifications of the Battery shall be:</b>
            </Text>
            <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
              <Table w={'100%'}>
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Battery location:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.batterySpecs.location}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Battery capacity:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.batterySpecs.capacity}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Battery description and technical specification:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.batterySpecs.description}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Premises and Battery layout :</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.batterySpecs.premisesAndLayout}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
          {/* Battery System Specs */}
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
              <b>The specifications of the Inverter shall be:</b>
            </Text>
            <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
              <Table w={'100%'}>
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Inverter location:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.inverterSpecs.location}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Inverter description and technical specification:</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.inverterSpecs.description}
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      <b>Premises and Inverter layout :</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      w="50%"
                      border={'1px solid black'}
                    >
                      {data.inverterSpecs.premisesAndLayout}
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
        </Flex>
      </Flex>

      {/* Schedule 3 - Premises*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Schedule 3 - Premises" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>[To be inserted by technical team.]</b>
          </Text>
        </Flex>
      </Flex>
      {/*Schedule 4*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Schedule 4" />
        <Flex w="full" direction={'column'} gap="8px">
          {/* Solar System Specs */}
          <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
            <Box overflowX={'auto'} w="100%" maxW={'100vw'}>
              <Table w={'100%'}>
                <Thead>
                  <Tr>
                    <Th
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Documents</b>
                    </Th>
                    <Th
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Required if the Customer is a natural person
                    </Th>
                    <Th
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Required if the Customer is a legal entity
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>
                        Completed and signed consent to credit check as set out in Annexure 1 to
                        this Schedule 4
                      </b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>
                        Completed and signed debit order mandate in the form specified by the Seller
                      </b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Identification Document or Passport</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      No
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Constitutional documents</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      No
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Latest power utility bill or token for electricity if prepaid</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Proof of address in the form of latest utility bill </b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Latest 3 month's payslips</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      No
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      <b>Latest 3 month's bank statements</b>
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                    <Td
                      textAlign="center"
                      padding={'8px'}
                      fontSize={['11px', '12px', '13px', '14px']}
                      border={'1px solid black'}
                    >
                      Yes
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </Box>
          </Box>
          <Flex w="full" direction={'column'} gap="8px" mt="25px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>Annexure 1 to Schedule 4 – Consent to Credit Check </b>
            </Text>
          </Flex>
          <Flex w="full" direction={'column'} gap="8px" mt="25px">
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              I/We, the undersigned, hereby duly authorises <b>GO SOLR Proprietary Limited</b>
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              <b>("the Seller")</b>, to obtain a credit report from a credit reporting company
              chosen by the Seller.
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              I/We agree and understand that the credit report will be used to determine whether it
              is in the Seller's interest to conclude an agreement with me/us.
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              I/We understand that this report will solely be used by the Seller and will not be
              disclosed to any third party without prior written consent from me/us.
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']} mt="25px">
              Signed by: <b>{data.customer.name}</b> with ID/Passport number{' '}
              <b>{data.customer.idNumber}</b>
            </Text>
            <Text fontSize={['11px', '12px', '12px', '12px']}>
              Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
              <b>
                {data.signedMonthAndYear}, {data.timestamp}
              </b>
            </Text>
          </Flex>
        </Flex>
      </Flex>

      {/* Schedule 5 - Form of owner's consent*/}
      <Flex
        w={['100%', '100%', '100%', '100%']}
        gap="20px"
        direction={'column'}
        bgColor="brand.neutral"
        p="25px"
        rounded="8px"
      >
        <ContainerHeading title="Schedule 5 - Form of owner's consent" />
        <Flex w="full" direction={'column'} gap="8px">
          <Text fontSize={['11px', '12px', '12px', '12px']}>Dear</Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            {data.customer.premisesOwner} <b> ("Landlord") </b>is the registered owner of the
            premises located at {data.customer.address} <b> ("Premises"). </b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            Reference is made to the Subscription Agreement entered into or to be entered into
            between and GO SOLR Proprietary Limited in terms of which the Seller is appointed to
            install and maintain a solar photovoltaic system <b>(the "Solar System")</b> for the
            purpose of providing electric energy to portions of the facilities located on the
            Premises and which is currently occupied by the Customer{' '}
            <b>("Subscription Agreement").</b>
          </Text>
          <Box mt="25px"></Box>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>The Landlord (or his/its duly authorised agent) confirms as follows:</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>1. </b> The Landlord is the registered owner of the Premises where the Solar System
            is to be installed.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>2. </b> The Customer is the lessee of the Premises in terms of a valid lease
            agreement entered into with the Landlord, which lease agreement commenced on and will
            continue until ("Premises Lease Agreement").
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>3. </b> The Landlord hereby consents to the installation of the Solar System, on the
            terms and conditions set out in the Subscription Agreement and has no objections
            thereto.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>4. </b> The Landlord is aware of the Access Rights (as defined in the Subscription
            Agreement) that is granted to the Seller and, insofar it is necessary, confirms and
            affirms/consents to the Access Rights as granted by the Customer to the Seller in terms
            of the Subscription Agreement.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>5. </b> The Landlord is aware of the fact that the Solar System will remain the
            property of the Seller and will not become part of the immovable property that is the
            Premises.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>6. </b> The Landlord will inform all third parties that has/might have/will have a
            right of retention and/or lien over the Building and the Premises of the fact that the
            Solar System does not and will not become part of the immovable property that is the
            Premises and accordingly cannot be attached due to the Customer's default in terms of
            the Premises Lease Agreement.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>7. </b> The Landlord will, upon being so requested, provide the Seller with written
            confirmation that any bondholder, or other third party holding a real right in the
            Premises has been duly informed of the ownership of the Solar System.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>8. </b> The Landlord will provide to the Seller a copy of any breach notices that may
            be sent to the Customer in terms of the Premises Lease Agreement and further advise the
            Seller as to whether the Premises Lease Agreement will potentially terminate if the
            breach is not remedied by the Customer.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>9. </b> Should the Premises Lease Agreement be terminated by either the Landlord or
            the Customer, for any reason whatsoever, and with immediate effect, the Landlord shall
            allow the Seller a period of 3 months within which to dismantle and remove the Solar
            System from the Premises, during which period the Landlord will not be liable for any
            electrical energy, water or other consumables that may be consumed on the Premises, nor
            any Monthly Payment to the Seller.
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']}>
            <b>10. </b> The Landlord will advise the Seller should it cede and assign its rights and
            obligations in terms of the Premises Lease Agreement to any third party and shall ensure
            that such third party is aware of the rights and undertakings provided herein and will
            remain bound by same. The above shall apply equally in the event of the Landlord wishing
            to dispose of the Premises to a third party.
          </Text>
        </Flex>

        <Box marginTop="25px">
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed by: <b>{data.customer.name}</b> with ID/Passport number{' '}
            <b>{data.customer.idNumber}</b>
          </Text>
          <Text fontSize={['11px', '12px', '12px', '12px']} mt={'25px'}>
            Signed at <b>{data.signedAt}</b> on this <span>{data.signedDay}</span> day of{' '}
            <b>
              {data.signedMonthAndYear}, {data.timestamp}
            </b>
          </Text>
        </Box>
      </Flex>
    </>
  )
}

export default SubscriptionAgreementPage
