import React, { FC, useEffect, useState } from 'react'
import {
  Flex,
  Input,
  FormControl,
  Button,
  Text,
  Heading,
  FormLabel,
  useDisclosure,
  Box,
  FormErrorMessage,
  HStack,
  PinInput,
  PinInputField,
  Link
} from '@chakra-ui/react'
import { Formik, Form } from 'formik'
import * as yup from 'yup'
import { LoginInputs, PasswordResetInputs } from '../../types'
import { useAuthContext } from '../../context/AuthProvider'
import ModalDialog from '../../components/Modals/ModalDialog'
import { FiChevronRight, FiInfo } from 'react-icons/fi'
import TextInput from '../../components/FormElements/TextInput'
import { useSearchParams } from 'react-router-dom'
import { CognitoUser, CognitoUserPool } from 'amazon-cognito-identity-js'
import ConnectedPasswordGroup from '../../components/FormElements/ConnectedPasswordGroup'

const validationSchema = yup.object({
  password: yup
    .string()
    .required('Password is required')
    .matches(
      /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
      'Invalid password. Please ensure password contains capital letters/ numbers and special characters'
    ),
  confirmPassword: yup
    .string()
    .equals([yup.ref('password'), null], 'Passwords must match')
    .required('Password Confirmation is required')
})

const CreateNewPasswordPage = (): JSX.Element => {
  const { user } = useAuthContext()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchParams, setSearchParams] = useSearchParams()
  const email = searchParams.get('email')
  const code = searchParams.get('code')

  const [inputCode, setInputCode] = useState<string | any>(code)
  const [userEmail, setUserEmail] = useState<string | any>(email)

  const [response, setResponse] = useState({
    status: '',
    title: '',
    description: ''
  })
  const poolData: any = {
    UserPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_CLIENT_ID
  }
  const userPool = new CognitoUserPool(poolData)

  const [resendCode, setResendCode] = useState<boolean>(false)

  const {
    isOpen: passwordResetModalIsOpen,

    onOpen: passwordResetModalOpen,

    onClose: passwordResetModalClose
  } = useDisclosure()

  useEffect(() => {
    code && setInputCode(code) // set if not received in useState hook
    setUserEmail(email || user?.info?.email)
  }, [searchParams])

  const handleComplete = (value: string) => {
    setInputCode(value)
  }
  const resendCodeHandler = () => {
    setResendCode(true)

    passwordResetModalOpen()
  }

  return (
    <>
      <Flex
        direction={'column'}
        gap="50px"
        w="100%"
        h="100vh"
        alignItems={'center'}
        justifyContent="center"
        color="brand.text.primary"
      >
        <Flex
          w="full"
          direction={'column'}
          gap="50px"
          alignItems={'center'}
          justifyContent="center"
          color="brand.text.primary"
          px="25px"
        >
          <Heading w="full" textAlign={'center'} maxW="500px">
            Reset password
          </Heading>
          <Text w="full" textAlign={'center'} maxW="500px">
            A reset code was sent to you via email. Enter the code and create a new password.
          </Text>
        </Flex>
        <Formik
          initialValues={{ code: '', password: '', confirmPassword: '' } as PasswordResetInputs}
          validationSchema={validationSchema}
          onSubmit={(values: PasswordResetInputs, { setSubmitting }) => {
            try {
              setSubmitting(true)
              const cognitoUser = new CognitoUser({
                Username: userEmail,
                Pool: userPool
              })
              cognitoUser.confirmPassword(inputCode, values.password, {
                onSuccess() {
                  onOpen()
                  setResponse({
                    status: 'success',
                    title: 'Your password has been changed successfully',
                    description:
                      'We have updated your details, you are now ready to continue with GoSolr'
                  })
                },
                onFailure(err: any) {
                  onOpen()
                  setResponse({
                    status: 'error',
                    title: 'Error',
                    description: err.message
                  })
                }
              })
              setSubmitting(false)
            } catch (e: any) {
              setResponse({
                status: 'error',
                title: 'Error',
                description: e?.message
              })
            }
          }}
        >
          {(props): any => (
            <Form
              onSubmit={props.handleSubmit}
              style={{
                width: '300px',
                textAlign: 'start'
              }}
            >
              <Flex
                direction={'column'}
                gap="50px"
                w="full"
                minW={['250px', '300px', '300px', '300px']}
              >
                <FormControl isInvalid={!props.isValid} mt={1} mb={-2}>
                  <FormLabel htmlFor="code">Reset code</FormLabel>
                  <HStack>
                    <PinInput defaultValue={inputCode && inputCode} onComplete={handleComplete}>
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                      <PinInputField />
                    </PinInput>
                  </HStack>
                  {!props.isValid && (
                    <FormErrorMessage color="crimson">{props.errors.code}</FormErrorMessage>
                  )}
                </FormControl>
                {/* Don't Delete; This is a ghost input to prevent auto populate of code with emails */}
                <input type="text" name="email" style={{ padding: 0, width: 0, height: 0 }} />
                <ConnectedPasswordGroup
                  name="password"
                  label="Password"
                  placeholder="Enter your password"
                  bg="transparent"
                />
                <ConnectedPasswordGroup
                  name="confirmPassword"
                  label="Confirm password"
                  placeholder="Re-enter password"
                  bg="transparent"
                />
                <Flex gap="10px" alignItems={'center'} justifyContent="center">
                  <Text width="auto" textAlign="left">
                    Didn&lsquo;t get a link?{' '}
                  </Text>
                  <Link
                    href="/send-reset-password-link"
                    fontWeight={'bold'}
                    textDecoration="underline"
                  >
                    Resend
                  </Link>
                </Flex>
                <Button
                  variant={'dark'}
                  w="full"
                  type="submit"
                  isLoading={props?.isSubmitting}
                  isDisabled={!props?.isValid}
                >
                  Reset password
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
      </Flex>
      {/*** Reset Password Success Modal ***/}
      <ModalDialog
        status={response.status}
        description={response.description}
        isOpen={isOpen}
        onClose={onClose}
        buttonTextSuccess="Login"
        successLink="/login"
        buttonTextError="Try Again"
        hasCloseIcon={false}
      />
      {/* {resendCode ? (
              <ResendPassword
                isOpen={passwordResetModalIsOpen}
                onClose={passwordResetModalClose}
                email={userEmail}
              />
            ) : null} */}
    </>
  )
}

export default CreateNewPasswordPage
