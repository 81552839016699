/* istanbul ignore file */
import * as React from 'react'
import {
  Flex,
  Image,
  Progress,
  Text,
  useToast,
  FormControl,
  StyleProps,
  useMediaQuery,
  Button,
  Center,
  Spinner
} from '@chakra-ui/react'
import { useField } from 'formik'
import { FileUploader as ReactFileUploader } from 'react-drag-drop-files'

import { ERROR_TOAST } from '../../../../constants'

import { bytesToSize, bytesToValue } from '../../../../helpers'
import { uploadUserFile, uploadProofOfAddress, uploadFile } from '../../../../helpers/api'

import { useAuthContext } from '../../../../context/AuthProvider'
import { User } from '../../../../generated/graphql'
import { useEffect } from 'react'
import { AiOutlineFileAdd, AiOutlineFileDone } from 'react-icons/ai'
import { TbCircleCheck } from 'react-icons/tb'

type FileUploaderProps = StyleProps & {
  name: string
  recordId: string
  proofOfAddress?: boolean
  placeholder?: string
  label?: string
  onUpload?: (upload?: File) => void
  onChange?: (file?: File) => void
  isDisabled?: boolean
  loading?: boolean
  destination: string
}

type ProgressObject = {
  state?: string
  percentage: number
}

type UploadProgress = ProgressObject

/**
 *
 * @param name
 * @param onChange
 * @param onUpload
 * @param rest
 * @constructor
 *
 * NOTE: the "name" param - which comes from the form name - needs to match the same name as the one chosen for the backend, or vice versa.
 */
const FileUploader: React.FC<FileUploaderProps> = ({
  name,
  onChange,
  onUpload,
  proofOfAddress = false,
  recordId,
  loading,
  destination,
  ...rest
}) => {
  const [isTabletOrDesktop] = useMediaQuery('(min-width: 48em)')
  const { user } = useAuthContext()
  const [uploading, setUploading] = React.useState(false)
  const [{ value }, meta, helpers] = useField<File | null>(name)
  const [stateFile, setStateFile] = React.useState<File | null>()
  const [uploadProgress, setUploadProgress] = React.useState<UploadProgress | null>()
  const [uploaded, setUploaded] = React.useState(false)
  const toast = useToast()

  useEffect(() => {
    if (value) {
      setStateFile(value)
    }
  }, [value])

  const progressFileUpload = (progress: ProgressEvent): void => {
    setUploadProgress({
      state: 'pending',
      percentage: Math.round((progress.loaded * 100) / progress.total) - 20
    })
  }

  async function handleChange(file: File) {
    helpers.setError(undefined)
    const MAX_FILE_SIZE = 20000 // 5MB

    // if (file.type !== 'application/pdf' && file.type !== 'image/jpeg') {
    //   return
    // }
    if (
      file.type !== 'application/pdf' &&
      file.type !== 'image/jpeg' &&
      file.type !== 'image/png' &&
      file.type !== 'image/heic'
    ) {
      helpers.setError('File must be a PDF, JPEG, PNG, or HEIC. Please try again.')
      return
    }

    if (parseFloat(bytesToSize(file.size)) > MAX_FILE_SIZE) {
      console.log('file too large: ', bytesToSize(file.size))
      helpers.setError('File must be 5mb or less.')
      return
    }

    setUploaded(false)
    await setStateFile(file)
    onChange && (await onChange(file))

    handleUpload(file)
  }

  const handleUpload = async (file: File) => {
    try {
      setUploadProgress({
        percentage: 20
      })
      helpers.setError(undefined)
      setUploaded(false)
      setUploading(true)

      if (file) {
        if (proofOfAddress) {
          await uploadProofOfAddress(name, user?.info as User, recordId, file, progressFileUpload)
        } else {
          await uploadFile(
            name,
            user?.info as User,
            recordId,
            file,
            destination,
            progressFileUpload
          )
        }

        setUploadProgress({
          percentage: 100
        })

        helpers.setValue(file)

        setTimeout(() => {
          setUploadProgress(null)
          setUploading(false)
          setUploaded(true)
          onUpload && onUpload(file)
        }, 500)
      }
    } catch (e) {
      setUploading(false)
      console.log('....error on file upload....', e)

      console.log(e)
      toast({
        description: 'Something went wrong while uploading your file.',
        ...ERROR_TOAST
      })
    }
  }

  const removeUploadedFile = () => {
    helpers.setValue(null)
    setStateFile(null)
    helpers.setError(undefined)
  }

  const touchedError = meta.error
  //   let border = value && !uploading ? theme.borders.lightBrand : theme.borders.lightBrandDashed

  if (touchedError) {
    // border = '1.5px solid red'
  }

  if (loading) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Loading document</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <Flex id="file-uploader" direction="column" borderRadius={25} width="100%" {...rest}>
      <FormControl>
        <Flex direction="column" align="center">
          <Flex direction="column" w="100%" bgColor={'brand.neutral'}>
            {stateFile && (
              <Flex
                minH={'100px'}
                direction="column"
                justify="center"
                p={4}
                borderRadius={'8px'}
                border={'1px dashed'}
                borderColor={{
                  base: touchedError ? 'red' : 'grey',
                  md: touchedError ? 'red' : stateFile ? 'brand.primary' : 'grey'
                }}
              >
                <Flex align="center">
                  {/* <AiOutlineFileAdd /> */}
                  <Flex direction="column" gap={'15px'} flex={1}>
                    <Flex alignItems={'center'} gap={'15px'}>
                      <AiOutlineFileDone size={25} />
                      <Text fontSize={14} maxWidth={'150px'} noOfLines={1}>
                        {stateFile.name.replace(`${recordId}_`, '')}
                      </Text>
                    </Flex>
                    <Text fontSize={12} color={isTabletOrDesktop ? 'grey' : 'brand.primary'}>
                      {bytesToSize(stateFile.size)}
                    </Text>
                  </Flex>
                  {uploaded && (
                    <Flex
                      color={'brand.success'}
                      gap={'10px'}
                      alignItems={'center'}
                      justifyContent={'center'}
                    >
                      <Text fontSize={14}>Success</Text>
                      <TbCircleCheck size={30} />
                    </Flex>
                  )}
                </Flex>
                {uploading && (
                  <Progress
                    mt={6}
                    width="100%"
                    height={2}
                    colorScheme="brand"
                    rounded="lg"
                    hasStripe={true}
                    isAnimated={true}
                    value={uploadProgress?.percentage || 0}
                  />
                )}
                {meta.error ? (
                  <Text mt={2} ml={3} fontSize="10px" color="red.500" textAlign="left">
                    {meta.error}
                  </Text>
                ) : null}
              </Flex>
            )}
            {!stateFile && !value && (
              <ReactFileUploader name={name} handleChange={handleChange}>
                <Flex
                  direction="column"
                  align="center"
                  _hover={{ cursor: 'pointer' }}
                  p={4}
                  minH={'100px'}
                  borderRadius={'8px'}
                  border={'1px dashed'}
                  borderColor={{
                    base: touchedError ? 'red' : 'grey',
                    md: touchedError ? 'red' : stateFile ? 'brand.primary' : 'grey'
                  }}
                >
                  <Text mt={2} mb={2} align={'center'}>
                    Click to upload file
                  </Text>
                  <Text>or drag and drop</Text>
                  {meta.error ? (
                    <Text mt={2} ml={3} fontSize="10px" color="red.500" textAlign="left">
                      {meta.error}
                    </Text>
                  ) : null}
                </Flex>
              </ReactFileUploader>
            )}
          </Flex>
          <Flex
            mt={stateFile ? '25px' : '0px'}
            w="full"
            justifyContent={'space-between'}
            h={stateFile ? '40px' : '0px'}
          >
            <Button
              variant={'dark'}
              bgColor={'brand.neutral'}
              color={'brand.text.primary'}
              border={'2px solid'}
              borderColor={'brand.text.primary'}
              _hover={{ transform: 'scale(1.01)' }}
              shadow={'none'}
              hidden={!stateFile}
              mr={!uploaded ? 2 : 0}
              onClick={() => removeUploadedFile()}
            >
              Change
            </Button>
            {/* {!uploaded && (
              <Button variant={'dark'} hidden={!stateFile} onClick={() => handleUpload()}>
                Upload
              </Button>
            )} */}
          </Flex>
        </Flex>
      </FormControl>
    </Flex>
  )
}

FileUploader.defaultProps = {
  placeholder: 'Add Files'
}

export default FileUploader
