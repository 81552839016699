import React from 'react'
import { Flex, Box, Icon, Text } from '@chakra-ui/react'
import { IconType } from 'react-icons'
import { WiHorizonAlt } from 'react-icons/wi'
import { RiEditLine } from 'react-icons/ri'
import { BsPersonBadge } from 'react-icons/bs'
import { ImCreditCard } from 'react-icons/im'
import { FiHome } from 'react-icons/fi'

export type StagesType = {
  name: string
  stepCount: number
  icon?: IconType
}
export type ApplicationProgressProps = {
  stepsCompleted: number
}

const ProgressBar = ({ stepsCompleted }: ApplicationProgressProps): JSX.Element => {
  const stages: StagesType[] = [
    { name: 'info', stepCount: 1, icon: WiHorizonAlt },
    { name: 'Creditcheck', stepCount: 2, icon: BsPersonBadge },
    { name: 'Payment', stepCount: 3, icon: ImCreditCard },
    { name: 'Installation', stepCount: 4, icon: FiHome }
  ]

  return (
    <Flex>
      {stages.map((stage, index) => {
        if (index !== stages.length - 1) {
          return (
            <Flex alignItems={'center'} key={`progress_${index}`}>
              <Flex
                position={'relative'}
                direction={'column'}
                alignItems="center"
                justifyContent={'center'}
                gap={2}
              >
                <Flex alignItems="center">
                  <ProgressIcon
                    icon={stage?.icon}
                    stepCount={stage.stepCount}
                    stepsCompleted={stepsCompleted}
                    name={stage.name}
                    active={index + 1 === stepsCompleted + 1}
                  />
                  <Box
                    key={`progress_Box_${index}`}
                    h="3px"
                    w={['20px', '30px', '30px', '35px']}
                    maxW={'35px'}
                    bgColor={'#171717'}
                  />
                </Flex>
                {}
              </Flex>
            </Flex>
          )
        }

        return (
          <Flex alignItems={'center'} key={`progress_${index}`}>
            <Flex
              position={'relative'}
              direction={'column'}
              alignItems="start"
              justifyContent={'center'}
              gap={2}
            >
              <Flex alignItems="center">
                <ProgressIcon
                  icon={stage?.icon}
                  stepCount={stage.stepCount}
                  stepsCompleted={stepsCompleted}
                  name={stage.name}
                  active={index + 1 === stepsCompleted + 1}
                />
              </Flex>
            </Flex>
          </Flex>
        )
      })}
    </Flex>
  )
}
export type ProgressIconType = {
  stepCount: number
  stepsCompleted: number
  icon?: IconType
  name: string
  active: boolean
}
export const ProgressIcon = ({
  icon,
  stepCount,
  stepsCompleted,
  name,
  active
}: ProgressIconType): JSX.Element => {
  return (
    <Flex position={'relative'} direction={'column'} gap={'10px'} alignItems={'center'}>
      <Flex
        w={['40px', '40px', '45px', '50px']}
        h={['40px', '40px', '45px', '50px']}
        rounded={'full'}
        border={'1px solid #171717'}
        alignItems="center"
        justifyContent={'center'}
        bgColor={stepsCompleted >= stepCount ? '' : 'brand.secondary'}
      >
        <Icon
          as={icon}
          w={6}
          h={6}
          color={
            stepsCompleted >= stepCount ? 'brand.text.primary' : active ? 'brand.primary' : 'white'
          }
        />
      </Flex>
      <Text
        position={'absolute'}
        bottom={'-25px'}
        textAlign={'center'}
        fontSize={[9, 10, 11, 11.5]}
        fontWeight={['bold', 'bold', 'normal', 'normal']}
      >
        {name}
      </Text>
    </Flex>
  )
}

export default ProgressBar
