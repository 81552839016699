import { Flex, Button, Box, Text, keyframes, Grid, GridItem, Image } from '@chakra-ui/react'
import { Form, Formik, FormikState, FormikValues, useFormikContext } from 'formik'
import SubmitButton from '../../../FormElements/SubmitButton'
import TextInput from '../../../FormElements/TextInput'
import DropDownInput from '../../../FormElements/DropDownInput'
import { CheckoutSumamryValidation } from '../../../../formValidation/CheckoutSummary'
import { Dispatch, FC, SetStateAction, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import MapModal from './MapModal'
import Tooltip from '../../../Tooltip'
import { IoMdInformationCircleOutline } from 'react-icons/io'
import GoogleMapStandAlone from '../../../FormElements/GoogleMapInput/GoogleMapStandAlone'
import ContainerHeading from '../../../Headings/ContainerHeading'
import formatMoneyValue from '../../../../utils/formatMoneyValue'
import {
  BACKUP_POWER_OPTIONS,
  CUSTOMER_TYPE_OPTIONS,
  DWELLING_TYPES,
  ELECTRICITY_SPEND_OPTIONS,
  HOMEOWNERS_INSURANCE_OPTIONS,
  METER_TYPE_OPTIONS,
  ROOF_ANGLE_OPTIONS,
  ROOF_TYPE_DROPDOWN_OPTIONS
} from '../../../../constants'
import {
  CountryType,
  ElectricitySpendType,
  PhaseType,
  PostalCodeType,
  PreSignUpPropertyType,
  Product,
  UserStorage
} from '../../../../types'
import SubscriptionOptionModal from './SubscriptionOptionModal'
import PropertyLocationForm from './propertyLocation'
import {
  Municipality,
  Subscription,
  useGetMunicpalitiesQuery,
  useUpdateDynamoUserMutation
} from '../../../../generated/graphql'

import ElectricityPhaseUpdateModal from './ElectricityPhaseUpdateModal'

import { useGetValidatedUserAndProperty } from '../../../../context/OnboardingPathValidationProvider/OnboardingPathValidationProvider'
import PhoneInput from '../../../FormElements/PhoneInput'
import { MdLocationPin } from 'react-icons/md'
import { DefaultModal } from '../../../Modals'
import UnsupportedDwellingTypeModal from '../../Pre-singup-flow/PropertyRoof/UnsupportedDwellingTypeModal'
import ConnectedFormGroup from '../../../FormElements/ConnectedFormGroup'

type CheckoutSummaryType = {
  propertyLat: string | number
  propertyLng: string | number
  propertyW3w: string
  fullAddress: string
  streetAddress: string
  streetNumber: string
  streetName: string
  suburb: string
  city: string
  postalCode: PostalCodeType
  province: string
  country: CountryType
  roofLat: number
  roofLng: number
  roofW3w: string
  roofLocationConfirmed: boolean
  subscription: Subscription | null
  roofType: string
  electricityPhaseType: string
  isInsured: string
  electricityProvider: string
  meterType: string
  customerType: string
  isRoofFlat: string | boolean
  monthlyElectricitySpend: string
  backupPowerNeeds: string
  firstName?: string
  lastName?: string
  contactNumber?: string
  dwellingType?: string
}

const pulseAnimation = keyframes`
  0% {
        opacity: 1 
    }
    50% {
          opacity: .5
        }
    100% {
      opacity: 1          
}`
const bounceAnimation = keyframes`
70% { transform:translateY(0%); }
    80% { transform:translateY(-15%); }
    90% { transform:translateY(0%); }
    95% { transform:translateY(-7%); }
    97% { transform:translateY(0%); }
    99% { transform:translateY(-3%); }
    100% { transform:translateY(0); }

`

const initValues: CheckoutSummaryType = {
  fullAddress: '',
  propertyLat: '',
  propertyLng: '',
  propertyW3w: '',
  streetAddress: '',
  streetNumber: '',
  streetName: '',
  suburb: '',
  city: '',
  postalCode: '',
  province: '',
  country: 'South Africa',
  roofLat: 0,
  roofLng: 0,
  roofW3w: '',
  roofLocationConfirmed: true,
  subscription: null,
  roofType: '',
  electricityPhaseType: '',
  isInsured: '',
  isRoofFlat: 'No',
  electricityProvider: '',
  meterType: '',
  customerType: '',
  monthlyElectricitySpend: '',
  backupPowerNeeds: '',
  firstName: '',
  lastName: '',
  contactNumber: '',
  dwellingType: ''
}

type CheckoutSummaryPropsType = {
  recordId: string
  authUser: UserStorage
}

type PropertyLocationInitialValuesType = {
  streetName: string
  streetNumber: string
  city: string
  suburb: string
  province: string
  postalCode: string
}

const CheckoutSummary: FC<CheckoutSummaryPropsType> = ({ authUser }) => {
  const [mapModalIsOpen, setMapModalIsOpen] = useState<boolean>(false)
  const [phaseModalIsOpen, setPhaseModalIsOpen] = useState<boolean>(false)
  const [initialState, setInitialState] = useState<CheckoutSummaryType>(() => initValues)
  const [editRoofActive, setEditRoofActive] = useState<boolean>(false)
  const [currSubscription, setCurrentSubscription] = useState<Product | null>(null)
  const [roofType, setRoofType] = useState<string | null>(null)
  const [isLoadingPage, setIsLoadingPage] = useState<boolean>(true)
  const [showProductUpdateModal, setShowProductUpdateModal] = useState<boolean>(false)
  const [propertyItem, setPropertyItem] = useState<PreSignUpPropertyType | null>(null)
  const [propertyFullAddress, setPropertyFullAddress] = useState<string>('')
  const [propertyCurrdAddress, setPropertyCurrdAddress] =
    useState<PropertyLocationInitialValuesType | null>(null)
  const [municipalities, setMunicipailites] = useState<Municipality[]>([])
  const [providerList, setProviderList] = useState<{ value: string; label: string }[]>([])
  const [previousPhase, setPreviousPhase] = useState<string>('')
  const [updateDynamoUser] = useUpdateDynamoUserMutation()

  const { data: municipalityData } = useGetMunicpalitiesQuery()

  const { recordId, user, property, loading } = useGetValidatedUserAndProperty()

  // console.log('Validated data: ', { recordId, user, property, loading })
  //   This will be use to navigate the user to next page
  const navigate = useNavigate()

  //   Create a try catch for handleSetDate
  useEffect(() => {
    if (property && !loading) {
      if (municipalityData?.getMunicipalities?.__typename === 'Municipalities') {
        const municipalitiesArr = municipalityData?.getMunicipalities?.municipalities
        if (municipalitiesArr) {
          setMunicipailites(municipalitiesArr as Municipality[])
          const providerArr = municipalitiesArr.map((item) => ({
            value: item?.municipality as string,
            label: item?.municipality as string
          }))

          const sortedProviderList = providerArr.sort((a, b) => a.label.localeCompare(b.label))
          setProviderList(sortedProviderList)
        }
      }

      setInitialState({
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        contactNumber: user?.contactNumber || '',
        fullAddress: property?.fullAddress || '',
        propertyLat: property?.propertyLat || '',
        propertyLng: property?.propertyLng || '',
        propertyW3w: property?.propertyW3w || '',
        streetAddress: property?.streetAddress || '',
        streetNumber: property?.streetNumber || '',
        streetName: property?.streetName || '',
        suburb: property?.suburb || '',
        city: property?.city || '',
        postalCode: property?.postalCode || '',
        province: property?.province || '',
        country: 'South Africa',
        roofLat: Number(property?.roofLat) || 0,
        roofLng: Number(property?.roofLng) || 0,
        roofW3w: property?.roofW3W || '',
        roofLocationConfirmed: true,
        subscription: property?.subscription || null,
        roofType: property?.roofType || '',
        electricityPhaseType: property?.electricityPhaseType || '',
        isInsured: property?.insurance || '',
        isRoofFlat: property?.isRoofFlat || '',
        electricityProvider: property?.electricityProvider || '',
        meterType: property?.meterType || '',
        customerType: property?.customerType || '',
        monthlyElectricitySpend: property?.monthlyElectricitySpend || '',
        backupPowerNeeds: property?.backupPowerNeeds || '',
        dwellingType: property?.dwellingType || ''
      })
      const subscription = property.subscription ? (property.subscription as Product) : null

      property && setPropertyItem(property as PreSignUpPropertyType)
      setPropertyCurrdAddress({
        streetNumber: property?.streetNumber || '',
        streetName: property?.streetName || '',
        suburb: property?.suburb || '',
        city: property?.city || '',
        postalCode: property?.postalCode || '',
        province: property?.province || ''
      })

      setPropertyFullAddress(property?.fullAddress!)
      setRoofType(property?.roofType!)
      setCurrentSubscription(subscription)
      setIsLoadingPage(false)
    }
  }, [property, loading])

  const handleSubmit = async (values: CheckoutSummaryType) => {
    const data = { ...values }
    if (data) {
      const userPayload = {
        recordId: user?.recordId, //This record id must reflect the lead recordId that will be updated
        customerNumber: user?.customerNumber, //This will reference the user's customer number in quickbase
        email: user?.email,
        firstName: values?.firstName,
        lastName: values?.lastName,
        contactNumber: values?.contactNumber
      }

      // Remove these values as they aren't required for the property obj only the user obj
      delete values.firstName
      delete values.lastName
      delete values.contactNumber

      const propertyPayload = {
        recordId, //This must be the record id of the property not the user
        ...values,
        insurance: values.isInsured
      }

      const reqBody = {
        user: userPayload,
        property: propertyPayload
      }

      try {
        const url = `${process.env.REACT_APP_API_HOST}/checkout-summary-lead-update`
        await axios
          .post(url, reqBody)
          .then((res) => {
            if (res.data.status === 'failed') {
              throw Error(res.data.message)
            }
          })
          .catch((err) => {
            console.log(err)
            throw Error('Could not update lead')
          })
          .finally(async () => {
            await updateDynamoUser({
              variables: {
                email: userPayload.email as string,
                input: {
                  onboardingProgressCompletedStep: 5
                }
              }
            })
            navigate(`/user/onboarding/credit-type/${recordId}`)
          })
      } catch (e) {
        console.error('Error occurred while trying to update user and property data: ', e)
      }
    }
  }

  const handleConfirmRoofLocation = () => {
    setEditRoofActive(false)
  }
  const bounce_animation = `${bounceAnimation} 2s ease infinite`

  if (isLoadingPage) {
    // @ts-ignore
    const placeholderArr = [...Array(5).keys()]
    const animation = `${pulseAnimation} infinite 2s`

    return (
      <Flex
        w="full"
        direction={'column'}
        alignItems={'center'}
        justifyContent={'center'}
        gap={25}
        padding={25}
      >
        {placeholderArr.map((_, index) => (
          <Box
            key={index}
            w="full"
            maxW={'750px'}
            h={250}
            shadow={'md'}
            bg={'brand.neutral'}
            rounded={'lg'}
            animation={animation}
          ></Box>
        ))}
      </Flex>
    )
  }

  return (
    <Flex
      userSelect={'none'}
      w="full"
      direction={'column'}
      alignItems={'center'}
      justifyContent={'start'}
    >
      <MapModal
        isOpen={mapModalIsOpen}
        onClose={() => setMapModalIsOpen(false)}
        heading={'Confirm roof on map'}
      />

      <Flex direction={'column'} w="full" maxWidth={'2xl'} gap={25}>
        <Flex
          direction={'column'}
          gap={25}
          bg={'brand.neutral'}
          shadow={'md'}
          rounded={'lg'}
          padding={4}
        >
          <ContainerHeading title={'Review your order'} />

          <Text>Review your order details below</Text>
        </Flex>

        <PropertyLocationForm
          initialProperty={propertyCurrdAddress}
          fullAddress={propertyFullAddress}
          setPropertyCurrdAddress={setPropertyCurrdAddress}
        />

        <Formik
          initialValues={initialState}
          validateOnMount
          validateOnBlur={false}
          enableReinitialize
          validationSchema={CheckoutSumamryValidation}
          onSubmit={(values) => handleSubmit(values)}
        >
          {({ values, errors, setFieldValue, isSubmitting, isValidating, submitCount }) => {
            // console.log(values)
            return (
              <>
                <Form>
                  <Flex
                    gap={25}
                    direction={['column']}
                    bg={'brand.neutral'}
                    shadow={'md'}
                    rounded={'lg'}
                    padding={4}
                    mb={25}
                  >
                    <ContainerHeading title={'Personal Details'} />
                    <Flex w={'full'} direction={['column', 'column', 'row', 'row']} gap={25}>
                      <TextInput
                        name="firstName"
                        label="Legal name (As shown on your ID)"
                        placeholder="First name (and middle name)"
                        type="text"
                      />
                      <TextInput
                        name="lastName"
                        label="Last name"
                        placeholder="Last name"
                        type="text"
                      />
                    </Flex>
                    <Flex w={'full'} direction={['column', 'column', 'row', 'row']} gap={25}>
                      <PhoneInput
                        name="contactNumber"
                        label="Phone number"
                        placeholder="Phone number"
                      />
                    </Flex>
                  </Flex>
                  <AddressUpdateObserver
                    setPropertyFullAddress={setPropertyFullAddress}
                    setFieldValue={setFieldValue}
                    propertyUpdatedAddress={propertyCurrdAddress}
                  />
                  <Flex direction={'column'} w="full" maxWidth={'2xl'} gap={25}>
                    <Flex
                      gap={25}
                      direction={['column']}
                      bg={'brand.neutral'}
                      shadow={'md'}
                      rounded={'lg'}
                      padding={4}
                    >
                      <Flex
                        direction={'row'}
                        width="full"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <ContainerHeading title={'Roof'} />

                        <Button
                          onClick={() => {
                            setEditRoofActive((prev) => !prev)
                          }}
                          py={2}
                          px={4}
                          fontWeight="normal"
                          fontSize="sm"
                          size="md"
                          variant="link"
                        >
                          Edit Roof Location
                        </Button>
                      </Flex>
                      {!values.streetAddress ||
                      !values.streetNumber ||
                      !values.province ||
                      !values.city ||
                      !values.suburb ? (
                        <Flex
                          w={'full'}
                          gap={25}
                          direction={'column'}
                          alignItems={'center'}
                          justifyContent={'center'}
                        >
                          <Flex direction={'column'}>
                            <Box animation={bounce_animation}>
                              <MdLocationPin size={50} fill="red" />
                            </Box>
                            <Box
                              w={'full'}
                              maxW={'50px'}
                              height={'4px'}
                              rounded={'full'}
                              backgroundColor={'black'}
                            />
                          </Flex>
                          <Text mb={25}>
                            Please complete your property address so that we can try and locate your
                            roof.
                          </Text>
                        </Flex>
                      ) : (
                        <>
                          <Text>
                            Place the pin on the roof where you prefer to install the solar panels
                          </Text>
                          <GoogleMapStandAlone
                            handleConfirmLocation={handleConfirmRoofLocation}
                            lat={values.roofLat}
                            lng={values.roofLng}
                            fullAddress={values.fullAddress}
                            isDraggable={editRoofActive}
                            setFieldValue={setFieldValue}
                          />
                        </>
                      )}
                    </Flex>

                    <Flex
                      gap={25}
                      direction={['column']}
                      bg={'brand.neutral'}
                      shadow={'md'}
                      rounded={'lg'}
                      padding={4}
                    >
                      <Flex
                        direction={'row'}
                        width="full"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <ContainerHeading title={'Roof Type'} />

                        <Button
                          py={2}
                          px={4}
                          fontWeight="normal"
                          fontSize="sm"
                          size="md"
                          variant="link"
                        >
                          Edit Roof Type
                        </Button>
                      </Flex>

                      <DwellingTypeObserver />
                      <Grid
                        templateColumns={[
                          'repeat(1, 1fr)',
                          'repeat(1, 1fr)',
                          'repeat(2, 1fr)',
                          'repeat(2, 1fr)'
                        ]}
                        gap={6}
                      >
                        <GridItem>
                          <Flex width={'100%'} direction={'row'} justifyContent={'space-between'}>
                            <Box w="full" maxW={'300px'}>
                              <DropDownInput
                                name="roofType"
                                label="Select your roof type:"
                                options={ROOF_TYPE_DROPDOWN_OPTIONS}
                                placeholder={'Select your roof type'}
                              />
                            </Box>
                            <Tooltip
                              label="Asbestos and Thatched roofs are not accepted by the department of labour.
                                     Please choose an alternative roof type if available."
                              ariaLabel="Roof type tool tip"
                              placement="auto"
                            >
                              <Box m={7} cursor={'pointer'}>
                                <IoMdInformationCircleOutline size={25} />
                              </Box>
                            </Tooltip>
                          </Flex>
                        </GridItem>
                        <GridItem>
                          {' '}
                          <Flex width={'100%'} direction={'row'} justifyContent={'space-between'}>
                            <Box w="full" maxW={'300px'}>
                              <DropDownInput
                                name="isRoofFlat"
                                label="Is your roof flat?"
                                options={ROOF_ANGLE_OPTIONS}
                                placeholder={'Yes / No'}
                              />
                            </Box>
                            <Tooltip
                              label="Solar panels mounted on flat roofs will be mounted flat for safety reasons. However, panels on a flat solar roof produce less power than panels on a sloped roof. Please be aware of this limitation before making your selection."
                              ariaLabel="Roof type tool tip"
                              placement="auto"
                            >
                              <Box m={7} cursor={'pointer'}>
                                <IoMdInformationCircleOutline size={25} />
                              </Box>
                            </Tooltip>
                          </Flex>
                        </GridItem>
                      </Grid>
                      <Flex w={'full'} direction={'column'}>
                        <DropDownInput
                          name="dwellingType"
                          label="Dwelling type"
                          options={DWELLING_TYPES}
                          placeholder={'Select dwelling type'}
                        />
                        {values.dwellingType === 'Apartment block' && (
                          <Text fontSize={'small'} color={'brand.text.danger'}>
                            Currently we do not support solar installation on apartment blocks.
                          </Text>
                        )}

                        <Flex
                          gap={'25px'}
                          hidden={
                            values.dwellingType === 'Free standing house' ||
                            values.dwellingType === '' ||
                            values.dwellingType === 'Apartment block'
                          }
                        >
                          <ConnectedFormGroup
                            name="complexBuildingName"
                            label="Complex/Building name"
                            placeholder="Complex/Building name"
                            handleChange={(value?: string) =>
                              setFieldValue('complexBuildingName', value?.toString())
                            }
                          />
                          <ConnectedFormGroup
                            name="unitNumber"
                            label="Unit number"
                            placeholder="Unit number"
                            handleChange={(value?: string) =>
                              setFieldValue('unitNumber', value?.toString())
                            }
                          />
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex
                      gap={25}
                      direction={['column']}
                      bg={'brand.neutral'}
                      shadow={'md'}
                      rounded={'lg'}
                      padding={4}
                    >
                      <ContainerHeading title={'Electricity'} />
                      <ElectricityPhaseUpdateModal
                        isOpen={phaseModalIsOpen}
                        onClose={() => setPhaseModalIsOpen(false)}
                        heading={''}
                        resetProduct={() => {
                          //TODO: clear product if phase changed
                          setFieldValue('subscription', null)
                        }}
                        cancelUpdate={() => {
                          setPhaseModalIsOpen(false)
                          console.log('Resetting to previous phase', previousPhase)
                          setFieldValue('electricityPhaseType', previousPhase)
                        }}
                      />

                      <Flex width={'full'} position={'relative'} direction={['row']}>
                        <DropDownInput
                          name="monthlyElectricitySpend"
                          label="What is your normal electricity bill per month?"
                          placeholder="Select amount"
                          options={ELECTRICITY_SPEND_OPTIONS}
                        />
                        <Tooltip
                          label="We will use this to recommend the right system size for your home."
                          ariaLabel="Electricity spend tooltip"
                        >
                          <Box m={7} cursor={'pointer'}>
                            <IoMdInformationCircleOutline size={25} />
                          </Box>
                        </Tooltip>
                      </Flex>
                      <Flex position={'relative'} width={'full'} direction={['row']}>
                        <DropDownInput
                          name="backupPowerNeeds"
                          label="What are your needs for backup power during load-shedding?"
                          placeholder="Select loadshedding protection"
                          options={BACKUP_POWER_OPTIONS}
                        />
                        <Tooltip
                          label="Our solutions are designed to give you enough power up to stage 4 load-shedding. If you want to stay powered above stage 4 you might need additional batteries. 

                This can be selected in the next step."
                          ariaLabel="Backup power needs during load-shedding tooltip"
                        >
                          <Box m={7} cursor={'pointer'}>
                            <IoMdInformationCircleOutline size={25} />
                          </Box>
                        </Tooltip>
                      </Flex>

                      {values.monthlyElectricitySpend && values.backupPowerNeeds && (
                        <>
                          <Flex width={'full'} direction={['column', 'column', 'row', 'row']}>
                            <Flex width="100%" justifyContent={'space-between'}>
                              <Box w="full" maxW={'300px'}>
                                <DropDownInput
                                  name="electricityPhaseType"
                                  label="Electricity Phase"
                                  options={[
                                    { value: 'Single', label: 'One Phase' },
                                    { value: 'Three', label: 'Three Phase' }
                                  ]}
                                  onChange={() => {
                                    //Save the current phase before the change
                                    setPreviousPhase(values.electricityPhaseType)
                                    setShowProductUpdateModal(true)
                                  }}
                                />
                              </Box>

                              <Tooltip
                                label="Your electricity phase can be seen on your electrical box where the trip-switches are located. Look at the main trip-switch (its marked main). If this trip-switch has three “legs”, then you are on three-phase, else you are on single-phase"
                                ariaLabel="Phase type tool tip"
                                placement="auto"
                              >
                                <Box m={7} cursor={'pointer'}>
                                  <IoMdInformationCircleOutline size={25} />
                                </Box>
                              </Tooltip>
                            </Flex>
                            <Flex width="full" justifyContent="space-between">
                              <DropDownInput
                                name="meterType"
                                label="Meter type"
                                placeholder="Select Pre-paid / Post-paid"
                                options={METER_TYPE_OPTIONS}
                              />
                              <Tooltip
                                label="A pre paid meter means you're buying electricity vouchers regularly and topping up your meter.If you're on a post paid meter, you'll get a monthly invoice based on your electricity consumption."
                                ariaLabel="Meter type tool tip"
                                placement="auto"
                              >
                                <Box m={7} cursor={'pointer'}>
                                  <IoMdInformationCircleOutline size={25} />
                                </Box>
                              </Tooltip>
                            </Flex>
                          </Flex>

                          <Flex width="full" justifyContent="space-between">
                            <DropDownInput
                              name="electricityProvider"
                              label="Who is your electricity provider"
                              placeholder="Select electricity provider"
                              options={providerList}
                            />

                            <Tooltip
                              label="This is usually your local municipality or Eskom"
                              ariaLabel="Electricity provider tool tip"
                              placement="auto"
                            >
                              <Box m={7} cursor={'pointer'}>
                                <IoMdInformationCircleOutline size={25} />
                              </Box>
                            </Tooltip>
                          </Flex>
                          {values.electricityProvider && (
                            <Flex
                              direction={'column'}
                              gap={25}
                              border={'1px solid '}
                              borderColor={'brand.secondary'}
                              py={'12px'}
                              px={'25px'}
                              rounded={'8px'}
                            >
                              <Text fontWeight={'bold'}>Compliance Acknowledgement:</Text>

                              {municipalities.map((item) => {
                                if (
                                  item?.municipality &&
                                  values.electricityProvider.trim() === item?.municipality.trim()
                                )
                                  return (
                                    <Flex direction="column" key={item.municipality}>
                                      <Text
                                        fontWeight={'bold'}
                                        fontSize={['11px', '12px', '12px', '12px']}
                                      >
                                        {item.municipality}:
                                      </Text>
                                      <Text fontSize={['11px', '12px', '12px', '12px']}>
                                        {item.legal_text}
                                      </Text>
                                    </Flex>
                                  )
                              })}
                              <Text fontSize={['11px', '12px', '12px', '12px']}>
                                By clicking on the below confirm button, I acknowledge that I have
                                read, understood, and accept the solar registration, meter and
                                tariff implications of the electricity supplier relevant to me as
                                outline above.
                              </Text>
                            </Flex>
                          )}
                        </>
                      )}
                    </Flex>

                    <Flex
                      gap={25}
                      direction={['column']}
                      width="full"
                      bg={'brand.neutral'}
                      shadow={'md'}
                      rounded={'lg'}
                      padding={4}
                    >
                      <Flex
                        direction={'row'}
                        width="full"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                      >
                        <ContainerHeading title={'Subscription'} />
                        {currSubscription && (
                          <Button
                            variant={'link'}
                            fontWeight={'normal'}
                            fontSize={'sm'}
                            onClick={() => setShowProductUpdateModal(true)}
                          >
                            Edit product
                          </Button>
                        )}
                      </Flex>

                      <Flex
                        width="full"
                        direction={['column', 'column', 'column', 'column']}
                        justifyContent={'space-between'}
                        m={1}
                        gap={25}
                      >
                        {!currSubscription && (
                          <>
                            <Flex
                              w="full"
                              direction={'column'}
                              alignItems={'center'}
                              justifyContent={'center'}
                            >
                              <Button
                                variant={'dark'}
                                fontWeight={'normal'}
                                // fontSize={'sm'}
                                disabled={
                                  Boolean(!values.monthlyElectricitySpend) ||
                                  Boolean(!values.backupPowerNeeds)
                                }
                                onClick={() => setShowProductUpdateModal(true)}
                              >
                                Select your solar system
                              </Button>
                            </Flex>
                            {
                              <>
                                {!values.monthlyElectricitySpend && (
                                  <Text w={'full'} textAlign={'center'} color={'red'}>
                                    Please select what your normal monthly electricity bill looks
                                    like.
                                  </Text>
                                )}
                                {!values.backupPowerNeeds && (
                                  <Text w={'full'} textAlign={'center'} color={'red'}>
                                    Please select your backup power needs during load-shedding.
                                  </Text>
                                )}
                                <Text
                                  w={'full'}
                                  textAlign={'center'}
                                  color={submitCount > 0 ? 'red' : ''}
                                >
                                  {errors.subscription}
                                </Text>
                              </>
                            }
                            <Image src={'/images/gosolr-house.png'} />
                          </>
                        )}

                        {currSubscription && (
                          <>
                            <Flex gap={'5px'} direction={'column'}>
                              <Flex direction={'column'} gap="10px">
                                <Flex gap={'10px'}>
                                  {' '}
                                  <Text fontSize={14} fontWeight={'bold'}>
                                    Subscription:
                                  </Text>
                                  <Text fontSize={14}>
                                    Gosolr{' '}
                                    {currSubscription?.subscriptionOption?.replace('2023_', '')}
                                  </Text>
                                </Flex>
                                <Flex gap={'5px'} direction={'column'}>
                                  <Flex width={'full'} justifyContent={'space-between'}>
                                    <Flex fontSize={14} gap="10px">
                                      <Text fontWeight={'bold'}>Add ons: </Text>
                                      {!currSubscription?.addOns?.length && (
                                        <Text fontWeight={'normal'}>none</Text>
                                      )}
                                    </Flex>
                                  </Flex>
                                  {currSubscription?.addOns?.includes('2 panels') && (
                                    <Text fontSize={14}>2 x {currSubscription?.panelSpec}</Text>
                                  )}
                                  {currSubscription?.addOns?.includes('4 panels') && (
                                    <Text fontSize={14}>4 x {currSubscription?.panelSpec}</Text>
                                  )}
                                  {currSubscription?.addOns?.includes('battery') && (
                                    <Text fontSize={14}>1 x 100Ah Lithium battery</Text>
                                  )}
                                </Flex>
                              </Flex>
                            </Flex>
                            <Flex
                              gap={25}
                              border={'1px solid '}
                              borderColor={'brand.secondary'}
                              py={'12px'}
                              px={'25px'}
                              rounded={'8px'}
                              direction={'column'}
                              alignItems="left"
                              justifyContent={'center'}
                            >
                              <Flex gap={25} alignItems={'center'}>
                                <Text flex={1} fontSize={18}>
                                  <b>Monthly fee:</b>
                                  {''}
                                </Text>
                                <Text fontSize={18}>
                                  {' '}
                                  <b>
                                    R{formatMoneyValue(currSubscription?.subscriptionFee, '0,0')}
                                  </b>
                                </Text>
                              </Flex>
                              <Box w="100%" height={'1px'} bgColor={'brand.text.primary'}></Box>
                              <Flex direction={'column'}>
                                <Flex gap={25} alignItems={'center'}>
                                  <Text flex={1} fontSize={18} textDecoration="underline">
                                    {values.isRoofFlat === 'Yes'
                                      ? 'Once off fees:'
                                      : 'Once off fee:'}
                                    {''}
                                  </Text>
                                </Flex>
                                <Flex gap={25} alignItems={'center'}>
                                  <Text flex={1} fontSize={18}>
                                    Administration fee:{''}
                                  </Text>
                                  <Text fontSize={18}>
                                    {' '}
                                    <b>R{formatMoneyValue(currSubscription?.onceOffFee, '0,0')}</b>
                                  </Text>
                                </Flex>

                                {values.isRoofFlat === 'Yes' && (
                                  <>
                                    <Flex gap={25} alignItems={'center'}>
                                      <Text flex={1} fontSize={18}>
                                        Flat roof fee:{''}
                                      </Text>
                                      <Text fontSize={18}>
                                        <b>
                                          R
                                          {formatMoneyValue(
                                            currSubscription?.flatRoofFee as number,
                                            '0,0'
                                          )}
                                        </b>
                                      </Text>
                                    </Flex>
                                    <Box
                                      w="100%"
                                      height={'1px'}
                                      bgColor={'brand.text.primary'}
                                      mt={'15px'}
                                    ></Box>
                                    <Flex gap={25} alignItems={'center'} mt={'5px'}>
                                      <Text flex={1} fontSize={18}>
                                        <b>Total once off fee:</b>
                                        {''}
                                      </Text>
                                      <Text fontSize={18}>
                                        <b>
                                          R
                                          {formatMoneyValue(
                                            (Number(currSubscription?.flatRoofFee) +
                                              Number(currSubscription?.onceOffFee)) as number,
                                            '0,0'
                                          )}
                                        </b>
                                      </Text>
                                    </Flex>
                                  </>
                                )}
                              </Flex>
                            </Flex>
                          </>
                        )}

                        {propertyItem && (
                          <SubscriptionOptionModal
                            isOpen={showProductUpdateModal}
                            // onClose={() => setShowProductUpdateModal(false)}
                            onClose={() => {}}
                            setPropertyItem={setPropertyItem}
                            propertyItem={{
                              ...propertyItem,
                              electricityPhaseType: values.electricityPhaseType as PhaseType,
                              monthlyElectricitySpend:
                                values.monthlyElectricitySpend as ElectricitySpendType,
                              backupPowerNeeds: values.backupPowerNeeds
                            }}
                            setCurrentSubscription={setCurrentSubscription}
                            setFieldValue={setFieldValue}
                            setShowProductUpdateModal={setShowProductUpdateModal}
                            previousPhase={previousPhase}
                          />
                        )}
                      </Flex>
                    </Flex>
                    <HomeOwnerObserver values={values} />
                    <Flex
                      gap={25}
                      direction={['column']}
                      bg={'brand.neutral'}
                      shadow={'md'}
                      rounded={'lg'}
                      padding={4}
                    >
                      <ContainerHeading title={'Insurance'} />
                      <Flex
                        width="100%"
                        justifyContent={'space-between'}
                        direction={['column', 'column', 'row', 'row']}
                      >
                        <Flex w={'full'}>
                          <Box w="full" maxW={'300px'}>
                            <DropDownInput
                              name="customerType"
                              label="Do you own or rent?"
                              placeholder={'Do you own or rent?'}
                              options={CUSTOMER_TYPE_OPTIONS}
                            />
                          </Box>
                          <Tooltip
                            label="Note that only property owners can sign the agreement"
                            ariaLabel="Property Ownership required tooltip"
                            placement="auto"
                          >
                            <Box m={7} cursor={'pointer'}>
                              <IoMdInformationCircleOutline size={25} />
                            </Box>
                          </Tooltip>
                        </Flex>

                        <Flex w={'full'}>
                          <Box w="full" maxW={'300px'}>
                            <DropDownInput
                              name="isInsured"
                              label="Is your property insured?"
                              options={HOMEOWNERS_INSURANCE_OPTIONS}
                            />
                          </Box>
                          <Tooltip
                            label="We require the property to be covered by a valid homeowners insurance policy. Once installed, the GoSolr system will be included as a fitting/fixture to the property and included in the homeowner's insurance policy."
                            ariaLabel="Insurance tool tip"
                            placement="auto"
                          >
                            <Box m={7} cursor={'pointer'}>
                              <IoMdInformationCircleOutline size={25} />
                            </Box>
                          </Tooltip>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex gap={25} direction={['column']} rounded={'lg'} padding={4}>
                    <Flex width="100%" justifyContent={'flex-end'} gap={25}>
                      <Flex mt="20.5" alignSelf="right">
                        {/* <SubmitButton title="Confirm" /> */}
                        <Button
                          variant={'dark'}
                          type="submit"
                          isLoading={isSubmitting || isValidating}
                        >
                          Confirm
                        </Button>
                      </Flex>
                    </Flex>
                  </Flex>
                </Form>
              </>
            )
          }}
        </Formik>
      </Flex>
    </Flex>
  )
}

export default CheckoutSummary

type AdressUpdateObserverPropsType = {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  propertyUpdatedAddress: PropertyLocationInitialValuesType | null
  setPropertyFullAddress: Dispatch<SetStateAction<string>>
}
const AddressUpdateObserver = ({
  propertyUpdatedAddress,
  setFieldValue,
  setPropertyFullAddress
}: AdressUpdateObserverPropsType) => {
  useEffect(() => {
    if (propertyUpdatedAddress) {
      const streetAddress =
        propertyUpdatedAddress.streetNumber && propertyUpdatedAddress.streetName
          ? `${propertyUpdatedAddress.streetNumber} ${propertyUpdatedAddress.streetName}`
          : ''

      let fullAddress = ''

      if (
        streetAddress &&
        propertyUpdatedAddress.postalCode &&
        propertyUpdatedAddress.city &&
        propertyUpdatedAddress.suburb &&
        propertyUpdatedAddress.province
      ) {
        // Build full address
        fullAddress = `${streetAddress}, ${propertyUpdatedAddress.suburb}, ${propertyUpdatedAddress.city}, ${propertyUpdatedAddress.postalCode}, South Africa`
      }

      setFieldValue('streetName', propertyUpdatedAddress.streetName, true)
      setFieldValue('streetNumber', propertyUpdatedAddress.streetNumber, true)
      setFieldValue('city', propertyUpdatedAddress.city, true)
      setFieldValue('suburb', propertyUpdatedAddress.suburb, true)
      setFieldValue('province', propertyUpdatedAddress.province, true)
      setFieldValue('postalCode', propertyUpdatedAddress.postalCode, true)
      setFieldValue('streetAddress', streetAddress, true)
      setFieldValue('fullAddress', fullAddress, true)
      setPropertyFullAddress(fullAddress)
    }
  }, [propertyUpdatedAddress])

  return null
}
function HomeOwnerObserver({ values }: { values: CheckoutSummaryType }) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)

  useEffect(() => {
    if (values.customerType.toLowerCase() === 'tenant') {
      setModalIsOpen(true)
    }
  }, [values.customerType])

  return (
    <DefaultModal
      isOpen={modalIsOpen}
      onClose={() => {
        setModalIsOpen(false)
      }}
      heading={'Home owner signature required'}
    >
      Please note that we require the homeowner to sign the GoSolr subscription agreement.
      <Button variant={'dark'} onClick={() => setModalIsOpen(false)}>
        Got it
      </Button>
    </DefaultModal>
  )
}

function DwellingTypeObserver() {
  const { values }: FormikState<FormikValues> = useFormikContext()
  const { setFieldValue, setFieldTouched } = useFormikContext<FormikState<FormikValues>>()
  const [modalIsOpen, setmodalIsOpen] = useState<boolean>(false)

  const handleSetAddress = useCallback(() => {
    setFieldValue('complexBuildingName', '')
    setTimeout(() => setFieldTouched('complexBuildingName', true))
  }, [])
  const handleUnitReset = useCallback(() => {
    setFieldValue('unitNumber', '')
    setTimeout(() => setFieldTouched('complexBuildingName', true))
  }, [])

  useEffect(() => {
    if (values.dwellingType === 'Apartment block') {
      setmodalIsOpen(true)
    }
    if (values.dwellingType === 'Free standing house') {
      handleUnitReset()
      handleSetAddress()
    }
  }, [values.dwellingType])

  return (
    <UnsupportedDwellingTypeModal
      isOpen={modalIsOpen}
      onClose={() => {
        setmodalIsOpen(false)
      }}
      heading={'Dwelling Type Is Not Supported'}
      dwellingType={values.dwellingType.toLowerCase()}
    />
  )
}
