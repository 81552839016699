import { FC, useEffect, useState } from 'react'
import {
  Button,
  Flex,
  Grid,
  Highlight,
  useDisclosure,
  Text,
  Heading,
  Box,
  Center,
  Spinner
} from '@chakra-ui/react'
import { ContainerHeading } from '../../Headings'
import { useAuthContext } from '../../../context/AuthProvider'
import {
  useCreateSubscriptionQueryMutation,
  useCreateSupportRequestMutation,
  useGetUserPropertiesQuery,
  UserProperties,
  UserProperty
} from '../../../generated/graphql'
import * as Yup from 'yup'
import { Form, Formik, FormikProps } from 'formik'
import SubmitButton from '../../FormElements/SubmitButton'
import ModalDialog from '../../Modals/ModalDialog'
import ManageSubscriptionRequestType from './ManageSubscriptionRequestType'
import ManageSubscriptionContactMethods from './ManageSubscriptionContactMethods'
import ManageSubscriptionInputs from './ManageSubscriptionRequestInputs'
import { useParams } from 'react-router-dom'
import { DateTime } from 'luxon'
import { IoMdCheckmarkCircle } from 'react-icons/io'
import { capitalizeFirstLetter } from '../../../helpers'
type Props = {}

type InitialValues = {
  requestType: string
  property: string
  subject: string
  description: string
}
type PropertyOption = {
  id: string
  streetAddress: string
  suburb: string
  city: string
  province: string
  postalCode: string
  productName: string
  installationDate: string
  active: boolean
}
type FormData = {
  name: string
  surname: string
  email: string
  requestType: string
  property: string
  subject: string
  description: string
}

const FormValidation = Yup.object().shape({
  requestType: Yup.string().required('Request type is required.'),
  subject: Yup.string().required('Subject is required.'),
  description: Yup.string().required('Description is required.'),
  preferredContactMethod: Yup.string().required('Contact method is required.')
})

const ManageSubscriptionContainer: FC<Props> = (props: Props) => {
  const { user } = useAuthContext()
  const { orderNumber } = useParams()

  const [selectedProperty, setSelectedProperty] = useState<PropertyOption | undefined>()
  const {
    isOpen: isOpenSubmitDialog,
    onOpen: onOpenSubmitDialog,
    onClose: onCloseSubmitDialog
  } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [createSubscriptionQuery] = useCreateSubscriptionQueryMutation()
  const { data, loading } = useGetUserPropertiesQuery({
    variables: {
      customerNumber: user?.info?.customerNumber as string
    }
  })

  const userProperties = (data?.getUserProperties as UserProperties)?.properties || []

  const properties: PropertyOption[] = (userProperties as UserProperty[]).map((p) => ({
    id: p.recordId as string,
    streetAddress: p.streetAddress as string,
    suburb: p.suburb as string,
    city: p.suburb as string,
    province: p.suburb as string,
    postalCode: p.postalCode as string,
    productName: p.subscription?.productName as string,
    installationDate: p.installationDate as string,
    active: p.active as boolean
  }))

  const INITIAL_VALUES: any = {
    requestType: '',
    property: selectedProperty?.id,
    subject: '',
    description: ''
  }

  const [formData, setFormData] = useState<FormData>({
    name: user?.info?.firstName,
    surname: user?.info?.lastName,
    email: user?.info?.email,
    ...INITIAL_VALUES
  })

  useEffect(() => {
    if (orderNumber) {
      const property = properties.find((p) => p.id === orderNumber)
      // console.log(properties[0])

      if (property) {
        setSelectedProperty(property)
      }
    } else {
      setSelectedProperty(properties[0])
    }
  }, [user, loading])

  if (loading) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Loading Subription Management Form...</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex gap={'25px'} direction="column">
        <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
          <Flex
            bgColor={'brand.neutral'}
            w={['100%', '100%', '100%', '50%']}
            h="auto"
            maxH={'auto'}
            gap={'25px'}
            direction="column"
            rounded={'8px'}
            shadow={'md'}
            p={'25px'}
            color={'brand.text.primary'}
          >
            <Flex w="full" alignItems={'center'} justifyContent={'space-between'}>
              <ContainerHeading title={`Location:`} />
            </Flex>
            <Flex w="full" gap={'25px'} alignItems={'center'} justifyContent={'flex-start'}>
              {/* <Center w="100px" h="100" rounded={'full'} overflow="hidden" bgColor={'brand.input'}>
                <Img src="/images/gosolr-home-box.png" objectFit={'cover'} w="100px" h="100px" />
              </Center> */}
              <Flex direction={'column'} gap={'5px'}>
                <Text fontWeight={700}>{`${selectedProperty?.suburb} property`}</Text>
                <Text>{selectedProperty?.streetAddress},</Text>
                <Text>
                  {selectedProperty?.suburb}, {selectedProperty?.city},
                </Text>
                <Text>
                  {selectedProperty?.province} , {selectedProperty?.postalCode}
                </Text>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            bgColor={'brand.secondary'}
            w={['100%', '100%', '100%', '50%']}
            h="auto"
            maxH={'auto'}
            gap={'25px'}
            direction="column"
            rounded={'8px'}
            shadow={'md'}
            p={'25px'}
            color={'brand.text.light'}
          >
            <Flex
              w="full"
              h={'100%'}
              direction={'column'}
              justifyContent="space-between"
              alignItems={'center'}
              color={'brand.text.light'}
            >
              <Flex
                w="full"
                h={'100%'}
                direction={'column'}
                justifyContent="center"
                alignItems={'center'}
                gap={'5px'}
                color={'brand.text.light'}
              >
                <Flex
                  color={'brand.light'}
                  gap={'15px'}
                  alignItems="center"
                  justifyContent={'center'}
                >
                  <Text color={'brand.text.light'}>
                    {selectedProperty?.active ? 'Active account' : ' Account not active'}
                  </Text>
                  <IoMdCheckmarkCircle size={25} />
                </Flex>
                <Text color={'brand.primary'}>{`${
                  selectedProperty?.productName as string
                } subscription`}</Text>
                <Text fontSize={'12px'} color={'brand.text.secondary'} letterSpacing={'wide'}>
                  Since{' '}
                  {DateTime.fromISO(selectedProperty?.installationDate as string).toFormat(
                    'dd LLLL yyyy'
                  )}
                </Text>
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex
          direction={'column'}
          w="100%"
          maxW={'650px'}
          bg="#ffff"
          rounded="6px"
          shadow={'md'}
          p="25px"
          gap={'25px'}
        >
          <ContainerHeading title={'How can we help you?'} />
          <Formik
            validationSchema={FormValidation}
            initialValues={INITIAL_VALUES}
            enableReinitialize
            onSubmit={async (
              { requestType, property, subject, description },
              { setSubmitting, setStatus }
            ) => {
              try {
                setSubmitting(true)
                setFormData((prevState: FormData) => ({
                  ...prevState,
                  requestType,
                  property,
                  subject,
                  description
                }))
                onOpenSubmitDialog()
                setStatus(null)
                setSubmitting(false)
              } catch (error: any) {
                setStatus(error.message)
              }
            }}
          >
            {({ setFieldValue, isSubmitting, values }: FormikProps<InitialValues>) => {
              return (
                <Form>
                  <Flex direction={'column'} w="100%" gap={'25px'}>
                    <ManageSubscriptionRequestType setFieldValue={setFieldValue} />

                    <ManageSubscriptionContactMethods setFieldValue={setFieldValue} />
                    <ManageSubscriptionInputs setFieldValue={setFieldValue} />
                  </Flex>
                  <Flex w="full" justifyContent={'end'} pt="25px">
                    <SubmitButton />
                  </Flex>
                </Form>
              )
            }}
          </Formik>
          <ModalDialog
            title=""
            description={
              <Flex flexDir="column">
                <Flex
                  justify={'center'}
                  alignItems={'center'}
                  backgroundColor={'brand.secondary'}
                  p={'25px'}
                  color={'brand.text.yellow'}
                  rounded={'8px'}
                  mb="25px"
                >
                  <Heading as="h3" fontSize={20} fontWeight={'medium'}>
                    Are you sure you want to submit this request?
                  </Heading>
                </Flex>
                <Text>
                  Once this is set in motion, the necessary steps will be taken to ensure your
                  satisfaction
                </Text>
                <Flex w="full" gap={'25px'} justify="space-between" align={'center'} mt="50px">
                  <Button variant={'unstyled'} rounded="full" onClick={onCloseSubmitDialog}>
                    Cancel
                  </Button>
                  <Button
                    variant={'dark'}
                    onClick={async () => {
                      await createSubscriptionQuery({
                        variables: {
                          input: {
                            ...formData
                          }
                        }
                      })

                      onOpen()
                    }}
                  >
                    Submit
                  </Button>
                </Flex>
              </Flex>
            }
            isOpen={isOpenSubmitDialog}
            onClose={onCloseSubmitDialog}
            hasModalFooter={false}
            hasCloseIcon={false}
          />
          <ModalDialog
            status="success"
            title="Request sent"
            description="Your support request has been sent, we will be in contact to discuss  what is needed"
            isOpen={isOpen}
            onClose={onClose}
            buttonTextSuccess="Got It!"
            successLink="/user/dashboard"
            hasCloseIcon={false}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default ManageSubscriptionContainer
