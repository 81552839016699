import { Flex, Highlight, Text } from '@chakra-ui/layout'
import React from 'react'
const RecommendedOptionHeader = ({ optionName }: { optionName: string }) => {
  return (
    <Flex direction={'column'} w="full" gap={'25px'}>
      <Text>
        <Highlight query={optionName || ''} styles={{ fontWeight: 'bold' }}>
          {`Based on your electricity consumption, as well as your choice for loadshedding protection, GoSolr recommends the "${optionName}" package`}
        </Highlight>
      </Text>
    </Flex>
  )
}

export default RecommendedOptionHeader
