import React, { FC } from 'react'
import { Flex, Text } from '@chakra-ui/react'
import { MdOutlineLightbulb } from 'react-icons/md'

export type Props = {
  consumed: string
}

const ConsumedItem: FC<Props> = ({ consumed }) => {
  return (
    <Flex direction={'column'} gap="5px" alignItems="center" w={['100%', '100%', '50%', '50%']}>
      <Flex alignItems={'center'} justifyContent="center" color={'brand.primary'} gap={'10px'}>
        <MdOutlineLightbulb size={25} />
      </Flex>
      <Text color={'brand.text.light'} fontWeight={700} fontSize={'xl'}>
        {consumed ? (+consumed).toFixed(2) : 0} kWh
      </Text>
      <Text color={'brand.text.light'} fontWeight={300} textAlign="center">
        Daily consumption
      </Text>
    </Flex>
  )
}

export default ConsumedItem
