//TODO
//Check Date Validation??

import * as yup from 'yup'
import YupPassword from 'yup-password'
import {
  SA_PROVINCE_OPTIONS,
  HOMEOWNERS_INSURANCE_OPTIONS,
  ELECTRICITY_PHASE_OPTIONS,
  ELECTRICITY_PROVIDERS_TYPE_OPTIONS,
  METER_TYPE_OPTIONS,
  ELECTRICITY_SPEND_OPTIONS,
  TEXT_ONLY_REGEX,
  SA_PHONE_NUMBER_REGEX,
  ROOF_TYPE_VALIDATION_OPTIONS,
  ROOF_ANGLE_OPTIONS
} from '../../constants'
YupPassword(yup)

type objArr = {
  value: string
  label: string
}

const objArrToIndexArr = (objArr: objArr[]) => {
  let newArr = objArr.map((obj) => obj.value)
  return newArr
}

export const CheckoutSumamryValidation = yup.object().shape({
  firstName: yup
    .string()
    .required('Full name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),
  lastName: yup
    .string()
    .required('Last name required')
    .matches(TEXT_ONLY_REGEX.test, TEXT_ONLY_REGEX.message),
  contactNumber: yup
    .string()
    .matches(SA_PHONE_NUMBER_REGEX, 'Phone number is not valid')
    .required('Phone number is required.')
    .min(10)
    .max(13),
  fullAddress: yup.string(),
  // streetName: yup
  //     .string()
  //     .required('Street name is required.'),

  // streetNumber: yup
  //     .string()
  //     .required('Street number is required.'),

  // city: yup
  //     .string()
  //     .required('City is required.'),

  // province: yup
  //     .mixed()
  //     .oneOf(objArrToIndexArr(SA_PROVINCE_OPTIONS))
  //     .required('Select your province'),

  // cost:yup
  //     .string(),

  // suburb: yup
  //     .string()
  //     .required('Suburb is required.'),

  // postalCode: yup
  //     .string()
  //     .required('Postal code is required.'),
  roofType: yup
    .mixed()
    .oneOf(
      ROOF_TYPE_VALIDATION_OPTIONS,
      'Asbestos and Thatched roofs are not accepted by the department of labour.'
    )
    .required('Select a roof type'),
  isRoofFlat: yup
    .string()
    .oneOf(objArrToIndexArr(ROOF_ANGLE_OPTIONS))
    .required('Please specify if roof is flat.'),
  roofLocationConfirmed: yup.boolean().oneOf([true]),
  subscription: yup.mixed().nonNullable().required('Please select a GoSolr subscription.'),
  isInsured: yup
    .string()
    .oneOf(objArrToIndexArr(HOMEOWNERS_INSURANCE_OPTIONS))
    .required('Please enter whether or not you have insurance.'),
  customerType: yup
    .mixed()
    .oneOf(['Home owner'], 'Only home owners can sign the agreement.')
    .required('Select if you own or rent.'),
  electricityPhaseType: yup
    .string()
    .oneOf(objArrToIndexArr(ELECTRICITY_PHASE_OPTIONS))
    .required('Select your meter type.'),
  monthlyElectricitySpend: yup
    .string()
    .oneOf(objArrToIndexArr(ELECTRICITY_SPEND_OPTIONS))
    .required('Select an estimated monthly electricity usage.'),
  backupPowerNeeds: yup
    .string()
    .oneOf(['Below stage 4', 'Above stage 4'])
    .required('Select a backup power option.'),
  electricityProvider: yup.string().required('Please select your electricity provider.'),

  meterType: yup
    .string()
    .oneOf(objArrToIndexArr(METER_TYPE_OPTIONS))
    .required('Please select your meter type.'),
  // .test('compatibleSystem',
  //     'Incompatible System',
  //     (value, {parent})=>{

  //         const product = parent.productOfInterest

  //         if (value === 'Single' && (product === 'Large 3 Phase' || product === 'Extra Large')){

  //             return false
  //         }

  //         else return true
  //     }),

  promotionCode: yup.string().matches(/^[0-9A-Za-z-]*\s*$/, 'Invalid Promo Code!')
})
