import React from 'react'

const IdentificationIcon = () => {
  return (
    <svg width="98" height="54" viewBox="0 0 98 54" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5136 32.3655C12.254 30.538 14.6145 29.5114 17.0757 29.5114H31.9242C34.3855 29.5114 36.746 30.538 38.4864 32.3655C40.2268 34.1929 41.2045 36.6714 41.2045 39.2557V43.1535C41.2045 44.2298 40.3735 45.1023 39.3485 45.1023C38.3234 45.1023 37.4924 44.2298 37.4924 43.1535V39.2557C37.4924 37.7051 36.9058 36.218 35.8615 35.1216C34.8173 34.0251 33.401 33.4091 31.9242 33.4091H17.0757C15.599 33.4091 14.1827 34.0251 13.1384 35.1216C12.0942 36.218 11.5076 37.7051 11.5076 39.2557V43.1535C11.5076 44.2298 10.6766 45.1023 9.6515 45.1023C8.62643 45.1023 7.79544 44.2298 7.79544 43.1535V39.2557C7.79544 36.6714 8.77318 34.1929 10.5136 32.3655Z"
        fill="#131101"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.5 10.0227C21.4248 10.0227 18.9318 12.6403 18.9318 15.8693C18.9318 19.0983 21.4248 21.7159 24.5 21.7159C27.5752 21.7159 30.0682 19.0983 30.0682 15.8693C30.0682 12.6403 27.5752 10.0227 24.5 10.0227ZM15.2197 15.8693C15.2197 10.4877 19.3746 6.125 24.5 6.125C29.6254 6.125 33.7803 10.4877 33.7803 15.8693C33.7803 21.251 29.6254 25.6136 24.5 25.6136C19.3746 25.6136 15.2197 21.251 15.2197 15.8693Z"
        fill="#131101"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M93.5455 3.34091H4.45455C3.8395 3.34091 3.34091 3.8395 3.34091 4.45454V49C3.34091 49.615 3.8395 50.1136 4.45455 50.1136H93.5455C94.1605 50.1136 94.6591 49.615 94.6591 49V4.45455C94.6591 3.8395 94.1605 3.34091 93.5455 3.34091ZM4.45455 0C1.99437 0 0 1.99437 0 4.45454V49C0 51.4602 1.99437 53.4545 4.45455 53.4545H93.5455C96.0056 53.4545 98 51.4602 98 49V4.45455C98 1.99437 96.0056 0 93.5455 0H4.45455Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6818 11.6932C55.6818 10.7706 56.4297 10.0227 57.3523 10.0227H85.1932C86.1158 10.0227 86.8636 10.7706 86.8636 11.6932C86.8636 12.6157 86.1158 13.3636 85.1932 13.3636H57.3523C56.4297 13.3636 55.6818 12.6157 55.6818 11.6932Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6818 18.375C55.6818 17.4524 56.4297 16.7045 57.3523 16.7045H85.1932C86.1158 16.7045 86.8636 17.4524 86.8636 18.375C86.8636 19.2975 86.1158 20.0454 85.1932 20.0454H57.3523C56.4297 20.0454 55.6818 19.2975 55.6818 18.375Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6818 25.0568C55.6818 24.1342 56.4297 23.3864 57.3523 23.3864H85.1932C86.1158 23.3864 86.8636 24.1342 86.8636 25.0568C86.8636 25.9794 86.1158 26.7273 85.1932 26.7273H57.3523C56.4297 26.7273 55.6818 25.9794 55.6818 25.0568Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6818 31.7386C55.6818 30.8161 56.4297 30.0682 57.3523 30.0682H74.0568C74.9794 30.0682 75.7273 30.8161 75.7273 31.7386C75.7273 32.6612 74.9794 33.4091 74.0568 33.4091H57.3523C56.4297 33.4091 55.6818 32.6612 55.6818 31.7386Z"
        fill="black"
      />
    </svg>
  )
}

export default IdentificationIcon
