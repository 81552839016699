import { Flex } from '@chakra-ui/react'
import DBProperty from './DBProperty'
import KPIStatsContainer from '../KPI'
import { UserProperty } from '../../generated/graphql'
import { KPIDataType } from '../../types'
import { useEffect, useState } from 'react'
import { getJWT } from '../../helpers/auth'
import { GET_KPI_URL } from '../../constants'

const initKPIData: KPIDataType = {
  dailyConsumption: '0',
  dailyProduction: '0',
  batteryCharge: '0',
  currentProduction: '0',
  activeBatterySetting: '0',
  gsmStatus: 'false',
  settingsChangeStatus: 'completed'
}

export const PropertyMainContainer = ({ property }: { property: UserProperty }) => {
  const [kpiData, setKpiData] = useState<KPIDataType>(() => initKPIData)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setLoading(true)

    const GetKPIData = async () => {
      const url = GET_KPI_URL
      const fetchPropertyKpiDataRes = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ orderNumber: property?.orderNumber }),
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: getJWT()
        }
      })

      if (fetchPropertyKpiDataRes.status >= 400) {
        console.log(
          `There was an error fetching the KPI data: ${fetchPropertyKpiDataRes.statusText}`
        )
      }

      const result = await fetchPropertyKpiDataRes.json()

      if (result.status === 'success') {
        setKpiData(result.data)
      }
    }

    GetKPIData()

    const interval = setInterval(() => {
      GetKPIData()
    }, 60 * 2 * 1000)

    setLoading(false)

    return () => clearInterval(interval)
  }, [])

  return (
    <Flex
      w={'100%'}
      p="0px"
      h="100%"
      maxH={'100%'}
      display="flex"
      bgColor="none"
      direction={['column', 'column', 'column', 'row']}
      key={`${property.propertyLat}-${property.recordId}`}
      gap="20px"
    >
      <DBProperty property={property} kpiData={kpiData} />
      <KPIStatsContainer kpiData={kpiData} isLoading={loading} />
    </Flex>
  )
}
