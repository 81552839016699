import React, { useEffect, useState } from 'react'
import { Flex } from '@chakra-ui/react'
import AccHeroContainer from '../../../components/containers/Account/AccHeroContainer'
import AccTransactionsContainer from '../../../components/Transactions/AccTransactionsContainer'
import AccBankingDetailsContainer from '../../../components/containers/Account/AccBankingDetailsContainer'
import AccIDVerificationContainer from '../../../components/containers/Account/AccIDVerificationContainer'
import { useAuthContext } from '../../../context/AuthProvider'

const AccountPage = (): JSX.Element => {
  const { user } = useAuthContext()
  const info = user?.info || {}
  const { email, firstName, lastName, contactNumber, alternateNumber, referralCode } = info

  return (
    <>
      <AccHeroContainer
        email={email}
        fullName={`${firstName} ${lastName}`}
        contactNumber={contactNumber}
        alternateNumber={alternateNumber}
        referralCode={referralCode}
      />
      <Flex w="100%" gap="20px" direction={['column', 'column', 'column', 'row']}>
        <AccBankingDetailsContainer />
        <AccIDVerificationContainer />
      </Flex>
      <AccTransactionsContainer />
    </>
  )
}

export default AccountPage
