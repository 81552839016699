import React, { useEffect, useState } from 'react'
import { Button, Flex, Link, Text } from '@chakra-ui/react'
import { DefaultModal } from '../../../Modals'
import TextInput from '../../../FormElements/TextInput'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import axios from 'axios'
import SubmitButton from '../../../FormElements/SubmitButton'
import { useCookies } from 'react-cookie'

type UnsupportedRoofTypeProps = {
  isOpen: boolean
  onClose: () => void
  heading: string
  roofType: string
}

export const SubscribeValidation = yup.object().shape({
  //Subscribe CHECK

  email: yup
    .string()
    .email('This is not a valid email address. Please use a valid email address.')
    .required('Email is required'),
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required')
})

function UnsupportedRoofTypeModal({
  isOpen,
  onClose,
  heading,
  roofType
}: UnsupportedRoofTypeProps) {
  const initValues = {
    firstName: '',
    lastName: '',
    email: ''
  }
  const [cookies] = useCookies()
  const [isPartner, setIspartner] = useState<boolean>(false)

  useEffect(() => {
    if (cookies.promocode && cookies.promocode === 'hippo') {
      setIspartner(true)
    }
  }, [cookies])

  return (
    <DefaultModal heading={heading} isOpen={isOpen} onClose={onClose}>
      <Flex direction={'column'} gap={'25px'} alignItems={'center'} justifyContent="center">
        <>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Unfortunately solar installations on {roofType} roofs are not accepted by the department
            of labour.
          </Text>
          <Text textAlign={'center'} color={'brand.text.primary'}>
            Please choose an alternative roof type if available.
          </Text>
        </>

        <>
          {!isPartner && (
            <Text textAlign={'center'} color={'brand.text.primary'}>
              Feel free to contact us at{' '}
              <Link href={`mailto: hello@gosolr.co.za?subject = Support Request`} color={'#3b82f6'}>
                hello@gosolr.co.za
              </Link>{' '}
              or give us a call at{' '}
              <Link href={`tel:010 880 3948`} color={'#3b82f6'}>
                010 880 3948
              </Link>{' '}
              if you require more information.
            </Text>
          )}
        </>
        <Button alignSelf={'flex-end'} variant={'dark'} onClick={onClose}>
          Got it
        </Button>
      </Flex>
    </DefaultModal>
  )
}

export default UnsupportedRoofTypeModal
