import React, { Dispatch, FC, SetStateAction, useEffect, useRef, useState } from 'react'
import { useGoogleContext } from '../../../../context/GoogleProvider'
import { Box, Button, Flex, Text, Tooltip } from '@chakra-ui/react'
import GoogleMapsSearchBar from '../../../FormElements/GoogleMapInput/GoogleMapsSearchBar'

import { IoMdInformationCircleOutline } from 'react-icons/io'
import { ContainerHeading } from '../../../Headings'
import DropDownInput from '../../../FormElements/DropDownInput'
import { SA_PROVINCE_OPTIONS } from '../../../../constants'
import TextInput from '../../../FormElements/TextInput'
import { Form, Formik } from 'formik'
import { CheckoutSummaryPropertyAddressValidation } from '../../../../formValidation/preSignUpFlow'
import SubmitButton from '../../../FormElements/SubmitButton'
import { PreSignUpPropertyType } from '../../../../types'

type PropertyLocationFormType = {
  fullAddress: string
  initialProperty: PropertyLocationInitialValuesType | null
  setPropertyCurrdAddress: Dispatch<SetStateAction<PropertyLocationInitialValuesType | null>>
}

type PropertyLocationInitialValuesType = {
  streetName: string
  streetNumber: string
  city: string
  suburb: string
  province: string
  postalCode: string
}

const PropertyLocationForm: FC<PropertyLocationFormType> = ({
  fullAddress,
  initialProperty,
  setPropertyCurrdAddress
}) => {
  const { selectedAddress } = useGoogleContext()
  const [editAddressActive, setEditAddressActive] = useState<boolean>(false)
  const [initialValues, setInitialValues] = useState<PropertyLocationInitialValuesType>(() => ({
    streetName: initialProperty?.streetName || '',
    streetNumber: initialProperty?.streetNumber || '',
    city: initialProperty?.city || '',
    suburb: initialProperty?.suburb || '',
    province: initialProperty?.province || '',
    postalCode: initialProperty?.postalCode || ''
  }))

  const isPageFirstLoad = useRef<boolean>(true)

  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      ...selectedAddress
    }))
  }, [selectedAddress])

  useEffect(() => {
    setInitialValues((prev) => ({
      ...prev,
      ...initialProperty
    }))
  }, [editAddressActive])

  const handleSubmit = async (values: PropertyLocationInitialValuesType) => {
    const { streetName, streetNumber, city, suburb, province, postalCode } = values

    if (streetName && streetNumber && city && suburb && province && postalCode) {
      setPropertyCurrdAddress(values)
      setEditAddressActive(false)
    }
  }

  return (
    <Flex
      gap={25}
      direction={['column']}
      bg={'brand.neutral'}
      shadow={'md'}
      rounded={'lg'}
      padding={4}
    >
      <Flex direction={'row'} width="full" justifyContent={'space-between'} alignItems={'center'}>
        <ContainerHeading title={'Address'} />
        <Button
          variant={'link'}
          fontWeight={'normal'}
          fontSize={'sm'}
          onClick={() => setEditAddressActive(true)}
        >
          Edit Address
        </Button>
      </Flex>
      <Flex width="full" justifyContent={'space-between'}>
        {fullAddress && <Text>{fullAddress}</Text>}
        {!fullAddress && (
          <Text>{'Please fill in the form to help us find your property location'}</Text>
        )}
      </Flex>
      {(editAddressActive || !fullAddress) && (
        <>
          <Flex pos={'relative'} w={'full'} alignItems={'center'}>
            <GoogleMapsSearchBar onAfterAddressSelected={() => (isPageFirstLoad.current = false)} />
            <Tooltip
              label="If your address does not appear in the list, then please complete the fields below manually and click on “next”"
              aria-label="Backup power needs during loadshedding tooltip"
            >
              <Box position={'absolute'} right={-20} top={'10'} cursor={'pointer'}>
                <IoMdInformationCircleOutline size={25} />
              </Box>
            </Tooltip>
          </Flex>
          <Formik
            initialValues={initialValues}
            validateOnMount
            validateOnBlur={true}
            validateOnChange={true}
            validationSchema={CheckoutSummaryPropertyAddressValidation}
            enableReinitialize
            // isInitialValid={Boolean(fullAddress)}
            initialTouched={{
              streetName: !fullAddress,
              streetNumber: !fullAddress,
              province: !fullAddress,
              city: !fullAddress,
              suburb: !fullAddress,
              postalCode: !fullAddress
            }}
            onSubmit={(values) => handleSubmit(values)}
          >
            {({ errors }) => (
              <Form>
                <Flex gap={'25px'} direction={'column'}>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <TextInput name="streetName" label="Street name" placeholder="Street name" />
                    <TextInput
                      name="streetNumber"
                      label="Street number"
                      placeholder="Street number"
                    />
                  </Flex>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <TextInput name="city" label="City" placeholder="City" />
                    <TextInput name="suburb" label="Suburb" placeholder="Suburb" />
                  </Flex>
                  <Flex gap={'25px'} direction={['column', 'column', 'row', 'row']}>
                    <DropDownInput
                      name="province"
                      label="Province"
                      options={SA_PROVINCE_OPTIONS}
                      placeholder={'Province'}
                    />
                    <TextInput name="postalCode" label="Postal code" placeholder="Postal code" />
                  </Flex>

                  <Flex justifyContent={fullAddress ? 'space-between' : 'flex-end'}>
                    {fullAddress && (
                      <Button variant={'outline'} onClick={() => setEditAddressActive(false)}>
                        Cancel
                      </Button>
                    )}
                    <SubmitButton title="confirm" />
                  </Flex>
                </Flex>
              </Form>
            )}
          </Formik>
        </>
      )}
    </Flex>
  )
}

export default PropertyLocationForm
