/* istanbul ignore file */
import { PropertyLocation } from '../types'
import { UserProperty } from '../generated/graphql'
import { Location } from 'react-router-dom'
import { STAGING_ENVIRONMENT } from '../constants'

export const parseAddressFromGeocodeResult = (
  result: google.maps.GeocoderResult
): PropertyLocation => {
  const streetName = result?.address_components.find(
    (ac) => ac.types.indexOf('route') !== -1
  )?.long_name
  const streetNumber = result?.address_components.find(
    (ac) => ac.types.indexOf('street_number') !== -1
  )?.long_name
  const streetAddress = `${streetNumber} ${streetName}`.trim()

  return {
    fullAddress: result?.formatted_address,
    streetName,
    streetNumber,
    complexBuildingName: result?.address_components.find(
      (ac) =>
        ac.types.indexOf('point_of_interest') !== -1 || ac.types.indexOf('establishment') !== -1
    )?.long_name,
    streetAddress,
    suburb: result?.address_components.find((ac) => ac.types.indexOf('sublocality') !== -1)
      ?.long_name,
    city: result?.address_components.find((ac) => ac.types.indexOf('locality') !== -1)?.long_name,
    municipality: result?.address_components.find(
      (ac) => ac.types.indexOf('administrative_area_level_2') !== -1
    )?.long_name,
    province: result?.address_components.find(
      (ac) => ac.types.indexOf('administrative_area_level_1') !== -1
    )?.long_name,
    country: result?.address_components.find((ac) => ac.types.indexOf('country') !== -1)?.long_name,
    postalCode: result?.address_components.find((ac) => ac.types.indexOf('postal_code') !== -1)
      ?.long_name
  } as PropertyLocation
}

export const bytesToSize = (bytes: number): string => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i)) + ' ' + sizes[i]
}
export const bytesToValue = (bytes: number): number | string => {
  if (bytes === 0) return '0 Byte'
  const i = Math.floor(Math.log(bytes) / Math.log(1024))
  return Math.round(bytes / Math.pow(1024, i))
}

export const buildFullAddress = (address: UserProperty): string => {
  const { streetAddress, complexBuildingName, suburb, city, province, postalCode } = address
  let fullAddress = ''

  if (complexBuildingName) fullAddress += `${complexBuildingName}, `
  if (streetAddress) fullAddress += `${streetAddress}, `
  if (suburb) fullAddress += `${suburb}, `
  if (city) fullAddress += `${city}, `
  if (province) fullAddress += `${province}, `
  if (postalCode) fullAddress += postalCode

  return (fullAddress += ', South Africa')
}

export const redirectOnboardingUri = (
  recordId: string,
  nextOnboardingStep: number | null
): string => {
  switch (nextOnboardingStep) {
    case 1:
      return `/user/onboarding/subscription/${recordId}`
    case 2:
      return `/user/onboarding/billing/${recordId}`
    case 3:
      return `/user/onboarding/documents/${recordId}`
    case 5:
      return `/user/onboarding/credit-check/${recordId}`
    case 4:
      return `/user/onboarding/agreement/${recordId}`
    case 6:
      return `/user/onboarding/credit/${recordId}`

    case -1:
      return '/user/dashboard'
    default:
      return `/user/onboarding/subscription?${recordId}`
  }
}

export const capitalizeFirstLetter = (str: string) => {
  if (str) {
    const string = str.toLowerCase()
    const capitalized = string.charAt(0).toUpperCase() + string.slice(1)
    return capitalized
  } else {
    return ''
  }
}

export const formatWattsToKW = (n: number) => {
  if (n < 1) return n + 'W'
  if (n >= 1) return +n.toFixed(2) + 'kW'
}

export const assignPageTitle = (
  location: Location,
  setPageTitle: (value: React.SetStateAction<string>) => void
) => {
  const pathName: string = location?.pathname
  const stringToRemove = '/user/'

  if (pathName.includes(stringToRemove)) {
    let newPath = pathName.replace(stringToRemove, '')

    if (newPath.includes('support')) {
      newPath += ' Request'
    }
    if (newPath.includes('property')) {
      newPath = 'Property'
    }
    if (newPath.includes('manage-subscription')) {
      newPath = 'Manage subscription'
    }

    setPageTitle(newPath.charAt(0).toUpperCase() + newPath.slice(1))
  }
  if (pathName.includes('debit-order-mandate')) {
    setPageTitle('Debit order mandate')
  }
  if (pathName.includes('subscription-agreement')) {
    setPageTitle('Subscription agreement')
  }
  if (pathName.includes('compliance-acknowledgement')) {
    setPageTitle('Compliance acknowledgement')
  }
  if (pathName.includes('optional-uploads')) {
    setPageTitle('Optional uploads')
  }
}

export const getDomain = () => {
  if (STAGING_ENVIRONMENT === 'uat' || STAGING_ENVIRONMENT === 'prod') {
    return '.gosolr.co.za'
  }
  return '.localhost' // Default or handle other cases
}
