import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Image
} from '@chakra-ui/react'
import { FiXCircle, FiCheckCircle, FiAlertCircle } from 'react-icons/fi'
import { useNavigate } from 'react-router-dom'
import { ModalDialogProps } from '../../../types'

import React from 'react'

const ModalDialog = ({
  status,
  title,
  description,
  isOpen,
  onClose,
  buttonTextSuccess,
  buttonTextError,
  successLink,
  errorLink,
  hasModalFooter = true,
  linkState,
  hasCloseIcon = true,
  showStatusIcon = true
}: ModalDialogProps): JSX.Element => {
  const navigate = useNavigate()

  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent textAlign="center" alignItems="center" py="2rem" borderRadius="8px" width="90%">
        {hasCloseIcon && (
          <Button variant={'ghost'} alignSelf="flex-end" mr={4} onClick={onClose} cursor="pointer">
            <FiXCircle size="72px" />
          </Button>
        )}
        {showStatusIcon && status === 'success' && (
          <FiCheckCircle size="72px" strokeWidth="1.5px" color="green" />
        )}
        {showStatusIcon && status === 'error' && (
          <FiXCircle size="72px" strokeWidth="1.5px" color="red" />
        )}
        {showStatusIcon && status === 'warning' && (
          <FiAlertCircle size="72px" strokeWidth="1.5px" color="orange" />
        )}
        {title && (
          <ModalHeader mt={4} mb={0} pb={0} fontWeight="bold" fontSize="18px">
            {title}
          </ModalHeader>
        )}
        <ModalBody>{description}</ModalBody>
        {hasModalFooter && (
          <ModalFooter width="100%">
            {status === 'success' || status === 'warning' ? (
              <Button
                cursor="pointer"
                onClick={() => {
                  onClose()
                  // history.push(successLink || '', linkState)
                  navigate(successLink || '')
                }}
                bg="black"
                borderRadius="20px"
                border={0}
                width="100%"
                color="white"
                _hover={{
                  bg: 'black'
                }}
              >
                {buttonTextSuccess}
              </Button>
            ) : (
              <Button
                onClick={() => {
                  onClose()
                  if (errorLink && errorLink.includes('reset-password')) {
                  } else {
                    window.location.href = 'https://www.gosolr.co.za/'
                  }
                  // history.push(errorLink || '', linkState)
                }}
                bg="black"
                borderRadius="20px"
                border={0}
                width="100%"
                color="white"
                _hover={{
                  bg: 'black'
                }}
                _active={{
                  bg: 'black'
                }}
              >
                {buttonTextError}
              </Button>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default ModalDialog
