import React, { FC, useState, useEffect, createContext, Dispatch, SetStateAction } from 'react'
import { useLocation, Location } from 'react-router-dom'

import {
  CountryType,
  CustomerType,
  DwellingType,
  ElectricitySpendType,
  HomeOwnersInsuranceType,
  MeterType,
  PhaseType,
  PostalCodeType,
  Product,
  RoofType
} from '../../types'
import PropertyLocation from '../../components/Onboarding/Pre-singup-flow/PropertyLocation/PropertyLocation'
import PropertyRoof from '../../components/Onboarding/Pre-singup-flow/PropertyRoof/PropertyRoof'
import ElectricityNeeds from '../../components/Onboarding/Pre-singup-flow/ElectricityNeeds'
import ProductsOptions from '../../components/Onboarding/Pre-singup-flow/ProductsOptions'

type FormProviderType = {
  children?: React.ReactNode
}
export type FormData = {
  propertyLat: string | number
  propertyLng: string | number
  propertyW3w: string
  fullAddress: string
  streetAddress: string
  streetNumber: string
  streetName: string
  suburb: string
  city: string
  postalCode: PostalCodeType
  province: string
  country: CountryType
  dwellingType: DwellingType | ''
  complexBuildingName: string
  roofLat: string | number
  roofLng: string | number
  roofW3w: string
  roofLocationConfirmed: boolean
  unitNumber: string
  customerType: CustomerType | ''
  monthlyElectricitySpend: ElectricitySpendType | ''
  electricityPhaseType: PhaseType | ''
  backupPowerNeeds: string | ''
  roofType: RoofType
  subscription: Product | null
  isRoofFlat: string
  insurance: HomeOwnersInsuranceType | ''
}

export type OnBoardingStep = {
  stepCount?: number
  stepName?: string
  helperText?: string
  stepLabel?: string
  stepDetails?: string
  container?: React.ReactNode
}

export type ActiveStep = OnBoardingStep

export type FormContextType = {
  activeStepName: string
  handleSetActiveStepName: (name: string) => void
  isUserFirstProperty?: boolean
  activeStepIndex: number
  handleNextStepIndex: () => void
  handlePrevStepIndex: () => void
  formData: FormData
  handleSetFormData: (formData: FormData) => void
  onboardingSteps: OnBoardingStep[]
  activeStep: ActiveStep
  isManualAddressFields: boolean
  handleIsManualAddressFields: (value: boolean) => void
  setFormData: Dispatch<SetStateAction<FormData>>
}
const initialFormData: FormData = {
  propertyLat: '',
  propertyLng: '',
  propertyW3w: '',
  fullAddress: '',
  streetAddress: '',
  streetNumber: '',
  streetName: '',
  suburb: '',
  city: '',
  postalCode: '',
  province: '',
  country: 'South Africa',
  dwellingType: '',
  complexBuildingName: '',
  unitNumber: '',
  roofLat: '',
  roofLng: '',
  roofW3w: '',
  roofLocationConfirmed: false,
  customerType: '',
  monthlyElectricitySpend: '',
  electricityPhaseType: '',
  backupPowerNeeds: '',
  roofType: 'Tiles',
  subscription: null,
  isRoofFlat: '',
  insurance: ''
}

export const onboardingSteps: OnBoardingStep[] = [
  {
    stepCount: 0,
    stepName: 'Property location',
    stepLabel: 'Address',
    stepDetails: 'Confirm your property',
    helperText: 'Please fill in the form to help us find your property location',
    container: <PropertyLocation />
  },
  {
    stepCount: 1,
    stepName: 'Confirm your roof',
    stepLabel: 'Roof',
    stepDetails: 'Confirm your roof',
    helperText: '',
    container: <PropertyRoof />
  },
  {
    stepCount: 2,
    stepName: 'Specify your back-up electricity needs',
    stepLabel: 'Needs',
    stepDetails: 'Electricity needs',
    helperText: '',
    container: <ElectricityNeeds />
  },
  {
    stepCount: 3,
    stepName: 'Choose a subscription',
    stepLabel: 'Options',
    stepDetails: 'Choose your subscription',
    helperText: '',
    container: <ProductsOptions />
  }
]

export const OnboardingFormContext = createContext<FormContextType>({
  activeStepName: '',
  handleSetActiveStepName: (name: string) => {},
  setFormData: () => {},
  activeStepIndex: 0,
  handleNextStepIndex: () => {},
  handlePrevStepIndex: () => {},
  formData: initialFormData,
  handleSetFormData: (formData: FormData) => {},
  onboardingSteps: onboardingSteps,
  activeStep: onboardingSteps[0],
  isManualAddressFields: false,
  handleIsManualAddressFields: (isManualAddressFields) => {}
})

const OnboardingFormProvider: FC<FormProviderType> = ({ children }) => {
  const [activeStepName, setActiveStepName] = useState<string>('Personal Details')
  const [activeStepIndex, setActiveStepIndex] = useState<number>(0)
  const [isManualAddressFields, setIsManualAddressFields] = useState<boolean>(false)
  const [activeStep, setActiveStep] = useState<OnBoardingStep>(() => onboardingSteps[0])
  const [formData, setFormData] = useState<FormData>(() => initialFormData)
  const location: Location = useLocation()
  const [isUserFirstProperty, setIsUserFirstProperty] = useState<boolean>(true)

  const handleSetActiveStepName = (name: string) => {
    setActiveStepName(name)
  }
  const handleNextStepIndex = () => {
    if (activeStepIndex <= onboardingSteps.length) setActiveStepIndex((prev) => prev + 1)
  }
  const handlePrevStepIndex = () => {
    if (activeStepIndex > 0) setActiveStepIndex((prev) => prev - 1)
  }
  const handleSetFormData = async (formData: FormData): Promise<void> => {
    await setFormData(formData)
  }
  const handleIsManualAddressFields = (value: boolean) => {
    setIsManualAddressFields(value)
  }

  // If we are adding additional properties
  //  set the activeStepIndex to 1
  useEffect(() => {
    const pathName = location.pathname
    if (pathName.includes('user/onboarding/new-subscription')) {
      setActiveStepIndex(1)
      localStorage.setItem('isNewProperty', JSON.stringify({ value: true }))
      setIsUserFirstProperty(false)
    } else {
      localStorage.setItem('isNewProperty', JSON.stringify({ value: false }))
      setActiveStepIndex(0)
      setIsUserFirstProperty(true)
    }
  }, [])

  useEffect(() => {
    const stepActive = onboardingSteps.find((obj) => obj.stepCount === activeStepIndex)
    stepActive && setActiveStep(stepActive)
    handleSetActiveStepName(stepActive?.stepName || '')
  }, [activeStepIndex])

  const expose: FormContextType = {
    activeStep,
    activeStepName,
    handleSetActiveStepName,
    isUserFirstProperty,
    activeStepIndex,
    handleNextStepIndex,
    handlePrevStepIndex,
    formData,
    setFormData,
    handleSetFormData,
    onboardingSteps,
    isManualAddressFields,
    handleIsManualAddressFields
  }

  return <OnboardingFormContext.Provider value={expose}>{children}</OnboardingFormContext.Provider>
}

export default OnboardingFormProvider
