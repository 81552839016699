export const buyoutFees = [
  {
    product: 'Medium',
    standard_Once_Off_Installation_Fee: 'R1,580 ',
    monthly_Fee: 'R1,580 ',
    flat_Roof_Fee: 'R3 940',
    de_installation_fee_first_3_Years: 'R20 700',
    de_installation_fee_then: 'R10 350',
    year_3: 'R161,464 ',
    year_4: 'R156,909 ',
    year_5: 'R152,581 ',
    year_6: 'R147,590 ',
    year_7: 'R142,310 '
  },
  {
    product: 'Medium + 2 panels',
    standard_Once_Off_Installation_Fee: 'R1,790 ',
    monthly_Fee: 'R1,790 ',
    flat_Roof_Fee: 'R3 940',
    de_installation_fee_first_3_Years: 'R20 700',
    de_installation_fee_then: 'R10 350',
    year_3: 'R171,594 ',
    year_4: 'R167,018 ',
    year_5: 'R162,711 ',
    year_6: 'R157,731 ',
    year_7: 'R152,482 '
  },
  {
    product: 'Medium + battery',
    standard_Once_Off_Installation_Fee: 'R2,210 ',
    monthly_Fee: 'R2,210 ',
    flat_Roof_Fee: 'R3 940',
    de_installation_fee_first_3_Years: 'R20 700',
    de_installation_fee_then: 'R10 350',
    year_3: 'R198,785 ',
    year_4: 'R191,820 ',
    year_5: 'R184,992 ',
    year_6: 'R177,189 ',
    year_7: 'R168,828 '
  },
  {
    product: 'Medium + 2 panels + battery',
    standard_Once_Off_Installation_Fee: 'R2,420 ',
    monthly_Fee: 'R2,420 ',
    flat_Roof_Fee: 'R3 940',
    de_installation_fee_first_3_Years: 'R20 700',
    de_installation_fee_then: 'R10 350',
    year_3: 'R208,915 ',
    year_4: 'R201,929 ',
    year_5: 'R195,122 ',
    year_6: 'R187,329 ',
    year_7: 'R179,000 '
  },
  {
    product: 'Large',
    standard_Once_Off_Installation_Fee: 'R2,635 ',
    monthly_Fee: 'R2,635 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R26 450',
    de_installation_fee_then: 'R13 225',
    year_3: 'R269,595 ',
    year_4: 'R261,209 ',
    year_5: 'R253,119 ',
    year_6: 'R243,833 ',
    year_7: 'R233,946 '
  },
  {
    product: 'Large + battery',
    standard_Once_Off_Installation_Fee: 'R3,265 ',
    monthly_Fee: 'R3,265 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R26 450',
    de_installation_fee_then: 'R13 225',
    year_3: 'R306,916',
    year_4: 'R296,119',
    year_5: 'R285,530 ',
    year_6: 'R273,431 ',
    year_7: 'R260,464 '
  },
  {
    product: 'Large + 2 panels',
    standard_Once_Off_Installation_Fee: 'R2,845 ',
    monthly_Fee: 'R2,845 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R26 450',
    de_installation_fee_then: 'R13 225',
    year_3: 'R279,725',
    year_4: 'R271,318',
    year_5: 'R263,250 ',
    year_6: 'R253,973 ',
    year_7: 'R244,118 '
  },
  {
    product: 'Large + 2 panels + battery',
    standard_Once_Off_Installation_Fee: 'R3,475 ',
    monthly_Fee: 'R3,475 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R26 450',
    de_installation_fee_then: 'R13 225',
    year_3: 'R317,046',
    year_4: 'R306,229',
    year_5: 'R295,660 ',
    year_6: 'R283,572 ',
    year_7: 'R270,636 '
  },
  {
    product: 'Large 3phase',
    standard_Once_Off_Installation_Fee: 'R3,265 ',
    monthly_Fee: 'R3,265 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R29 900',
    de_installation_fee_then: 'R14 950',
    year_3: 'R310,591',
    year_4: 'R300,810',
    year_5: 'R291,358 ',
    year_6: 'R280,512 ',
    year_7: 'R268,958 '
  },
  {
    product: 'Large 3phase + battery',
    standard_Once_Off_Installation_Fee: 'R3,895 ',
    monthly_Fee: 'R3,895 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R29 900',
    de_installation_fee_then: 'R14 950',
    year_3: 'R347,912',
    year_4: 'R335,720',
    year_5: 'R323,769 ',
    year_6: 'R310,111 ',
    year_7: 'R295,476 '
  },
  {
    product: 'Large 3phase + 2 panels',
    standard_Once_Off_Installation_Fee: 'R3,475 ',
    monthly_Fee: 'R3,475 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R29 900',
    de_installation_fee_then: 'R14 950',
    year_3: 'R320,721',
    year_4: 'R310,919',
    year_5: 'R301,488 ',
    year_6: 'R290,653 ',
    year_7: 'R279,130 '
  },
  {
    product: 'Large 3phase + 2 panels + battery',
    standard_Once_Off_Installation_Fee: 'R4,105 ',
    monthly_Fee: 'R4,105 ',
    flat_Roof_Fee: 'R6,670 ',
    de_installation_fee_first_3_Years: 'R29 900',
    de_installation_fee_then: 'R14 950',
    year_3: 'R358,043',
    year_4: 'R345,830',
    year_5: 'R333,899 ',
    year_6: 'R320,251 ',
    year_7: 'R305,648 '
  },
  {
    product: 'Extra large',
    standard_Once_Off_Installation_Fee: 'R3,999',
    monthly_Fee: 'R3,999 ',
    flat_Roof_Fee: 'R7 575',
    de_installation_fee_first_3_Years: 'R32 200',
    de_installation_fee_then: 'R16 100',
    year_3: 'R377,078 ',
    year_4: 'R364,826 ',
    year_5: 'R352,934 ',
    year_6: 'R339,307 ',
    year_7: 'R324,762 '
  },
  {
    product: 'Extra Large + battery',
    standard_Once_Off_Installation_Fee: 'R4,629 ',
    monthly_Fee: 'R4,629',
    flat_Roof_Fee: 'R7 575',
    de_installation_fee_first_3_Years: 'R32 200',
    de_installation_fee_then: 'R16 100',
    year_3: 'R414,400 ',
    year_4: 'R399,737 ',
    year_5: 'R385,345 ',
    year_6: 'R368,905 ',
    year_7: 'R351,280 '
  },
  {
    product: 'Extra Large + 2 panels',
    standard_Once_Off_Installation_Fee: 'R4,209 ',
    monthly_Fee: 'R4,209',
    flat_Roof_Fee: 'R7,575 ',
    de_installation_fee_first_3_Years: 'R32 200',
    de_installation_fee_then: 'R16 100',
    year_3: 'R387,208 ',
    year_4: 'R374,935 ',
    year_5: 'R363,065 ',
    year_6: 'R349,447 ',
    year_7: 'R334,934 '
  },
  {
    product: 'Extra Large + 2 panels + battery',
    standard_Once_Off_Installation_Fee: 'R4,839 ',
    monthly_Fee: 'R4,839',
    flat_Roof_Fee: 'R7,575 ',
    de_installation_fee_first_3_Years: 'R32 200',
    de_installation_fee_then: 'R16 100',
    year_3: 'R424,530 ',
    year_4: 'R409,846 ',
    year_5: 'R395,475 ',
    year_6: 'R379,046 ',
    year_7: 'R361,452 '
  },
  {
    product: 'Extra battery',
    standard_Once_Off_Installation_Fee: 'R690 ',
    monthly_Fee: '',
    flat_Roof_Fee: '',
    de_installation_fee_first_3_Years: '',
    de_installat: '',
    year_6: '',
    year_7: ''
  },
  {
    product: 'Extra 2 Panels',
    standard_Once_Off_Installation_Fee: 'R230 ',
    monthly_Fee: '',
    flat_Roof_Fee: '',
    de_installation_fee_first_3_Years: '',
    de_installat: '',
    year_6: '',
    year_7: ''
  }
]

export type UpgradeFeesType = {
  from: string
  upgradeTo: string
  flatRoofFee: string
  battery: string
  panels: string
  upgrade: string
  upgrade_plus_battery: string
  upgrade_plus_panels: string
  upgrade_plus_battery_plus_panels: string
  upgrade_plus_battery_plus_four_panels: string
}

export type UpgradeFeesObjectType = {
  smallToMedium?: UpgradeFeesType
  meduimToLarge?: UpgradeFeesType
  largeThreePhaseToExtraLarge?: UpgradeFeesType
}

export const upgradeCosts: UpgradeFeesObjectType = {
  smallToMedium: {
    from: 'small',
    upgradeTo: 'medium',
    flatRoofFee: 'R4340',
    battery: 'R690',
    panels: 'R230',
    upgrade: 'R1,740',
    upgrade_plus_battery: 'R2,430',
    upgrade_plus_panels: 'R1,970',
    upgrade_plus_battery_plus_panels: 'R2,660',
    upgrade_plus_battery_plus_four_panels: ''
  },
  meduimToLarge: {
    from: 'medium',
    upgradeTo: 'large',
    flatRoofFee: 'R7,340',
    battery: 'R690',
    panels: 'R230',
    upgrade: 'R2,900',
    upgrade_plus_battery: 'R3,590',
    upgrade_plus_panels: 'R3,130',
    upgrade_plus_battery_plus_panels: 'R3,820',
    upgrade_plus_battery_plus_four_panels: 'R4,050'
  },
  largeThreePhaseToExtraLarge: {
    from: 'large 3-phase',
    upgradeTo: 'extra-large',
    flatRoofFee: 'R8,335',
    battery: 'R690',
    panels: 'R230',
    upgrade: 'R4,400',
    upgrade_plus_battery: 'R5,090',
    upgrade_plus_panels: 'R4,630',
    upgrade_plus_battery_plus_panels: 'R5,320',
    upgrade_plus_battery_plus_four_panels: ''
  }
}
