import { Link as RouterLink } from 'react-router-dom'
import { Flex, IconButton, Avatar, Box, Link, Image } from '@chakra-ui/react'
import { useEffect, useState, useContext } from 'react'
import { SideBarContext, Expose } from '../../context/SideBarContext'
import { AiOutlineMenuFold, AiOutlineMenuUnfold } from 'react-icons/ai'

const SideBarHeader = (): JSX.Element => {
  const { sideBarIsOpen, handleToggleSidebar } = useContext<Expose>(SideBarContext)

  const [display, setDisplay] = useState<Boolean | undefined>(sideBarIsOpen)
  useEffect(() => {
    if (!sideBarIsOpen) {
      setTimeout(() => {
        setDisplay(sideBarIsOpen)
      }, 150)
    } else {
      setDisplay(sideBarIsOpen)
    }
  }, [sideBarIsOpen])

  return (
    <Flex
      w="100%"
      h="50px"
      justifyContent={sideBarIsOpen ? 'space-between' : 'center'}
      alignItems={'center'}
    >
      <Link
        as={RouterLink}
        to={'dashboard'}
        color="white"
        transition={'all 0.2s ease-in-out'}
        _active={{ color: '#FFD015', transform: 'scale(0.95)' }}
      >
        <Image
          // as={'button'}
          h={'auto'}
          w="50px"
          // color={'black'}
          // name="Go Solr"
          src="/images/gosolr-logo-new.svg"
          alt="GoSolr logo"
          transition={'all 0.2s ease-in-out'}
          display={display ? 'flex' : 'none'}
          position={display ? 'relative' : 'absolute'}
          transform={sideBarIsOpen ? 'scale(1)' : 'scale(0)'}
          transformOrigin={'left'}
        />
      </Link>
      <Box as="button" type="button" aria-label="Sidebar toggle" onClick={handleToggleSidebar}>
        {sideBarIsOpen ? (
          <AiOutlineMenuFold color="white" size={'25px'} />
        ) : (
          <AiOutlineMenuUnfold color="white" size={'25px'} />
        )}
      </Box>
    </Flex>
  )
}

export default SideBarHeader
