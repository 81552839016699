import React, { useContext, useEffect, useState } from 'react'
import { Flex, Button, Icon } from '@chakra-ui/react'

import { FiHome, FiLogOut } from 'react-icons/fi'
import SideBarLink, { sideBarMainRoutes, sideBarMiscRoutes } from './SideBarLink'
import { SideBarContext, Expose } from '../../context/SideBarContext'
import SideBarHeader from './SideBarHeader'
import { useNavigate, NavigateFunction, NavLink } from 'react-router-dom'
import { useAuthContext } from '../../context/AuthProvider'

type SidebarType = {
  busyOnboarding?: boolean
}
const SideBar: React.FC<SidebarType> = ({ busyOnboarding }) => {
  const { sideBarIsOpen, handleToggleSidebar, sbOpenWidth, sbclosedWidth } =
    useContext<Expose>(SideBarContext)
  const { logout, user } = useAuthContext()

  const [userPrortyNumber, setUserPropertyNumber] = useState<string | number | undefined | null>()

  useEffect(() => {
    if (user?.info) {
      // @ts-ignore
      if (user?.info?.properties[0] !== null && user?.info?.properties[0] !== undefined)
        setUserPropertyNumber(user?.info?.properties[0].orderNumber)
    }
  }, [user?.info])

  const navigate: NavigateFunction = useNavigate()

  const handleLogOut = async () => {
    logout && (await logout())
    navigate('/login')
  }

  return (
    <Flex
      pos="fixed"
      top="0"
      left="0"
      as="nav"
      direction={'column'}
      py={'40px'}
      px={sideBarIsOpen ? '40px' : '5px'}
      // w='230px'
      w={sideBarIsOpen ? (sbOpenWidth as string) : (sbclosedWidth as string)}
      alignItems={sideBarIsOpen ? 'left' : 'center'}
      height={'100vh'}
      bg={'#171717'}
      overflow="hidden"
      gap={'50px'}
      transition={'all 0.3s ease-in-out'}
      zIndex={2}
      shadow={sideBarIsOpen ? 'lg' : 'xs'}
      userSelect="none"
    >
      <SideBarHeader />
      <Flex direction={'column'} flex={1} alignItems="left" justifyContent="start" gap={'45px'}>
        {!busyOnboarding &&
          sideBarMainRoutes.map((item, index) => (
            <SideBarLink
              key={`${item.path}-${index}`}
              title={sideBarIsOpen ? item.title : ''}
              path={item.path}
              icon={item.icon}
            />
          ))}
      </Flex>
      <Flex
        direction={'column'}
        justifySelf="flex-end"
        alignItems="left"
        justifyContent="start"
        gap={'45px'}
      >
        {!busyOnboarding &&
          sideBarMiscRoutes.map((item, index) => (
            <SideBarLink
              key={`${item.path}-${index}`}
              title={sideBarIsOpen ? item.title : ''}
              path={item.path}
              icon={item.icon}
            />
          ))}
      </Flex>
      <Flex w={'100%'} justifyContent={sideBarIsOpen ? 'flex-start' : 'center'} mb="50px">
        <Button
          onClick={(e) => handleLogOut()}
          aria-label="log out button"
          leftIcon={<FiLogOut />}
          variant={'unstyled'}
          color={'white'}
          fontWeight={300}
          _active={{
            transform: 'scale(0.95)'
          }}
          transition="all 0.2s ease-in-out"
        >
          {sideBarIsOpen && 'Log Out'}
        </Button>
      </Flex>
    </Flex>
  )
}

export default SideBar
