import {
  Box,
  Button,
  Center,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  Highlight,
  Spinner,
  Text
} from '@chakra-ui/react'
import React, { FC, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { Subscription, useGetUserQuery, User, UserProperty } from '../../../../generated/graphql'
import { AgreementDataType, UserStorage } from '../../../../types'
import { ContainerHeading } from '../../../Headings'

type AgreementInfoType = {
  user?: UserStorage
  recordId: string
  inputValue: InputValue
  setInputValue: (vale: any) => void
  subscriptionAgreementData: AgreementDataType
}
interface InputValue {
  [key: string]: Object | Object[]
}

const AgreementInfo: FC<AgreementInfoType> = ({
  user,
  recordId,
  inputValue,
  setInputValue,
  subscriptionAgreementData
}) => {
  const location = useLocation()
  const [isLoadingSubscriptions, setLoadingSubscriptions] = useState<boolean>(true)
  const [property, setProperty] = useState<UserProperty | undefined>()
  const [subscriptionFee, setSubscriptionFee] = useState<number | undefined | null>()
  const [debitOrderDate, setDebitOrderDate] = useState<string | undefined | null>()
  const [onceOffFee, setOnceOffFee] = useState<number | undefined | null>()
  const [subscription, setSubscription] = useState<Subscription | undefined | null>()
  const [recentUserData, setRecentUserData] = useState<User>()
  const [escationYear, setEscalationYear] = useState(() => {
    return 2024
  })
  const {
    data,
    loading: userDataLoading,
    refetch: getUserData
  } = useGetUserQuery({
    variables: {
      email: user?.info?.email as string
    }
  })

  useEffect(() => {
    const fetchUserData = async () => {
      const { data } = await getUserData()
      // console.log(data?.getUser)
      setRecentUserData(data?.getUser as User)
    }

    fetchUserData()
  }, [])

  useEffect(() => {
    const getProperty = async () => {
      if (recentUserData) {
        let userProperty = await (recentUserData?.properties as UserProperty[]).find(
          (p) => p.recordId === recordId
        )

        await setProperty(userProperty)
        await setSubscriptionFee(userProperty?.subscription?.subscriptionFee)
        await setDebitOrderDate(recentUserData.debitOrderDate)
        await setOnceOffFee(userProperty?.subscription?.onceOffFee)
        await setSubscription(userProperty?.subscription)

        setLoadingSubscriptions(false)
      }
    }

    getProperty()
  }, [user, recentUserData, subscription, location.state])

  if (userDataLoading || isLoadingSubscriptions) {
    return (
      <Center w="full" h={'150px'} bgColor={'brand.input'} rounded={'8px'}>
        <Flex gap="25px" alignItems={'center'} justifyContent="center">
          <Center>
            <Spinner size={'lg'}></Spinner>
          </Center>
          <Text>Generating your contract summary....</Text>
        </Flex>
      </Center>
    )
  }

  return (
    <>
      <Flex w="full" gap="25px" direction={'column'} color={'brand.text.primary'}>
        {/* Contract Summary */}
        <Flex
          width="full"
          justifyContent="space-between"
          alignItems={'start'}
          direction={'column'}
          gap={15}
          bgColor={'brand.neutral'}
          p={25}
          rounded={'8px'}
          h={'full'}
          shadow={'md'}
        >
          <ContainerHeading title={'Contract summary'} />
          <Text w={'100%'} textAlign={'center'} color={'brand.text.primary'}>
            This is our standard customer contract for your GoSolr{' '}
            {subscription?.productName?.replace('2023_', '')} subscription.
          </Text>
        </Flex>
        {/* Subscription and Installation comments*/}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Subscription'} />
              <Flex
                direction={'row'}
                w="full"
                gap="25px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontWeight={'bold'} fontSize={13}>
                  GoSolr {subscription?.productName?.replace('2023_', '')} subscription
                </Text>
                <Flex
                  direction={'column'}
                  gap="10px"
                  justifyContent={'center'}
                  alignItems={'center'}
                  w="full"
                  textAlign={'right'}
                >
                  <Text w={'full'} fontSize={13}>
                    {subscription?.batterySpec}{' '}
                  </Text>
                  <Text w={'full'} fontSize={13}>
                    1 x {subscription?.inverterSpec}{' '}
                  </Text>
                  <Text w={'full'} fontSize={13}>
                    {subscription?.panelCount} x {subscription?.panelSpec}
                  </Text>
                </Flex>
              </Flex>
              <IAcceptCheckbox
                name={'subscriptionBox'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Flex
              w="full"
              h={'full'}
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Administration fee'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Flex gap={'10px'} w="full" justifyContent={'space-between'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Once-off administration fee:
                  </Text>
                  <Text fontSize={13}>R{subscription?.onceOffFee} (including VAT)</Text>
                </Flex>
                <Flex gap={'10px'} w="full" justifyContent={'space-between'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Flat roof fee:
                  </Text>
                  <Text fontSize={13}>
                    R
                    {property?.isRoofFlat && property?.isRoofFlat.toLowerCase() === 'yes'
                      ? subscription?.flatRoofFee
                      : 0}{' '}
                    (including VAT)
                  </Text>
                </Flex>
                <Text fontSize={13} mt={25}>
                  Once-off payment via EFT
                </Text>
              </Flex>
              <IAcceptCheckbox
                name={'installationFee'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
        </Grid>
        {/* Monthly Fee and Escaltion */}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Monthly fee'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontWeight={'bold'} fontSize={13}>
                  R{subscriptionFee} (including VAT) per month via monthly debit order
                </Text>
                <Text fontSize={13} textAlign="center">
                  Payable by the Customer to the Seller in advance, for the duration of the Term
                  within 7 days of the date of invoice therefor.
                </Text>
                {/* <Text fontSize={13}>
                  Debit order date set to the <b>{debitOrderDate}</b> of each month{' '}
                </Text> */}
              </Flex>
              <IAcceptCheckbox
                name={'monthlyFee'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Annual escalation '} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
                textAlign="center"
              >
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  <b> The Fixed Monthly Fee will:</b>
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  - from July <b>{escationYear}</b>, escalate annually on the first day of July each
                  year (capped at CPI).
                </Text>

                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  The <b>De-Installation Fee</b> will not escalate, unless notified in writing by
                  the Seller to the Customer on no less than 30 days’ written notice, in line with
                  CPI.
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  <b>The Upgrade Fees will: </b>
                </Text>
                <Text fontSize={['11px', '12px', '12px', '12px']}>
                  - from July <b>{escationYear}</b>, escalate annually on the first day of July each
                  year (capped at CPI).
                </Text>
              </Flex>
              <IAcceptCheckbox
                name={'annualEscalation'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
        </Grid>
        {/* Fees and Termination*/}
        <Grid
          templateColumns={['repeat(1, 1fr)', 'repeat(1, 1fr)', 'repeat(2, 1fr)', 'repeat(2, 1fr)']}
          gap={'15px'}
        >
          <GridItem>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Other fees'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Upgrade fees:
                  </Text>
                  <Text fontSize={13}>
                    Additional upgrade fees as per the{' '}
                    <Button
                      variant={'unstyled'}
                      color="#3b82f6"
                      fontSize={13}
                      onClick={() =>
                        window.open(`/user/agreement/subscription-agreement/${recordId}`, '_blank')
                      }
                    >
                      Subscription Agreement
                    </Button>
                  </Text>
                </Flex>
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    De-installation fee:
                  </Text>
                  <Text fontSize={13}>
                    <b>R{subscriptionAgreementData.deInstallationFee}</b> (including VAT). The
                    De-Installation Fee is the amount payable by the Customer to the Seller for the
                    de-installation of the Solar System, following cancellation of this Agreement
                    and where the Customer has not elected to purchase the Solar System.
                  </Text>
                </Flex>
                <Flex gap={'10px'} direction={'column'} w="full" textAlign={'center'}>
                  <Text fontWeight={'bold'} fontSize={13}>
                    Call out fee:
                  </Text>
                  <Text fontSize={13} flex={1}>
                    The call out Fee is initially <b>R650</b> (including VAT) as at the Signature
                    Date and will be escalated or varied from time to time by the Seller and
                    communicated to the Customer by way of written notice.
                  </Text>
                </Flex>
              </Flex>
              <IAcceptCheckbox
                name={'otherFees'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
          <GridItem>
            <Flex
              h={'full'}
              w="full"
              gap="25px"
              direction={'column'}
              p={'25px'}
              rounded="8px"
              bgColor={'brand.neutral'}
              justifyContent={'space-between'}
              shadow={'md'}
            >
              <ContainerHeading title={'Termination'} />
              <Flex
                direction={'column'}
                w="full"
                gap="10px"
                justifyContent={'center'}
                alignItems={'center'}
              >
                <Text fontSize={13} textAlign={'center'}>
                  <Highlight query={'20 days'} styles={{ fontWeight: 'bold' }}>
                    The Customer is entitled to terminate this Agreement at any time on 20 days'
                    prior notice to the Seller.
                  </Highlight>
                </Text>
                <Text fontSize={13} textAlign={'center'}>
                  In the event of an early termination of this Agreement by the Customer, the
                  Customer is liable to pay to the Seller, within <b>10 days</b> of the Termination
                  Date, the De-installation Fee and any and all other amounts previously accrued
                  under this Agreement and then owed by the Customer to the Seller.
                </Text>
              </Flex>
              <IAcceptCheckbox
                name={'termination'}
                inputValue={inputValue}
                setInputValue={setInputValue}
              />
            </Flex>
          </GridItem>
        </Grid>
        {/* Insurance and technival agreement*/}

        <Flex
          h={'full'}
          w="full"
          gap="25px"
          direction={'column'}
          p={'25px'}
          rounded="8px"
          bgColor={'brand.neutral'}
          justifyContent={'space-between'}
          shadow={'md'}
        >
          <ContainerHeading title={'Insuring party'} />
          <Flex
            direction={'column'}
            w="full"
            gap="10px"
            justifyContent={'center'}
            alignItems={'center'}
          >
            <Text fontSize={13} textAlign={'center'}>
              <Highlight query={'3,6kW'} styles={{ fontWeight: 'bold' }}>
                The Customer, unless the Customer gives notice in writing to the Seller requesting
                that the Seller be the Insuring Party and the Seller expressly notifies the Customer
                that it elects to be the Insuring Party pursuant to such request.
              </Highlight>
            </Text>
          </Flex>
          <IAcceptCheckbox
            name={'insurance'}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </Flex>

        {subscription?.productName?.toLowerCase().includes('small') && (
          <Flex
            h={'full'}
            w="full"
            gap="25px"
            direction={'column'}
            p={'25px'}
            rounded="8px"
            bgColor={'brand.neutral'}
            justifyContent={'space-between'}
            shadow={'md'}
          >
            <ContainerHeading title={'Technical Acknowledgment'} />
            <Flex
              direction={'column'}
              w="full"
              gap="10px"
              justifyContent={'center'}
              alignItems={'center'}
            >
              <Text fontSize={13} textAlign={'center'}>
                <Highlight query={'3,6kW'} styles={{ fontWeight: 'bold' }}>
                  Should the grid be unavailable, the GoSolr system will be limited to the
                  throughput of the inverter. For the GoSolr Small, this will be limited to 3,6kW.
                  Should consumption in the home exceed this when the grid is unavailable, the
                  system will trip.
                </Highlight>
              </Text>
            </Flex>
            <IAcceptCheckbox
              name={'technicalAcknowledgment'}
              inputValue={inputValue}
              setInputValue={setInputValue}
            />
          </Flex>
        )}

        <Flex
          w="full"
          gap="25px"
          direction={'column'}
          p={'25px'}
          rounded="8px"
          bgColor={'brand.neutral'}
          shadow={'md'}
        >
          <ContainerHeading title={'Purchase of the system'} />
          <Flex
            direction={'column'}
            w="full"
            gap="10px"
            justifyContent={'center'}
            alignItems={'center'}
            textAlign="center"
          >
            <Text fontSize={13}>
              The Customer has the option at any time during the Term following the third
              anniversary of the Signature Date to purchase the Solar System (and all its component
              parts, specifically excluding the Solar Software) from the Seller ("Purchase Option").
              The option may be exercised by the Customer at any time during the Term by the
              Customer giving written notice thereof to the Seller. If the Purchase Option is
              exercised, the purchase price of the Solar System shall be equivalent to the Purchase
              Price at the proposed date of purchase.
            </Text>
            <Text fontSize={13}>
              Purchase price is set on a sliding scale basis, outlined in the{' '}
              <Button
                variant={'unstyled'}
                color="#3b82f6"
                fontSize={13}
                onClick={() => window.open('/user/agreement/subscription-agreement', '_blank')}
              >
                Subscription Agreement
              </Button>
            </Text>
          </Flex>
          <IAcceptCheckbox
            name={'purchaseOfSystem'}
            inputValue={inputValue}
            setInputValue={setInputValue}
          />
        </Flex>
      </Flex>
    </>
  )
}

export default AgreementInfo

type AgreementItemType = {
  name: string
  inputValue: InputValue
  setInputValue: (vale: any) => void
}

export const IAcceptCheckbox: React.FC<AgreementItemType> = ({
  name,
  inputValue,
  setInputValue
}) => {
  return (
    <Flex direction={'column'} gap={'25px'}>
      <Box w="full" bgColor={'brand.input'} height="1px"></Box>
      <Flex flex={1} justifyContent={'flex-end'}>
        <Checkbox
          alignSelf={'flex-end'}
          justifySelf={'flex-end'}
          borderRadius="2px"
          borderColor="brand.500"
          size={'lg'}
          colorScheme="green"
          id={name}
          isInvalid={!inputValue[name]}
          onChange={(value) => {
            setInputValue((prevState: any) => ({
              ...prevState,
              [name]: !inputValue[name]
            }))
          }}
        >
          I accept
        </Checkbox>
      </Flex>
    </Flex>
  )
}
